import {httpCommunicator} from "@api/httpCommunicator";

const url = process.env.REACT_APP_API_URL;

export class NewsroomService {
  static async getNoticeList(page: number, limit: number, guestSessionId?: number) {
    const header = {guest_session_id: guestSessionId};
    const res = await httpCommunicator(
      `${url}/v1/op/notice/list?page=${page}&limit=${limit}`,
      "get",
      {},
      header
    );
    return res.data.data;
  }

  static async getOneNoticeData(id: any, guestSessionId?: number) {
    const header = {guest_session_id: guestSessionId};
    const res = await httpCommunicator(
      `${url}/v1/op/notice/get_one?id=${id}`,
      "get",
      {},
      header
    );
    return res.data.data;
  }

  static async getNewsLetterList(page: number, limit: number, guestSessionId?: number) {
    const header = {guest_session_id: guestSessionId};
    const res = await httpCommunicator(
      `${url}/v1/news_letter/list?page=${page}&limit=${limit}`,
      "get",
      {},
      header
    );
    return res.data.data;
  }

  static async getOneNewsLetterData(id: any, guestSessionId?: number) {
    const header = {guest_session_id: guestSessionId};
    const res = await httpCommunicator(
      `${url}/v1/news_letter/get_one?id=${id}`,
      "get",
      {},
      header
    );
    return res.data.data;
  }

  static async newsletterSubscriptionEmail(email: string, guestSessionId?: number) {
    const header = {guest_session_id: guestSessionId};
    const res = await httpCommunicator(`${url}/v1/news_letter/create`, "post", {
      email: email,
    }, header);
    return res.data;
  }
}

import { BrowserRouter } from 'react-router-dom';
import AppRoute from '@routes/Route';
import ScrollToTop from '@components/scroll/ScrollToTop';
import { useGetGuestIdHelper } from '@hooks/useGetGuestID';
import '@styles/main.scss';
// @ts-ignore
/* import SystemCheck from '@components/system/SystemCheck'; */

function App() {
  const { guestIdState } = useGetGuestIdHelper();

  return (
    <BrowserRouter>
      {/* <SystemCheck /> */}
      <ScrollToTop />
      <AppRoute />
    </BrowserRouter>
  );
}

export default App;

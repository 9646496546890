import { SwiperInterface } from '@interfaces/components/SwiperInterface';
import './productItem.scss';

export default function ProductItemArt({
  item,
  className,
  onClick,
  alt,
}: {
  item: any /* SwiperInterface.SwiperItemInterface; */;
  className?: string;
  onClick: (idx: number) => void;
  alt: string;
}) {
  return (
    <div className={`product-wrap ${className}`}>
      <div className='product-img' onClick={(idx: any) => onClick(idx)}>
        <img
          src={item}
          alt={alt}
          style={{
            width: '100%',
          }}
        />
      </div>
    </div>
  );
}

import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { Trans, useTranslation } from 'react-i18next';
import { useRecoilState } from 'recoil';
import MoreWhiteBtn from '@components/btns/MoreWhiteBtn';
import { getArticleNotice } from '@services/NoticeService';
import { NoticeInterface } from '@interfaces/notice/NoticeInterface';
import changeDate from 'helpers/changeDate';
import { guestId } from '@stores/guest';
import './mainNewsRoom.scss';

export default function MainNewsRoom() {
  const { t } = useTranslation();
  const [guestIdState, setGuestIdState] = useRecoilState(guestId);
  const navigate = useNavigate();

  const handleClickMore = (link: string) => {
    (window as any).gaWrite('web_click_main_newsroom_more', 'home', '');
    navigate(link);
  };

  const [noticeData, setNoticeData] = useState([]);

  const getArticleNoticeLists = async () => {
    try {
      const data = await getArticleNotice(1, 4, guestIdState);

      setNoticeData(data.data.list);
    } catch (err) {
      console.log(err);
    }
  };

  useEffect(() => {
    getArticleNoticeLists();
  }, []);

  return (
    <div className='main-content-wrap main-news-wrap'>
      <div className='main-content-inner'>
        <div className='main-content-title-wrap'>
          <div className='main-content-title about-title'>
            <p
              className='main-content-title-sub'
              data-aos='fade-zoom-in'
              data-aos-easing='linear'
              data-aos-delay='100'
              data-aos-offset='0'
            >
              GalleryST Newsroom
            </p>
            <h3 className='main-content-title-big maruBuri'>
              {t('mainNewsTitle')}
            </h3>
          </div>

          <div className='main-content'>
            <div
              className='main-content-text'
              data-aos='zoom-in'
              data-aos-duration='1500'
            >
              <Trans i18nKey={`mainNewsText`} components={[<span />]} />
            </div>
          </div>

          <div className='main-content-newsRoom-btn'>
            <MoreWhiteBtn
              className='main-content-more-btn'
              text='View more'
              onClick={() => handleClickMore(`/newsroom/notice`)}
            />
          </div>
        </div>
        <ul className='main-news-notice'>
          {noticeData
            ?.slice(0, 4)
            .map((item: NoticeInterface.Item, i: number) => (
              <li
                className='main-news-notice-list'
                key={item.id}
                onClick={() =>
                  handleClickMore(`/newsroom/notice/detail/${item.id}`)
                }
              >
                {/* 추후 다시 살리기 mark 현재 api에 없어서 주석처리해둠 */}
                {/*  {item.mark ? (
                <span className='main-news-notice-list-mark maruBuri fw6Bold'>
                  {item.mark}
                </span>
              ) : null} */}
                <div className='main-news-notice-list-text-wrap'>
                  <p className='main-news-notice-list-title maruBuri'>
                    {item.title}
                  </p>
                  <p className='main-news-notice-list-time maruBuri'>
                    {changeDate(item.created_at)}
                  </p>
                </div>
              </li>
            ))}
        </ul>
      </div>
    </div>
  );
}

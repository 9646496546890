import Lottie from 'lottie-react';
import loadingIcon from '@assets/lottie/animation_loading_row.json';
import './loading.scss';

export interface LoadingModalInterface {
  Loading: boolean;
}

export default function LoadingRowBgBlack({ Loading }: LoadingModalInterface) {
  return (
    <div
      className={Loading ? 'view-loading-modal' : 'hidden-loading-modal'}
      style={{
        background: 'rgba(0,0,0)',
        width: '100%',
        height: '100%',
      }}
    >
      <div className='loading-box row-loading-box'>
        <Lottie animationData={loadingIcon} />
      </div>
    </div>
  );
}

import { useState } from 'react';
import { useRecoilState } from 'recoil';
import { Trans, useTranslation } from 'react-i18next';
import ClearIcon from '@mui/icons-material/Clear';
import CustomModal from '@components/modal/CustomModal';
import { AgreeService } from '@services/AgreeService';
import { guestId } from '@stores/guest';
import './orderAgree.scss';

export default function OrderAgree({
  isHidden,
  setIsHidden,
}: {
  isHidden: boolean;
  setIsHidden: React.Dispatch<React.SetStateAction<boolean>>;
}) {
  const { t } = useTranslation();
  const [guestIdState, setGuestIdState] = useRecoilState(guestId);
  const [siteChecked, setSiteChecked] = useState(false);
  const [personalChecked, setPersonalChecked] = useState(false);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [modalTitle, setModalTitle] = useState('');
  const [modalContent, setModalContent] = useState('');
  const onSiteChange = (e: boolean) => {
    setSiteChecked(e);
  };

  const onPersonalChange = (e: boolean) => {
    setPersonalChecked(e);
  };
  const goOrderPape = () => {
    setIsHidden(true);
  };

  const handleModal = async (e: number) => {
    const data = await AgreeService.getAgreeRule(guestIdState);
    setIsModalOpen(true);
    if (e === 1) {
      setModalTitle(t('orderAgreeModalTitle1'));
      setModalContent(data.terms_condition.value);
    } else {
      setModalTitle(t('orderAgreeModalTitle2'));
      setModalContent(data.privacy_policy.value);
    }
  };

  const closedModal = () => {
    setIsModalOpen(false);
  };
  return (
    <div
      className={isHidden ? 'orderAgree-wrap hidden' : 'orderAgree-wrap view'}
    >
      <div className='orderAgree-inner'>
        <div className='orderAgree-content check-box-content'>
          <div className='orderAgree-check-wrap maruBuri'>
            <div className='orderAgree-check-item'>
              <div className='orderAgree-check-item-input-wrap'>
                <input
                  type='checkbox'
                  id='site'
                  checked={siteChecked}
                  onChange={({ target: { checked } }) => {
                    (window as any).gaWrite(
                      'web_click_artinfo_order_term_check ',
                      'order',
                      ''
                    );
                    onSiteChange(checked);
                  }}
                />
                <label htmlFor='site'>{t('orderAgreeCheckText1')}</label>
              </div>
              <button
                className='sCoreDream'
                onClick={() => {
                  (window as any).gaWrite(
                    'web_click_artinfo_order_term_detail ',
                    'order',
                    ''
                  );
                  handleModal(1);
                }}
              >
                {t('orderAgreeBtnText1')}
              </button>
            </div>
            <div className='orderAgree-check-item'>
              <div className='orderAgree-check-item-input-wrap'>
                <input
                  type='checkbox'
                  id='personal'
                  checked={personalChecked}
                  onChange={({ target: { checked } }) => {
                    (window as any).gaWrite(
                      'web_click_artinfo_order_privacy_check ',
                      'order',
                      ''
                    );
                    onPersonalChange(checked);
                  }}
                />
                <label htmlFor='personal'>{t('orderAgreeCheckText2')}</label>
              </div>
              <button
                className='sCoreDream'
                onClick={() => {
                  (window as any).gaWrite(
                    'web_click_artinfo_order_privacy_detail ',
                    'order',
                    ''
                  );
                  handleModal(2);
                }}
              >
                {t('orderAgreeBtnText1')}
              </button>
            </div>
          </div>
        </div>
        <div className='orderAgree-content maruBuri'>
          <button
            disabled={!siteChecked || !personalChecked}
            onClick={() => {
              (window as any).gaWrite(
                'web_click_artinfo_order_step_02 ',
                'order',
                ''
              );

              goOrderPape();
            }}
            className={
              !siteChecked || !personalChecked
                ? 'disabled submit-btn'
                : 'active submit-btn'
            }
          >
            <p>{t('orderAgreeBtnText2')}</p>
            <div className='icon'></div>
          </button>
        </div>
        <CustomModal open={isModalOpen} onClose={closedModal}>
          <div className='orderAgree-modal-wrap'>
            <h3 className='modal-title maruBuri'>{modalTitle}</h3>
            <button className='modal-close-btn' onClick={closedModal}>
              <ClearIcon />
            </button>
            <div className='orderAgree-modal-text-box sCoreDream'>
              <div
                className='orderAgree-modal-text-box-inner'
                dangerouslySetInnerHTML={{ __html: modalContent }}
              ></div>
            </div>
          </div>
        </CustomModal>
      </div>
    </div>
  );
}

import "./qna.scss";
import { useState } from "react";
import { useLocation } from "react-router";
import { Trans, useTranslation } from "react-i18next";
import { InquiryService } from "./../../../../services/InquiryService";
import { Validations } from "helpers/validations";
import CommonModal from "@components/modal/CommonModal";
import { useRecoilState } from "recoil";
import { guestId } from "@stores/guest";

export default function Qna() {
  const { t } = useTranslation();
  const [guestIdState, setGuestIdState] = useRecoilState(guestId);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [alarmText, setAlarmText] = useState("");
  const { state } = useLocation();
  const selectOptionList = [
    {
      value: "1",
      option: t("serviceInquiryQNAOption1"),
    },
    {
      value: "2",
      option: t("serviceInquiryQNAOption2"),
    },
    {
      value: "3",
      option: t("serviceInquiryQNAOption3"),
    },
    {
      value: "4",
      option: t("serviceInquiryQNAOption4"),
    },
  ];
  const [inquiryValue, setInquiryValue] = useState({
    type: "",
    email: "",
    title: "",
    content: "",
    group: 0,
    art_info_id: Number(state),
  });
  if (window.localStorage.getItem("qnaType") == "buyType") {
    inquiryValue.type = "1";
    window.localStorage.removeItem("qnaType");
  }

  //글자 수 자르기
  const handleChangeLengthCut = (
    e: React.ChangeEvent<HTMLTextAreaElement>,
    maxLength: number
  ) => {
    if (maxLength < e.target.value.length) {
      e.target.value = e.target.value.slice(0, maxLength);
    }

    handleFromChange(e);
  };

  const handleFromChange = (e: any) => {
    const { name, value } = e.target;
    setInquiryValue({
      ...inquiryValue,
      [name]: value,
    });
  };

  const getFromDate = async () => {
    // console.log(regex.nonGap.test(inquiryValue.title));
    (window as any).gaWrite("web_click_service_Q&A_apply ", "service", "");
    if (inquiryValue.type === "") {
      setAlarmText(t("alertTxt2"));
      setIsModalOpen(true);
      return;
    }

    if (inquiryValue.email === "") {
      setAlarmText(t("alertTxt3"));
      setIsModalOpen(true);
      return;
    }
    if (!Validations.formatEmail(inquiryValue.email)) {
      setAlarmText(t("alarmTxt1"));
      setIsModalOpen(true);
      return;
    }
    if (inquiryValue.title === "" || inquiryValue.title.trim() === "") {
      setAlarmText(t("alertTxt7"));
      setIsModalOpen(true);
      return;
    }
    if (inquiryValue.content === "" || inquiryValue.content.trim() === "") {
      setAlarmText(t("alertTxt8"));
      setIsModalOpen(true);
      return;
    }
    if (inquiryValue.content.length > 1000) {
      setAlarmText(t("alertTxt9"));
      setIsModalOpen(true);
      return;
    }

    const res = await InquiryService.setQnaCreate(inquiryValue, guestIdState);
    if (res.code === 200) {
      setAlarmText(t("alertTxt1"));
      setIsModalOpen(true);
      setInquiryValue({
        type: "",
        email: "",
        title: "",
        content: "",
        group: 0,
        art_info_id: 0,
      });
    }
  };
  const doCloseModal = () => {
    setIsModalOpen(false);
    document.documentElement.style.overflow = "auto";
  };

  return (
    <div className="sub-page-contents-wrap service-inquiry-qna-wrap">
      <div className="service-inquiry-qna-inner">
        <h3 className="maruBuri">{t("serviceInquiryQNATitle")}</h3>
        <div className="form-wrap sCoreDream">
          <div className="required-guide-box SCoreDream">
            <small>{t("orderRequired")}</small>
          </div>
          <div className="form-item">
            <span>{t("serviceInquiryQNAInputText1")}</span>
            <select
              value={inquiryValue.type}
              onChange={handleFromChange}
              name="type"
            >
              <option value="">{t("serviceInquiryQNAOption")} </option>
              {/* 유형 선택 */}
              {selectOptionList.map((item, idx) => (
                <option value={item.value} key={idx}>
                  {item.option}
                </option>
              ))}
            </select>
          </div>
          <div className="form-item email-box">
            <span>{t("serviceInquiryQNAInputText2")}</span>
            <input
              type="text"
              onChange={handleFromChange}
              value={inquiryValue.email}
              maxLength={500}
              name="email"
              placeholder={t("placeholder1")}
              className="email-input"
            />
            {/* <small>{t("serviceInquiryQNASmallText")}</small> */}
          </div>
          <div className="form-item">
            <span>{t("serviceInquiryQNAInputText3")}</span>
            <input
              type="text"
              onChange={handleFromChange}
              value={inquiryValue.title}
              name="title"
              maxLength={60}
              placeholder={t("placeholder2")}
            />
          </div>
          <div className="form-item content-box">
            <span>{t("serviceInquiryQNAInputText4")}</span>
            <textarea
              onChange={(e) => handleChangeLengthCut(e, 1000)}
              value={inquiryValue.content}
              name="content"
              maxLength={1000}
              placeholder={`${t("placeholder3")} (${t(t("placeholder9"))})`}
            ></textarea>
          </div>
          <div className="form-item content-box length-item">
            <span></span>
            <p className="length-text">
              {String(inquiryValue.content).length}{" "}
              {t("serviceInquiryQNAContentLength")}
            </p>
          </div>
        </div>
        <div className="btn-wrap">
          <button className="maruBuri" onClick={getFromDate}>
            <p>{t("serviceInquiryQNABtnText")}</p>
            <div className="icon"></div>
          </button>
        </div>
      </div>
      <CommonModal
        open={isModalOpen}
        isShowBtn={false}
        btnCloseText="닫기"
        onClose={() => doCloseModal()}
        alarmText={alarmText}
      />
    </div>
  );
}

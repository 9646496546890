import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router';
import { useTranslation } from 'react-i18next';
import { useRecoilState } from 'recoil';
import PlayCircleFilledIcon from '@mui/icons-material/PlayCircleFilled';
import ArtMarketSearch from './ArtMarketSearch';
import { ArtSalesInterface } from '@interfaces/artSales/artMarket';
import { useForm } from '@hooks/useForm';
import Paging from '@components/pagination/Pagination';
import {
  getThemeLists,
  getColorLists,
  getRecommendTags,
  getArtMarketLists,
} from '@services/ArtMarketService';
import { guestId } from '@stores/guest';
import './artMarket.scss';

export default function ArtMarket() {
  const { t } = useTranslation();
  const [guestIdState, setGuestIdState] = useRecoilState(guestId);
  const [metaData, setMetaData] = useState({
    themes: [],
    colors: [],
    tags: [],
    sizes: [
      {
        id: 1,
        name: '~ 30cm',
        minSize: 0,
        maxSize: 30,
      },
      {
        id: 2,
        name: '31 ~ 60cm',
        minSize: 31,
        maxSize: 60,
      },
      {
        id: 3,
        name: '61 ~ 100cm',
        minSize: 61,
        maxSize: 100,
      },
      {
        id: 4,
        name: '101 ~ 150cm',
        minSize: 101,
        maxSize: 150,
      },
      {
        id: 5,
        name: '151cm ~',
        minSize: 151,
        maxSize: 0,
      },
    ],
    orders: [
      {
        id: 1,
        name: t('artSalesArtMarketSearchSort3'),
      },
      {
        id: 2,
        name: t('artSalesArtMarketSearchSort6'),
      },
    ],
  });

  //작품 상세>뒤로가기 시 현재 페이지 유지
  let currentPageLocal;
  currentPageLocal = localStorage.getItem('currentPage');
  if (currentPageLocal) {
    currentPageLocal = localStorage.getItem('currentPage');
  } else {
    currentPageLocal = 1;
  }

  const [currentPage, setCurrentPage] = useState(currentPageLocal);
  const [itemsPerPage, setItemsPerPage] = useState({
    value: window.innerWidth <= 768 ? 12 : 10,
  });
  const [count, setCount] = useState(0);
  const [artMarketData, setArtMarketData] = useState<any[]>([]);
  const [searchData, setSearchData] = useState<{
    [key: string]: string | number | number[] | boolean | any[];
    keyword: string;
    themes: number[];
    tags: number[];
    sizes: number[];
    colors: number[];
    mniPrice: number;
    maxPrice: number;
    isSoldOut: boolean;
    orders: any[];
  }>({
    keyword: '',
    themes: [],
    sizes: [],
    colors: [],
    tags: [],
    mniPrice: 0,
    maxPrice: 10000000,
    isSoldOut: false,
    orders: [
      { id: 1, value: 'DESC' },
      { id: 2, value: 'DESC' },
    ],
  });

  const [searchViewData, setSearchViewData] = useState<{
    [key: string]: string | number | number[] | boolean;
    keyword: string;
    tags: any[];
    themes: any[];
    sizes: any[];
    colors: any[];
  }>({
    keyword: '',
    tags: [],
    themes: [],
    sizes: [],
    colors: [],
  });
  const [isLoaded, setIsLoaded] = useState(true);
  const [isFind, setIsFind] = useState(true);
  const handleResize = () => {
    if (window.innerWidth <= 768) {
      setItemsPerPage({ value: 12 });
    } else {
      setItemsPerPage({ value: 10 });
    }
    setIsFind(true);
  };

  useEffect(() => {
    const inti = async () => {
      await getThemeData();
      await getColorData();
      await getRecommendedTagList();
      await setSearchViewData((p) => ({
        ...p,
        sizes: metaData.sizes.map((size: any) => ({
          ...size,
          isSelected: false,
        })),
      }));
      handleResize();
      window.addEventListener('resize', handleResize);
      await getArtMarketSearchData();
      setIsLoaded(false);
    };
    inti();
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  useEffect(() => {
    const currentPage = localStorage.getItem('currentPage');
    if (currentPage) {
      setCurrentPage(Number(currentPage));
    }
    getArtMarketSearchData();

    if (!isLoaded) {
      Object.keys(searchData).forEach((key) => {
        const obj = searchData[key];
        const viewObj = searchViewData[key];
        if (Array.isArray(obj) && Array.isArray(viewObj)) {
          viewObj.forEach((vo: any) => {
            const id = vo.id;
            if (obj.indexOf(id) != -1) {
              vo.isSelected = true;
            } else {
              vo.isSelected = false;
            }
          });
        } else {
          searchViewData.keyword = searchData.keyword;
        }
      });

      setSearchViewData({ ...searchViewData });
    }
  }, [searchData, itemsPerPage, currentPage]);

  const updateData = async (
    key: string,
    value: any,
    isFind: boolean = true,
    format?: boolean
  ) => {
    if (format) {
      setSearchData({
        keyword: '',
        themes: [],
        sizes: [],
        colors: [],
        tags: [],
        mniPrice: 0,
        maxPrice: 10000000,
        isSoldOut: false,
        orders: [],
      });

      return;
    }

    const newData = searchData[key];
    setIsFind(isFind);
    if (Array.isArray(newData)) {
      const index = newData.indexOf(value);
      if (index == -1) {
        newData.push(value);
      } else {
        newData.splice(index, 1);
      }
      setSearchData({ ...searchData, [key]: newData });
    } else {
      setSearchData({ ...searchData, [key]: value });
    }
  };

  const getThemeData = async () => {
    try {
      if (metaData.themes.length < 1) {
        const data = await getThemeLists(guestIdState);
        setMetaData((p) => ({ ...p, themes: data.data.list }));
        searchViewData.themes = data.data.list.map((m: any) => ({
          ...m,
          isSelected: false,
        }));
      }
    } catch (err) {
      console.log(err);
    }
  };

  const getColorData = async () => {
    try {
      if (metaData.colors.length < 1) {
        const data = await getColorLists(guestIdState);
        setMetaData((p) => ({ ...p, colors: data.data.list }));
        searchViewData.colors = data.data.list.map((m: any) => ({
          ...m,
          isSelected: false,
        }));
      }
    } catch (err) {
      console.log(err);
    }
  };

  const getRecommendedTagList = async () => {
    if (metaData.themes.length < 1) {
      const data = await getRecommendTags(guestIdState);
      if (metaData.tags.length < 1) {
        const data = await getRecommendTags(guestIdState);
        setMetaData((p) => ({ ...p, tags: data.data.list }));
        searchViewData.tags = data.data.list.map((m: any) => ({
          ...m,
          isSelected: false,
        }));
      }
    }
  };

  const [selectOptions, setSelectOptions] =
    useState<ArtSalesInterface.ArtMarketOptionsInterface>({
      /*  search: '', */
      tag: [], //추천태그:보류임
      theme: [],
      size: '',
      price: [0, 10000000],
      color: [],
      non_sold_out: false,
      order_item_name: '',
      order_item_value: '',
      is_process: true,
      recommend_tag: [],
      keyword: '',
    });

  const navigate = useNavigate();
  const handleClickLink = (link: string, item: any) => {
    navigate(link);
    (window as any).gaWrite(
      'web_click_artinfo_detail ',
      'home',
      item?.name + '/' + item?.artist_info?.name
    );
  };

  const handlePageChange = (pageNumber: number) => {
    localStorage.setItem('currentPage', String(pageNumber));
    setIsFind(true);
    setCurrentPage(pageNumber);
  };

  const getArtMarketSearchData = async () => {
    try {
      if (isFind) {
        const body = {
          art_name: searchData.keyword,
          art_theme_id: searchData.themes,
          art_color_id: searchData.colors,
          // min_size: searchData.minSize,
          min_size: 0,
          // max_size: searchData.maxSize,
          max_size: 0,
          // min_amount: searchData.mniPrice,
          min_amount: 0,
          // max_amount: searchData.maxPrice,
          max_amount: 0,
          recommend_tag_id: searchData.tags,
          is_sale_done: searchData.isSoldOut ? 0 : 1,
          page: currentPage,
          limit: itemsPerPage.value,
        };
        const data = await getArtMarketLists(body, guestIdState);
        const list = data.data.data.list;
        setArtMarketData([...list]);
        setCount(data.data.data.count);
        setIsFind(false);
      }
    } catch (err) {
      console.log(err);
    }
  };

  return (
    <div className='sub-page-contents-wrap artsales-art-market-wrap'>
      <div className='sub-page-contents-inner artsales-art-market-innter'>
        <div>
          {/*  검색필터 */}
          {/* <p> {t('galleryStSupportText1')}</p> */}
          {/* <ArtMarketSearch updateData={updateData} viewData={searchViewData} /> */}
          <div className='artsales-art-market-lists-wrap'>
            {/*<div className="artsales-art-market-lists-top">
                <div className='artsales-art-market-lists-total maruBuri'>
                {t('artSalesArtMarketListsTotal1')} {count}
                <span>{t('artSalesArtMarketListsTotal2')}</span>
              </div> */}
            {/* 총갯수 */}

            {/* <div className='artsales-art-market-lists-sort-wrap'>
                <p className='artsales-art-market-lists-sort-txt maruBuri'>
                  {t('artSalesArtMarketSearchSort')}
                </p>
                <div className="artsales-art-market-lists-sort-lists sCoreDream">
                  {metaData.orders.map((item, index) => (
                    <div
                      key={index}
                      className={`artsales-art-market-lists-sort-item  ${(() => {
                        const order = searchData.orders.find(
                          (f) => f.id == item.id
                        );
                        if (!order) {
                          return "";
                        } else if (order.value == "DESC") {
                          return "active down";
                        } else {
                          return "active up";
                        }
                      })()}`}
                      onClick={(e) => {
                        const order = searchData.orders.find(
                          (f) => f.id == item.id
                        );
                        if (!order) {
                          searchData.orders.push({
                            id: item.id,
                            name: item.name,
                            value: "DESC",
                          });
                        } else if (order.value == "DESC") {
                          order.value = "ASC";
                        } else {
                          order.value = "DESC";
                        }

                        setSearchData((p) => ({ ...p }));
                      }}
                    >
                      {item.name}
                    </div>
                  ))}
                </div>
              </div>
            </div> */}

            <div className='artsales-art-market-lists-bottom'>
              {artMarketData?.map((item: any, i: number) =>
                selectOptions.non_sold_out ? (
                  item?.sell_status === 1 ? (
                    <div
                      key={i}
                      className='artsales-art-market-lists-total-item-wrap'
                      onClick={() =>
                        handleClickLink(
                          `/art_sales/art_market/art/${item.id}`,
                          item
                        )
                      }
                    >
                      <div className='artsales-art-market-lists-total-item-img'>
                        <img src={item?.images?.m} alt={item?.name} />
                        {item?.type === 'video' ? (
                          <p className='art-market-video-icon'>
                            <PlayCircleFilledIcon />
                          </p>
                        ) : null}
                      </div>
                      <div className='artsales-art-market-lists-total-item-info-wrap'>
                        <p className='artsales-art-market-lists-total-item-title maruBuri-bold'>
                          {item?.name}
                        </p>
                        <p className='artsales-art-market-lists-total-item-artist sCoreDream'>
                          {item?.artist_info?.name}
                        </p>
                      </div>
                    </div>
                  ) : null
                ) : item?.sell_status === 3 &&
                  item?.is_display === 'N' &&
                  item?.detail_display === 'close' ? null : (
                  <div
                    key={i}
                    className='artsales-art-market-lists-total-item-wrap'
                    onClick={() =>
                      handleClickLink(
                        `/art_sales/art_market/art/${item.id}`,
                        item
                      )
                    }
                  >
                    <div className='artsales-art-market-lists-total-item-img'>
                      <img src={item?.images?.m} alt={item?.name} />
                      {item?.type === 'video' ? (
                        <p className='art-market-video-icon'>
                          <PlayCircleFilledIcon />
                        </p>
                      ) : null}
                      {item?.sell_status === 2 ? (
                        <p className='art-market-sold-out-text'>
                          <span>
                            <span>Sold Out</span>
                          </span>
                        </p>
                      ) : item?.sell_status === 3 ? (
                        <p className='art-market-sold-out-text'>
                          <span>
                            <span>{t('commonNotForSale')}</span>
                          </span>
                        </p>
                      ) : null}
                    </div>
                    <div className='artsales-art-market-lists-total-item-info-wrap'>
                      <p className='artsales-art-market-lists-total-item-title maruBuri-bold'>
                        {item?.name}
                      </p>
                      <p className='artsales-art-market-lists-total-item-artist sCoreDream'>
                        {item?.artist_info?.name}
                      </p>
                    </div>
                  </div>
                )
              )}
              <Paging
                currentPage={currentPage}
                totalPages={Math.ceil(count / itemsPerPage.value)}
                onPageChange={handlePageChange}
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

import { useEffect } from 'react';
import { ModalInterface } from '@interfaces/components/ModalInterface';
import Modal from '@mui/material/Modal';
import Paper from '@mui/material/Paper';
import './modal.scss';

export default function CustomModal({
  open,
  onClose,
  children,
  className,
}: ModalInterface.CustomModalInterface) {
  useEffect(() => {
    if (open) {
      document.documentElement.style.overflow = 'hidden';
    } else {
      document.documentElement.style.overflow = 'auto';
    }
  }, [open]);
  return (
    <div>
      <Modal open={open} onClose={onClose} className={className}>
        <Paper
          elevation={2}
          sx={{
            position: 'absolute',
            top: '50%',
            left: '50%',
            transform: 'translate(-50%, -50%)',
            width: 'auto',
            bgcolor: 'background.paper',
            boxShadow: 24,
            p: 4,
            padding: '4rem',
          }}
        >
          {children}
        </Paper>
      </Modal>
    </div>
  );
}

import React, { useEffect, useState } from 'react';
import { RadiosInterface } from '@interfaces/components/RadiosInterface';

export default function RadioClassic({
  optionsLists,
  onChange,
  values,
  setValues,
  helperText,
  setHelperText,
}: RadiosInterface.RadioClassicInterface) {
  const [checkedValues, setCheckedValues] = useState<Set<string>>(new Set());

  const handleChangeValue = (e: React.ChangeEvent<HTMLInputElement>) => {
    const newValue = e.target.value;
    const isChecked = e.target.checked;

    if (isChecked) {
      setCheckedValues(new Set(newValue));

      if (helperText && setHelperText) {
        setHelperText((helperText) => ({
          ...helperText,
          display_art_count: '',
        }));
      }
    } else {
      const newCheckedValues = new Set(checkedValues);

      newCheckedValues.delete(newValue);
      setCheckedValues(newCheckedValues);
    }
  };

  useEffect(() => {
    setValues(Number(...checkedValues));
  }, [checkedValues]);

  return (
    <div className='radioWrap'>
      {optionsLists?.map(
        (item: RadiosInterface.OptionItemInterface, i: number) => (
          <label className='labelInputCheckBox' key={item.value}>
            <input
              type='checkBox'
              value={item.value}
              name='classicCheckBox'
              checked={checkedValues.has(item.value)}
              onChange={(e) => handleChangeValue(e)}
            />
            <span>{item.value}</span>
          </label>
        )
      )}
    </div>
  );
}

import { httpCommunicator } from '@api/httpCommunicator';

const url = process.env.REACT_APP_API_URL;

export async function getArtMarketMainLists(
  values: any,
  guestSessionId?: number
) {
  const header = { guest_session_id: guestSessionId };
  return await httpCommunicator(
    `${url}/v1/art/random_list`,
    'get',
    {
      params: values,
    },
    header
  );
}

export async function getArtMarketLists(values: any, guestSessionId?: number) {
  const header = { guest_session_id: guestSessionId };
  return await httpCommunicator(
    `${url}/v1/art/list`,
    'get',
    {
      params: values,
    },
    header
  );
}

/* export async function getRecommendedTagLists(values: any) {
  const res = await httpCommunicator(`${url}/v1/art/list`, 'get', {
    params: values,
  });
  return res.data;
}
 */

export async function getThemeLists(guestSessionId?: number) {
  const header = { guest_session_id: guestSessionId };
  const res = await httpCommunicator(
    `${url}/v1/art/meta/theme/list`,
    'get',
    header
  );
  return res.data;
}

export async function getColorLists(guestSessionId?: number) {
  const header = { guest_session_id: guestSessionId };
  const res = await httpCommunicator(
    `${url}/v1/art/meta/color/list`,
    'get',
    header
  );
  return res.data;
}

export async function getArtGetOne(values: any, guestSessionId?: number) {
  const header = { guest_session_id: guestSessionId };
  const res = await httpCommunicator(
    `${url}/v1/art/get_one`,
    'get',
    {
      params: values,
    },
    header
  );
  return res.data;
}

export async function getRecommendTags(guestSessionId?: number) {
  const header = { guest_session_id: guestSessionId };
  const res = await httpCommunicator(
    `${url}/v1/art/meta/recommend/tags/list`,
    'get',
    {},
    header
  );
  return res.data;
}

export async function postCreateBasket(values: any, guestSessionId?: number) {
  const header = { guest_session_id: guestSessionId };
  const res = await httpCommunicator(
    `${url}/v1/art/basket/create`,
    'post',
    values,
    header
  );
  return res.data;
}

import { useEffect, useState } from 'react';
import { guestId, guestSession } from '@stores/guest';
import { useRecoilState } from 'recoil';
import { getGuestId, postCreateGuest } from '@services/GuestService';
import { isMobile, isBrowser, isAndroid, isIOS } from 'react-device-detect';
import axios from 'axios';

export function useGetGuestIdHelper() {
  const [guestSessionState, setGuestSessionState] =
    useRecoilState(guestSession);
  const [guestIdState, setGuestIdState] = useRecoilState(guestId);

  const [isMobileOrWeb, setIsMobileOrWeb] = useState('');
  const [isAosOrIos, setIsAosOrIos] = useState('');

  useEffect(() => {
    const agent = window.navigator.userAgent.toLowerCase();
    let browserName = '';
    let ip = '';

    if (isMobile) {
      setIsMobileOrWeb('mobile');
    } else if (isBrowser) {
      setIsMobileOrWeb('PC');
      setIsAosOrIos('WEB');
    }

    if (isAndroid) {
      setIsAosOrIos('AOS');
    } else if (isIOS) {
      setIsAosOrIos('IOS');
    }

    if (agent.indexOf('edge') > -1) {
      browserName = 'MS Edge';
    } else if (agent.indexOf('edg/') > -1) {
      browserName = 'Edge(chromium based)"';
    } else if (agent.indexOf('opr') > -1) {
      browserName = 'Opera';
    } else if (agent.indexOf('whale') > -1) {
      browserName = 'whale';
    } else if (agent.indexOf('chrome') > -1) {
      browserName = 'Chrome';
    } else if (agent.indexOf('trident') > -1) {
      browserName = 'Internet explorer 11';
    } else if (agent.indexOf('msie') > -1) {
      browserName = 'Internet explorer'; // 11미만 버전
    } else if (agent.indexOf('firefox') > -1) {
      browserName = 'Firefox';
    } else if (agent.indexOf('safari') > -1) {
      browserName = 'Safari';
    } else {
      browserName = 'other';
    }

    /* get ip */
    const ipUrl: string | undefined = process.env.REACT_APP_GEOLOCATION_API_URL;
    const ipKey: string | undefined =
      process.env.REACT_APP_GEOLOCATION_RESPONSE_KEY;

    if (!guestIdState || !guestIdState) {
      const createGuest = async () => {
        if (!ipUrl || !ipKey) {
          return;
        }

        await axios.get(ipUrl).then((res) => {
          ip = res.data[ipKey];
        });

        // 공사중 페이지는 외부 통신 하지 않도록 한다.
        if (
          window.location.pathname !== '/maintenance' &&
          isMobileOrWeb &&
          isAosOrIos
        ) {
          const body = {
            ip: ip,
            browser_name: browserName,
            device_name: isMobileOrWeb,
            os_name: isAosOrIos,
          };

          const data = await postCreateGuest(body);
          const idBody = {
            uuid: data.data.guest_session,
          };

          const idData = await getGuestId(idBody);

          setGuestSessionState(data.data.guest_session);
          return setGuestIdState(idData.data.id);
        }
      };

      createGuest().then();
    }

    return;
  }, [isMobileOrWeb, isAosOrIos, guestIdState]);

  return {
    guestIdState,
  };
}

import { useNavigate } from 'react-router-dom';
import { Trans, useTranslation } from 'react-i18next';
import MoreWhiteBtn from '@components/btns/MoreWhiteBtn';
// import communityImg from "@assets/images/main/main_community_img_1.png";
import './mainCommunity.scss';

export default function MainCommunity() {
  const { t } = useTranslation();
  const navigate = useNavigate();

  const handleClickMore = () => {
    (window as any).gaWrite("web_click_main_community_more","home","");
    navigate('/community/academy_info');
  };

  return (
    <div className='main-content-wrap main-community-wrap'>
      <div className='main-content-inner'>
        <div className='main-content-title about-title'>
          <p
            className='main-content-title-sub'
            data-aos='fade-zoom-in'
            data-aos-easing='linear'
            data-aos-delay='50'
            data-aos-offset='0'
          >
            Art class and Community
          </p>
          <h3 className='main-content-title-big maruBuri'>
            {t('mainCommunityTitle')}
          </h3>
        </div>

        <div className='main-content'>
          <div className='main-content-img'></div>
          <div className='main-content-text-box'>
            <div className='main-content-text'>
              <p data-aos='zoom-in-up' data-aos-duration='1000'>
                <Trans i18nKey={`mainCommunityText`} components={[<span />]} />
              </p>
              <MoreWhiteBtn
                className='main-content-more-btn'
                text='View more'
                onClick={handleClickMore}
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

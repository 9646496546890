import Lottie from 'lottie-react';
import loadingIcon from '@assets/lottie/animation_loading.json';
import './loading.scss';

export interface LoadingModalInterface {
  Loading: boolean;
}

export default function Loading({ Loading }: LoadingModalInterface) {
  return (
    <div className={Loading ? 'view-loading-modal' : 'hidden-loading-modal'}>
      <div className='loading-box'>
        <Lottie animationData={loadingIcon} />
      </div>
    </div>
  );
}

import "./artConsulting.scss";
import { useState } from "react";
import { Trans, useTranslation } from "react-i18next";
import ClickBtn from "@components/btns/ClickBtn";
import CommonModal from "@components/modal/CommonModal";

export default function ArtConsulting() {
  const { t } = useTranslation();
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [alarmText, setAlarmText] = useState("");
  const [alarmSubText, setAlarmSubText] = useState("");
  const [btnCloseText, setBtnCloseText] = useState("");
  const [isShowBtn, setIsShowBtn] = useState(false);

  const imgList = [
    "metaverse_img01",
    "metaverse_img02",
    "metaverse_img03",
    "metaverse_img04",
    "metaverse_img05",
    "metaverse_img06",
    "metaverse_img07",
  ];
  const doDownload = () => {
    setIsModalOpen(true);
    setAlarmText("commonModalPreparingTitle");
    setAlarmSubText("coming soon");
    setBtnCloseText(t("commonCloseText"));
    setIsShowBtn(false);
    document.documentElement.style.overflow = "hidden";
  };
  const doCloseModal = () => {
    setIsModalOpen(false);
    document.documentElement.style.overflow = "auto";
  };

  return (
    <div className="sub-page-contents-wrap service-artConsulting-wrap">
      <div className="sub-page-contents-inner">
        <div className="service-artConsulting-title-box maruBuri">
          <h3>
            <Trans
              i18nKey={`serviceArtConsultingTitle`}
              components={[<span />]}
            />
          </h3>
          <div className="artConsulting-btn-wrap">
            <ClickBtn
              text={t("serviceArtConsultingButtonText")}
              className="consultingBtn consultingHover"
              onClick={ () => {
                (window as any).gaWrite("web_click_service_art_consulting_download_service_proposal ","order_check","");
                doDownload();
              }}
            />
          </div>
        </div>
        {/* <div className="service-artConsulting-img-wrap">
          <ul className="service-artConsulting-img-inner">
            {imgList.map((img, idx) => (
              <li key={idx}>
                <img src={`/images/service/${img}.png`} alt="" />
              </li>
            ))}
          </ul>
        </div> */}
        <CommonModal
          open={isModalOpen}
          isShowBtn={isShowBtn}
          btnCloseText={btnCloseText}
          onClose={() => doCloseModal()}
          alarmText={alarmText}
          alarmSubText={alarmSubText}
        />
      </div>
    </div>
  );
}

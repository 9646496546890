import './orderCheck.scss';
import { useEffect, useState } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import OrderCheckSuccess from './OrderCheckSuccess';
import MoreWhiteBtn from './../../components/btns/MoreWhiteBtn';
import { getOrderLists } from '@services/OrderService';
import { getOrderInfo } from '../../services/ArtService';
import { useRecoilState } from 'recoil';
import { guestId } from '@stores/guest';

export default function OrderCheck() {
  const { t } = useTranslation();
  const [guestIdState, setGuestIdState] = useRecoilState(guestId);
  const [isOrderSuccess, setIsOrderSuccess] = useState(false);
  const [orderData, setOrderData] = useState<any[] | any>();
  const [orderDataItem, setOrderDataItem] = useState<any[] | any>();
  const [orderInfo, setOrderInfo] = useState({
    number: '',
    name: '',
    email: '',
  });
  const [isCancel, setIsCancel] = useState<boolean>();

  useEffect(() => {
    (async () => {
      //TODO: API 대기중
      // const data =  await getOrderInfo(orderInfo.number);
      // setIsCancel(data.shipping_status == 1 ? true : false)
    })();
  }, []);

  const handleOrderInfoChange = (e: any) => {
    const { name, value } = e.target;
    setOrderInfo({
      ...orderInfo,
      [name]: value,
    });
  };
  const handleOrderCheck = () => {
    try{
      (window as any).gaWrite("web_click_artinfo_order_detail ","order_check","");
    }catch(e){
    
    }
    getOrderData();
  };

  const getOrderData = async () => {
    try {
      const body = {
        order_number: orderInfo.number,
        name: orderInfo.name,
        email: orderInfo.email,
      };
      const data = await getOrderLists(body, guestIdState);

      if (data.code === 200) {
        setIsOrderSuccess(true);
        setOrderData(data.data); //all
        setOrderDataItem(data.data.item_list); //주문 작품[]
      } else {
        alert(t('alertTxt5'));
      }
    } catch (err) {
      alert(t('alertTxtErr'));
    }
  };

  return (
    <div className='sub-page-contents-wrap'>
      <div className='sub-page-contents-inner'>
        <div className='orderCheck-wrap'>
          <h3 className='maruBuri'>{t('orderCheckTitle')}</h3>
          <div
            className={`orderCheck-wrap-inner  ${
              isOrderSuccess === true ? 'none' : ''
            }`}
          >
            <div className='orderCheck-show'>
              <form className='orderCheck-input-wrap center sCoreDream'>
                <div className='orderCheck-input-inner'>
                  <div className='orderCheck-input-item'>
                    <span>{t('orderCheckInputTitle1')}</span>
                    <input
                      type='text'
                      name='number'
                      value={orderInfo.number}
                      onChange={handleOrderInfoChange}
                    />
                  </div>
                  <div className='orderCheck-input-item'>
                    <span>{t('orderCheckInputTitle2')}</span>
                    <input
                      type='text'
                      name='name'
                      value={orderInfo.name}
                      onChange={handleOrderInfoChange}
                    />
                  </div>
                  <div className='orderCheck-input-item'>
                    <span>{t('orderCheckInputTitle3')}</span>
                    <input
                      type='text'
                      name='email'
                      value={orderInfo.email}
                      onChange={handleOrderInfoChange}
                    />
                  </div>
                </div>
              </form>
            </div>
            <div className='orderCheck-btn-wrap center'>
              <MoreWhiteBtn
                className='orderCheck-btn white shadowNone white-border'
                text={t('orderSuccess6')}
                onClick={handleOrderCheck}
              />
            </div>
          </div>

          {orderData && (
            <OrderCheckSuccess
              className={isOrderSuccess === true ? 'block' : 'none'}
              orderData={orderData}
              orderDataItem={orderDataItem}
              update={getOrderData}
            />
          )}
        </div>
      </div>
    </div>
  );
}

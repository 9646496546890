import { Trans, useTranslation } from 'react-i18next';
import galleryLogo from '@assets/images/logo.png';
import './footerPolicy.scss';

export default function FooterPolicy() {
  const { t } = useTranslation();

  return (
    <div className='ft ft-policy' id='contactUs'>
      <div className='ft-inner'>
        <div className='ft-content'>
          <div className='ft-company'>
            <div className='ft-logo'>
              <img src={galleryLogo} alt='galleryST logo' />
            </div>
            <div className='ft-info'>
              <span className='ft-info-list'>
                <Trans i18nKey='footerOffice' components={[<span />]} />
              </span>
              <span className='ft-info-list list-contact-us'>
                <Trans i18nKey='footerContactUs' components={[<span />]} />
              </span>

              <div className='ft-info-list two'>
                <p>{t('footerRegistrationNumber')}</p>
                <p>{t('footerCeo')}</p>
              </div>
              <div className='copyright-wrap'>
                <span className='ft-info-list copyright pretendard'>
                  Copyright ⓒ 2014 Secret Town. ALL RIGHTS RESERVED.
                </span>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

import { useEffect, useState, useRef } from 'react';
import { useNavigate } from 'react-router';
import { Trans, useTranslation } from 'react-i18next';
import { useRecoilState } from 'recoil';
import ClearIcon from '@mui/icons-material/Clear';
import OrderLists from '@components/order/OrderLists';
import Loading from '@components/Loading/Loading';
import formatNumberCommas from 'helpers/formatNumberCommas';
import {
  getCartLists,
  postCartDelete,
  postOrderCreate,
} from '@services/OrderService';
import { guestId } from '@stores/guest';
import MoreWhiteBtn from '@components/btns/MoreWhiteBtn';
import plusImg from '@assets/images/compute/plus.png';
import totalImg from '@assets/images/compute/total.png';
import './cart.scss';

export default function Cart() {
  const { t } = useTranslation();

  const totalCheckBoxRef = useRef<HTMLInputElement>(null);

  const [guestIdState, setGuestIdState] = useRecoilState(guestId);

  const [listsItem, setListsItem] = useState<any[]>([]);
  const [items, setItems] = useState<any[]>([
    [
      {
        id: 0,
        art_info_id: 0,
        created_at: 0,
        images: {
          id: 0,
          art_info_id: 0,
          original: '',
          l: '',
          m: '',
          s: '',
        },
        artist_info: {
          id: 0,
          name: '',
          description: '',
          history: '',
          image_url: '',
          lifetime: '',
          status: 0,
        },
        gallery_info: {
          id: '',
          address: '',
          ceo_name: '',
          ceo_phone_number: '',
          created_at: 0,
          homepage_url: '',
          manager_email: '',
          manager_grade: '',
          manager_name: '',
          manager_phone_number: '',
          name: '',
          status: 0,
        },
        art_infos: {
          id: 0,
          artist_info_id: 0,
          gallery_info_id: 0,
          name: '',
          material: '',
          description: '',
          detail_url: '',
          size_width: 0,
          size_height: 0,
          price: 0,
          sell_status: 0,
          status: 0,
          created_at: 0,
        },
      },
    ],
    {
      total_price: '',
    },
  ]);

  const [itemsCheck, setItemsCheck] = useState<number[]>([]);
  const [totalCheck, setTotalCheck] = useState(false);

  //선택상품금액
  const [selectPrice, setSelectPrice] = useState<number>(0);

  //장바구니 삭제
  const handleItemsCheckBox = (checked: boolean, id: number, index: number) => {
    const findModel = listsItem[index];
    if (checked && findModel.art_infos.sell_status == 1) {
      setItemsCheck((prev) => [...prev, id]);
      const indexItem = listsItem[index].art_infos.price;
      setSelectPrice((prev: number) => prev + indexItem);
    } else {
      setItemsCheck(itemsCheck.filter((el) => el !== id));
      const indexItem = listsItem[index].art_infos.price;
      setSelectPrice((prev: number) => prev - indexItem);
    }
  };

  const handleTotalCheckBox = (checked: boolean) => {
    if (totalCheckBoxRef.current?.checked && listsItem) {
      const filter = listsItem?.filter((el) => el.art_infos.sell_status == 1);
      const idArray: any = filter.map((m) => m.id);
      setItemsCheck(idArray);
      setSelectPrice(
        filter.reduce((acc, item) => acc + item.art_infos.price, 0)
      );
      setTotalCheck(true);
      /*  } */
    } else {
      setItemsCheck([]);
      setSelectPrice(0);
      setTotalCheck(false);
    }
  };

  const navigate = useNavigate();
  const handleClickLink = (link: string) => {
    navigate(link);
  };

  const [loading, setLoading] = useState(false);

  const getCartListsData = async () => {
    try {
      const body = {
        guest_session_id: guestIdState,
      };
      const timer = setTimeout(() => setLoading(true), 2000);

      const data = await getCartLists(body, guestIdState);

      clearTimeout(timer);
      setLoading(false);

      setItems(data.data);
      setListsItem(data.data[0]);
      setItemsCheck(
        data.data[0]?.map((el: any) => {
          if (el.art_infos.sell_status == 1) return el.id;
        })
      );

      return;
    } catch (err) {
      return console.log(err);
    }
  };

  //삭제
  const handleCartDelete = async (itemId: number[]) => {
    itemId.forEach((id) => {
      const item = listsItem.find((i) => i.id == id);
      if (item) {
        (window as any).gaWrite(
          'web_click_baskets_art_item_delete',
          'baskets',
          item?.art_infos?.name + '/' + item?.artist_info?.name
        );
      }
    });

    try {
      const body = {
        id: itemId,
      };
      const data = await postCartDelete(body, guestIdState);
      if (data.code === 200) {
        await getCartListsData();

        let totalPrice = 0;
        const id = itemId[0];
        itemsCheck.splice(itemsCheck.indexOf(id), 1);
        setItemsCheck(itemsCheck.filter((el) => !itemId.includes(el)));
        itemsCheck.forEach((check) => {
          const item = listsItem.find((f) => f.id == check);
          if (!item) {
            //TODO: 에러
          }
          totalPrice += item.art_infos.price;
        });
        setSelectPrice(totalPrice);
      }

      return;
    } catch (err) {
      console.log(err);
    }
  };

  //선택 상품 주문하기 button
  const handleOrderItems = async () => {
    const selectItemLists: any[] | any = listsItem.filter((el) =>
      itemsCheck.includes(el.id)
    );
    if (selectItemLists.length > 0) {
      const selectItemListsId = selectItemLists?.map(
        (el: any) => el.art_info_id
      );

      navigate('/art_sales/art_market/order_sheet', {
        state: { id: selectItemListsId, items: selectItemLists, is_basket: 1 }, //is_basket:1 장바구니
      });
    } else {
      alert(`${t('cartText9')}`);
    }
  };
  //선택 작품 array
  const selectItemLists: any[] | any = listsItem.filter((el) =>
    itemsCheck.includes(el.id)
  );

  //전체 작품 주문하기 button
  const handleOrderTotal = async () => {
    const idArray: any = listsItem?.map((el) => el.id);
    setItemsCheck(idArray);
    setSelectPrice(
      listsItem.reduce((acc, item) => acc + item.art_infos.price, 0)
    );
    const selectItemListsId = listsItem?.map((el: any) => el.art_info_id);

    if (itemsCheck.length === listsItem.length) {
      navigate('/art_sales/art_market/order_sheet', {
        state: { id: selectItemListsId, items: listsItem, is_basket: 1 }, //is_basket:1 장바구니
      });
    }
  };

  const handleLinkArt = (artLinkId: number) => {
    navigate(`/art_sales/art_market/art/${artLinkId}`);
  };

  useEffect(() => {
    getCartListsData();
  }, []);

  useEffect(() => {
    let totalPrice = 0;
    itemsCheck.forEach((f) => {
      const model = listsItem.find((find) => find.id == f);
      if (model) {
        totalPrice += model.art_infos.price;
      }
    });
    setSelectPrice(totalPrice);
    if (listsItem.length === itemsCheck.length) {
      setTotalCheck(true);
    } else {
      setTotalCheck(false);
    }
  }, [itemsCheck, listsItem, selectPrice]);

  return (
    <div className='sub-page-contents-wrap cart-wrap'>
      <div className='sub-page-contents-inner inner-1400 '>
        <OrderLists />
        {guestIdState && listsItem.length > 0 ? (
          <div>
            <div className='cart-select-lists-total-count-delete sCoreDream'>
              {/* 선택한 작품 수 */}
              <div className='cart-select-lists-total-count'>
                <span>{t('cartText12')} : </span>
                <span className='cart-total-number'>
                  {selectItemLists.length} &#47; {listsItem.length}
                </span>
              </div>
              {/* 선택작품 삭제 */}
              <div
                className='cart-select-delete-btn delete-btn'
                onClick={() =>
                  handleCartDelete(
                    selectItemLists.map(
                      (deleteItem: any, i: number) => deleteItem.id
                    )
                  )
                }
              >
                {t('cartText13')}
              </div>
            </div>
            <table className='cart-lists maruBuri'>
              <thead className='cart-lists-title-wrap'>
                <tr>
                  <th scope='col' className='table-th-input-check'>
                    <input
                      type='checkbox'
                      ref={totalCheckBoxRef}
                      disabled={
                        listsItem.filter((f) => f.art_infos.sell_status == 1)
                          .length > 0
                          ? false
                          : true
                      }
                      name='checkTotal'
                      className='cart-checkbox cart-total-checkbox'
                      onChange={(e) => handleTotalCheckBox(e.target.checked)}
                      checked={totalCheck}
                    />
                  </th>
                  <th className='item-img table-th-img' scope='col'>
                    {t('ordertitle1')}
                  </th>
                  <th className='item-info' scope='col'>
                    {t('ordertitle2')}
                  </th>
                  <th className='item-amount' scope='col'>
                    {t('ordertitle3')}
                  </th>
                  <th className='item-sellPrice' scope='col'>
                    {t('ordertitle4')}
                  </th>
                  {/*  <th className='item-totalPrice' scope='col'>
                    {t('ordertitle5')}
                  </th> */}
                  <th className='table-th-delete'></th>
                </tr>
              </thead>
              <tbody className='table-tbody'>
                {listsItem?.map((item: any, i: number) => (
                  <tr key={i}>
                    <td align='center'>
                      <input
                        type='checkbox'
                        disabled={item.art_infos.sell_status !== 1}
                        name={`checkItem${item?.id}`}
                        className='cart-checkbox cart-item-checkbox'
                        onChange={(e) => {
                          handleItemsCheckBox(e.target.checked, item?.id, i);
                        }}
                        checked={itemsCheck.includes(item?.id) ? true : false}
                      />
                    </td>
                    <td align='center'>
                      <div
                        className='art-img'
                        onClick={() => {
                          handleLinkArt(item?.art_infos?.id);
                        }}
                      >
                        {item?.images?.s && (
                          <img
                            src={item?.images?.s}
                            alt={item?.art_infos?.name}
                          />
                        )}
                      </div>
                    </td>
                    <td align='left' className='table-art-info'>
                      {item?.art_infos?.name && (
                        <p className='table-art-name'>
                          {item?.art_infos?.name}
                        </p>
                      )}
                      {item?.artist_info?.name && (
                        <p className='table-art-artist'>
                          {item?.artist_info?.name}
                        </p>
                      )}
                      {item?.art_infos?.size_real && (
                        <p>{item?.art_infos?.size_real}</p>
                      )}
                    </td>
                    <td align='center'>1</td>
                    <td align='center' className='table-td-price'>
                      {item?.art_infos && item?.art_infos?.sell_status === 1
                        ? formatNumberCommas(item?.art_infos?.price) +
                          t('commonPriceText')
                        : item?.art_infos && item?.art_infos?.sell_status === 2
                        ? 'sold out'
                        : item?.art_infos && item?.art_infos?.sell_status === 3
                        ? t('commonNotForSale')
                        : null}
                    </td>
                    {/*  <td align='center'>
                      {item?.art_infos.price &&
                        `${formatNumberCommas(item?.art_infos.price)} ${t(
                          'commonPriceText'
                        )}`}
                    </td> */}
                    <td className='delete-btn-td sCoreDream'>
                      <div
                        className='delete-btn'
                        onClick={() => handleCartDelete([item?.id])}
                      >
                        <span className='delete-txt'>{t('commonDelete')}</span>
                        <span className='delete-icon'>
                          <ClearIcon />
                        </span>
                      </div>
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>

            <div className='total-price-wrap'>
              <div className='total-price-box select'>
                <p className='total-title sCoreDream'>{t('cartText4')}</p>
                <p className='total-txt'>
                  {formatNumberCommas(selectPrice)}
                  {t('commonPriceText')}
                </p>
              </div>

              <div className='total-price-img'>
                <img src={plusImg} alt='plus' />
              </div>

              <div className='total-price-box deliveryFee'>
                <p className='total-title sCoreDream'>{t('cartText5')}</p>
                <p className='total-txt'>0{t('commonPriceText')}</p>
              </div>

              <div className='total-price-img'>
                <img src={totalImg} alt='plus' />
              </div>

              <div className='total-price-box total'>
                <p className='total-title sCoreDream'>{t('cartText6')}</p>
                <p className='total-txt'>
                  {formatNumberCommas(selectPrice)}
                  {t('commonPriceText')}
                </p>
              </div>
            </div>

            <div className='total-btn-wrap'>
              <MoreWhiteBtn
                className='cart-two-btn white white-border shadowNone'
                text={t('cartText11')}
                onClick={handleOrderItems}
              />
              {/* <MoreWhiteBtn
                className='cart-two-btn blue shadowNone'
                text={t('cartText8')}
                onClick={handleOrderTotal}
              /> */}
            </div>
          </div>
        ) : (
          <div className='non-cart'>
            <p className='non-cart-big-txt maruBuri'>{t('cartText1')}</p>
            <p className='non-cart-small-txt sCoreDream'>{t('cartText2')}</p>
            <MoreWhiteBtn
              className='modal-two-btn white cart-shopping-btn sCoreDream'
              text={t('cartText3')}
              onClick={() => {
                (window as any).gaWrite(
                  'web_click_baskets_put_in ',
                  'baskets',
                  ''
                );
                handleClickLink('/art_sales/art_market');
              }}
            />
          </div>
        )}

        {loading && <Loading Loading={loading} />}
      </div>
    </div>
  );
}

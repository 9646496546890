import { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import { Trans, useTranslation } from 'react-i18next';
import classNames from 'classnames/bind';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import galleryLogo from '@assets/images/logo.png';
import insta from '@assets/images/footer/sns_1@2x.png';
import facebook from '@assets/images/footer/sns_2@2x.png';
import youtube from '@assets/images/footer/sns_3@2x.png';
import styles from './footer.module.scss';

const cx = classNames.bind(styles);

export default function Footer() {
  const { t } = useTranslation();
  const location = useLocation();

  const ftFamilyLists = [
    {
      name: 'SecretTown',
      url: 'https://www.secrettown.co.kr/',
    },
    {
      name: 'Coconutbox',
      url: 'http://www.coconutbox.co.kr/',
    },
    {
      name: 'Purple box',
      url: 'https://purpleboxmuseum.com/',
    },
    {
      name: 'NFTOPIA',
      url: 'https://nftopia.gallery/',
    },
    /*  {
      name: 'Metakingz',
      url: 'https://www.metakingz.io/',
    },
    {
      name: 'NFTopia',
      url: 'https://nftopia.gallery/',
    }, */
  ];

  const [isSelect, setIsSelect] = useState(false);
  function familyListOpen() {
    if (isSelect === false) {
      setIsSelect(true);
    } else {
      setIsSelect(false);
    }
  }

  useEffect(() => {
    setIsSelect(false);
  }, [location]);

  return (
    <div className={cx('ft')} id='contactUs'>
      <div className={cx('ft-inner')}>
        <div className={cx('ft-content')}>
          <div className={cx('ft-company')}>
            <div className={cx('ft-logo')}>
              <img src={galleryLogo} alt='galleryST logo' />
            </div>
            <div className={cx('ft-info')}>
              <span className={cx('ft-info-list')}>
                <Trans i18nKey={t('footerOffice')} components={[<span />]} />
              </span>
              <span className={cx('ft-info-list', 'list-contact-us')}>
                <Trans i18nKey={t('footerContactUs')} components={[<span />]} />
              </span>

              <div className={cx('ft-info-list', 'two')}>
                <p>{t('footerRegistrationNumber')}</p>
                <p>{t('footerCeo')}</p>
              </div>
              <div className={cx('copyright-wrap')}>
                <div className={cx('ft-sns-mobile')}>
                  <div className={cx('ft-sns-logo')}>
                    <p
                      onClick={() => {
                        window.open(
                          'https://www.instagram.com/galleryst_official'
                        );
                      }}
                    >
                      <img src={insta} alt='instagram' />
                    </p>
                    {/*  <p
                      onClick={() => {
                        window.open(
                          'https://www.facebook.com/galleryst.official'
                        );
                      }}
                    >
                      <img src={facebook} alt='facebook' />
                    </p> */}
                    <p
                      onClick={() => {
                        setIsSelect(false);
                        window.open(
                          'https://www.youtube.com/channel/UCBnLwNguF8VHIcXPmNByTDw'
                        );
                      }}
                    >
                      <img src={youtube} alt='youtube' />
                    </p>
                  </div>
                </div>

                <span className={cx('ft-info-list', 'copyright', 'pretendard')}>
                  Copyright ⓒ 2014 Secret Town. ALL RIGHTS RESERVED.
                </span>
              </div>
            </div>
          </div>

          <div className={cx('ft-absolute')}>
            <dl
              className={cx('ft-family', 'pretendard', {
                'chang-height': isSelect,
              })}
            >
              <dt className={cx('ft-family-title')} onClick={familyListOpen}>
                <span>Family Site</span>
                <span
                  className={cx(isSelect === true ? 'change-arrow' : 'arrow')}
                >
                  <KeyboardArrowDownIcon />
                </span>
              </dt>
              <dd>
                <ul className={cx('ft-family-lists')}>
                  {ftFamilyLists.map((item, idx) => (
                    <li
                      key={idx}
                      onClick={() => {
                        setIsSelect(false);
                        (window as any).gaWrite(
                          'web_click_footer_family_site',
                          'home',
                          'item.url'
                        );
                        window.open(item.url);
                      }}
                    >
                      {item.name}
                    </li>
                  ))}
                </ul>
              </dd>
            </dl>

            <div className={cx('ft-sns', 'pc-sns')}>
              <div className={cx('ft-sns-logo')}>
                <p
                  onClick={() => {
                    (window as any).gaWrite(
                      'web_click_footer_instagram',
                      'home',
                      ''
                    );
                    window.open('https://www.instagram.com/galleryst_official');
                  }}
                >
                  <img src={insta} alt='instagram' />
                </p>
                {/* <p
                  onClick={() => {
                    (window as any).gaWrite("web_click_footer_instagram","home","");
                    window.open("https://www.facebook.com/galleryst.official");
                  }}
                  >
                  <img src={facebook} alt="facebook" />
                </p> */}
                <p
                  onClick={() => {
                    setIsSelect(false);
                    (window as any).gaWrite(
                      'web_click_footer_youtube',
                      'home',
                      ''
                    );
                    window.open(
                      'https://www.youtube.com/channel/UCBnLwNguF8VHIcXPmNByTDw'
                    );
                  }}
                >
                  <img src={youtube} alt='youtube' />
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

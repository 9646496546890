import { useEffect, useState } from 'react';
import formatNumberCommas from 'helpers/formatNumberCommas';
import { Trans, useTranslation } from 'react-i18next';
import './orderArtListsBox.scss';

export default function OrderArtListsBox({
  items,
  orderDataTotalPrice,
}: {
  items: any | any[];
  orderDataTotalPrice: number;
}) {
  const { t } = useTranslation();

  return (
    <div className='order-art-lists-box'>
      <table className='cart-lists maruBuri'>
        <thead className='cart-lists-title-wrap'>
          <tr>
            <th className='item-img' scope='col'>
              {t('ordertitle1')}
            </th>
            <th className='item-info' scope='col'>
              {t('ordertitle2')}
            </th>
            <th className='item-amount' scope='col'>
              {t('ordertitle3')}
            </th>
            <th className='item-sellPrice' scope='col'>
              {t('ordertitle4')}
            </th>
            <th className='item-totalPrice' scope='col'>
              {t('ordertitle5')}
            </th>
          </tr>
        </thead>
        <tbody className='table-tbody'>
          {items.map((item: any, i: number) => (
            <tr key={i}>
              <td align='center'>
                <div className='art-img'>
                  {item?.art_info?.images?.s && (
                    <img
                      src={item?.art_info?.images?.s}
                      alt={item?.art_info?.name}
                    />
                  )}
                </div>
              </td>
              <td align='left' className='table-art-info'>
                {item?.art_info?.name && (
                  <p className='table-art-name'>{item?.art_info?.name}</p>
                )}
                {item?.art_info?.artist_info?.name && (
                  <p className='table-art-artist'>
                    {item?.art_info?.artist_info?.name}
                  </p>
                )}
                {/*  {item?.art_info.size_width && item?.art_info.size_height && (
                  <p>
                    H {item?.art_info.size_height} x W{' '}
                    {item?.art_info.size_width} cm
                  </p>
                )} */}
                {item?.art_info?.size_real && (
                  <p>{item?.art_info?.size_real}</p>
                )}
              </td>
              <td align='center'>1</td>
              <td align='center' className='table-art-info-price'>
                {item?.art_info?.price &&
                  `${formatNumberCommas(item?.art_info?.price)} ${t(
                    'commonPriceText'
                  )}`}
              </td>
              {i === 0 ? (
                <td
                  align='center'
                  className='total-price'
                  rowSpan={items.length + 1}
                >
                  {`${formatNumberCommas(orderDataTotalPrice)} ${t(
                    'commonPriceText'
                  )}`}
                </td>
              ) : null}
            </tr>
          ))}

          <tr>
            <td align='center'></td>
            <td>{t('cartText5')}</td>
            <td align='center'>1</td>
            <td align='center'>0 {t('commonPriceText')}</td>
          </tr>
        </tbody>
      </table>
    </div>
  );
}

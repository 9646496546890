import "./hotelArtFairQr.scss";
import { useState, useEffect } from "react";
import { useParams } from "react-router";
import { getTicketInfo } from "@services/HotelArtFairService";
import Banner from "@assets/images/hotelArtFair/hotel_art_fair_regular_banner.png";
// import MetaTag from "helpers/SEOMetaTag";
// import testImg from "@assets/images/test.jpg";

export default function HotelArtFairQr() {
  const { qr } = useParams();
  const [ticket, setTicket] = useState({
    qr_image_url: "",
    status: 0,
    count: 0,
    used_count: 0,
    company_info: {
      image: "",
      company_name: "",
      time_detail: "",
      type: 0,
    },
  });
  const getUserTicket = async () => {
    if (qr) {
      let data = await getTicketInfo(qr);
      setTicket(data);
    }
  };

  const ticketCount = (totalCount: number, usedCount: number) => {
    if (totalCount < usedCount) {
      return 0;
    } else {
      return totalCount - usedCount;
    }
  };

  useEffect(() => {
    getUserTicket();
  }, []);
  return (
    <div className="ticket-wrap sCoreDream">
      <div className="ticket-inner">
        <main className="ticket-banner">
          {ticket.company_info ? (
            <img
              src={ticket.company_info.image}
              alt={ticket.company_info.type === 1 ? "초대권" : "할인권"}
            />
          ) : (
            <img src={Banner} alt="일반권" />
          )}
        </main>
        <article className="ticket-qr-wrap">
          {ticket.company_info !== null && (
            <h3>
              <strong>{ticket.company_info.company_name}</strong> 고객님을 위한
              <br /> VIP 티켓입니다.
            </h3>
          )}
          <div className="ticket-qr">
            <img src={ticket.qr_image_url} alt="" />
            {ticket.status === 3 ? (
              <div className="cancel-ticket">
                <p>사용 불가</p>
              </div>
            ) : ticketCount(ticket.count, ticket.used_count) === 0 ? (
              <div className="cancel-ticket">
                <p>사용 완료</p>
              </div>
            ) : null}
            {/* {ticketCount(ticket.count, ticket.used_count) === 0 && (
              <div className="cancel-ticket">
                <p>사용 불가</p>
              </div>
            )} */}
          </div>
          <div className="ticket-bottom-wrap">
            <small>
              {ticket.count}매 중 {ticketCount(ticket.count, ticket.used_count)}
              매 사용 가능
            </small>
            <small>
              본 티켓은 일일권으로 아트페어 기간 중 1회 사용 가능합니다.
            </small>
          </div>
        </article>
        <article className="ticket-info-wrap">
          <h3>관람 정보</h3>
          <div className="ticket-info">
            <div className="ticket-info-item">
              <span>장소</span>
              <p>조선 팰리스 서울 강남 호텔, 29F</p>
            </div>
            <div className="ticket-info-item">
              <span>기간</span>
              <p>2023. 11. 24 (금) ~ 2023. 11.26 (일)</p>
            </div>
            <div className="ticket-info-item">
              <span>일정</span>
              {ticket.company_info !== null ? (
                <div className="date-wrap">
                  {ticket.company_info.time_detail}
                </div>
              ) : (
                <div className="date-wrap">
                  <p>11.24 (금) 15:00 ~ 20:00</p>
                  <p>11.25 (토) 11:00 ~ 20:00</p>
                  <p>11.26 (일) 11:00 ~ 20:00</p>
                </div>
              )}
            </div>
          </div>
        </article>
      </div>
      {/* <MetaTag imgsrc={testImg} title="test" /> */}
    </div>
  );
}

import { useEffect, useRef, useState } from 'react';
import { SwiperRef } from 'swiper/react';
import { useNavigate } from 'react-router-dom';
import { useRecoilState } from 'recoil';
import { Trans, useTranslation } from 'react-i18next';
import BannerClickBtn from '@components/btns/BannerClickBtn';
import Header from '@components/layouts/Header';
import SwiperImg from '@components/swiper/SwiperImg';
import btnArrow from '@assets/images/btn/btn_arrow@3x.png';
import { getBanner } from '@services/BannerService';
import { BannerInterface } from '@interfaces/banner/BannerInterface';
import { guestId } from '@stores/guest';
import { isMobile } from 'react-device-detect';
import './mainBanner.scss';

export default function MainBanner() {
  const { t } = useTranslation();
  const [guestIdState, setGuestIdState] = useRecoilState(guestId);
  const navigate = useNavigate();
  const handleClickLink = (isMobile: boolean, item: BannerInterface.Item) => {
    let link = '';
    (window as any).gaWrite('web_click_main_banner', 'home', item.name);

    if (isMobile) {
      link = item.link_mo;
    } else {
      link = item.link_pc;
    }
    window.open(link); //현재 전체링크시
  };

  const mouseOverGoToSlide = (index: number, status: string) => {
    if (status === 'on') {
      if (swiperRef.current && swiperRef.current.swiper) {
        swiperRef.current.swiper.slideTo(index);
      }
    } else {
      return null;
    }
  };

  const [bannerData, setBannerData] = useState([]);

  const swiperRef = useRef<SwiperRef>(null);

  const getBannerLists = async () => {
    try {
      const data = await getBanner(guestIdState);
      setBannerData(data.data.list);
    } catch (err) {
      console.log(err);
    }
  };

  const reversedData = [...bannerData].reverse();
  useEffect(() => {
    getBannerLists();
  }, []);

  return (
    <div className='main-banner-wrap'>
      <div className='main-banner-inner'>
        {reversedData && <SwiperImg items={reversedData} ref={swiperRef} />}

        <div className='main-banner-fix-wrap'>
          {reversedData?.map((item: BannerInterface.Item, i: number) => (
            <div
              className='main-banner-fix-box'
              key={item.id}
              onClick={() => handleClickLink(isMobile, item)}
              onMouseOver={() => mouseOverGoToSlide(i, 'on')}
              onMouseLeave={() => mouseOverGoToSlide(i, 'leave')}
            >
              <p
                className='main-banner-fix-list maruBuri'
                dangerouslySetInnerHTML={{ __html: item.name }}
              ></p>
              <div className='main-banner-fix-bottom'>
                <p className='main-banner-fix-arrow'>
                  <img src={btnArrow} alt='more button' />
                </p>
                <p className='main-banner-fix-number ELAND_Nice_M'>0{i + 1}</p>
              </div>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
}

import { atom } from 'recoil';
import { recoilPersist } from 'recoil-persist';

const { persistAtom } = recoilPersist();

export const guestSession = atom({
  key: 'guestSession',
  default: 0,
});

export const guestId = atom({
  key: 'guestId',
  default: 0,
});

import { useEffect, useRef } from 'react';
import { ModalInterface } from '@interfaces/components/ModalInterface';
import Modal from '@mui/material/Modal';
import Paper from '@mui/material/Paper';
import './modal.scss';

export default function CustomModalFullSize({
  open,
  onClose,
  children,
  className,
}: ModalInterface.CustomModalInterface) {
  const elements = document.getElementsByClassName(
    'react-transform-component'
  ) as HTMLCollectionOf<HTMLElement>;

  const element = elements[0];

  function setScreenSize() {
    if (element) {
      element.style.setProperty('transform', 'translate(0px, 0px) scale(1)');
    } //react-zoom-pan-pinch (mobile-chrome 세로>가로 scale 커지는 현상 조정)
  }

  useEffect(() => {
    if (open) {
      document.documentElement.style.overflow = 'hidden';

      /* el.style.setProerty('width', '100%', 'important'); */
    } else {
      document.documentElement.style.overflow = 'auto';
    }
  }, [open]);

  useEffect(() => {
    const handleResize = () => {
      window.addEventListener('resize', () => {
        // setScreenSize();
        setScreenSize();
      });
    };
    handleResize();
    return () => {
      window.removeEventListener('resize', handleResize);
      /*      document.documentElement.style.setProperty('width', `100%`);
      document.documentElement.style.setProperty('height', `100%`); */
    };
  }, []);
  return (
    <div className='custom-modal-fullsize' /* ref={modalRef} */>
      <Modal open={open} onClose={onClose} className={className}>
        <Paper
          elevation={2}
          sx={{
            position: 'absolute',
            top: '50%',
            left: '50%',
            transform: 'translate(-50%, -50%)',
            width: '100%',
            height: '100%',
            bgcolor: 'background.paper',
            boxShadow: 24,
            p: 4,
            // overflowY: "auto",
            padding: '4rem',
          }}
        >
          {children}
        </Paper>
      </Modal>
    </div>
  );
}

import { ModalInterface } from "@interfaces/components/ModalInterface";
import GuideIcon from "@assets/images/guideIcon.png";
import ClearIcon from "@mui/icons-material/Clear";
import Modal from "@mui/material/Modal";
import Paper from "@mui/material/Paper";
import { Trans, useTranslation } from "react-i18next";
import "./modal.scss";

export default function CommonModal({
  open,
  onClose,
  onClick,
  isShowBtn,
  btnCloseText,
  alarmText,
  alarmSubText,
}: ModalInterface.CommomModalInterface) {
  return (
    <div>
      <Modal open={open} onClose={onClose} className="common-modal-wrap">
        <Paper
          elevation={2}
          sx={{
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            width: "auto",
            // bgcolor: "background.paper",
            boxShadow: 24,
            p: 4,
            padding: "4rem",
          }}
        >
          <div className="common-modal-content">
            <img src={GuideIcon} alt="느낌표 아이콘" />
            <div className="common-alarm-text-box maruBuri">
              <p>
                <Trans i18nKey={alarmText} components={[<span />]} />
              </p>
              <small>{alarmSubText}</small>
            </div>
            <div className="btn-box">
              <button
                className={isShowBtn === false ? "none" : ""}
                onClick={onClick}
              >
                확인
              </button>
              <button
                className={isShowBtn === false ? "change-style" : ""}
                onClick={onClose}
              >
                {btnCloseText}
              </button>
            </div>
          </div>
        </Paper>
      </Modal>
    </div>
  );
}

const name = /^\S+(\s+\S+)*$/;
const history = /\S+/;
const artDescription = /\S+/;
const address = /^\S+(\s+\S+)*\s*$/;
const birth = /^(?!0000)[0-9]{8}$/;
const youngartistbirth =
  /^(19[9][0-9]|200[0-5])(0[1-9]|1[0-2])(0[1-9]|[12][0-9]|3[01])$/; //1990년~2005년만 허용
const phone = /^(01[016789]{1})[0-9]{4}[0-9]{4}$/;
const phoneStart = /^(01[016789]{1})$/;
const phoneMid = /^[0-9]{4}$/;
const phoneEnd = /^[0-9]{4}$/;
const email =
  /^([a-zA-Z0-9]+[a-zA-Z0-9_\.-]+)@([a-zA-Z0-9\.-]+)\.([a-zA-Z0-9]{2,3})$/;
const artAmount = /^(?!0)[0-9]+$/;

const categoryEtc = /\S+/;
const nonGap = /\S+/;

const registrationNumber = /^(?!0)[0-9]+$/;
const refundAccountNumber = /^(?!0)[0-9]+$/;
const postNumber = /(\d{3}-\d{3}|\d{5})/;

const fileName = /^\S+(\s+\S+)*$/;
const artCreation = /^[1-9]\d{3}$/;

const regex = {
  name,
  birth,
  phone,
  phoneStart,
  phoneMid,
  phoneEnd,
  email,
  history,
  address,
  artDescription,
  artAmount,
  registrationNumber,
  refundAccountNumber,
  categoryEtc,
  nonGap,
  postNumber,
  fileName,
  artCreation,
  youngartistbirth,
};

export default regex;

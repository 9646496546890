import { FloatingMainInterface } from '@interfaces/components/FloatingCircleInterface';
import './floatingMain.scss';

export default function FloatingMain({
  img,
  text,
  text2,
  alt,
  className,
  onClick,
}: FloatingMainInterface) {
  return (
    <div className={`floating-main ${className}`} onClick={onClick}>
      <p className='floating-main-img'>
        <img src={img} alt={alt} />
      </p>
      <p className='floating-main-text sCoreDream'>
        {text}
        <span>{text2}</span>
      </p>
    </div>
  );
}

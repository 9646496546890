import { useEffect } from 'react';
import { useRecoilState } from 'recoil';
import { mobileMenuTrigger } from '@stores/mobileMenuTrigger';
import classNames from 'classnames/bind';
import styles from './menuTrigger.module.scss';

const cx = classNames.bind(styles);

export default function MenuTrigger() {
  const [isOn, setIsOn] = useRecoilState(mobileMenuTrigger);

  useEffect(() => {
    if (!isOn) {
      document.documentElement.style.overflow = 'auto';
    } else {
      document.documentElement.style.overflow = 'hidden';
    }
  }, [isOn]);

  return (
    <ul className={cx('menu-trigger')} onClick={() => setIsOn(!isOn)}>
      <li className={cx(isOn === true ? 'on' : '')}></li>
      <li className={cx(isOn === true ? 'on' : '')}></li>
      <li className={cx(isOn === true ? 'on' : '')}></li>
    </ul>
  );
}

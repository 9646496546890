import {httpCommunicator} from "@api/httpCommunicator";

const url = process.env.REACT_APP_API_URL;

export class AgreeService {
  static async getAgreeRule(guestSessionId?: number) {
    const header = {guest_session_id: guestSessionId};
    const res = await httpCommunicator(`${url}/v1/op/config/rule`, "get", {}, header);
    return res.data.data;
  }
}

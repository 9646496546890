import { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { Trans, useTranslation } from 'react-i18next';
import MoreWhiteBtn from '@components/btns/MoreWhiteBtn';
import 'aos/dist/aos.css';
import aboutBg1 from '@assets/images/main/main_about_01_1.png';
import aboutBg2 from '@assets/images/main/main_about_02_1.png';
import aboutBg3 from '@assets/images/main/main_about_03.png';
import aboutBg4 from '@assets/images/main/main_about_04.png';
import aboutBg5 from '@assets/images/main/main_about_05_1.png';
import './mainAboutUs.scss';

export default function MainAboutUs() {
  const { t } = useTranslation();
  const navigate = useNavigate();

  const handleClickMore = () => {
    (window as any).gaWrite('web_click_main_galleryst_more', 'home', 'about');
    navigate('/galleryst/about');
  };

  return (
    <div className='main-content-wrap main-aboutUs-wrap'>
      <div className='main-content-inner'>
        <div className='main-content-title about-title pretendard'>
          <p
            className='main-content-title-sub'
            data-aos='fade-zoom-in'
            data-aos-easing='linear'
            data-aos-delay='200'
            data-aos-offset='0'
            data-aos-duration='1000'
          >
            About Us
          </p>
          <h3 className='main-content-title-big maruBuri'>
            {t('mainAboutUsTitle')}
          </h3>
        </div>

        <div className='main-content'>
          <div className='main-content-text'>
            <Trans i18nKey={`mainAboutUsText`} components={[<span />]} />
          </div>
        </div>

        <div className='main-content-more-btn-wrap'>
          <MoreWhiteBtn
            className='main-content-more-btn'
            text='View more'
            onClick={handleClickMore}
          />
        </div>

        <div className='content-about-bg'>
          <div
            className='content-about-img content-about-img1'
            data-aos='fade-up'
            data-aos-anchor-placement='top-bottom'
            data-aos-easing='linear'
            data-aos-duration='1000'
          >
            <img src={aboutBg1} alt='gallery st' />
          </div>
          <div
            className='content-about-img content-about-img2'
            data-aos='zoom-in'
          >
            <img src={aboutBg2} alt='gallery st' />
          </div>
          <div
            className='content-about-img content-about-img3'
            data-aos='zoom-in'
            data-aos-easing='linear'
            data-aos-duration='1500'
          >
            <img src={aboutBg3} alt='gallery st' />
          </div>
          <div
            className='content-about-img content-about-img4'
            data-aos='zoom-in'
            data-aos-easing='linear'
            data-aos-duration='1000'
          >
            <img src={aboutBg4} alt='gallery st' />
          </div>
          <div
            className='content-about-img content-about-img5'
            data-aos='fade-up'
            data-aos-anchor-placement='top-bottom'
            data-aos-easing='linear'
            data-aos-duration='1000'
          >
            <img src={aboutBg5} alt='gallery st' />
          </div>
        </div>
      </div>
    </div>
  );
}

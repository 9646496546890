import { Fragment, useEffect, useRef, useState } from 'react';
import { RadiosInterface } from '@interfaces/components/RadiosInterface';
import regex from 'helpers/regex';

export default function Radio({
  optionsLists,
  onChange,
  values,
  defaultValues,
  setValues,
  categoryEtcPass,
  setCategoryEtcPass,
  helperText,
  setHelperText,
  originalArray,
  helperTextKey,
}: RadiosInterface.RadioInterface) {
  const [checkedValues, setCheckedValues] = useState<string[]>([]);
  const [etcValue, setEtcValue] = useState<string | undefined>('');
  // const [helperText, setHelperText] = useState<string | undefined>('');

  const etcRef = useRef<HTMLInputElement>(null);
  const etcRadioRef = useRef<HTMLInputElement>(null);

  const getSelectedValue = (
    e: React.ChangeEvent<HTMLInputElement>,
    index: number
  ) => {
    if (optionsLists[index].type === 'text') {
      e.target.value = e.target.value.slice(0, 15);
      setEtcValue(e.target.value);

      if (e.target.value === '' && etcRadioRef.current?.checked) {
        setCategoryEtcPass(false);
      } else if (e.target.value !== '' && etcRadioRef.current?.checked) {
        if (regex.categoryEtc.test(e.target.value)) {
          setCategoryEtcPass(true);
        } else {
          setCategoryEtcPass(false);
        }
      }
    } else {
      setCategoryEtcPass(true);
    }
  };

  const handleChangeValue = (
    e: React.ChangeEvent<HTMLInputElement>,
    index: number
  ) => {
    const newValue = e.target.value;
    const isChecked = e.target.checked;

    if (optionsLists[index].type !== 'text' && isChecked) {
      if (!values.includes(newValue)) {
        setCheckedValues([...checkedValues, newValue]);
      }
    }
    if (etcRadioRef.current?.checked && etcValue) {
      if (regex.categoryEtc.test(etcValue)) {
        setCategoryEtcPass(true);
      }
      if (!regex.categoryEtc.test(etcValue)) {
        setCategoryEtcPass(false);
      }
      if (checkedValues.length > 0) {
        setValues(`${etcValue},${checkedValues.join(',')}`);
      } else {
        setValues(etcValue);
      }
    }
    if (etcRadioRef.current?.checked && etcValue === '') {
      setCategoryEtcPass(false);
    }
    if (!etcRadioRef.current?.checked) {
      setCategoryEtcPass(true);
    }
    if (!isChecked) {
      setCheckedValues(
        checkedValues.filter((option: string) => option !== newValue)
      );
    }
  };

  const defaultValuesArray = defaultValues?.split(',');
  const filterCheckedValues = defaultValuesArray?.filter((item: string) =>
    originalArray.includes(item)
  );
  const filterEtcValues = defaultValuesArray?.filter(
    (item: string) => !originalArray.includes(item)
  );

  useEffect(() => {
    if (defaultValues) {
      let combinedString = '';

      if (filterCheckedValues.length > 0) {
        combinedString = filterCheckedValues;
      } else if (filterEtcValues.length > 0) {
        combinedString = filterEtcValues;
      } else if (filterCheckedValues.length > 0 && filterEtcValues.length > 0) {
        combinedString = filterCheckedValues + ',' + filterEtcValues;
      }
      setEtcValue(String(filterEtcValues));
      setCheckedValues([...filterCheckedValues]);
      setValues(combinedString);
    }
  }, []);

  useEffect(() => {
    if (etcRadioRef.current?.checked && etcValue) {
      if (checkedValues.length > 0) {
        setValues(`${etcValue},${checkedValues.join(',')}`);
      } else {
        setValues(etcValue);
      }
    } else {
      setValues(`${checkedValues.join(',')}`);
    }
  }, [etcValue, checkedValues, categoryEtcPass, values]);

  useEffect(() => {
    if (!setHelperText) {
      return;
    }
    if (categoryEtcPass) {
      setHelperText((helperText) => ({
        ...helperText,
        [helperTextKey]: '',
      }));
    }
    if (!categoryEtcPass) {
      setHelperText((helperText) => ({
        ...helperText,
        [helperTextKey]: '기타 선택시 내용을 입력해주세요.(15자 이내)',
      }));
    }
    if (values && categoryEtcPass) {
      setHelperText((helperText) => ({
        ...helperText,
        [helperTextKey]: '',
      }));
    }
  }, [values, categoryEtcPass]);

  return (
    <div className='radioWrap'>
      {optionsLists?.map(
        (item: RadiosInterface.OptionItemInterface, i: number) => (
          <Fragment key={item.value}>
            <label className='labelInputCheckBox'>
              <input
                type='checkbox'
                value={item.type === 'text' ? etcValue : item.value}
                ref={item.type === 'text' ? etcRadioRef : null}
                name={item.value}
                onChange={(e) => handleChangeValue(e, i)}
                defaultChecked={
                  defaultValues?.includes(item.value)
                    ? true
                    : item.type === 'text' && filterEtcValues?.length > 0
                    ? true
                    : false
                }
              />
              <span>{item.value}</span>
              {item.type === 'text' ? (
                <input
                  type='text'
                  placeholder='기타 분야시 작성'
                  className='etcTxt'
                  value={etcValue}
                  ref={etcRef}
                  onChange={(e) => getSelectedValue(e, i)}
                  maxLength={15}
                />
              ) : null}
            </label>
            {/*  {item.type === 'text' && !categoryEtcPass ? (
              <p className='categoryEtcHelperText helperText '>
                기타 선택시 내용을 입력해주세요.
              </p>
            ) : null} */}
          </Fragment>
        )
      )}
    </div>
  );
}

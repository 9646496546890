import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router';
import dayjs from 'dayjs';
import isBetween from 'dayjs/plugin/isBetween';
import Loading from '@components/Loading/Loading';
import { getArtFairDate } from '@services/DateArtFairService';
import './hotelArtFairTicketIntro.scss';
import btnOrder from '@assets/images/hotelArtFair/hotel_ticket_order_btn.jpg';
import btnRefund from '@assets/images/hotelArtFair/hotel_ticket_refund_btn.jpg';

export default function HotelArtFairTicketIntro() {
  const navigate = useNavigate();

  const currentDate = dayjs();

  const [isView, setIsView] = useState(false);
  const [isPageLoading, setIsPageLoading] = useState(true);

  //호텔 티켓팅
  const [isBetweenDateHiddenOrder, setIsBetweenDateHiddenOrder] =
    useState(false);
  //호텔 티켓팅-환불
  const [isBetweenDateHiddenRefund, setIsBetweenDateHiddenRefund] =
    useState(false);

  //모집요강 기한
  dayjs.extend(isBetween);

  //티켓 구매 버튼
  const handleClickOrder = () => {
    (window as any).gaWrite(
      'web_click_hotelartfair_ticket_order',
      '2023_hotel_artfair/intro',
      ''
    );

    if (!isBetweenDateHiddenOrder) {
      navigate('/2023_hotel_artfair/ticketing');
    } else {
      alert('신청 기간이 아닙니다.');
    }
  };

  //티켓 환불 버튼
  const handleClickRefund = () => {
    (window as any).gaWrite(
      'web_click_hotelartfair_ticket_refund',
      '2023_hotel_artfair/intro',
      ''
    );

    if (!isBetweenDateHiddenRefund) {
      navigate('/2023_hotel_artfair/ticket_refund');
    } else {
      alert('취소 기간이 아닙니다.');
    }
  };

  //day
  useEffect(() => {
    setIsPageLoading(true);

    const getDate = async () => {
      //티켓 구매기간
      let orderLocalStartDate;
      let orderLocalEndDate;
      //티켓 환불기간
      let refundLocalStartDate;
      let refundLocalEndDate;
      try {
        const data = await getArtFairDate('josun_hotel_art_fair');
        orderLocalStartDate = data.data.start_data;
        orderLocalEndDate = data.data.end_data;
        refundLocalStartDate = data.data.start_data;
        refundLocalEndDate = data.data.refund_data;

        /*  orderLocalStartDate = '2023-10-27'; //시작날짜(부터)
        orderLocalEndDate = '2023-11-26'; //종료날짜(까지)
        refundLocalStartDate = '2023-10-26';
        refundLocalEndDate = '2023-11-19'; */
        /*
        //test
        orderLocalStartDate = '2023-10-25'; //시작날짜(부터)
        orderLocalEndDate = '2023-10-27'; //종료날짜(까지) */
        //test
        /*   refundLocalStartDate = '2023-10-26';
        refundLocalEndDate = '2023-10-27'; */
      } catch (err) {
        console.log(err);
      }
      const orderStartDate = dayjs(orderLocalStartDate);
      const orderEndDate = dayjs(orderLocalEndDate);
      //
      const refundStartDate = dayjs(refundLocalStartDate);
      const refundEndDate = dayjs(refundLocalEndDate);

      //티켓 구매
      if (dayjs().isBetween(orderStartDate, orderEndDate, 'day', '[]')) {
        setIsBetweenDateHiddenOrder(false);
      } else {
        setIsBetweenDateHiddenOrder(true); //true:hidden
      }
      //티켓 환불
      if (dayjs().isBetween(refundStartDate, refundEndDate, 'day', '[]')) {
        setIsBetweenDateHiddenRefund(false);
      } else {
        setIsBetweenDateHiddenRefund(true); //true:hidden
      }

      /*  let localEndDate;
      try {
        // const data = await getArtistPrizeDate();
           setStartDateState(data.data.start_data);
        setEndDateState(data.data.end_data);
        //localEndDate = data.data.reset_data; //추후수정
      } catch (err) {
        console.log(err);
      }
 */
      // TODO: date 적용시
      /*   const infoTargetDate = dayjs(localEndDate); */
      /*  if (dayjs().isBefore(infoTargetDate)) {
        setIsView(true);
      } else {
        setIsView(false);
      }
      */
      setIsView(true); //date 미적용(전체 페이지)
      setIsPageLoading(false);
    };

    getDate();
  }, []);

  // console.log('isBetweenDateHiddenOrder', isBetweenDateHiddenOrder);
  // console.log('isBetweenDateHiddenRefund', isBetweenDateHiddenRefund);

  return (
    <>
      <div
        className={
          isPageLoading
            ? 'artistContestWrap hotelContestWrap hotelIntroWrap sCoreDream artistContestWrapGap'
            : isView
            ? `artistContestWrap hotelContestWrap hotelIntroWrap sCoreDream artistContestWrapView`
            : `artistContestWrap hotelContestWrap hotelIntroWrap sCoreDream artistContestWrapHidden`
        }
      >
        <div className='artistContestInner'>
          {/*   <h2 className='artistBlueLogo' onClick={handleClickMain}>
            <img src={blueLogo} alt='secret town logo' />
          </h2> */}

          <div className='artistContestContentsWrap'>
            <div className='artistContestBtnsWrap hotelArtFairDownBtn'>
              <p
                className={
                  isBetweenDateHiddenOrder
                    ? `artistContestBtn artistContestBtnDisabled`
                    : `artistContestBtn`
                }
                onClick={handleClickOrder}
              >
                <img src={btnOrder} alt='티켓구매하기' />
              </p>

              <p
                className={
                  isBetweenDateHiddenRefund
                    ? `artistContestBtn artistContestBtnDisabled`
                    : `artistContestBtn`
                }
                onClick={handleClickRefund}
              >
                <img src={btnRefund} alt='구매확인/취소하기' />
              </p>
            </div>
          </div>

          {/*   <div className='artistContestWrapHiddenBox'>
            <ClosedBox />
          </div> */}
        </div>
      </div>
      {isPageLoading && <Loading Loading={isPageLoading} />}
    </>
  );
}

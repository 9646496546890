import { useNavigate } from 'react-router-dom';
import { Trans, useTranslation } from 'react-i18next';
import MultipleSwiper from '@components/swiper/MultipleSwiper';
import ProductItem from '../../components/swiper/product/ProductItem';
import './mainArtMarket.scss';
import MoreWhiteOverBtn from '@components/btns/MoreWhiteOverBtn';
import { getArtMarketMainLists } from '@services/ArtMarketService';
import { useEffect, useState } from 'react';
import MoreWhiteBtn from '@components/btns/MoreWhiteBtn';

export default function MainArtMarket() {
  const { t } = useTranslation();
  const navigate = useNavigate();

  const handleClickMore = () => {
    navigate('/art_sales/art_market');
  };

  const [artMarketData, setArtMarketData] = useState([]);

  const getArtMarketData = async () => {
    try {
      const body = {
        limit: 15,
      };
      const data = await getArtMarketMainLists(body);
      setArtMarketData(data.data.data.list);
    } catch (err) {
      console.log(err);
    }
  };

  useEffect(() => {
    getArtMarketData();
  }, []);

  /*   console.log(artMarketData); */

  return (
    <div className='main-content-wrap main-artMarket-wrap'>
      <div className='main-content-inner'>
        <div className='main-content-title about-title'>
          <p
            className='main-content-title-sub'
            data-aos='fade-zoom-in'
            data-aos-easing='linear'
            data-aos-delay='200'
            data-aos-offset='0'
            data-aos-duration='1000'
          >
            Art market and Collection
          </p>
          <h3 className='main-content-title-big maruBuri'>
            {t('mainArtMarketTitle')}
          </h3>
        </div>

        <div className='main-content'>
          <div className='main-content-text'>
            <Trans i18nKey={`mainArtMarketText`} components={[<span />]} />
          </div>
        </div>

        {/* <div className='main-artMarket-swiper'></div> */}
        <MultipleSwiper items={artMarketData} />

        <MoreWhiteBtn
          className='main-content-more-btn'
          text='View more'
          onClick={handleClickMore}
        />
      </div>
    </div>
  );
}

import { httpCommunicator } from '@api/httpCommunicator';
import { ArtistApplyInterface } from '@interfaces/youngartist/ArtistApplyInterface';

const url = process.env.REACT_APP_API_URL;

export async function doSubmitArtistApply(
  values: any
) /* : Promise<ArtistApplyInterface.ResponceSchema> */ {
  const res = await httpCommunicator(
    `${url}/v1/event/new_frontier/create_apply`,
    'post',
    values,
    {
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    }
  );
  return res.data;
}

export async function doSubmitArtistUpdateReceive(
  values: any
) /*:  Promise<ArtistApplyInterface.ResponceSchema> */ {
  const res = await httpCommunicator(
    `${url}/v1/event/new_frontier/get_one`,
    'post',
    values,
    {}
  );
  return res.data;
}

export async function doSubmitArtistUpdate(
  values: any /*  ArtistApplyInterface.UpdateFormItem */
) {
  const res = await httpCommunicator(
    `${url}/v1/event/new_frontier/update_apply`,
    'post',
    values,
    {
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    }
  );
  return res.data;
}

export async function doSubmitCreateRefund(
  values: ArtistApplyInterface.DoSubmitRefundValuesInterface
) {
  const res = await httpCommunicator(
    `${url}/v1/event/new_frontier/create_refund`,
    'post',
    values,
    {}
  );
  return res.data;
}

export async function deleteArtistFiles(
  values: any /*  ArtistApplyInterface.DeleteArtistFilesInterface */
) {
  const res = await httpCommunicator(
    `${url}/v1/event/new_frontier/delete_file`,
    'post',
    values,
    {}
  );
  return res.data;
}

//
export async function getYoungartistDate() {
  const res = await httpCommunicator(
    `${url}/v1/event/new_frontier/check_date`,
    'post',
    {},
    {}
  );
  return res.data;
}

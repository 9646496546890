import { useNavigate } from 'react-router-dom';
import { Trans, useTranslation } from 'react-i18next';
import exhibitionImg from '@assets/images/main/main_exhibition_img_01.jpg';
import MoreWhiteBtn from '@components/btns/MoreWhiteBtn';
import { isMobile } from 'react-device-detect';
import './mainExhibition.scss';

export default function MainExhibition() {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const metaverse_art_land_url = process.env.REACT_APP_METAVERSE_ARTLAND_URL;

  const handleClickMore = () => {
    (window as any).gaWrite(
      'web_click_main_meraverse_more',
      'home',
      'meta_bus_art_rand'
    );
    /*  if (isMobile) {
      window.open(`${metaverse_art_land_url}/mo`);
    } else {
      window.open(`${metaverse_art_land_url}/pc`);
    } */
    window.open(`${metaverse_art_land_url}`);
  };

  return (
    <div className='main-content-wrap main-exhibition-wrap'>
      <div className='main-content-inner'>
        <div className='main-content-title about-title'>
          <p
            className='main-content-title-sub'
            data-aos='fade-zoom-in'
            data-aos-easing='linear'
            data-aos-delay='100'
            data-aos-offset='0'
            data-aos-duration='1000'
          >
            Metaverse Artland and Exhibition
          </p>
          <h3 className='main-content-title-big maruBuri'>
            {t('mainExhibitionTitle')}
          </h3>
        </div>

        <div className='main-content-border-box pc'>
          <div className='main-content'>
            <div
              className='main-content-text'
              data-aos='zoom-in'
              data-aos-duration='1500'
            >
              <Trans i18nKey={`mainExhibitionText`} components={[<span />]} />
            </div>

            <div className='main-content-exhibition-img'>
              <img src={exhibitionImg} alt='메타버스 아트 랜드' />
            </div>
          </div>
          <div className='main-content-exhidition-btn'>
            <MoreWhiteBtn
              className='main-content-more-btn'
              text='View more'
              onClick={handleClickMore}
            />
          </div>
        </div>
      </div>
    </div>
  );
}

import './notice.scss';
import { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';

import { NewsroomService } from './../../../services/NewsRoomService';
import BaseTable from './../../../components/table/BaseTable';
import Paging from './../../../components/pagination/Pagination';
import { useRecoilState } from 'recoil';
import { guestId } from '@stores/guest';

export default function Notice() {
  const { t } = useTranslation();
  const [guestIdState, setGuestIdState] = useRecoilState(guestId);
  const [currentPage, setCurrentPage] = useState(1);
  const [itemsPerPage] = useState(10); // 페이지당 아이템 수
  const [currentData, setCurrentData] = useState([]);
  const [count, setCount] = useState(0);
  const heaer = [t('commonNumber'), t('commonTitle'), t('commonDate')];
  const getNoticeList = async () => {
    const data = await NewsroomService.getNoticeList(
      currentPage,
      itemsPerPage,
      guestIdState
    );
    if (data.count === 0) {
      setCount(1);
    } else {
      setCount(data.count);
    }
    setCurrentData(data.list);
  };

  const handlePageChange = (pageNumber: number) => {
    localStorage.setItem('currentPage', String(pageNumber));
    setCurrentPage(pageNumber);
  };

  useEffect(() => {
    getNoticeList();
    const currentPage = localStorage.getItem('currentPage');
    if (currentPage) {
      setCurrentPage(Number(currentPage));
    }
  }, [currentPage, count]);

  return (
    <div className='sub-page-contents-wrap newsroom-notice-wrap'>
      <div className='sub-page-contents-inner'>
        <div className='table-container'>
          <div className='table-wrap'>
            <BaseTable
              header={heaer}
              list={currentData}
              link='/newsroom/notice/detail/'
              count={count}
              currentPage={currentPage}
            />
          </div>
          <Paging
            currentPage={currentPage}
            totalPages={Math.ceil(count / itemsPerPage)}
            onPageChange={handlePageChange}
          />
        </div>
      </div>
    </div>
  );
}

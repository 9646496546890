import { forwardRef } from 'react';
import { useEffect, useRef, useState } from 'react';
import { Swiper, SwiperRef, SwiperSlide } from 'swiper/react';
import { Autoplay, EffectFade, Navigation, Pagination } from 'swiper';
import 'swiper/css';
import 'swiper/css/effect-fade';
import 'swiper/css/navigation';
import 'swiper/css/pagination';
import bg from './../../assets/images/main/main_banner1.png';
import btnArrow from '@assets/images/btn/btn_arrow@3x.png';
import { isMobile } from 'react-device-detect';

interface item {
  image: string;
  image_mo: string;
  name: string;
  link_pc: string;
  link_mo: string;
}

export default forwardRef(function SwiperImg(
  {
    items,
  }: {
    items: item[];
  },
  ref: React.ForwardedRef<SwiperRef> | undefined
) {
  const handleClickLink = (link: string) => {
    // navigate(link); 뒤에꺼만 보낼시
    window.open(link); //현재 전체링크시
  };
  const [isMobileState, setIsMobileState] = useState(window.innerWidth < 789);
  useEffect(() => {
    const handleResize = () => {
      setIsMobileState(window.innerWidth < 789);
    };

    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);
  return (
    <Swiper
      ref={ref}
      autoplay={{
        delay: 4000,
        disableOnInteraction: false,
      }}
      modules={[Navigation, EffectFade, Autoplay, Pagination]}
      className='mySwiper'
      loop={false} //true
      speed={500}
      navigation={false} //true:prev,next 화살표 표시
      pagination={{
        /*   type: 'fraction', */ //페이지 숫자로 표시
        clickable: true, //원모양 표시
      }}
      effect={'fade'} //설정시 effect(현재는 fade)
    >
      {items.map((item, idx) => {
        return (
          <SwiperSlide key={idx}>
            <div
              style={{
                background: `url(${
                  isMobileState ? item.image_mo : item.image
                }) center center/ cover no-repeat `,
                width: '100%',
                height: '100%',
              }}
            >
              <div className='main-banner-mobile-bottom-wrap'>
                <div
                  className='main-banner-mobile-bottom-inner'
                  onClick={() =>
                    handleClickLink(isMobile ? item.link_mo : item.link_pc)
                  }
                >
                  <div className='banner-mobile-bottom-text-wrap'>
                    <h3 className='pretendard'>{`0${idx + 1}`}</h3>
                    <p
                      dangerouslySetInnerHTML={{ __html: item.name }}
                      className='maruBuri'
                    ></p>
                  </div>
                  <div className='banner-arrow'>
                    <img src={btnArrow} alt='more button' />
                  </div>
                </div>
              </div>
            </div>
          </SwiperSlide>
        );
      })}
    </Swiper>
  );
});

import { Trans, useTranslation } from 'react-i18next';

import img1 from '@assets/images/metaverseArtland/pc/pc1_galleryhome.png';
import img2 from '@assets/images/metaverseArtland/pc/pc2_artfair_page1.png';
import img3 from '@assets/images/metaverseArtland/pc/pc3_artfair_page2.png';
import img4 from '@assets/images/metaverseArtland/pc/pc4_interaction_page1.png';
import img5 from '@assets/images/metaverseArtland/pc/pc5_interaction_page2.png';

export default function ArtlandPc() {
  const { t } = useTranslation();

  return (
    <div className='artland-content-box sCoreDream'>
      <div className='artland-content'>
        <h4 className='maruBuri'>STEP 1. {t('artlandContentTitle1')}</h4>
        <p className='step-content'>
          {t('artlandContentTxt1')}
          <span>{t('artlandContentTxt2')}</span>
        </p>
        <div className='step-img'>
          <img src={img1} alt='artland guide' />
        </div>
      </div>

      <div className='line'></div>

      <div className='artland-content'>
        <h4 className='maruBuri'>STEP 2. {t('artlandContentTitle2')}</h4>
        <p className='step-content'>
          {t('artlandContentTxt3')}
          <span>{t('artlandContentTxt4')}</span>
        </p>
        <div className='step-img'>
          <img src={img2} alt='artland guide' />
        </div>
      </div>

      <ul className='artland-text list'>
        <h5>{t('artlandContentSubTitle1')}</h5>
        <li>{t('artlandContentTxt5Pc')}</li>
        <li>{t('artlandContentTxt6Pc')}</li>
        <li>{t('artlandContentTxt7Pc')}</li>
        <li>{t('artlandContentTxt8Pc')}</li>
      </ul>

      <div className='step-img'>
        <img src={img3} alt='artland guide' />
      </div>

      <div className='artland-text'>
        <h5>{t('artlandContentSubTitle2_1')}</h5>
        <p>{t('artlandContentTxt8')}</p>
      </div>

      <div className='artland-text'>
        <h5>{t('artlandContentSubTitle2_2')}</h5>
        <p>{t('artlandContentTxt9')}</p>
      </div>

      <div className='artland-text'>
        <h5>{t('artlandContentSubTitle2_3')}</h5>
        <p>{t('artlandContentTxt10')}</p>
      </div>

      <div className='line'></div>

      <div className='artland-content'>
        <h4 className='maruBuri'>STEP 3. {t('artlandContentTitle3')}</h4>
        <p className='step-content'>
          {t('artlandContentTxt11')}
          <span>{t('artlandContentTxt12')}</span>
        </p>
        <div className='step-img'>
          <img src={img4} alt='artland guide' />
        </div>
      </div>

      <div className='artland-text'>
        <h5>{t('artlandContentSubTitle4')}</h5>
        <p>{t('artlandContentTxt13')}</p>
      </div>

      <div className='artland-text'>
        <h5>{t('artlandContentSubTitle4_2')}</h5>
        <p>{t('artlandContentTxt13_2')}</p>
      </div>

      <div className='step-img'>
        <img src={img5} alt='artland guide' />
      </div>

      <div className='artland-text'>
        <h5>{t('artlandContentSubTitle5')}</h5>
        <p>{t('artlandContentTxt14')}</p>
      </div>

      <div className='artland-text'>
        <h5>{t('artlandContentSubTitle6')}</h5>
        <p>
          {t('artlandContentTxt15')}
          <span className='padding-top'>{t('artlandContentTxt16')}</span>
          <span>{t('artlandContentTxt17')}</span>
        </p>
      </div>

      <div className='artland-text'>
        <h5>{t('artlandContentSubTitle7')}</h5>
        <p>{t('artlandContentTxt18')}</p>
      </div>

      <div className='artland-text'>
        <h5>{t('artlandContentSubTitle8')}</h5>
        <p>{t('artlandContentTxt19')}</p>
      </div>
    </div>
  );
}

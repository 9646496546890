import { BtnInterface } from '@interfaces/components/ButtonInterface';
import './btn.scss';

export default function ClickBtn({
  text,
  onClick,
  className,
  disabled,
}: BtnInterface.ClickBtnInterface) {
  return (
    <button
      type='button'
      onClick={onClick}
      className={className}
      disabled={disabled}
    >
      {text}
    </button>
  );
}

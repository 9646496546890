import { useNavigate } from 'react-router';
import { useTranslation } from 'react-i18next';
import MoreWhiteBtn from '@components/btns/MoreWhiteBtn';
import changeDateSeconds from 'helpers/changeDate/changeDateSeconds';
import successIcon from '@assets/images/order/success_icon.png';
import './success.scss';

export default function Success({
  bigTitle,
  orderNumber,
  created,
  subTitle,
  children,
  btnText,
  btnLink,
}: {
  bigTitle: string;
  orderNumber: number;
  created: number;
  subTitle?: string;
  children: React.ReactNode;
  btnText: string;
  btnLink: string;
}) {
  const { t } = useTranslation();
  const navigate = useNavigate();

  const createdDate = changeDateSeconds(created);

  return (
    <div className='success-wrap sCoreDream'>
      <div className='success-inner inner-1400 sub-page-contents-inner'>
        <div className='success-box'>
          <p className='success-big-title maruBuri'>
            <img src={successIcon} alt='success' />
            {bigTitle && bigTitle}
          </p>

          {subTitle && (
            <p className='success-sub-title sCoreDream'>{subTitle}</p>
          )}

          <div className='success-contents-wrap sCoreDream'>
            <div className='success-contents-box'>
              <p className='success-content'>
                <span className='title'>{t('orderSuccess5')}</span>
                <span className='title'>{t('orderCheckSuccessTitle2')}</span>
              </p>

              <p className='success-content'>
                <span className='txt'>{orderNumber && orderNumber}</span>
                <span className='txt'>{createdDate && createdDate}</span>
              </p>
            </div>
          </div>

          {children}
        </div>

        <MoreWhiteBtn
          className='more-btn white white-border shadowNone'
          text={t('orderSuccess6')}
          onClick={() => navigate(btnLink)}
        />
      </div>
    </div>
  );
}

import galleryStLogo from '@assets/images/white-st-big-logo.png';
import WallpaperIcon from '@mui/icons-material/Wallpaper';

export default function ZoomNoParams() {
  return (
    <div className='zoomin-no-params-wrap'>
      <div className='zoomin-no-params-inner'>
        <div className='zoomin-no-params-contents-wrap'>
          <p className='logo'>
            <img src={galleryStLogo} alt='galleryST' />
          </p>

          <div className='zoomin-no-params-text-box'>
            <p className='big-text'>
              <WallpaperIcon />
              Zoom In Out Page
            </p>
            <div className='sub-text-box'>
              <p className='sub-text'>요청하신 페이지를 찾을 수 없습니다.</p>

              <p className='sub-text'>
                입력하신 주소가 정확한지 다시 확인해 주시기 바랍니다.
              </p>
            </div>

            <p className='footer-copyright pretendard'>
              Copyright ⓒ 2014 Secret Town. ALL RIGHTS RESERVED.
            </p>
          </div>
        </div>
      </div>
    </div>
  );
}

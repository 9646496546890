import './classApply.scss';
import { Trans, useTranslation } from 'react-i18next';
import { useState } from 'react';
import CommonModal from '@components/modal/CommonModal';
import { Validations } from 'helpers/validations';
import { CommunityService } from '@services/CommunityService';
import { useRecoilState } from 'recoil';
import { guestId } from '@stores/guest';
export default function ClassApply() {
  const { t } = useTranslation();
  const [guestIdState, setGuestIdState] = useRecoilState(guestId);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [alarmText, setAlarmText] = useState('');
  const [email, setEmail] = useState('');
  const classList = [
    {
      img: 'preparing.png',
      name: '클래스 명',
      status: 2,
      period: '0000년 00월 00일  ~ 00월  00일',
      address: '서울특별시 마포구 홍익로3길 20 서교프라자',
    },
    {
      img: 'preparing.png',
      name: '클래스 명',
      status: 2,
      period: '0000년 00월 00일  ~ 00월  00일',
      address: '서울특별시 마포구 홍익로3길 20 서교프라자',
    },
    {
      img: 'preparing.png',
      name: '클래스 명',
      status: 2,
      period: '0000년 00월 00일  ~ 00월  00일',
      address: '서울특별시 마포구 홍익로3길 20 서교프라자',
    },
    {
      img: 'preparing.png',
      name: '클래스 명',
      status: 2,
      period: '0000년 00월 00일  ~ 00월  00일',
      address: '서울특별시 마포구 홍익로3길 20 서교프라자',
    },
  ];

  const sendClassApplyEmail = async (email: string) => {
    if (email === '') {
      setIsModalOpen(true);
      document.documentElement.style.overflow = 'hidden';
      setAlarmText('메일 주소를 입력해주세요.');
      return;
    } else if (email.length > 500) {
      setIsModalOpen(true);
      document.documentElement.style.overflow = 'hidden';
      setAlarmText(t('alarmTxt3'));
      return;
    } else if (!Validations.formatEmail(email)) {
      setIsModalOpen(true);
      document.documentElement.style.overflow = 'hidden';
      setAlarmText(t('alarmTxt1'));
      return;
    }

    const data = await CommunityService.sendClassOpenNotificationEmail(
      email,
      guestIdState
    );
    setEmail('');
    if (data.code === 200) {
      setIsModalOpen(true);
      document.documentElement.style.overflow = 'hidden';
      setAlarmText(t('alarmTxt4'));
      return;
    }
  };

  const handleEmailChange = (e: any) => {
    setEmail(e.target.value);
  };

  const doCloseModal = () => {
    setIsModalOpen(false);
    document.documentElement.style.overflow = 'auto';
  };

  return (
    <div className='sub-page-contents-wrap community-classApply-wrap'>
      <div className='sub-page-contents-inner'>
        <div className='community-classApply-alarm-wrap'>
          <div className='community-classApply-alarm-text-box'>
            <h2 className='maruBuri'>{t('communityClassApplyTopBoxTitle')}</h2>
            <p>{t('communityClassApplyTopBoxText')}</p>
          </div>
          <div className='classApply-bottom maruBuri'>
            <div className='email-box sCoreDream'>
              <span>{t('communityClassApplyInputTitle')}</span>
              <input
                type='text'
                value={email}
                maxLength={500}
                onChange={handleEmailChange}
              />
            </div>
            <button onClick={() => sendClassApplyEmail(email)}>
              {t('communityClassApplyTopBoxBtn')}
            </button>
          </div>
        </div>
        <div className='community-class-list-wrap'>
          <h2 className='maruBuri'>{t('communityClassApplyListTitle')}</h2>
          <div className='community-class-item-box'>
            {classList.map((item, idx) => (
              <div className='community-class-item' key={idx}>
                <div className='img-box'>
                  <img src={`/images/${item.img}`} alt='준비중 이미지' />
                </div>
                <div className='text-box'>
                  <div className='title-box maruBuri'>
                    <span className={item.status === 1 ? 'possible' : ''}>
                      {item.status === 1 ? '신청가능' : '준비중'}
                    </span>
                    {/* <p>{item.name}</p> */}
                  </div>
                  <div className='text-bottom sCoreDream'>
                    <div className='item'>
                      {/* <span>기간 :</span>
                      <p>{item.period}</p> */}
                    </div>
                    <div className='item'>
                      {/* <span>장소 :</span>
                      <p>{item.address}</p> */}
                    </div>
                  </div>
                </div>
              </div>
            ))}
          </div>
        </div>
        <CommonModal
          open={isModalOpen}
          isShowBtn={false}
          btnCloseText={t('commonCloseText')}
          onClose={() => doCloseModal()}
          alarmText={alarmText}
        />
      </div>
    </div>
  );
}

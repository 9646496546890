import { useEffect, useState } from 'react';
import { useRecoilState } from 'recoil';
import { useLocation, useNavigate } from 'react-router-dom';
import { mobileMenuTrigger } from '@stores/mobileMenuTrigger';
import { Trans, useTranslation } from 'react-i18next';
import classNames from 'classnames/bind';
import styles from './mobileMenuLists.module.scss';
import { isMobile } from 'react-device-detect';
import CommonModal from '@components/modal/CommonModal';

const cx = classNames.bind(styles);
const metaverse_art_land_url = process.env.REACT_APP_METAVERSE_ARTLAND_URL;

export default function MobileMenuLists() {
  const [isOn, setIsOn] = useRecoilState(mobileMenuTrigger);
  const { t } = useTranslation();
  const navigate = useNavigate();
  const location = useLocation();
  const currentPage = location.pathname.split('/')[1];
  const current2depthPage = location.pathname.split('/')[2];
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [alarmText, setAlarmText] = useState('');
  const [alarmSubText, setAlarmSubText] = useState('');

  const [mouseHover, setMouseHover] = useState('');

  const headerLists = [
    {
      list: 'GalleryST',
      link: '/galleryst/about',
      linkMatching: '/galleryst',
      twoDepth: [{ list: t('headerTwoDepth1_1'), link: '/galleryst/about' }],
    },
    {
      list: 'Art Sales',
      link: '/art_sales/art_market',
      linkMatching: '/art_sales',
      twoDepth: [
        {
          list: t('headerTwoDepth2_1'),
          link: '/art_sales/art_market',
        },
        {
          list: t('headerTwoDepth2_2'),
          link: 'preparing',
          //   link: "/art_sales/open_market",
        },
        {
          list: t('headerTwoDepth2_3'),
          link: 'preparing',
          //   link: "/art_sales/piece_trade",
        },
        {
          list: t('headerTwoDepth2_4'),
          link: 'preparing',
          //   link: "/art_sales/group_buying",
        },
      ],
    },
    {
      list: 'Metaverse Artland',
      link: `/artland`,
      linkMatching: '/artland',
      twoDepth: [
        {
          list: t('headerTwoDepth6'),
          link: '/artland',
        },
        {
          list: t('headerTwoDepth6_2'),
          link: '/artland/guide',
        },
      ],
    },
    {
      list: 'Community',
      link: '/community/academy_info',
      linkMatching: '/community',
      twoDepth: [
        {
          list: t('headerTwoDepth3_1'),
          link: '/community/academy_info',
        },
        {
          list: t('headerTwoDepth3_2'),
          link: '/community/class_apply',
        },
      ],
    },
    {
      list: 'Newsroom',
      link: '/newsroom/notice',
      linkMatching: '/newsroom',
      twoDepth: [
        {
          list: t('headerTwoDepth4_1'),
          link: '/newsroom/notice',
        },
        {
          list: t('headerTwoDepth4_2'),
          link: '/newsroom/newsletter',
        },
      ],
    },
    {
      list: 'Service',
      link: '/service/exhibitionhall_rental',
      linkMatching: '/service',
      twoDepth: [
        {
          list: t('headerTwoDepth5_1'),
          link: '/service/exhibitionhall_rental',
        },
        {
          list: t('headerTwoDepth5_2_2'),
          link: '/service/metaverse_exhibition',
        },
        {
          list: t('headerTwoDepth5_3'),
          link: '/service/art_consulting',
        },

        {
          list: t('headerTwoDepth5_4'),
          link: '/service/inquiry',
        },
      ],
    },
    {
      list: t('commonCartText'),
      link: '/cart',
      linkMatching: '/cart',
    },
    {
      list: t('orderCheckTitle'),
      link: '/order_check',
      linkMatching: '/order_check',
    },
  ];

  const doCloseModal = () => {
    setIsModalOpen(false);
    document.documentElement.style.overflow = 'auto';
  };

  const handleClickMenu = (link: string) => {
    localStorage.removeItem('activeTab');
    localStorage.removeItem('currentPage');
    setIsOn(false);
    if (link === `${metaverse_art_land_url}`) {
      /*  if (isMobile) {
        window.open(`${metaverse_art_land_url}/mo`);
      } else {
        window.open(`${metaverse_art_land_url}/pc`);
      } */
      window.open(`${metaverse_art_land_url}`);
    } else if (link === 'preparing') {
      setIsModalOpen(true);
      setAlarmText('서비스 준비중 입니다.');
      setAlarmSubText('coming soon');
      document.documentElement.style.overflow = 'hidden';
      return;
    } else {
      navigate(link);
      document.documentElement.style.overflow = 'auto';
    }
  };

  //Menu 나타날 시 배경 스크롤 막기
  useEffect(() => {
    const handleResize = () => {
      if (isOn && window.innerWidth > 1024) {
        document.documentElement.style.overflow = 'auto';
        setIsOn(false);
      } else if (isOn) {
        document.documentElement.style.overflow = 'hidden';
      }
    };

    handleResize();

    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, [isOn]);

  useEffect(() => {
    if (isModalOpen) {
      document.documentElement.style.overflow = 'hidden';
    } else {
      document.documentElement.style.overflow = 'auto';
    }
  }, [isModalOpen]);

  return (
    <div className='mobile-menu-lists-wrap'>
      <ul className='mobile-menu-lists'>
        <ul
          className={cx('header-lists')}
          onMouseOver={() => setMouseHover('active')}
          onMouseLeave={() => setMouseHover('')}
        >
          {headerLists.map((item, idx) => (
            <li className={`maruBuri ${cx('header-lists-box')}`} key={idx}>
              <p
                onClick={() => handleClickMenu(item.link)}
                className={
                  item.linkMatching && `/${currentPage}` === item.linkMatching
                    ? cx('header-list-1depth', 'active')
                    : cx('header-list-1depth')
                }
              >
                <span>{item.list}</span>
              </p>
              <ul className={cx('twoDepth-lists')}>
                {/* {item.twoDepth
                    ? item.twoDepth
                        .split(',')
                        .map((depthItem, i) => <li>{depthItem}</li>)
                    : null} */}
                {item.twoDepth
                  ? item.twoDepth.map((depthItem: any, i: number) => (
                      <li
                        key={i}
                        onClick={() => handleClickMenu(depthItem.link)}
                        className={
                          location.pathname === depthItem.link
                            ? cx('twoDepth-list', 'active')
                            : cx('twoDepth-list')
                        }
                      >
                        <p
                          className={cx('twoDepth-list-text')}
                          dangerouslySetInnerHTML={{ __html: depthItem.list }}
                        ></p>
                      </li>
                    ))
                  : null}
              </ul>
            </li>
          ))}
        </ul>
      </ul>

      <CommonModal
        open={isModalOpen}
        isShowBtn={false}
        btnCloseText='닫기'
        onClose={() => doCloseModal()}
        alarmText={alarmText}
        alarmSubText={alarmSubText}
      />
    </div>
  );
}

import { ModalInterface } from '@interfaces/components/ModalInterface';

import Modal from '@mui/material/Modal';
import Paper from '@mui/material/Paper';

import styled from '@emotion/styled';

const Box = styled.div({
  display: 'flex',
  justifyContent: 'flex-end',
});

export default function CustomModalBottomBtn({
  open,
  onClose,
  children,
  className,
}: ModalInterface.CustomModalInterface) {
  return (
    <div>
      <Modal open={open} onClose={onClose} className={className}>
        <Paper
          elevation={2}
          sx={{
            position: 'absolute',
            top: '50%',
            left: '50%',
            transform: 'translate(-50%, -50%)',
            width: 'auto',
            bgcolor: 'background.paper',
            boxShadow: 24,
            p: 4,
            overflowY: 'auto',
            padding: '4rem',
          }}
        >
          {children}

          <button className='modal-close-btn' onClick={onClose}>
            닫기
          </button>
        </Paper>
      </Modal>
    </div>
  );
}

export const hotelArtFairTermsOfUseData = [
  `[전시 티켓 구매 및 이용 약관]

제1조(목적)
본 약관은 시크릿타운㈜(이하 ‘회사’이라 한다)의 서비스를 이용하여 티켓을 예매하는 고객에게 필요한 제반 사항들을 규정함으로써 시스템 운영 및 관리의 효율성을 제고하고, 고객 편의를 증대하는 것을 목적으로 합니다.

제2조(약관의 효력)
① 회사는 관계 법령의 범위에서 본 약관을 개정할 수 있으며, 개정사유와 적용일자를 명시하여 현행 약관과 함께 회사의 홈페이지 초기화면이나 팝업화면에 그 적용일자 15일 전부터 적용일자 전일까지 공지합니다.
② 회사가 약관을 개정할 경우에는 그 개정약관은 그 적용일자 이후(적용일 포함)에 체결되는 계약에만 적용되고 그 이전에 이미 체결된 계약에 대해서는 개정 전 약관조항이 적용됩니다. 다만, 이미 계약을 체결한 고객이 개정약관 조항의 적용을 받기를 원하는 뜻을 제1항의 개정약관 공지기간 내에 회사에 발송하여 회사의 동의를 받는 경우에는 개정약관 조항이 적용됩니다.
③ 회원은 변경된 약관에 동의하지 않을 경우 회원탈퇴를 요청할 수 있으며, 변경된 약관의 효력 발생일 이후 해지 요청을 하지 않을 경우 약관의 변경사항에 동의한 것으로 간주됩니다.

제3조(용어 정의)
본 약관에서 사용하는 용어는 다음과 같습니다.
1."티켓 발권"이란 회사가 인정하는 예매 경로를 통하여 제5조에 해당하는 결제수단으로 티켓 금액이 지불 완료된 상태를 의미합니다.
2."티켓"이란 회사에서 전시를 관람할 수 있는 권한이 기재된 문서를 의미하며, 티켓에 명시된 전시명, 일시, 장소석에 한하여 그 효력이 있습니다.
3. "고객"이란 회사가 운영하는 사이트에 접속하여 이 약관에 의해 서비스를 제공받는 이용고객을 의미합니다.


제4조(티켓 예매서비스)
① 회사는 다음의 티켓 예매서비스를 제공합니다.
  1. 인터넷∙모바일 예매 서비스
② 인터넷∙모바일 예매 서비스(운영시간 24시간 연중무휴) 고객은 직접 예매 절차에 따라 전시 종료 전까지 인터넷∙모바일로 예매할 수 있습니다.
③ 회사는 인터넷∙모바일 서비스 이용 중 고객의 부주의로 발생하는 전시 일시, 티켓 할인율 누락, 좌석 수량 및 등급 등의 오적용에 대하여 책임지지 않습니다.
④ 허위구매에 대한 규제
 1. 회사는 선량한 이용자의 보호를 위하여 다음에 해당하는 고객에 대하여 일정한 기간을 정하여 예매제한 또는 해당 예매 건에 대한 예매취소 조치를 취할 수 있습니다.
   - 부정한 방법을 통한 예매의 경우(예. 매크로 이용 등 비정상적인 방법을 통한 예매)
   - 반복적인 다량 구매 후 취소하는 경우(예. 재판매를 위하여 일정기간 동안 다량 구매 후 취소를 반복하는 경우)
   - 암표매매의 목적으로 구매하거나 암표매매를 이미 한 경우(예. 구매한 티켓을 회사의 판매가 보다 높은 가격을 받고 타에 판매하는 경우)
   - 기타 이에 준하는 부정한 이용으로 판단되는 경우
 2. 상기 1호의 경우 회사는 해당 고객에게 1일 이상, 최대 5일 이내의 소명기한을 부여하며, 그 기한 내 고객으로부터 소명서를 제출할 경우 그 내용이 합당하다고 판단되면 해당 조치를 해지할 수 있습니다.


제5조(티켓 예매 시 결제 수단)
회사의 공연 티켓은 다음과 같은 결제 수단을 이용하여 예매할 수 있습니다.
1. 계좌이체 : 해당 전시 종료 전까지 인터넷으로 예매할 수 있으며, 티켓 금액을 지정계좌로 입금하는 경우 예매가 완료됩니다. 입금을 하지 않을 경우 고객에게 별도의 통보 없이 예매내역이 전산으로 자동 취소됩니다.
2. 신용카드 : 결제예매 개시일부터 공연시작 전까지 신용카드를 이용하여 티켓을 예매할 수 있으며, 전자금융거래법에 따라 결제가 처리됩니다.
3. 간편결제 : 결제예매 개시일부터 공연시작 전까지 간편결제를 이용하여 티켓을 예매할 수 있으며, 전자금융거래법에 따라 결제가 처리됩니다.


제6조(티켓 변경 및 환불)
① 회사문화티켓에서 판매하고 있는 티켓은 다음의 각호에 따라 예매 취소 및 환불이 가능합니다. 
 가. 티켓 환불 및 변경
   1. 티켓 환불은 2023년 11월 19일까지 별도의 수수료 없이 전액 환급되며, 해당 일자 이후 환불 처리가 불가능 합니다.
나. 티켓 환불은 다음의 각호에 따라 예매 시 사용한 결제 수단으로만 처리합니다.
  1. 계좌 결제 시 : 예매자명의 계좌로 환불합니다.
  2. 신용카드 결제 시 : 티켓 예매 시 사용했던 카드를 통하여 전액 환불합니다. (신용카드로 결제된 티켓을 선물 받은 고객이 개인 사정으로 인해 환불을 요청할 경우 예매 시 결제했던 신용카드를 통해서만 처리 가능)
  3. 간편 결제 시 : 티켓 예매 시 사용했던 간편 결제 수단을 통하여 전액 환불합니다. (간편결제로 결제된 티켓을 선물 받은 고객이 개인 사정으로 인해 환불을 요청할 경우 예매 시 결제했던 수단을 통해서만 처리 가능)
 4. 티켓 분실 시에는 환불 처리가 불가능 합니다.


제7조(기타 운영)
① 개인 사정으로 티켓을 분실하였을 경우 티켓을 환불할 수 없으며, 전시 입장이 제한될 수 있습니다.
② 회사는 회사 제공 티켓 판매처 이외의 경로를 통하여 티켓을 예매한 고객에게 발생하는 손해에 대해서는 책임지지 않습니다.
④ 본 약관이 정하지 않는 사항은 관계 법령 또는 전시 운영 관례에 따라 처리합니다.


제8조(보상 규정)
① 회사는 다음의 각 호에 해당할 경우 별도의 비상 운영 방침을 정하고 회사 홈페이지를 통하여 공지합니다.
 1. 국가 비상사태 또는 천재지변 등의 사유로 전시가 취소되었을 경우
 2. 전시가 시작되었으나 불가항력적인 사유로 중단되었을 경우
② 회사의 부득이한 사정으로 전시가 취소되었을 경우 다음의 각호와 같이 티켓금액을 전액 환불합니다.
 1. 티켓 예매 시 이용한 결제방법과 동일한 절차로 환불합니다.
 2. 회사는 전시 일시가 변경되거나 취소되었을 경우, 회사의 홈페이지를 통하여 공지합니다.


제9조(관할 법원)
본 서비스와 관련된 분쟁으로 소송이 제기될 경우, 회사 소재지를 관할하는 법원을 전속 관할 법원으로 합니다.



[개인 정보 처리 방침]

시크릿타운㈜ (이하 ‘회사’라 한다)는 정보 통신망 이용 촉진 및 정보 보호에 관한 법률 및 개인정보보호법에 따라 정보 주체의 개인정보를 보호하고 이와 관련한 고충을 신속하고 원활하게 처리할 수 있도록 하기 위하여 다음과 같이 개인정보 처리 지침을 수립/공개합니다.

제 1조 (개인정보의 처리 목적)
회사는 다음의 목적을 위하여 개인정보를 처리합니다. 처리하고 있는 개인정보는 다음의 목적 이외의 용도로는 이용되지 않으며, 이용 목적이 변경되는 경우에는 관련 법률에 따라 별도의 동의를 받는 등 필요한 조치를 이행할 예정입니다.
 
1. 홈페이지 사용자 관리
서비스 제공에 따른 본인 식별/인증, 제한적 본인 확인제 시행에 따른 본인 확인, 서비스 부정 이용 방지 등을 목적으로 개인정보를 처리합니다.
2. 재화 또는 서비스 제공
물품 배송, 서비스 제공, 계약서/청구서 발송, 콘텐츠 제공, 맞춤 서비스 제공, 본인 인증, 연령 인증, 요금 결제/정산 등을 목적으로 개인정보를 처리합니다.
3. 고충처리
민원인의 신원 확인, 민원 사항 확인, 사실 조사를 위한 연락/통지, 처리 결과 통보 등의 목적으로 개인정보를 처리합니다.
4. 마케팅 및 광고에 활용
이벤트 등 광고성 정보 전달, 접속 빈도 파악 또는 사용자의 서비스 이용에 대한 통계 등을 목적으로 개인정보를 처리합니다.


제 2조 (개인정보의 처리 및 보유 기간)
① 회사는 법령에 따른 개인정보 보유/이용 기간 또는 정보 주체로부터 개인정보를 수집 시에 동의 받은 개인정보 보유/이용 기간 내에서 개인정보를 처리/보유합니다.
② 각각의 개인정보 처리 및 보유 기간은 다음과 같습니다
1. 홈페이지 회원 가입 및 관리 : 동의 받은 정보 보유 이용 기간 내
다만, 다음의 사유에 해당하는 경우에는 해당 사유 종료 시까지          
가) 관계 법령 위반에 따른 수사/조사 등이 진행 중인 경우에는 해당 수사/조사 종료 시까지
나) 홈페이지 이용에 따른 채권/채무 관계 잔존 시에는 해당 채권/채무 관계 정산 시까지
2. 재화 또는 서비스 제공 : 재화/서비스 공급 완료 및 요금 결제/정산 완료 시까지
다만, 다음의 사유에 해당하는 경우에는 해당 기간 종료 시까지
가) 「전자 상거래 등에서의 소비자 보호에 관한 법률」에 따른 표시/광고, 계약 내용 및 이행 등 거래에 관한 기록              
- 표시, 광고에 관한 기록 : 6월
- 계약 또는 청약 철회, 대금 결제, 재화 등의 공급 기록 : 5년
- 소비자 불만 또는 분쟁 처리에 관한 기록 : 3년
나) 「통신비밀보호법」제 41조에 따른 통신 사실 확인자료 보관
- 가입자 전기 통신 일시, 개시/종료 시간, 상대방 가입자 번호, 사용 도수, 발신 기지국 위치 추적 자료 : 1년
- 컴퓨터 통신, 인터넷 로그 기록 자료, 접속지 추적 자료 : 3개월


제 3조 (정보 주체의 권리․의무 및 행사 방법)
① 정보 주체는 회사에 대해 언제든지 다음 각 호의 개인정보 보호 관련 권리를 행사할 수 있습니다. 
1. 개인정보 열람 요구
2. 오류 등이 있을 경우 정정 요구
3. 삭제 요구
4. 처리 정지 요구
② 제 1항에 따른 권리 행사는 회사에 대해 서면, 전화, 전자우편, 모사 전송(FAX) 등을 통하여 하실 수 있으며 회사는 이에 대해 지체 없이 조치하겠습니다.
③ 정보 주체가 개인정보의 오류 등에 대한 정정 또는 삭제를 요구한 경우에는 회사는 정정 또는 삭제를 완료할 때까지 당해 개인정보를 이용하거나 제공하지 않습니다.
④ 제 1항에 따른 권리 행사는 정보 주체의 법정 대리인이나 위임을 받은 자 등 대리인을 통하여 하실 수 있습니다. 이 경우 개인정보 보호법 시행 규칙 별지 제 11호 서식에 따른 위임장을 제출하셔야 합니다.
⑤ 정보 주체는 개인정보 보호법 등 관계 법령을 위반하여 회사가 처리하고 있는 정보 주체 본인이나 타인의 개인정보 및 사생활을 침해하여서는 아니 됩니다.


제 4조 (처리하는 개인정보 항목)
회사는 다음의 개인정보 항목을 처리하고 있습니다.
① 필수 항목 : 성명, 생년월일, 아이디, 비밀번호, 주소, 전화번호, 성별, 이메일 주소
선택 항목 : 자택 전화번호
② 인터넷 서비스 이용 과정에서 아래 개인정보 항목이 자동으로 생성되어 수집 될 수 있습니다.
IP주소, 쿠키, MAC주소, 서비스 이용 기록, 방문 기록, 불량 이용 기록 등
 

제 5조 (개인정보의 파기)
① 회사는 개인정보 보유 기간의 경과, 처리 목적 달성 등 개인정보가 불필요하게 되었을 때에는 지체 없이 해당 개인정보를 파기합니다.
②  정보 주체로부터 동의 받은 개인정보 보유 기간이 경과하거나 처리 목적이 달성 되었음에도 불구하고 다른 법령에 따라 개인정보를 계속 보존하여야 하는 경우에는, 해당 개인정보를 별도의 데이터베이스(DB)로 옮기거나 보관 장소를 달리하여 보존합니다.
③ 개인정보 파기의 절차 및 방법은 다음과 같습니다.
1. 파기 절차
회사는 파기 사유가 발생한 개인정보를 선정하고, 회사의 개인정보 보호 책임자의 승인을 받아 개인정보를 파기합니다.
2. 파기 방법
회사는 전자적 파일 형태로 기록/저장된 개인정보는 기록을 재생할 수 없도록 로우레밸포멧(Low Level Format) 등의 방법을 이용하여 파기하며, 종이 문서에 기록/저장된 개인정보는 분쇄기로 분쇄하거나 소각하여 파기합니다.
 

제 6조 (개인정보의 안전성 확보 조치)
회사는 개인정보의 안전성 확보를 위해 다음과 같은 조치를 취하고 있습니다.
1. 관리적 조치 : 내부 관리 계획 수립․시행, 정기적 직원 교육 등
2. 기술적 조치 : 개인정보처리시스템 등의 접근 권한 관리, 접근 통제 시스템 설치, 고유 식별 정보 등의 암호화, 보안 프로그램 설치
3. 물리적 조치 : 전산실, 자료보관실 등의 접근 통제
    

제 7조(개인정보 자동 수집 장치의 설치∙운영 및 거부에 관한 사항)
① 회사는 이용자에게 개별적인 맞춤 서비스를 제공하기 위해 이용 정보를 저장하고 수시로 불러오는 ‘쿠키(cookie)’를 사용합니다.

② 쿠키는 웹사이트를 운영하는데 이용되는 서버(http)가 이용자의 컴퓨터 브라우저에게 보내는 소량의 정보이며 이용자들의 PC 컴퓨터 내의 하드디스크에 저장되기도 합니다.
1. 쿠키의 사용 목적: 이용자가 방문한 각 서비스와 웹 사이트들에 대한 방문 및 이용 형태, 인기 검색어, 보안 접속 여부, 등을 파악하여 이용자에게 최적화된 정보 제공을 위해 사용됩니다.
2. 쿠키의 설치∙운영 및 거부 : 웹 브라우저 상단의 도구>인터넷 옵션>개인정보 메뉴의 옵션 설정을 통해 쿠키 저장을 거부 할 수 있습니다.
3. 쿠키 저장을 거부할 경우 맞춤형 서비스 이용에 어려움이 발생할 수 있습니다.


제 8조 (개인정보 보호 책임자)
① 회사는 개인정보 처리에 관한 업무를 총괄해서 책임지고, 개인정보 처리와 관련한 정보 주체의 불만 처리 및 피해 구제 등을 위하여 아래와 같이 개인정보 보호 책임자를 지정하고 있습니다.
▶ 개인정보 보호 담당 부서 
- 부서 명 : 갤러리ST 서비스 팀
- 연락처 : galleryst@secrettown.co.kr
② 정보 주체께서는 회사의 서비스(또는 사업)을 이용하시면서 발생한 모든 개인정보 보호 관련 문의, 불만 처리, 피해 구제 등에 관한 사항을 문의하실 수 있습니다. 회사는 정보 주체의 문의에 대해 지체 없이 답변 및 처리해드릴 것입니다.

제 9조 (권익 침해 구제 방법)
아래의 기관은 회사는 별개의 기관으로서, 회사의 자체적인 개인정보 불만 처리, 피해 구제 결과에 만족하지 못하시거나 보다 자세한 도움이 필요하시면 문의하여 주시기 바랍니다.
▶ 개인정보 침해 신고 센터 (한국인터넷진흥원 운영)
- 소관 업무 : 개인정보 침해 사실 신고, 상담 신청
- 홈페이지 : privacy.kisa.or.kr
- 전화 : (국번 없이) 118
- 주소 : (58324) 전남 나주시 진흥길9(빛가람동301-2) 3층 개인정보침해신고센터
▶ 개인정보 분쟁 조정 위원회
- 소관 업무 : 개인정보 분쟁 조정 신청, 집단 분쟁 조정 (민사적 해결)
- 홈페이지 : www.kopico.go.kr
- 전화 : (국번 없이) 1833-6972
- 주소 : (03171)서울특별시 종로구 세종대로 209 정부 서울 청사 4층
▶ 대검찰청 사이버 범죄 수사단
- 홈페이지 : www.spo.go.kr
- 전화 : 02-3480-3570
▶ 경찰청 사이버안전국
- 홈페이지 : http://cyberbureau.police.go.kr
- 전화 : (국번 없이) 182
`,
];
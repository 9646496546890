import "./pagination.scss";
import { PaginationInterface } from "./../../interfaces/components/PaginationInterface";
export default function Paginaion({
  currentPage,
  totalPages,
  onPageChange,
}: PaginationInterface.PagingInterface) {
  const maxPageNumbers = 5;
  const middlePage = Math.floor(maxPageNumbers / 2);
  let startPage = Math.max(1, currentPage - middlePage);
  let endPage = Math.min(startPage + 4, totalPages);
  if (endPage > totalPages) {
    endPage = totalPages;
    startPage = Math.max(1, endPage - maxPageNumbers + 1);
  }

  if (startPage > totalPages - maxPageNumbers + 1) {
    startPage = Math.max(1, totalPages - maxPageNumbers + 1);
    endPage = totalPages;
  }
  const pageNumbers = Array.from(
    { length: endPage - startPage + 1 },
    (_, i) => startPage + i
  );

  const onFirstAndLastPage = (page: number) => {
    onPageChange(page);
  };
  return (
    <div className="pagination-wrap">
      <div className="pagination-inner">
        <div className="prev-btn-wrap">
          <button
            className="prev-arrow-1"
            onClick={() => onFirstAndLastPage(1)}
          ></button>
          <button
            className="prev-arrow-2"
            onClick={() => onPageChange(currentPage - 1)}
            disabled={currentPage === 1}
          ></button>
        </div>
        <ul>
          {pageNumbers.map((number) => (
            <li
              key={number}
              className={
                "number maruBuri " + (currentPage === number ? "active" : "")
              }
              onClick={() => onPageChange(number)}
            >
              {number}
            </li>
          ))}
        </ul>
        <div className="next-btn-wrap">
          <button
            className="next-arrow-1"
            disabled={currentPage === totalPages}
            onClick={() => onPageChange(currentPage + 1)}
          ></button>
          <button
            className="next-arrow-2"
            onClick={() => onFirstAndLastPage(totalPages)}
          ></button>
        </div>
      </div>
    </div>
  );
}

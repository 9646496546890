import "./hotelArtFairTicketRefund.scss";
import { useState, useRef, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { useForm } from "@hooks/useForm";
import {
  getBuyerInfo,
  getRefundTicketPrice,
  setTicketCancel,
} from "@services/HotelArtFairService";
import { HotelArtFairTicketRefundInterface } from "@interfaces/HotelArtFairTicketing/HotelArtfairTicketRefund";
import { getArtFairDate } from "@services/DateArtFairService";
import changeDateSeconds from "helpers/changeDate/changeDateSeconds";
import formatNumberCommas from "helpers/formatNumberCommas";
import dayjs from "dayjs";
import isBetween from "dayjs/plugin/isBetween";
import regex from "helpers/regex";
import ClosedBox from "@components/closed/ClosedBox";
import Loading from "@components/Loading/Loading";
import Banner from "@assets/images/hotelArtFair/hotel_art_fair_refund_banner.png";
import CustomModal from "@components/modal/CustomModal";

export default function HotelArtFairTicketRefund() {
  const navigate = useNavigate();

  const [loading, setLoading] = useState(false);
  const [isPageLoading, setIsPageLoading] = useState(true);
  //day
  const [isBetweenDateHidden, setIsBetweenDateHidden] = useState(false);
  dayjs.extend(isBetween);

  const phoneStartRef = useRef<HTMLInputElement>(null);
  const phoneMidRef = useRef<HTMLInputElement>(null);
  const phoneEndRef = useRef<HTMLInputElement>(null);
  const { values, setValues, handleChange } = useForm({
    name: "",
    phoneStart: "",
    phoneMid: "",
    phoneEnd: "",
    email: "",
  });

  const [helperText, setHelperText] = useState({
    name: "",
    phone: "",
    email: "",
  });

  const [isBtnOn, setIsBtnOn] = useState(true);
  const emailValue = String(values?.email);
  const name = String(values?.name);
  const phoneStart = String(values?.phoneStart);
  const phoneMid = String(values?.phoneMid);
  const phoneEnd = String(values?.phoneEnd);

  const [isCancelListView, setIsCancelListView] = useState(false);
  const [purchaseData, setPurchaseData] = useState([]);
  const [checkedList, setCheckedList] = useState<number[]>([]);
  const [cancelTotalPrice, setCancelTotalPrice] = useState<number>(0);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [modalTitle, setModalTitle] = useState("");

  const [ticketType, setTicketType] = useState({
    type: 0,
  });
  const [regularCount, setRegularCount] = useState<number[]>([]);
  const [invitationCount, setInvitation] = useState<number[]>([]);
  const [discountCount, setDiscountCount] = useState<number[]>([]);

  const helperTextShow = (value: any, key: string, text: string) => {
    if (value === "" || value.trim() === "") {
      setHelperText((helperText) => ({
        ...helperText,
        [key]: text,
      }));
    } else {
      setHelperText((helperText) => ({
        ...helperText,
        [key]: "",
      }));
    }
  };

  const handleFormChange = (
    e:
      | React.ChangeEvent<HTMLInputElement>
      | React.ChangeEvent<HTMLTextAreaElement>,
    name?: any,
    maxLength?: number
  ) => {
    handleChange(e);
    if (name === "name") {
      helperTextShow(
        e.target.value,
        "name",
        "구매자 명은 필수 입력 정보입니다."
      );
    } else if (
      name === "phoneStart" ||
      name === "phoneMid" ||
      name === "phoneEnd"
    ) {
      if (
        e.target.value !== "" &&
        maxLength &&
        e.target.value.length > maxLength
      ) {
        e.target.value = e.target.value.slice(0, maxLength);
      }
      if (e.target.value === "" || e.target.value.trim() === "") {
        helperTextShow(
          e.target.value,
          "phone",
          "핸드폰 번호는 필수 입력 정보입니다."
        );
      }
      if (
        (e.target.value !== "" &&
          !regex.phoneStart.test(String(phoneStartRef.current?.value))) ||
        !regex.phoneMid.test(String(phoneMidRef.current?.value)) ||
        !regex.phoneEnd.test(String(phoneEndRef.current?.value))
      ) {
        return setHelperText((helperText) => ({
          ...helperText,
          phone: "핸드폰 번호를 정확히 입력해주세요.(숫자만 입력)",
        }));
      } else if (e.target.value !== "") {
        return setHelperText((helperText) => ({
          ...helperText,
          phone: "",
        }));
      }
    } else if (name === "email") {
      if (e.target.value === "" || e.target.value.trim() === "") {
        helperTextShow(
          e.target.value,
          "email",
          "이메일은 필수 입력 정보입니다."
        );
      }
      if (e.target.value !== "" && !regex.email.test(emailValue)) {
        return setHelperText((helperText) => ({
          ...helperText,
          email: "이메일을 정확하게 입력해주세요.",
        }));
      } else if (e.target.value !== "") {
        return setHelperText((helperText) => ({
          ...helperText,
          email: "",
        }));
      }
    }
  };
  useEffect(() => {
    if (!values) {
      return;
    }

    if (
      values.name !== "" &&
      values.email !== "" &&
      regex.email.test(emailValue) &&
      values.phoneStart !== "" &&
      values.phoneMidRef !== "" &&
      values.phoneEndRef !== ""
    ) {
      setIsBtnOn(false);
      if (values.email !== "" && regex.email.test(emailValue)) {
        return setHelperText((helperText) => ({
          ...helperText,
          email: "",
        }));
      }
    } else {
      setIsBtnOn(true);
    }
  }, [values]);

  const getBuyerDetail = async () => {
    let phoneTotal = phoneStart + phoneMid + phoneEnd;
    let data = await getBuyerInfo(name, phoneTotal, emailValue);

    (window as any).gaWrite(
      "web_click_hotelartfair_ticket_refund_user_check",
      "2023_hotel_artfair/ticket_refund",
      ""
    );

    if (data.code === 200 && data.data.length === 0) {
      alert("구매 내역이 없습니다. 구매자 정보를 확인 해주세요.");
    } else {
      setPurchaseData(data.data);
      setIsCancelListView(true);
      setIsBtnOn(true);
    }
  };

  useEffect(() => {
    setIsPageLoading(true);
    setLoading(true);

    const getDate = async () => {
      let localStartDate;
      let localEndDate;
      try {
        /*   const data = await getArtistPrizeDate();
        localStartDate = data.data.start_data;
        localEndDate = data.data.end_data; */
        const data = await getArtFairDate("josun_hotel_art_fair");
        localStartDate = data.data.start_data;
        localEndDate = data.data.refund_data;
      } catch (err) {
        console.log(err);
      }
      const startDate = dayjs(localStartDate);
      const endDate = dayjs(localEndDate);

      if (dayjs().isBetween(startDate, endDate, "day", "[]")) {
        setIsBetweenDateHidden(false);
      } else {
        setIsBetweenDateHidden(true); //true:hidden
      }
      setIsPageLoading(false);
      setLoading(false);
    };
    getDate();
  }, []);

  useEffect(() => {
    if (loading) {
      document.documentElement.style.overflow = "hidden";
    } else {
      document.documentElement.style.overflow = "auto";
    }
  }, [loading]);

  const getCancelTicketPrice = async (ids: number[]) => {
    let res = await getRefundTicketPrice(ids);
    setCancelTotalPrice(res.data);
  };

  useEffect(() => {
    if (checkedList.length > 0) {
      setIsBtnOn(false);
      getCancelTicketPrice(checkedList);
    } else {
      setIsBtnOn(true);
      setCancelTotalPrice(0);
    }
  }, [checkedList, cancelTotalPrice]);

  const handleCheckbox = (e: any, id: number, type: any) => {
    const isChecked = e.target.checked;

    if (isChecked) {
      setCheckedList([...checkedList, id]);
      if (type === null) {
        setRegularCount([...regularCount, id]);
      } else if (type?.type === 1) {
        setInvitation([...invitationCount, id]);
      } else {
        setDiscountCount([...discountCount, id]);
      }
    } else {
      setCheckedList(checkedList.filter((item) => item !== id));
    }
  };

  const openCancelModal = () => {
    setModalTitle("티켓을 취소하시겠습니까?");
    setIsModalOpen(true);
    document.documentElement.style.overflow = "hidden";

    (window as any).gaWrite(
      "web_click_hotelartfair_ticket_refund_user_cancel_selection",
      "2023_hotel_artfair/ticket_refund",
      ""
    );
  };

  const doTicketCancel = async () => {
    let data = await setTicketCancel(checkedList);

    switch (data.code) {
      case 200:
        setModalTitle("티켓 취소가 완료되었습니다.");
        break;
      case 402:
        alert("취소 진행 중 입니다. 잠시만 기다려주세요.");
        break;
      case 403:
        alert("취소에 실패했습니다. 관리자에게 문의해주세요.");
        setIsModalOpen(false);
        break;
      case 405:
        alert("사용된 티켓은 환불이 어렵습니다.");
        setIsModalOpen(false);
    }

    // GA

    let ticketTypeName;
    let totalCount;

    if (ticketType?.type === 1) {
      ticketTypeName = "초대권";
      totalCount = invitationCount.length;
    } else if (ticketType?.type === 2) {
      ticketTypeName = "할인권";
      totalCount = discountCount.length;
    } else {
      ticketTypeName = "일반권";
      totalCount = regularCount.length;
    }
    (window as any).gaWrite(
      "web_click_hotelartfair_ticket_refund_user_cancel_complete",
      "2023_hotel_artfair/ticket_refund",
      `${ticketTypeName}/${totalCount}`
    );
  };

  const closedModal = (type?: string) => {
    if (modalTitle === "티켓 취소가 완료되었습니다.") {
      navigate("/");
    }
    setIsModalOpen(false);
    document.documentElement.style.overflow = "auto";

    // GA
    if (type === "cancel_close") {
      (window as any).gaWrite(
        "web_click_hotelartfair_ticket_refund_user_cancel_close",
        "2023_hotel_artfair/ticket_refund",
        ""
      );
    } else {
      if ("cancel_close_complete") {
        (window as any).gaWrite(
          "web_click_hotelartfair_ticket_refund_user_cancel_close_complete",
          "2023_hotel_artfair/ticket_refund",
          ""
        );
      } else {
        return;
      }
    }
  };

  return (
    <div
      className={
        isPageLoading
          ? "hotelArtFairRefund-wrap Gap"
          : isBetweenDateHidden
          ? "hotelArtFairRefund-wrap Hidden"
          : "hotelArtFairRefund-wrap View"
      }
    >
      <div className="hotelArtFairRefund-inner sCoreDream">
        <div className="hotelArtFairRefund-contents-wrap">
          <div className="hotelArtFairRefund-img-wrap">
            <img src={Banner} alt="배너 이미지" />
          </div>
          {!isCancelListView ? (
            <div className="hotelArtFairRefund-user-info-wrap">
              <h3>
                확인 정보를 입력해주세요. ( <span>항목 필수 입력</span> )
              </h3>
              <div className="user-info">
                <div className="user-info-item">
                  <label htmlFor="name">구매자 명</label>
                  <div className="input-wrap">
                    <input
                      type="text"
                      id="name"
                      name="name"
                      placeholder="구매자 명을 입력해주세요."
                      required={true}
                      value={values?.name}
                      spellCheck={false}
                      onChange={(e) => handleFormChange(e, e.target.name)}
                    />
                    {helperText.name && <span>{helperText.name}</span>}
                  </div>
                </div>
                <div className="user-info-item">
                  <label htmlFor="phoneNumber">핸드폰 번호</label>
                  <div className="input-wrap">
                    <div className="phone-wrap">
                      <input
                        type="text"
                        name="phoneStart"
                        maxLength={3}
                        placeholder="숫자만 입력"
                        required={true}
                        value={values?.phoneStart}
                        ref={phoneStartRef}
                        onChange={(e) => handleFormChange(e, e.target.name, 3)}
                      />
                      <p className="hyphen">-</p>
                      <input
                        type="text"
                        name="phoneMid"
                        maxLength={4}
                        placeholder="숫자만 입력"
                        required={true}
                        value={values?.phoneMid}
                        ref={phoneMidRef}
                        onChange={(e) => handleFormChange(e, e.target.name, 4)}
                      />
                      <p className="hyphen">-</p>
                      <input
                        type="text"
                        name="phoneEnd"
                        maxLength={4}
                        placeholder="숫자만 입력"
                        required={true}
                        value={values?.phoneEnd}
                        ref={phoneEndRef}
                        onChange={(e) => handleFormChange(e, e.target.name, 4)}
                      />
                    </div>
                    {helperText.phone && <span>{helperText.phone}</span>}
                  </div>
                </div>
                <div className="user-info-item">
                  <label htmlFor="email">이메일</label>
                  <div className="input-wrap">
                    <input
                      type="text"
                      id="email"
                      name="email"
                      placeholder="구매시 작성한 이메일을 입력해주세요."
                      required={true}
                      value={values?.email}
                      onChange={(e) => handleFormChange(e, e.target.name)}
                    />
                    {helperText.email && <span>{helperText.email}</span>}
                  </div>
                </div>
              </div>
              <div className="btn-wrap">
                <button onClick={getBuyerDetail} disabled={isBtnOn}>
                  구매 확인/취소하기
                </button>
              </div>
            </div>
          ) : (
            <div className="hotelArtFairRefund-purchase-list-wrap">
              <h3>티켓 구매 내역 확인</h3>
              <div className="hotelArtFairRefund-purchase-list">
                <table>
                  <thead>
                    <tr>
                      <th>구매 내역</th>
                      <th>구매 날짜</th>
                      <th>결제 상태</th>
                      <th>선택</th>
                    </tr>
                  </thead>
                  <tbody>
                    {purchaseData?.map(
                      (
                        list: HotelArtFairTicketRefundInterface.Item,
                        idx: number
                      ) =>
                        list.details.map(
                          (
                            detailsList: HotelArtFairTicketRefundInterface.Details,
                            detailsIdx: number
                          ) => (
                            <tr key={detailsList.id}>
                              <td>
                                {list.discount === null
                                  ? "일반권"
                                  : list.discount.type === 1
                                  ? "초대권"
                                  : "할인권"}
                              </td>
                              <td>
                                {changeDateSeconds(detailsList.created_at)}
                              </td>
                              <td>
                                {detailsList.status === 1
                                  ? "결제 완료"
                                  : "취소 완료"}
                              </td>
                              <td>
                                <input
                                  type="checkbox"
                                  name={`checkedItem${detailsList.id}`}
                                  disabled={detailsList.status === 2}
                                  onChange={(e) =>
                                    handleCheckbox(
                                      e,
                                      detailsList.id,
                                      list.discount
                                    )
                                  }
                                  checked={checkedList.includes(detailsList.id)}
                                />
                              </td>
                            </tr>
                          )
                        )
                    )}
                  </tbody>
                </table>
              </div>
              <div className="refund-total-wrap">
                <span>최종 환불 신청 금액</span>
                <span>{formatNumberCommas(cancelTotalPrice)} 원</span>
              </div>
              <div className="btn-wrap">
                <button disabled={isBtnOn} onClick={openCancelModal}>
                  선택 티켓 취소하기
                </button>
              </div>
            </div>
          )}
        </div>
        <CustomModal
          open={isModalOpen}
          onClose={
            modalTitle === "티켓을 취소하시겠습니까?"
              ? () => closedModal("cancel_close")
              : () => closedModal("cancel_close_complete")
          }
        >
          <div className="hotelArtFair-cancel-modal">
            <div className="modal-title">
              <h3>
                시크릿타운 호텔 아트페어 2023 <br />
                {modalTitle}
              </h3>
              {modalTitle === "티켓을 취소하시겠습니까?" ? (
                <p>티켓 발권을 취소하고 결제 금액을 환불합니다.</p>
              ) : (
                <p>
                  카드 환불의 경우 환불 신청 접수 완료 후 <br /> 영업일 기준으로
                  3~7일 정도 소요됩니다. <br /> 카드사의 사정에 따라 7일 이상
                  경과될 수 있습니다. <br /> 정확한 카드 환불 일정은 해당
                  카드사에 문의하시기 바랍니다.
                </p>
              )}
            </div>
            {modalTitle === "티켓을 취소하시겠습니까?" && (
              <div className="payment-info">
                <div className="payment-item">
                  <span>취소 신청 수량</span>
                  <p>{checkedList.length} 매</p>
                </div>
                <div className="payment-item">
                  <span>총 금액</span>
                  <p>{formatNumberCommas(cancelTotalPrice)} 원</p>
                </div>
              </div>
            )}
            <div className="modal-btn-wrap">
              {modalTitle === "티켓을 취소하시겠습니까?" && (
                <button className="cancelBtn" onClick={() => doTicketCancel()}>
                  티켓 취소하기
                </button>
              )}
              {modalTitle === "티켓을 취소하시겠습니까?" ? (
                <button
                  onClick={() => closedModal("cancel_close")}
                  className="closeBtn"
                >
                  닫기
                </button>
              ) : (
                <button
                  onClick={() => closedModal("cancel_close_complete")}
                  className="closeBtn"
                >
                  닫기
                </button>
              )}
            </div>
          </div>
        </CustomModal>

        <div className="artistContestWrapHiddenBox">
          <ClosedBox />
        </div>
      </div>
    </div>
  );
}

import { useState, useEffect } from 'react';
import { useRecoilState } from 'recoil';
import { Trans, useTranslation } from 'react-i18next';
import changeDate from 'helpers/changeDate';
import { Validations } from 'helpers/validations';
import { NewsroomService } from './../../../services/NewsRoomService';
import BaseTable from './../../../components/table/BaseTable';
import Paging from './../../../components/pagination/Pagination';
import CommonModal from '@components/modal/CommonModal';
import { guestId } from '@stores/guest';
import './newsLetter.scss';

export default function NewsLetter() {
  const { t } = useTranslation();
  const [guestIdState, setGuestIdState] = useRecoilState(guestId);
  const [currentPage, setCurrentPage] = useState(1);
  const [itemsPerPage] = useState(10); // 페이지당 아이템 수
  const [currentData, setCurrentData] = useState([]);
  const [count, setCount] = useState(0);
  const heaer = [t('commonNumber'), t('commonTitle'), t('commonDate')];

  const [isModalOpen, setIsModalOpen] = useState(false);
  const [alarmText, setAlarmText] = useState('');

  const [emailValue, setEmailValue] = useState('');
  const handleEmailChange = (e: any) => {
    setEmailValue(e.target.value);
  };
  const getNewsLetterList = async () => {
    const data = await NewsroomService.getNewsLetterList(
      currentPage,
      itemsPerPage,
      guestIdState
    );
    if (data.count === 0) {
      setCount(1);
    } else {
      setCount(data.count);
    }
    setCurrentData(data.list);
  };

  const handlePageChange = (pageNumber: number) => {
    localStorage.setItem('currentPage', String(pageNumber));
    setCurrentPage(pageNumber);
  };

  const sendNewsLetterEmail = async (email: string) => {
    if (email === '') {
      setIsModalOpen(true);
      document.documentElement.style.overflow = 'hidden';
      setAlarmText(t('alertTxt3'));
      return;
    } else if (!Validations.formatEmail(email)) {
      setIsModalOpen(true);
      document.documentElement.style.overflow = 'hidden';
      setAlarmText(t('alarmTxt1'));
      return;
    }

    const data = await NewsroomService.newsletterSubscriptionEmail(
      email,
      guestIdState
    );
    setEmailValue('');
    if (data.code === 200) {
      setIsModalOpen(true);
      document.documentElement.style.overflow = 'hidden';
      setAlarmText(t('alarmTxt2'));
      return;
    }
  };

  const doCloseModal = () => {
    setIsModalOpen(false);
    document.documentElement.style.overflow = 'auto';
  };

  useEffect(() => {
    getNewsLetterList();
    const currentPage = localStorage.getItem('currentPage');
    if (currentPage) {
      setCurrentPage(Number(currentPage));
    }
  }, [currentPage]);
  return (
    <div className='sub-page-contents-wrap newsroom-newsLetter-wrap'>
      <div className='sub-page-contents-inner'>
        <div className='newsLetter-email-send-wrap'>
          <div className='newsLetter-text-wrap'>
            <h2 className='maruBuri'>{t('newsRoomTopBoxTitle')}</h2>
          </div>
          <div className='newsLetter-bottom-box'>
            <div className='email-box sCoreDream'>
              <span>{t('newsRoomTopBoxInputTitle')}</span>
              <input
                type='text'
                onChange={handleEmailChange}
                value={emailValue}
              />
            </div>
            <button
              className='maruBuri'
              onClick={() => sendNewsLetterEmail(emailValue)}
            >
              {t('newsRoomTopBoxBtnText')}
            </button>
          </div>
        </div>
        <div className='table-container'>
          <div className='table-wrap'>
            <BaseTable
              header={heaer}
              list={currentData}
              link='/newsroom/newsletter/detail/'
              count={count}
              currentPage={currentPage}
              //   onClick={getOneNewsLetterData}
            />
          </div>
          <Paging
            currentPage={currentPage}
            totalPages={Math.ceil(count / itemsPerPage)}
            onPageChange={handlePageChange}
          />
        </div>
      </div>
      <CommonModal
        open={isModalOpen}
        isShowBtn={false}
        btnCloseText={t('commonCloseText')}
        onClose={() => doCloseModal()}
        alarmText={alarmText}
      />
    </div>
  );
}

import { httpCommunicator } from '@api/httpCommunicator';

const url = process.env.REACT_APP_API_URL;

export class PolicyService {
  //artLand 이용약관
  static async getServiceRule(guestSessionId?: number) {
    const header = { guest_session_id: guestSessionId };
    const res = await httpCommunicator(
      `${url}/v1/op/config/list`,
      'get',
      {},
      header
    );
    return res.data.data;
  }
}

import { httpCommunicator } from '@api/httpCommunicator';

const url = process.env.REACT_APP_API_URL;

export async function getCartLists(values: any, guestSessionId?: number) {
  const header = {guest_session_id: guestSessionId};
  const res = await httpCommunicator(
    `${url}/v1/art/basket/get_one`,
    'post',
    values,
    {}
  );
  return res.data;
}

export async function postCartDelete(values: any, guestSessionId?: number) {
  const header = {guest_session_id: guestSessionId};
  const res = await httpCommunicator(
    `${url}/v1/art/basket/delete`,
    'post',
    values,
    {}
  );
  return res.data;
}

export async function postOrderCreate(values: any, guestSessionId?: number) {
  const header = {guest_session_id: guestSessionId};
  const res = await httpCommunicator(
    `${url}/v1/art/order/create`,
    'post',
    values,
    {}
  );
  return res.data;
}

export async function getOrderLists(values: any, guestSessionId?: number) {
  const header = {guest_session_id: guestSessionId};
  const res = await httpCommunicator(
    `${url}/v1/art/order/get_one`,
    'post',
    values,
    header
  );
  return res.data;
}

export async function postOrderPayment(values: any, guestSessionId?: number) {
  const header = {guest_session_id: guestSessionId};
  const res = await httpCommunicator(
    `${url}/v1/payment/art/market`,
    'post',
    values,
    header
  );
  return res.data;
}


export async function postArtCheck(values : number[], guestSessionId?: number){
  const header = {guest_session_id: guestSessionId};
  const res = await httpCommunicator(
    `${url}/v1/art/item/check`,
    'post',
    {id : values},
    header
  );
  return res.data;
}

export async function postCancelOrder(marketType:string , orderNumber:number, guestSessionId?: number){
  const header = {guest_session_id: guestSessionId};
  const res = await httpCommunicator(
    `${url}/v1/payment/art/cancel`,
    'post',
    {market_type : marketType , order_number : orderNumber},
    header
  );
  return res.data;
}
export async function postRefundOrder(marketType:string , orderNumber:number, refundComment : string, guestSessionId?: number){
  const header = {guest_session_id: guestSessionId};
  const res = await httpCommunicator(
    `${url}/v1/payment/art/refund/apply`,
    'post',
    {market_type : marketType , order_number : orderNumber , refund_comment :  refundComment},
    header
  );
  return res.data;
}

import "./exhibitionhallRental.scss";
import { Trans, useTranslation } from "react-i18next";
import { Swiper, SwiperSlide } from "swiper/react";
import { Navigation, Pagination, Autoplay } from "swiper";
import "swiper/css";
import "swiper/css/effect-fade";
import "swiper/css/navigation";
import "swiper/css/pagination";

export default function ExhibitionhallRental() {
  const { t } = useTranslation();
  const exhibitionhall = [
    {
      id: 1,
      title: "serviceExhibitionHallTitle1",
      link: "http://www.coconutbox.co.kr/",
      content: t("serviceExhibitionhallRental1"),
      addressName: t("serviceExhibitionhallRental2"),
      address: t("serviceExhibitionhallRental3"),
      imgsrc: [
        "coconut_box_01.png",
        "coconut_box_02.png",
        "coconut_box_03.png",
      ],
    },
    {
      id: 2,
      title: "serviceExhibitionHallTitle2",
      link: "https://purpleboxmuseum.com/",
      content: t("serviceExhibitionhallRental4"),
      addressName: t("serviceExhibitionhallRental5"),
      address: t("serviceExhibitionhallRental6"),
      imgsrc: ["purple_box_01.png", "purple_box_02.png", "purple_box_03_1.png"],
    },
  ];
  return (
    <div className="sub-page-contents-wrap service-exhibitionhallRental-wrap">
      <div className="sub-page-contents-inner">
        <div className="service-exhibitionhall-wrap">
          <ul className="service-exhibitionhall-box">
            {exhibitionhall.map((item, idx) => (
              <li key={idx}>
                <div className="swiper-box">
                  <div className="swiper-button-box">
                    <div className="button-box-inner">
                      <button
                        id={`prevBtn${item.id}`}
                        className="prevBtn-custom"
                      ></button>
                      <button
                        id={`nextBtn${item.id}`}
                        className="nextBtn-custom"
                      ></button>
                    </div>
                  </div>
                  <Swiper
                    modules={[Navigation, Pagination, Autoplay]}
                    autoplay={{
                      delay: 5000,
                      disableOnInteraction: false,
                    }}
                    loop={true}
                    className="hallSwiper"
                    centeredSlides={false}
                    grabCursor={true}
                    slidesPerView={1} //처음 보여지는개수
                    slidesPerGroup={1} //보여지는 개수 묶음
                    spaceBetween="2.8%" //slide gap
                    pagination={{ clickable: true }}
                    navigation={{
                      prevEl: `#prevBtn${item.id}`,
                      nextEl: `#nextBtn${item.id}`,
                    }} //버튼 커스텀
                  >
                    {exhibitionhall[idx].imgsrc.map((img, i) => {
                      return (
                        <SwiperSlide key={i} className="swiper-wrap">
                          <div className="img-box">
                            <img src={`/images/service/${img}`} alt="" />
                          </div>
                        </SwiperSlide>
                      );
                    })}
                    <div className="swiper-pagination"></div>
                  </Swiper>
                </div>
                <div className="service-text-box">
                  <h3 className="maruBuri">
                    <a href={item.link}>{t(item.title)}</a>
                  </h3>
                  <div
                    className="content sCoreDream"
                    style={{ whiteSpace: "pre-wrap" }}
                  >
                    {item.content}
                  </div>
                  <div className="service-text-bottom maruBuri">
                    <div className="service-address-box">
                      <span>{t("serviceExhibitionHallAddress")}</span>
                      <div className="address">
                        <p>{item.addressName}</p>
                        <p>{item.address}</p>
                      </div>
                    </div>
                  </div>
                </div>
              </li>
            ))}
          </ul>
        </div>
      </div>
    </div>
  );
}

import dayjs from 'dayjs';

export default function changeDateSeconds(timestamp?: number): string {
  let date = '';
  if (timestamp !== undefined) {
    date = dayjs.unix(Number(timestamp)).format('YYYY.MM.DD HH:mm:ss'); //YYYY-MM-DD HH:mm:ss
  } else {
    date = dayjs().format('YYYY.MM.DD HH:mm:ss');
  }
  return date;
}

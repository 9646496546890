import { SwiperInterface } from '@interfaces/components/SwiperInterface';
import { useNavigate } from 'react-router';
import './productItem.scss';
import PlayCircleFilledIcon from '@mui/icons-material/PlayCircleFilled';

export default function ProductItem({
  item,
  className,
}: {
  item: any /* SwiperInterface.SwiperItemInterface; */;
  className?: string;
}) {
  const navigate = useNavigate();

  const handleClickLink = (link: string) => {
    (window as any).gaWrite(
      'web_click_main_art',
      'home',
      item?.name + '/' + item?.artist_info?.name
    );
    navigate(`/art_sales/art_market/art/${link}`);
  };

  return (
    <div
      className={`product-wrap ${className}`}
      onClick={() => handleClickLink(String(item?.id))}
    >
      <div className='product-img'>
        <img
          src={item?.images?.m}
          alt='art'
          style={{
            width: '100%',
          }}
        />
        {item?.type === 'video' ? (
          <p className='art-market-video-icon'>
            <PlayCircleFilledIcon />
          </p>
        ) : null}
      </div>

      <div className='product-info'>
        <div className='product-title-box'>
          <h4 className='product-title maruBuri-bold'>{item?.name}</h4>
        </div>
        <p className='product-artist sCoreDream'>{item?.artist_info?.name}</p>
      </div>
    </div>
  );
}

import { Outlet } from 'react-router';

import './layoutDefault.scss';
import Lnb from '@components/layouts/Lnb';
import SubMenuBanner from '@components/layouts/SubMenuBanner';
import { useRecoilState } from 'recoil';
import { artLandLayout } from '@stores/artLand';

export default function LayoutDefaultLnb() {
  const [artLandLayoutState, setArtLandLayoutState] =
    useRecoilState(artLandLayout);

  return (
    <div className='layout-default-lnb-contents-wrap'>
      {/* <Lnb /> */}
      {!artLandLayoutState && <SubMenuBanner />}

      <div className='layout-default-lnb-contents-inner'>
        <Outlet />
      </div>
    </div>
  );
}

import { useEffect, useState } from 'react';
import { Swiper, SwiperSlide } from 'swiper/react';
import SwiperCore, { Navigation, Pagination } from 'swiper';
import 'swiper/css';
import 'swiper/css/effect-fade';
import 'swiper/css/navigation';
import 'swiper/css/pagination';
import CustomModal from '@components/modal/CustomModal';
import ProductItemArt from './product/ProductItemArt';
import { Box } from '@mui/material';
import ClearIcon from '@mui/icons-material/Clear';
import { ArtSalesInterface } from '@interfaces/artSales/artMarket';
import './multipleSwiperCustom.scss';
import './multipleSwiper.scss';

export default function MultipleSwiperCustom({
  items,
  buttonPrevId,
  buttonNextId,
  alt,
}: {
  items: ArtSalesInterface.ArtMarketDetailImagesInterface[];
  buttonPrevId: string;
  buttonNextId: string;
  alt: string;
}) {
  const urls = items?.map((item: any) => item?.original);

  const [swiper, setSwiper] = useState<SwiperCore>();
  const [imageIndex, setImageIndex] = useState(0);
  const [isModalOpen, setIsModalOpen] = useState(false);

  const openModal = (idx: number) => {
    setImageIndex(idx);
    setIsModalOpen(true);
  };
  const closeModal = () => {
    setIsModalOpen(false);
    document.documentElement.style.overflow = 'auto';
  };

  useEffect(() => {
    if (swiper) {
      swiper.slideTo(imageIndex, 0);
    }
  }, [imageIndex, openModal]);

  return (
    <div className='article-multiple-swiper'>
      <div className='article-multiple-swiper-arrow-wrap'>
        <button
          id={buttonPrevId}
          className='swiper-arrow-btn swiper-arrow-btn-prev'
        ></button>
        <button
          id={buttonNextId}
          className='swiper-arrow-btn swiper-arrow-btn-next'
        ></button>
      </div>

      <Swiper
        modules={[Navigation, Pagination]}
        className='mySwiper'
        centeredSlides={false}
        grabCursor={true}
        pagination={{
          el: '.swiper-pagination', // 페이지네이션 컨테이너 선택자
          clickable: true, // 페이지 번호를 클릭해서 슬라이드로 이동할 수 있는지 설정
        }}
        slidesPerView={4} //처음 보여지는개수
        slidesPerGroup={4} //보여지는 개수 묶음
        spaceBetween='1%'
        navigation={{
          prevEl: `#${buttonPrevId}`,
          nextEl: `#${buttonNextId}`,
        }} //버튼 커스텀
        breakpoints={{
          0: {
            slidesPerView: 3,
            slidesPerGroup: 3,
          },
          780: {
            slidesPerView: 3,
            slidesPerGroup: 3,
          },
          1200: {
            slidesPerView: 4,
            slidesPerGroup: 4,
          },
        }}
      >
        {items &&
          urls &&
          urls?.map((item: any, idx: number) => {
            return (
              <SwiperSlide key={idx}>
                <ProductItemArt
                  className='product-wrap-full'
                  item={item}
                  onClick={() => openModal(idx)}
                  alt={alt}
                />
              </SwiperSlide>
            );
          })}
      </Swiper>
      <div className='swiper-pagination'></div>

      {/* 작품 상세 modal */}
      {isModalOpen && urls && items && (
        <CustomModal
          open={isModalOpen}
          onClose={closeModal}
          className='custom-full-modal-wrap'
        >
          <Box>
            <div className='art-img-modal-wrap'>
              <div className='art-img-modal-inner'>
                <Swiper
                  modules={[Navigation, Pagination]}
                  loop={false}
                  speed={800}
                  className='mySwiper'
                  centeredSlides={true}
                  grabCursor={true}
                  slidesPerView={1}
                  slidesPerGroup={1}
                  navigation={{
                    prevEl: `#artImgModalBtnPrev`,
                    nextEl: `#artImgModalBtnNext`,
                  }}
                  onSwiper={setSwiper}
                >
                  {items &&
                    urls &&
                    urls?.map((item: any, idx: number) => {
                      return (
                        <SwiperSlide key={idx} className='swiper-wrap'>
                          <div className='img-box'>
                            <img src={item} alt={alt} />
                          </div>
                        </SwiperSlide>
                      );
                    })}
                </Swiper>
              </div>

              <div className='art-img-modal-btn-wrap'>
                <button
                  className='art-img-modal-btn art-img-modal-btn-prev'
                  id='artImgModalBtnPrev'
                ></button>
                <button
                  className=' art-img-modal-btn art-img-modal-btn-next'
                  id='artImgModalBtnNext'
                ></button>
              </div>
              <button className='modal-close-btn' onClick={closeModal}>
                <ClearIcon />
              </button>
            </div>
          </Box>
        </CustomModal>
      )}
    </div>
  );
}

import { useEffect, useState } from 'react';
import { Outlet, useLocation } from 'react-router';
import { useTranslation } from 'react-i18next';
import { useRecoilState } from 'recoil';
import { guestId } from '@stores/guest';
import { getVersion } from '@services/VersionService';
import { PolicyService } from '@services/Policy';
import PolicyHeader from '@components/layouts/Header/PolicyHeader';
import FloatingMain from '@components/floating/FloatingMain';
import FooterPolicy from '@components/layouts/Footer/footerPolicy';
import PolicyTab from '@pages/Policy/PolicyTab';
import { PolicyInterface } from '@interfaces/policy/policy';
import top from '@assets/images/floating/top.png';
import './layoutPolicy.scss';

export default function LayOutPolicy() {
  const { t } = useTranslation();
  const location = useLocation();
  const currentUrl = location.pathname;
  const [guestIdState, setGuestIdState] = useRecoilState(guestId);
  const [versionData, setVersionData] = useState({
    server_type: '',
    api_version: '',
    is_display: '',
  });
  const [content, setContent] = useState<
    PolicyInterface.PolicyContentsInterface[]
  >([
    {
      id: 0,
      name: '',
      key: '',
      value: '',
      created_at: 0,
    },
  ]);

  const [lists, setLists] = useState([]);
  const [activeList, setActiveList] = useState(0);

  const getVersionData = async () => {
    try {
      const data = await getVersion(guestIdState);
      setVersionData(data.data.data);
    } catch (err) {
      console.log(err);
    }
  };

  const handleClickTop = () => {
    window.scrollTo({
      top: 0,
      behavior: 'smooth',
    });
  };

  const getContents = async () => {
    const data = await PolicyService.getServiceRule(guestIdState);

    //메뉴
    //TODO :추후 list 추가되면 변경 필요
    let ruleLists = data?.list?.filter(
      (item: PolicyInterface.PolicyContentsInterface) =>
        item.key === 'artland_v2_tos' /* ||
        item.key === 'rule_privacy_policy' ||
        item.key === 'manager_info' ||
        item.key === 'rule_terms_and_condition' ||
        item.key === 'is_display_version' */
    );
    setLists(ruleLists);

    //내용
    //TODO :추후 list 추가되면 변경 필요
    let filteredContent;
    if (currentUrl.includes('/policy/service')) {
      filteredContent = data?.list?.filter(
        (item: PolicyInterface.PolicyContentsInterface) =>
          item.key === 'artland_v2_tos'
      );
    }
    setContent(filteredContent[0]);
    setActiveList(filteredContent[0].id);
  };

  useEffect(() => {
    getVersionData();
  }, []);

  useEffect(() => {
    document.documentElement.style.overflow = 'auto';
    getContents();
  }, []);

  return (
    <div className='layout-default-contents-wrap layout-policy-wrap'>
      {versionData?.is_display === 'Y' ? (
        <div className='version-wrap'>
          <span>개발환경: {versionData.server_type}</span> &#47;
          <span> 버전: {versionData.api_version}</span>
        </div>
      ) : null}
      <PolicyHeader />
      {/*//TODO :추후 list 추가되면 변경 필요(tab) */}
      {/*  {lists && <PolicyTab lists={lists} activeList={activeList} />} */}

      <div className='sub-page-contents-inner'>
        <Outlet context={{ content }} />
      </div>

      <div className='main-floating-wrap'>
        <FloatingMain
          className='main-floating-btn meta three'
          img={top}
          text={t('floatingTop')}
          alt={t('floatingTop')}
          onClick={handleClickTop}
        />
      </div>

      <FooterPolicy />
    </div>
  );
}

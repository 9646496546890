import { httpCommunicator } from '@api/httpCommunicator';

const url = process.env.REACT_APP_API_URL;

export async function getArtUrl(values: any) {
  const res = await httpCommunicator(
    `${url}/v1/art/getHighQualityArtUrl`,
    'get',
    {
      params: values,
    },
    {}
  );
  return res.data;
}

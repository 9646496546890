import { useEffect, useState } from 'react';
import CustomModal from '@components/modal/CustomModal';
import { Box } from '@mui/material';
import { ArtistApplyInterface } from '@interfaces/youngartist/ArtistApplyInterface';
import './ArtistApplyRadioAgreement.scss';
import ClearIcon from '@mui/icons-material/Clear';

export default function ArtistApplyRadioAgreement({
  onChange,
  values,
  setValues,
  helperText,
  setHelperText,
}: ArtistApplyInterface.ArtistApplyAgreementInterface) {
  const [isChecked, setIsChecked] = useState([
    false,
    false,
    false,
    false,
    false,
  ]);
  const handleChangeValue = (index: number) => {
    const newCheckedState = isChecked.map((value, i) =>
      i === index ? !value : value
    );
    setIsChecked(newCheckedState);
  };

  //보기 modal
  const [isModalOpen, setIsModalOpen] = useState(false);

  const openModal = () => {
    setIsModalOpen(true);

    document.documentElement.style.overflow = 'hidden';
  };
  const closeModal = () => {
    setIsModalOpen(false);
    document.documentElement.style.overflow = 'auto';
  };

  useEffect(() => {
    const allChecked = isChecked.every((value) => value);
    setValues(allChecked);

    if (values && setHelperText) {
      setHelperText((helperText) => ({
        ...helperText,
        totalAgreement: '',
      }));
    }
  }, [isChecked, values]);

  return (
    <div className='radioWrap artistApplyRadioAgreementWrap'>
      <div>
        <p className='inputTitle'>유의사항</p>
        <div className='noticeContentWrap'>
          <p>
            1. 공모일정, 발표 및 시상 시기는 사정에 따라 변경될 수 있으며 심사
            내용은 공개하지 않습니다.
          </p>
          <p>
            2. 응모작품 수, 심사 결과에 따라 시상 작품 수가 조정되거나 수상작이
            없을 수 있습니다.
          </p>
          <p>
            3. 수상자의 잘못된 정보 입력, 내용 불충분 등으로 인한 연락 누락,
            부재 등의 경우 수상 결정 후에도 수상이 취소될 수 있으며,
            시상내역(시상금 등) 재지급은 불가합니다.
          </p>
          <p>
            4. 상금에 부과되는 제세공과금은 수상자가 부담합니다.(개인 3.3%
            원천징수)
          </p>
          <p>
            5. 중복 출품(1인이 2번 이상 출품)은 가능하나 동일인에 대한 중복
            시상은 불가합니다.
          </p>
        </div>
        <label className='labelInputCheckBox'>
          <input
            type='checkBox'
            name='classicCheckBox'
            checked={isChecked[0]}
            onChange={() => handleChangeValue(0)}
          />
          <span>확인함</span>
        </label>
      </div>

      <div>
        <p className='inputTitle'>저작권 관련사항</p>
        <div className='noticeContentWrap'>
          <p>
            1. 대상, 최우수상, 우수상 수상작의 저작권(2차 저작물포함)은 주최사인
            시크릿타운(주)에 귀속됩니다. 모든 수상작은 온,오프라인 전시, 홍보
            등의 목적으로 활용, 배포 될 수 있습니다.
          </p>
          <p>
            2. 제출된 작품(이미지, 영상물, 폰트, 음원 등) 및 수상작은 법적인
            문제가 전혀 없는 것이어야 하고, 제3자의 초상권, 명예훼손, 저작권을
            포함한 지적재산권 등을 침해하지 않아야 하며 이와 관련된 민형사상의
            모든 책임은 응모자에게 있으며, 해당 작품은 수상목록에서 제외될 수
            있습니다.
          </p>
          <p>
            3. 제출된 작품의 일부 또는 전부가 이미 공지된 작품이거나 타인의
            저작물·명의 도용, 표절 등의 작품을 제출한 경우 심사에서 제외되며,
            선정 후 적발 시 수상이 취소되고 시상금 등은 환수 조치합니다.
          </p>
        </div>
        <label className='labelInputCheckBox'>
          <input
            type='checkBox'
            name='classicCheckBox'
            checked={isChecked[1]}
            onChange={() => handleChangeValue(1)}
          />
          <span>확인함</span>
        </label>
      </div>

      <div>
        <p className='inputTitle'>당선작 무효사례</p>
        <div className='noticeContentWrap'>
          <p>1. 응모자의 창작물(저작물)이 아닌 타인의 작품 제출</p>
          <p>2. 표절, 도용</p>
          <p>3. 지적재산권 침해작품</p>
          <p>4. 제3자에 대한 초상권 및 명예를 훼손한 작품</p>
          <p>5. 수상경력이 있는 작품</p>
        </div>
        <label className='labelInputCheckBox agreeGap'>
          <input
            type='checkBox'
            name='classicCheckBox'
            checked={isChecked[2]}
            onChange={() => handleChangeValue(2)}
          />
          <span>확인함</span>
        </label>
      </div>
      <label className='labelInputCheckBox'>
        <input
          type='checkBox'
          name='classicCheckBox'
          checked={isChecked[3]}
          onChange={() => handleChangeValue(3)}
        />
        <span>
          접수 마감일(2024.03.31) 이후 출품 취소와 출품료 환불이 불가합니다.
        </span>
      </label>
      <label className='labelInputCheckBox'>
        <input
          type='checkBox'
          name='classicCheckBox'
          checked={isChecked[4]}
          onChange={() => handleChangeValue(4)}
        />
        <span>개인정보 수집 및 이용에 동의합니다.</span>
      </label>

      <p className='agreementModalClickTxt' onClick={openModal}>
        보기
      </p>

      <CustomModal open={isModalOpen} onClose={closeModal}>
        <div className='orderAgree-modal-wrap'>
          <h3 className='modal-title maruBuri'>이용 약관</h3>
          <button className='modal-close-btn' onClick={closeModal}>
            <ClearIcon />
          </button>
          <div className='orderAgree-modal-text-box sCoreDream'>
            <div className='orderAgree-modal-text-box-inner'>
              <p>1. 수집 하는 내용 : 이름, 연락처, 이메일</p>
              <p>2. 수집 목적: 문의자 확인, 문의에 대한 회신 등의 처리</p>
              <p>
                3. 보유 기간 : 목적 달성 후 파기, 단, 관계법령에 따라 또는 회사
                정책에 따른 정보보유사유가 발생하여 보존할 필요가 있는 경우에는
                필요한 기간 동안 해당 정보를 보관합니다. 전자상거래 등에서의
                소비자 보호에 관한 법률, 전자금융거래법, 통신비밀보호법 등
                법령에서 일정기간 정보의 보관을 규정하는 경우, 이 기간 동안
                법령의 규정에 따라 개인정보를 보관하며, 다른 목적으로는 절대
                이용하지 않습니다.
              </p>
              <p>
                4. 귀하는 회사의 정보수집에 대해 동의하지 않거나 거부할 수
                있습니다. 다만, 이때 원활한 문의 및 서비스 이용 등이 제한될 수
                있습니다.
              </p>
            </div>
          </div>
        </div>
      </CustomModal>
    </div>
  );
}

import { Routes, Route, useLocation } from 'react-router-dom';
import LayoutDefault from '@layout/LayoutDefault';
import Maintenance from '@pages/Maintenance';
import MainWrap from '@pages/MainWrap';
import Analytics from 'Analytics';
import LayoutArtInfo from '@layout/LayoutArtInfo';
import ArtInfo from '@pages/ArtInfo';
import ArtInfoNotFound from '@pages/ArtInfo/ArtInfoNotFound';
import GallerySt from '@pages/GallerySt';
import LayoutDefaultLnb from '@layout/LayoutDefaultLnb';
import ArtMarket from '@pages/ArtSales/ArtMarket';
import OpenMarket from '@pages/ArtSales/OpenMarket';
import PieceTrade from '@pages/ArtSales/PieceTrade';
import GroupBuying from '@pages/ArtSales/GroupBuying';
import Notice from '@pages/NewsRoom/Notice';
import NoticeDetail from '@pages/NewsRoom/Notice/Detail';
import NewsLetter from '@pages/NewsRoom/NewsLetter';
import NewsLetterDetail from '@pages/NewsRoom/NewsLetter/Detail';
import ArtConsulting from '@pages/Service/ArtConsulting';
import ExhibitionhallRental from '@pages/Service/ExhibitionhallRental';
import MetaverseExhibition from '@pages/Service/MetaverseExhibition';
import Faq from '@pages/Service/Inquiry/Faq';
import Qna from '@pages/Service/Inquiry/Qna';
import AcademyInfo from '@pages/Community/AcademyInfo';
import ClassApply from '@pages/Community/ClassApply';
import SignUp from '@pages/User/SignUp';
import Login from '@pages/User/Login';
import FindIdPw from '@pages/User/FindIdPw';
import Profile from '@pages/MyPage/Profile';
import MyAdvantage from '@pages/MyPage/MyAdvantage';
import MyCollection from '@pages/MyPage/MyCollection';
import PurchaseHistory from '@pages/MyPage/PurchaseHistory';
import ClassHistory from '@pages/MyPage/ClassHistory';
import MyInquiry from '@pages/MyPage/MyInquiry';
import Cart from '@pages/Cart';
import Inquiry from '@pages/Service/Inquiry';
import Art from '@pages/ArtSales/ArtMarket/Art';
import ArtMarketOrderSheet from '@pages/artMarketOrderSheet';
import ArtMarketOrderSheetSuccess from '@pages/artMarketOrderSheet/ArtMarketOrderSheetSuccess';
import OrderCheck from '@pages/OrderCheck';
import HotelArtFairContest from '@pages/HotelArtFairContest';
import HotelArtFairTicketingOrder from '@pages/HotelArtFairTicketing/HotelArtFairTicketingOrder';
import HotelArtFairQr from '@pages/HotelArtFairTicketing/HotelArtFairQr';
import HotelArtFairTicketRefund from '@pages/HotelArtFairTicketing/HotelArtFairTicketRefund';
import HotelArtFairTicketIntro from '@pages/HotelArtFairTicketing/HotelArtFairTicketIntro';
import ArtLandArt from '@pages/ArtSales/ArtMarket/ArtLandArt';
import YoungartistApply from '@pages/YoungartistApply';
import YoungartistContest from '@pages/YoungartistContest';
import YoungartistApplyUpdate from '@pages/YoungartistApply/YoungartistApplyUpdate';
import LayOutPolicy from '@layout/LayOutPolicy';
import Policy from '@pages/Policy/Policy';
import License from '@pages/Policy/License';
import IosMaintenance from '@pages/Maintenance/IosMaintenance';
import HinhMaintenance from '@pages/Maintenance/HinhMaintenance';
import IosHinhMaintenance from '@pages/Maintenance/IosHinhMaintenance';
import ZoomInWrap from '@pages/ZoomInWrap';
import LayoutDefaultFullWrap from '@layout/LayoutDefaultFullWrap';
import Artland from '@pages/Artland';

export default function AppRoute(): JSX.Element {
  /*   const location = useLocation();
  Analytics(location); //GA */

  return (
    <Routes>
      <Route element={<LayoutDefault />}>
        <Route path='/' element={<MainWrap />} />
        <Route path='/galleryst/about' element={<GallerySt />} />
        <Route path='/artland' element={<Artland />} />
        <Route path='/artland/guide' element={<Artland />} />
        <Route path='/user/signup' element={<SignUp />} />
        <Route path='/user/login' element={<Login />} />
        <Route path='/user/find' element={<FindIdPw />} />
        <Route path='/cart' element={<Cart />} />
        <Route path='/order_check' element={<OrderCheck />} />

        <Route
          path='/2023_hotel_artfair/contest'
          element={<HotelArtFairContest />}
        />
        <Route
          path='/2023_hotel_artfair/intro'
          element={<HotelArtFairTicketIntro />}
        />
        <Route
          path='/2023_hotel_artfair/ticketing'
          element={<HotelArtFairTicketingOrder />}
        />
        <Route
          path='/2023_hotel_artfair/ticket_refund'
          element={<HotelArtFairTicketRefund />}
        />

        {/*2024_Youngartist */}
        <Route
          path='/2024_youngartist/contest'
          element={<YoungartistContest />}
        />
        <Route path='/2024_youngartist/apply' element={<YoungartistApply />} />
        <Route
          path='/2024_youngartist/apply/update'
          element={<YoungartistApplyUpdate />}
        />

        <Route element={<LayoutDefaultLnb />}>
          <Route path='/art_sales/art_market' element={<ArtMarket />} />
          <Route path='/art_sales/art_market/art/:id' element={<Art />} />
          {/* 아트랜드 전용 art-서울 아트쇼 */}
          {/*  <Route
            path='/art_sales/art_market/art_land/art/:id'
            element={<ArtLandArt />}
          /> */}
          <Route
            path='/art_sales/art_market/order_sheet'
            element={<ArtMarketOrderSheet />}
          />
          <Route
            path='/art_sales/art_market/order_sheet/success'
            element={<ArtMarketOrderSheetSuccess />}
          />
          <Route path='/art_sales/open_market' element={<OpenMarket />} />
          <Route path='/art_sales/piece_trade' element={<PieceTrade />} />
          <Route path='/art_sales/group_buying' element={<GroupBuying />} />
          <Route path='/community/academy_info' element={<AcademyInfo />} />
          <Route path='/community/class_apply' element={<ClassApply />} />
          <Route path='/newsroom/notice' element={<Notice />} />
          <Route
            path='/newsroom/notice/detail/:id'
            element={<NoticeDetail />}
          />
          <Route path='/newsroom/newsletter' element={<NewsLetter />} />
          <Route
            path='/newsroom/newsletter/detail/:id'
            element={<NewsLetterDetail />}
          />
          <Route
            path='/service/exhibitionhall_rental'
            element={<ExhibitionhallRental />}
          />
          <Route
            path='/service/metaverse_exhibition'
            element={<MetaverseExhibition />}
          />
          <Route path='/service/art_consulting' element={<ArtConsulting />} />
          <Route path='/service/inquiry' element={<Inquiry />} />
          <Route path='/service/inquiry/faq' element={<Faq />} />
          <Route path='/service/inquiry/qna' element={<Qna />} />
          {/*  <Route path='/mypage/profile' element={<Profile />} />

          <Route path='/mypage/my_collection' element={<MyCollection />} />
          <Route
            path='/mypage/purchase_history'
            element={<PurchaseHistory />}
          />
          <Route path='/mypage/class_history' element={<ClassHistory />} />
          <Route path='/mypage/my_inquiry' element={<MyInquiry />} /> */}
          {/* <Route path='/mypage/my_advantage' element={<MyAdvantage />} /> */}
        </Route>
      </Route>

      <Route element={<LayoutArtInfo />}>
        {/*  <Route path='/artinfo/:id' element={<ArtInfo />} />
        <Route path='/artinfo/not_found' element={<ArtInfoNotFound />} /> */}
      </Route>

      <Route path='/2023_hotel_artfair/qr/:qr' element={<HotelArtFairQr />} />

      {/* 점검 페이지 */}
      <Route path='/maintenance' element={<Maintenance />} />
      {/* 현대 백화점 점검 페이지 */}
      <Route path='/maintenance/hinh' element={<HinhMaintenance />} />
      {/* 현대 백화점 iOS 점검 페이지 */}
      <Route path='/ios/hinh' element={<IosHinhMaintenance />} />
      {/* 아트랜드-iOS 준비 페이지 */}
      <Route path='/ios' element={<IosMaintenance />} />

      {/* 이용약관 */}
      <Route element={<LayOutPolicy />}>
        <Route path='/policy/service' element={<Policy />} />
        {/* <Route path='/license' element={<License />} /> */}
      </Route>

      {/*줌인아웃*/}
      <Route element={<LayoutDefaultFullWrap />}>
        <Route path='/zoom/:id?' element={<ZoomInWrap />} />
      </Route>
    </Routes>
  );
}

import "./academyInfo.scss";
import { useNavigate } from "react-router-dom";
import { Trans, useTranslation } from "react-i18next";
import { Swiper, SwiperSlide } from "swiper/react";
import { Navigation } from "swiper";
import "swiper/css";
import "swiper/css/effect-fade";
import "swiper/css/navigation";

import ArrowLeftIcon from "@mui/icons-material/ArrowLeft";
import ArrowRightIcon from "@mui/icons-material/ArrowRight";

export default function AcademyInfo() {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const classList = [
    {
      img: "preparing.png",
      status: 3,
    },
    {
      img: "preparing.png",
      status: 3,
    },
  ];
  return (
    <div className="sub-page-contents-wrap community-academyInfo-wrap">
      <div className="sub-page-contents-inner">
        <div className="academyInfo-description-wrap">
          <div className="academyInfo-description-text-box">
            <h2 className="maruBuri">{t("communityAcademyInfoTopBoxTitle")}</h2>
            <p className="sCoreDream">
              <Trans
                i18nKey={`communityAcademyInfoTopBoxText`}
                components={[<span />]}
              />
            </p>
            <div className="bottom-text sCoreDream">
              <span>{t("communityAcademyInfoTopBoxBottom1")}</span>
              <span>{t("communityAcademyInfoTopBoxBottom2")}</span>
              <span>{t("communityAcademyInfoTopBoxBottom3")}</span>
              <span>{t("communityAcademyInfoTopBoxBottom4")}</span>
              <span>{t("communityAcademyInfoTopBoxBottom5")}</span>
            </div>
          </div>
          <div className="btn-box maruBuri">
            <button onClick={() => navigate("/community/class_apply")}>
              {t("communityAcademyInfoTopBoxBtn")}
            </button>
          </div>
        </div>
        <div className="community-class-wrap">
          <h2 className="maruBuri">{t("communityAcademyInfoTitle")}</h2>
          <div className="community-class-swiper-box">
            <div className="swiper-button-box">
              <div className="button-box-inner">
                <button id="prevBtn" className="prevBtn-custom">
                  <ArrowLeftIcon />
                </button>
                <button id="nextBtn" className="nextBtn-custom">
                  <ArrowRightIcon />
                </button>
              </div>
            </div>
            <Swiper
              modules={[Navigation]}
              className="communitySwiper"
              centeredSlides={false}
              grabCursor={true}
              slidesPerView={2} //처음 보여지는개수
              slidesPerGroup={1} //보여지는 개수 묶음
              spaceBetween="2.8%" //slide gap
              navigation={{
                prevEl: `#prevBtn`,
                nextEl: `#nextBtn`,
              }} //버튼 커스텀
              breakpoints={{
                769: {
                  slidesPerView: 2,
                },
                0: {
                  slidesPerView: 1.2,
                },
              }}
            >
              {classList.map((items, i) => {
                return (
                  <SwiperSlide key={i} className="swiper-wrap">
                    <div className="img-box">
                      <img src={`/images/${items.img}`} alt="준비 중 이미지" />
                    </div>
                    <div className="title-box">
                      <span className={items.status === 1 ? "possible" : ""}>
                        {items.status === 1 ? "" : "준비중"}
                      </span>
                      {/* <p>클래스 명</p> */}
                    </div>
                  </SwiperSlide>
                );
              })}
            </Swiper>
          </div>
        </div>
      </div>
    </div>
  );
}

import './orderCheck.scss';
import { useEffect, useState } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import ArrowIcon from '@assets/images/btn/btn_more_blue@3x_over.png';
import MoreWhiteBtn from './../../components/btns/MoreWhiteBtn';
import CustomModal from '@components/modal/CustomModal';
import ClearIcon from '@mui/icons-material/Clear';
import ArtSelectBox from '@pages/artMarketOrderSheet/ArtSelectBox';
import OrderArtListsBox from './OrderArtListsBox';
import changeDateSeconds from 'helpers/changeDate/changeDateSeconds';
import TextArea from '@components/Input/TextArea';
import { useForm } from '@hooks/useForm';
import { postCancelOrder, postRefundOrder } from '../../services/OrderService';
import Modal from '../../components/modal/CommonModal';
import PriorityHighIcon from '@mui/icons-material/PriorityHigh';
import regex from 'helpers/regex';
import { useRecoilState } from 'recoil';
import { guestId } from '@stores/guest';
import Loading from '@components/Loading/Loading';

export default function OrderCheckSuccess({
  className,
  orderData,
  orderDataItem,
  update,
}: {
  className?: string;
  orderData: any | any[];
  orderDataItem: any | any[];
  update: any;
}) {
  const { t } = useTranslation();
  const [guestIdState, setGuestIdState] = useRecoilState(guestId);

  const navigate = useNavigate();
  const [values, setValues] = useState({
    content: '',
    refundReason: '',
  });

  const [loading, setLoading] = useState(false);
  const [isModalView, setIsModalView] = useState(false);
  const [modalText, setModalText] = useState('');
  //modal
  const [viewOrderListModal, setViewOrderListModal] = useState(false);
  const openOrderListModal = () => {
    document.documentElement.style.overflow = 'hidden';
    setViewOrderListModal(true);
  };
  const closedOrderListModal = () => {
    setViewOrderListModal(false);
    document.documentElement.style.overflow = 'auto';
  };
  const [viewOrderCancelModal, setViewOrderCancelModal] = useState(false);
  const openOrderCancelModal = () => {
    try {
      (window as any).gaWrite(
        'web_click_artinfo_order_cancel_stop_01 ',
        'order_check',
        ''
      );
    } catch (e) {}
    document.documentElement.style.overflow = 'hidden';
    setViewOrderCancelModal(true);
  };
  const closedOrderCancelModal = () => {
    setViewOrderCancelModal(false);
    document.documentElement.style.overflow = 'auto';
  };
  const closedOrderCancelModalBtn = async () => {
    const data = await postCancelOrder(
      'artMarket',
      orderData.order_number,
      guestIdState
    );
    await update();
    if (data.code == 200) {
      setModalText('주문이 취소되었습니다.');
      setIsModalView(true);
    }

    setViewOrderCancelModal(false);
  };
  const [viewOrderRefundModal, setViewOrderRefundModal] = useState(false);
  const openOrderRefundModal = () => {
    document.documentElement.style.overflow = 'hidden';
    setViewOrderRefundModal(true);
  };
  const closedOrderRefundModal = () => {
    try {
      (window as any).gaWrite(
        'web_click_artinfo_order_refund_apply_close ',
        'refund',
        ''
      );
    } catch (e) {}

    setViewOrderRefundModal(false);
    setValues({
      content: '',
      refundReason: '',
    });
    document.documentElement.style.overflow = 'auto';
  };
  const closedOrderRefundModalBut = async (e: any) => {
    e.preventDefault();
    if (
      (values.refundReason as string).length < 1 ||
      !regex.nonGap.test(values.refundReason)
    ) {
      alert('환불 사유는 공백으로 입력 할 수 없습니다.');
      return;
    } else {
      setLoading(true);
      const data = await postRefundOrder(
        'artMarket',
        orderData.order_number,
        values?.refundReason as string,
        guestIdState
      );
      await update();
      if (data.code === 200) {
        setLoading(false);
        setModalText('환불 신청되었습니다.');
        setIsModalView(true);
      }
      setViewOrderRefundModal(false);
    }
  };

  const orderStatusText = (status: number, shipping_status: number) => {
    if (status === 2) {
      return shipping_status === 1
        ? '주문 완료'
        : shipping_status === 2
        ? '배송 준비중'
        : '배송 완료';
    } else if (status === 3) {
      return '주문 취소';
    } else if (status === 4) {
      return '환불 신청';
    } else if (status === 5) {
      return '환불 완료';
    }
  };

  //

  ///임의
  /*   const getOrderData = async () => {
    try {
      const body = {
        order_number: 1696392071925416,
        name: '김성범수정22',
        email: 'ksungb206@naver.com22',
      };
      const data = await getArtMarketLists(body);

      setArtMarketData(data.data.list);
    } catch (err) {
      console.log(err);
    }
  };

  useEffect(() => {
    getArtMarketData();
  }, []);
 */
  /*
     <p>{orderData.order_number}</p>
                    <p>{orderData.phone}</p>
                    <p>{orderData.order_address}</p>
                    <p>{orderData.order_detail_address}</p> */
  // console.log(orderDataItem);

  return (
    <div className={`orderCheck-succuss-wrap ${className}`}>
      <div className='orderCheck-show orderCheck-succuss-inner center'>
        <div className='orderCheck-succuss-inner-content'>
          <div className='orderCheck-succuss-inner-content-item'>
            <span>{t('orderCheckInputTitle1')}</span>
            <p>{orderData.order_number}</p>
          </div>
          <div className='orderCheck-succuss-inner-content-item'>
            <span>{t('orderCheckSuccessTitle2')}</span>
            <p>{changeDateSeconds(orderData.payment_log.created_at)}</p>
            {/* 결제일자 */}
          </div>
          <div className='orderCheck-succuss-inner-content-item'>
            <span>{t('orderCheckSuccessTitle3')}</span>
            <div className='orderDetail-btn-box'>
              <p className='order-des'>
                <span className='order-art-title-ellipsis'>
                  {orderDataItem[0].art_info?.name}
                </span>
                {orderDataItem.length > 1 ? (
                  <span className='order-des-txt2'>
                    {t('orderCheckSuccessTxt9')}
                  </span>
                ) : null}
              </p>

              {/* 주문 내역 상세보기 버튼 */}
              <button onClick={openOrderListModal}>
                {t('orderCheckSuccessButton1')}
              </button>
            </div>
          </div>
          {/* 주문상태 */}
          <div className='orderCheck-succuss-inner-content-item'>
            <span>{t('orderCheckSuccessTitle4')}</span>
            <p>
              {orderStatusText(orderData.status, orderData.shipping_status)}
            </p>
          </div>
          {/*  <div className='orderCheck-succuss-inner-content-item'>
            <span>{t('orderCheckSuccessTitle4')}</span>
            <p>배송 준비중 입니다.</p>
          </div> */}
        </div>
      </div>
      <div className='orderCheck-success-btn-box center'>
        <MoreWhiteBtn
          className='orderCheck-btn white shadowNone white-border'
          text={t('orderCheckSuccessButton2')}
          onClick={() => navigate('/')} /* 메인으로 바로가기 */
        />

        {orderData.shipping_status == 1 && orderData.status == 2 && (
          <MoreWhiteBtn
            className='orderCheck-btn margin-btn white shadowNone white-border ' /*  hidden */
            text={t('orderCheckSuccessButton3')}
            onClick={openOrderCancelModal} /* 주문취소 */
          />
        )}
        {orderData.shipping_status == 2 && orderData.status == 2 && (
          <button
            className='pretendard refundBtn'
            /*hidden */ onClick={() => {
              try {
                (window as any).gaWrite(
                  'web_click_artinfo_order_refund_apply_01',
                  'refund',
                  ''
                );
              } catch (e) {}
              openOrderRefundModal();
            }}
          >
            <p>{t('orderCheckSuccessButton4')}</p>
            <img src={ArrowIcon} alt='' />
          </button>
        )}
        {/* 환불신청 */}
      </div>

      {/* 주문내역 상세보기 모달 */}
      <CustomModal open={viewOrderListModal} onClose={closedOrderListModal}>
        <div className='orderAgree-modal-wrap order-detail-modal-wrap'>
          <h3 className='modal-title maruBuri'>
            <p>{t('orderCheckSuccessButton1')}</p>
          </h3>
          <button className='modal-close-btn' onClick={closedOrderListModal}>
            <ClearIcon />
          </button>
          <div className='orderAgree-modal-text-box sCoreDream'>
            <div className='orderAgree-modal-text-box-inner'>
              <OrderArtListsBox
                items={orderDataItem}
                orderDataTotalPrice={orderData.price}
              />
              <div className='border-box payment-wrap contents-wrap'>
                <p className='main-title maruBuri'>
                  {t('orderCheckSuccessTxt1')}
                </p>
                <div className='box'>
                  <div className='box-contents'>
                    <div className='box-title'>
                      {t('orderCheckSuccessTxt2')}
                    </div>
                    <p className='box-txt'>
                      {orderData.payment_log.pay_method}
                      {orderData.payment_log.fn_name && (
                        <span>{orderData.payment_log.fn_name}</span>
                      )}
                    </p>
                  </div>
                  <div className='box-contents'>
                    <div className='box-title'>
                      <p>{t('orderCheckSuccessTxt3')}</p>
                    </div>
                    <p className='box-txt sCoreDream'>
                      {changeDateSeconds(orderData.payment_log.created_at)}
                    </p>
                  </div>
                </div>
              </div>

              {/* 배송지 정보 */}
              {/*               <div className='border-box payment-wrap'>
                <p className='main-title maruBuri'>
                  {t('orderCheckSuccessTxt4')}
                </p>
                <div className='box'>
                  <div className='box-title sCoreDream'>
                    <p>{t('orderCheckSuccessTxt5')}</p>
                    <p>{t('orderCheckSuccessTxt6')}</p>
                    <p>{t('orderCheckSuccessTxt7')}</p>
                    <p>{t('orderCheckSuccessTxt8')}</p>
                  </div>
                  <div className='box-txt sCoreDream'>
                    <p>{orderData.order_name}</p>
                    <p>{orderData.phone}</p>
                    <p>
                      {orderData.order_address}{' '}
                      <span>{orderData.order_detail_address}</span>
                    </p>
                    <p>{orderData.delivery_request_message}</p>
                  </div>
                </div>
              </div> */}

              <div className='border-box contents-wrap'>
                <p className='main-title maruBuri'>
                  {t('orderCheckSuccessTxt4')}
                </p>
                <div className='box sCoreDream'>
                  <div className='box-contents'>
                    <div className='box-title'>
                      {t('orderCheckSuccessTxt5')}
                    </div>
                    <p className='box-txt'>{orderData.order_name}</p>
                  </div>

                  <div className='box-contents'>
                    <div className='box-title'>
                      {t('orderCheckSuccessTxt6')}
                    </div>
                    <p className='box-txt'>{orderData.order_phone}</p>
                  </div>

                  <div className='box-contents'>
                    <div className='box-title'>
                      {t('orderCheckSuccessTxt7')}
                    </div>
                    <p className='box-txt'>
                      {orderData.order_address}{' '}
                      <span>{orderData.order_detail_address}</span>
                      {orderData.order_zipcode && (
                        <span>
                          {t('orderCheckSuccessTxt10')} &#40;
                          {orderData.order_zipcode}&#41;
                        </span>
                      )}
                    </p>
                  </div>

                  <div className='box-contents'>
                    <div className='box-title'>
                      {t('orderCheckSuccessTxt8')}
                    </div>
                    <p className='box-txt'>
                      {orderData.delivery_request_message}
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </CustomModal>

      {/* 주문취소 모달 */}
      <CustomModal open={viewOrderCancelModal} onClose={closedOrderCancelModal}>
        <div className='orderAgree-modal-wrap order-detail-modal-wrap order-cancel-modal-wrap'>
          <h3 className='modal-title maruBuri'>
            <p>{t('orderCheckCancelTxt')}</p>
          </h3>
          <button className='modal-close-btn' onClick={closedOrderCancelModal}>
            <ClearIcon />
          </button>
          <div className='orderAgree-modal-text-box sCoreDream'>
            <div className='orderAgree-modal-text-box-inner'>
              <OrderArtListsBox
                items={orderDataItem}
                orderDataTotalPrice={orderData.price}
              />
              <div className='border-box payment-wrap'>
                <p className='main-title maruBuri'>
                  {t('orderCheckCancelTxt1')}
                </p>
                <div className='box sCoreDream'>
                  <p className='order-cancel-icon'>
                    <PriorityHighIcon />
                    {t('orderCheckCancelTxt2')}
                  </p>
                </div>
              </div>

              <MoreWhiteBtn
                className='orderCheck-btn margin-btn white shadowNone white-border'
                text={t('orderCheckSuccessButton3')}
                onClick={() => {
                  try {
                    (window as any).gaWrite(
                      'web_click_artinfo_order_cancel_stop_01 ',
                      'order_check',
                      ''
                    );
                  } catch (e) {}
                  closedOrderCancelModalBtn();
                }} /* >임의 추후수정/주문취소 */
              />
            </div>
          </div>
        </div>
      </CustomModal>

      {/* 환불신청 모달 */}
      <CustomModal open={viewOrderRefundModal} onClose={closedOrderRefundModal}>
        <div className='orderAgree-modal-wrap order-detail-modal-wrap order-refund-modal-wrap'>
          <h3 className='modal-title maruBuri'>
            <p>{t('orderCheckSuccessButton4')}</p>
          </h3>
          <button className='modal-close-btn' onClick={closedOrderRefundModal}>
            <ClearIcon />
          </button>
          <div className='orderAgree-modal-text-box sCoreDream'>
            <div className='orderAgree-modal-text-box-inner'>
              <label className='refundReasonLabel'>
                <textarea
                  name='refundReason'
                  onChange={(e) => (values.refundReason = e.target.value)}
                  placeholder={t('placeholder8')}
                  className='refundReason-input sCoreDream'
                  spellCheck={false}
                />
              </label>

              <div className='border-box payment-wrap'>
                <p className='main-title maruBuri'>
                  {t('orderCheckRefundTxt1')}
                </p>
                <div className='box sCoreDream'>
                  <p>
                    <Trans i18nKey={t('orderCheckRefundTxt2')} />
                  </p>
                </div>
              </div>

              <div className='border-box payment-wrap'>
                <p className='main-title maruBuri'>
                  {t('orderCheckRefundTxt4')}
                </p>
                <div className='box sCoreDream'>
                  <p>{t('orderCheckRefundTxt4Txt')}</p>
                </div>
              </div>

              <div className='border-box payment-wrap'>
                <p className='main-title maruBuri'>
                  {t('orderCheckRefundTxt5')}
                </p>
                <div className='box sCoreDream'>
                  <p>{t('orderCheckRefundTxt6')}</p>
                  <p>{t('orderCheckRefundTxt7')}</p>
                  <p>{t('orderCheckRefundTxt8')}</p>
                  <p>{t('orderCheckRefundTxt9')}</p>
                  <p>{t('orderCheckRefundTxt10')}</p>
                  <p>{t('orderCheckRefundTxt11')}</p>
                </div>
              </div>

              <div className='border-box payment-wrap'>
                <p className='main-title maruBuri'>
                  {t('orderCheckRefundTxt12')}
                </p>
                <div className='box sCoreDream'>
                  <p>{t('orderCheckRefundTxt13')}</p>
                  <p>{t('orderCheckRefundTxt14')}</p>
                  <p>{t('orderCheckRefundTxt15')}</p>
                  <p>{t('orderCheckRefundTxt16')}</p>
                  <p>{t('orderCheckRefundTxt17')}</p>
                  <p>{t('orderCheckRefundTxt18')}</p>
                </div>
              </div>

              <MoreWhiteBtn
                className='orderCheck-btn margin-btn white shadowNone white-border'
                text={t('orderCheckSuccessButton5')}
                onClick={(e: any) => {
                  try {
                    //TODO: 테스트를 위하여 가격및 설정 하여 확인 해봐야 하지만 작성 당시 담당자가 바빠 개발 불가능 향후 가능
                    //시기에 다시 개발
                    (window as any).gaWrite(
                      'web_click_artinfo_order_refund_apply_02',
                      'refund',
                      ''
                    );
                  } catch (e) {}
                  closedOrderRefundModalBut(e);
                }} /* >임의 추후수정/환불신청완료 */
              />
            </div>
          </div>
        </div>
      </CustomModal>
      <Modal
        open={isModalView}
        isShowBtn={false}
        btnCloseText={'확인'}
        onClose={() => {
          document.documentElement.style.overflow = 'auto';
          try {
            (window as any).gaWrite(
              'web_click_artinfo_order_cancel_stop_03 ',
              'order_check',
              ''
            );
          } catch (e) {}

          setIsModalView(false);
        }}
        // onClick={closedOrderCancelModalBtn}
        alarmText={modalText}
      />

      {loading && <Loading Loading={loading} />}
    </div>
  );
}

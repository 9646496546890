import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router';
import dayjs from 'dayjs';
import isBetween from 'dayjs/plugin/isBetween';
import btnApply from '@assets/images/btn/btn_apply.png';
import btnCheck from '@assets/images/btn/btn_check.png';
import YoungartistDownLoadBtn from './YoungartistDownLoadBtn';
import ReactGA from 'react-ga4';
// import { getArtistPrizeDate } from '@services/ArtistApplyService';
import Loading from '@components/Loading/Loading';
import './youngartistContest.scss';
import { getYoungartistDate } from '@services/YoungartistService';
import bigTitle from './../../assets/images/youngartist/contest_top.png';
import ClosedBoxNotDate from '@components/closed/ClosedBoxNotDate';

export default function YoungartistContest() {
  const navigate = useNavigate();
  const currentDate = dayjs();
  //모집요강 기한
  dayjs.extend(isBetween);
  const getDate = async () => {
    //공모기간
    let contestLocalStartDate;
    let contestLocalEndDate;
    //구매기간
    let orderLocalStartDate;
    let orderLocalEndDate;
    try {
      //추후 api연동하기
      const data = await getYoungartistDate();
      contestLocalStartDate = data.data.contest_start_date;
      contestLocalEndDate = data.data.contest_end_date;
      orderLocalStartDate = data.data.contest_apply_start_date;
      orderLocalEndDate = data.data.contest_apply_end_date;
    } catch (err) {
      console.log(err);
    }
    const contestStartDate = dayjs(contestLocalStartDate);
    const contestEndDate = dayjs(contestLocalEndDate);
    const orderStartDate = dayjs(orderLocalStartDate);
    const orderEndDate = dayjs(orderLocalEndDate);

    //모집요강페이지
    if (dayjs().isBetween(contestStartDate, contestEndDate, 'minute', '[]')) {
      setIsBetweenDateHiddenContest(false);
    } else {
      setIsBetweenDateHiddenContest(true); //true:hidden
    }
    //신청,수정
    if (dayjs().isBetween(orderStartDate, orderEndDate, 'minute', '[]')) {
      setIsBetweenDateHiddenOrder(false);
    } else {
      setIsBetweenDateHiddenOrder(true); //true:hidden
    }

    setIsPageLoading(false);
  };

  const [isPageLoading, setIsPageLoading] = useState(true);
  //공모요강 페이지
  const [isBetweenDateHiddenContest, setIsBetweenDateHiddenContest] =
    useState(false);
  //신청(&수정)
  const [isBetweenDateHiddenOrder, setIsBetweenDateHiddenOrder] =
    useState(false);

  //신청 버튼
  const handleClickOrder = () => {
    (window as any).gaWrite(
      'web_click_youngartist_order',
      '2024_youngartist/contest',
      ''
    );

    getDate();
    if (!isBetweenDateHiddenOrder) {
      navigate('/2024_youngartist/apply');
    } else {
      alert('신청 기간이 아닙니다.\n(2024.02.15 14:00 ~ 2024.03.31 24:00)');
    }
  };
  //수정 버튼
  const handleClickUpdate = () => {
    (window as any).gaWrite(
      'web_click_youngartist_update_refund',
      '2024_youngartist/contest',
      ''
    );

    getDate();
    if (!isBetweenDateHiddenOrder) {
      navigate('/2024_youngartist/apply/update');
    } else {
      alert('신청 기간이 아닙니다.\n(2024.02.15 14:00 ~ 2024.03.31 24:00)');
    }
  };

  useEffect(() => {
    setIsPageLoading(true);

    getDate(); //날짜get
  }, []);

  useEffect(() => {
    if (isPageLoading) {
      document.documentElement.style.overflow = 'hidden';
    } else {
      document.documentElement.style.overflow = 'auto';
    }
  }, [isPageLoading]);

  return (
    <>
      <div
        className={
          isPageLoading
            ? 'artistContestWrap youngartistContestWrap sCoreDream artistContestWrapGap'
            : !isBetweenDateHiddenContest
            ? `artistContestWrap youngartistContestWrap sCoreDream artistContestWrapView`
            : `artistContestWrap youngartistContestWrap sCoreDream artistContestWrapHidden`
        }
      >
        <div className='artistContestInner'>
          <div className='artistContestContentsWrap'>
            <div className='artistContestPoster'>
              <p className='artistContestBigTitle'>
                <img src={bigTitle} alt='k artist prize' />
              </p>
            </div>

            <div className='artistContentsBox'>
              <div className='artistContentBox'>
                <p className='artistContestPosterLine'></p>
                <div className='artistContentTitle'>참가자격</div>

                <div className='artistContentDes'>
                  만 19세 이상 ~ 만 35세 미만(1990년생 까지)
                  개인 및 팀으로 참가 가능 (갤러리 非 전속)
                </div>
              </div>

              <div className='artistContentBox'>
                <div className='artistContentTitle'>공모부문 / 접수분야</div>

                <div className='artistContentDes'>
                  평면 (회화, 판화, 사진) / 입체 (조각) / 영상 등
                </div>
              </div>

              <div className='artistContentBox'>
                <div className='artistContentTitle'>시상내용</div>

                <div className='artistContentDes'>
                  <p className='strong'>총 상금 : 1,500만 원</p>
                  <p>1) 대상 (1명 / 매입상금) : 500만 원</p>
                  <p>2) 최우수상 (2명 / 매입상금) : 각 300만 원</p>
                  <p>3) 평론가상 (1명 / 매입상금) : 300만 원</p>
                  <p>4) 특별상 (2명) : 각 50만 원</p>
                  <p>5) 특선 (10명) : 상패 및 상장</p>
                </div>
              </div>

              <div className='artistContentBox'>
                <div className='artistContentTitle'>
                  * 공모 수상 작가 혜택
                  <span>(모든 수상자 대상)</span>
                </div>
                <div className='artistContentDes'>
                  <p>
                    1&#41;
                    <span className='strong'>
                      {' '}
                      공모 수상작 특별 단체전 개최
                    </span>
                  </p>
                  <p>
                    2&#41;
                    <span className='strong'>
                      {' '}
                      개인전 장소 무료 제공(홍대 코코넛박스)
                    </span>
                  </p>
                  <p>
                    3&#41;
                    <span className='strong'>
                      {' '}
                      시크릿타운 호텔 아트페어 무료 참여
                    </span>
                  </p>
                  <p>
                    4&#41;
                    <span className='strong'>
                      {' '}
                      메타버스 가상 갤러리 전시 및 온라인 아트마켓 판매 지원
                    </span>
                  </p>
                </div>
              </div>

              <div className='artistContentBox'>
                <div className='artistContentTitle'>접수방법</div>

                <div className='artistContentDes'>
                  <p className='strong'>
                    갤러리ST 홈페이지 접수{' '}
                    <span
                      onClick={() => {
                        navigate('/2024_youngartist/apply');
                      }}
                      className='artistContentLink'
                    >
                      (갤러리ST 홈페이지로 신청 바로가기)
                    </span>
                  </p>
                  <p>
                    홈페이지 내 참가신청서 제출 시 출품 이미지 제출(필수),
                    포트폴리오(선택)
                  </p>
                  <p>파일 용량제한 : 100MB 이하</p>
                </div>
              </div>

              <div className='artistContentBox'>
                <div className='artistContentTitle'>출품료</div>

                <div className='artistContentDes'>
                  <p className='strong'>
                    1점 30,000원 / 2점 50,000원 / 3점 60,000원 (1인 3점까지
                    가능)
                  </p>
                  <p>
                    * 접수 시 카드결제, 계좌이체 결제 가능, 제시된 기간
                    내(3/31까지) 환불 가능
                  </p>
                </div>
              </div>

              <div className='artistContentBox'>
                <div className='artistContentTitle'>작품규격</div>

                <div className='artistContentDes'>
                  <p>
                    1&#41; 평면 및 영상 : 작품 규격과 사이즈에 제한은 없으나
                    수상작은 실내 갤러리에서 전시가 가능한 사이즈의 작품만 출품
                    요망
                  </p>
                  <p>2&#41; 입체 사이즈 안내: 120 x 120 x 180 cm 이내</p>
                  <p>
                    * 출품작은 파일로 제출하되 수상 확정 시, 전시에서 실제 작품
                    제출
                  </p>
                </div>
              </div>

              <div className='artistContentBox'>
                <div className='artistContentTitle'>공모 일정</div>

                <div className='artistContentDes'>
                  <p className='strong'>
                    1&#41; 작품접수 : 2024.02.15 (목) ~ 03.31(일) 24:00 마감
                  </p>
                  <p>
                    2&#41; 당선작 발표 : 2024.04.11 (목) 오후 12시 갤러리ST
                    홈페이지 팝업 공지
                  </p>
                  <p>3&#41; 전시 일정 : 2024.04.25(목) ~ 06.02(일)</p>
                  <p>4&#41; 시상식 일정 : 별도 공지</p>
                </div>
              </div>

              <div className='artistContentBox'>
                <div className='artistContentTitle'>문의</div>

                <div className='artistContentDes'>
                  <p>E-mail : galleryst@secrettown.co.kr</p>
                  <p>&#40;전화문의는 받지 않습니다&#41;</p>
                </div>
              </div>

              <div className='artistContentBox artistContentSupport'>
                <div className='artistContentSupportArticle'>
                  <div className='artistContentTitle'>주최</div>
                  <p className='artistContentDes'>시크릿타운㈜</p>
                </div>
                <div className='artistContentSupportArticle'>
                  <div className='artistContentTitle'>주관</div>
                  <p className='artistContentDes'>갤러리ST</p>
                </div>
                <div className='artistContentSupportArticle'>
                  <div className='artistContentTitle'>후원</div>
                  <p className='artistContentDes'>아트코리아방송</p>
                </div>
              </div>
            </div>

            <div className='artistContestBtnsWrap'>
              <YoungartistDownLoadBtn />

              <p
                className={
                  isBetweenDateHiddenOrder
                    ? `artistContestBtn artistContestBtnDisabled`
                    : `artistContestBtn`
                }
                onClick={handleClickOrder}
              >
                <img src={btnApply} alt='공모전 신청하기' />
              </p>

              <p
                className={
                  isBetweenDateHiddenOrder
                    ? `artistContestBtn artistContestBtnDisabled`
                    : `artistContestBtn`
                }
                onClick={handleClickUpdate}
              >
                <img src={btnCheck} alt='공모전 신청 확인 및 신청서 수정하기' />
              </p>
            </div>
          </div>

          <div className='artistContestWrapHiddenBox'>
            <ClosedBoxNotDate />
          </div>
        </div>
      </div>
      {isPageLoading && <Loading Loading={isPageLoading} />}
    </>
  );
}

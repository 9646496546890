import { useState, useEffect, useRef } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { useParams } from 'react-router';
import { useNavigate } from 'react-router-dom';
import { useRecoilState } from 'recoil';
import { guestId } from '@stores/guest';
import { getArtGetOne, postCreateBasket } from '@services/ArtMarketService';
import { Box } from '@mui/material';

import MoreWhiteBtn from '@components/btns/MoreWhiteBtn';
import formatNumberCommas from 'helpers/formatNumberCommas';
import CommonBorderWhiteBtn from '@components/btns/CommonBorderWhiteBtn';
import './art.scss';
import CustomModalBottomTwoBtn from '@components/modal/CustomModalBottomTwoBtn ';
import Loading from '@components/Loading/Loading';
import MultipleSwiperCustom from '@components/swiper/MultipleSwiperCustom';

import CustomModal from '@components/modal/CustomModal';
import ClearIcon from '@mui/icons-material/Clear';
import VolumeUpIcon from '@mui/icons-material/VolumeUp';

export default function Art() {
  const { id } = useParams();
  const navigate = useNavigate();
  const { t } = useTranslation();
  const metaverse_art_land_url = process.env.REACT_APP_METAVERSE_ARTLAND_URL;

  const [guestIdState, setGuestIdState] = useRecoilState(guestId);
  const [loading, setLoading] = useState(false);
  const [item, setItem] = useState({
    id: 0,
    type: '',
    description: '',
    artist_info: {
      id: 0,
      name: '',
      description: '',
      history: '',
      image_url: '',
      lifetime: '',
      status: 0,
      artist_voice: { id: 0, artist_info_id: 0, original: '', created_at: 0 },
    },
    name: '',
    creation: '',
    size_width: 0,
    size_height: 0,
    size_real: '',
    material: '',
    price: '',
    images: {
      id: 0,
      l: '',
      m: '',
      original: '',
      s: '',
      detail_images: [],
    },
    video: {
      original: '',
      runtime: '',
    },
    voice: {
      original: '',
    },
    sell_status: 0,
    gallery_info: {
      id: '',
      address: '',
      ceo_name: '',
      ceo_phone_number: '',
      created_at: 0,
      homepage_url: '',
      manager_email: '',
      manager_grade: '',
      manager_name: '',
      manager_phone_number: '',
      name: '',
      status: 0,
    },
    detail_display: '',
  });

  const [viewDocent, setViewDocent] = useState(false);
  const [artistViewDocent, setArtistViewDocent] = useState(false);

  //modal
  const [isCartModal, setIsCartModal] = useState(false);
  const openCartModal = () => {
    setIsCartModal(true);
    document.documentElement.style.overflow = 'hidden';
  };
  const closeCartModal = () => {
    setIsCartModal(false);
    document.documentElement.style.overflow = 'auto';
  };

  const moveQnaPage = () => {
    window.localStorage.setItem('qnaType', 'buyType');
    navigate('/service/inquiry', { state: id });
  };

  const handleClickCart = async () => {
    (window as any).gaWrite(
      'web_click_artinfo_detail_basket',
      'artsales',
      item?.name + '/' + item?.artist_info?.name
    );
    if (item?.sell_status === 1) {
      try {
        const body = {
          art_info_id: id,
          guest_session_id: guestIdState,
        };

        const data = await postCreateBasket(body, guestIdState);

        if (data.code === 200) {
          openCartModal();
        } else if (data.code === 6000) {
          alert(t('alertTxtErr3'));
        } else {
          alert(t('alertTxtErr'));
        }
      } catch (err) {
        alert(t('alertTxtErr'));
      }
    } else {
      alert(t('alertTxt4'));
    }
  };

  const handleClickBuy = () => {
    (window as any).gaWrite(
      'web_click_artinfo_detail',
      'artsales',
      item?.name + '/' + item?.artist_info?.name
    );
    const filterItem = [
      {
        images: {
          s: item?.images?.s,
        },
        art_infos: {
          name: item?.name,
          size_width: item?.size_width,
          size_height: item?.size_height,
          size_real: item?.size_real,
          price: item?.price,
        },
        artist_info: {
          name: item?.artist_info?.name,
        },
      },
    ];

    if (item?.sell_status === 1) {
      // navigate(`/art_sales/art_market/orderSheet/${id}`);
      navigate('/art_sales/art_market/order_sheet', {
        state: { id: [Number(id)], items: filterItem, is_basket: 0 }, //is_basket:0 바로구매
      });
    } else {
      alert(t('alertTxt4'));
    }
  };

  const handleClickLink = (link: string) => {
    navigate(link);

    closeCartModal();
  };

  const [isOpenImgModal, setIsOpenImgModal] = useState(false);
  const [imgType, setImgType] = useState('');
  const [imgSrc, setImgSrc] = useState('');
  const openImgModal = (type: string, src: string) => {
    if (type === 'video') {
      setImgType('video');
      openFullscreen();
    } else {
      setImgType('images');
      setImgSrc(src);
      setIsOpenImgModal(true);
      document.documentElement.style.overflow = 'hidden';
    }
  };

  const closeImgModal = () => {
    if (imgType == 'images') {
      (window as any).gaWrite(
        'web_click_artinfo_detail_image_close ',
        'artsales',
        item?.name + '/' + item?.artist_info?.name
      );
    }
    setIsOpenImgModal(false);
    document.documentElement.style.overflow = 'auto';
  };

  //영상클릭시 전체화면
  let elem: any;
  elem = document.getElementById('myVideo');

  function openFullscreen() {
    if (elem.requestFullscreen) {
      elem.requestFullscreen();
    } else if (elem.mozRequestFullScreen) {
      /* Firefox */

      elem.mozRequestFullScreen();
    } else if (elem.webkitRequestFullscreen) {
      /* Chrome, Safari & Opera */

      elem.webkitRequestFullscreen();
    } else if (elem.msRequestFullscreen) {
      /* IE/Edge */

      elem.msRequestFullscreen();
    }
  }

  useEffect(() => {
    const doGetOne = async () => {
      const body = {
        id: id,
      };

      if (id) {
        const data = await getArtGetOne(body, guestIdState);
        setItem(data.data);
      }
    };

    doGetOne();
  }, [id]);

  return (
    <div className='sub-page-contents-wrap art-market-art-wrap'>
      {/* sell_status =>1:판매중/2: 판매완료/3: 비매품 */}
      {/* 아트랜드에서만 구매 가능한 작품*/}
      {item?.detail_display === 'art_land_open' &&
      document.referrer !== `${metaverse_art_land_url}/` ? (
        <div className='onlyArtLandBuy-wrap sub-page-contents-inner inner-1400'>
          <div className='onlyArtLandBuy-inner maruBuri'>
            <p> {t('artSalesArtMartketArtLandOnly')}</p>
          </div>
        </div>
      ) : item?.sell_status === 3 && item?.detail_display === 'close' ? (
        /* 해당 작품은 비매품입니다. */
        <div className='onlyArtLandBuy-wrap sub-page-contents-inner inner-1400'>
          <div className='onlyArtLandBuy-inner maruBuri'>
            <p> {t('artSalesArtMartketNotForSale')}</p>
          </div>
        </div>
      ) : item?.detail_display !== 'close' ? (
        <div className='sub-page-contents-inner inner-1400 '>
          <div className='art-market-art-info-wrap'>
            {item?.type === 'video' ? (
              <div
                className='art-img'
                onClick={() => openImgModal('video', item?.video?.original)}
              >
                <video
                  className='art-video'
                  autoPlay
                  loop
                  controls
                  muted
                  playsInline
                  id='myVideo'
                >
                  <source src={item?.video.original}></source>
                </video>
              </div>
            ) : (
              <div
                className='art-img'
                onClick={() => {
                  (window as any).gaWrite(
                    'web_click_artinfo_detail_image ',
                    'artsales',
                    item?.name + '/' + item?.artist_info?.name
                  );
                  openImgModal('images', item?.images?.l);
                }}
              >
                <img src={item?.images?.l} alt={item?.name} />
              </div>
            )}

            <div className='art-info-wrap'>
              <div className='art-info-name maruBuri-bold'>{item?.name}</div>

              <div className='art-info-desc sCoreDream'>
                {item?.artist_info?.name && (
                  <p className='art-info-desc-line art-info-desc-artist'>
                    <span className='art-info-title'>
                      {t('artSalesArtMarketArtArtist')}
                    </span>
                    <span className='art-info-text'>
                      {item?.artist_info?.name}
                    </span>
                  </p>
                )}

                {item?.creation && (
                  <p className='art-info-desc-line art-info-desc-artist'>
                    <span className='art-info-title'>
                      {t('artSalesArtMarketCreation')}
                    </span>
                    <span className='art-info-text'>{item?.creation}</span>
                  </p>
                )}

                {item?.type === 'video' ? (
                  <p className='art-info-desc-line art-info-desc-material'>
                    <span className='art-info-title'>
                      {t('artSalesArtMarketVideoTime')}
                    </span>
                    <span className='art-info-text'>
                      {item?.video?.runtime}
                    </span>
                  </p>
                ) : (
                  <p className='art-info-desc-line art-info-desc-material'>
                    <span className='art-info-title'>
                      {t('artSalesArtMarketmaterial')}
                    </span>
                    <span className='art-info-text'>{item?.material}</span>
                  </p>
                )}

                <p className='art-info-desc-line art-info-desc-size'>
                  {item?.type === 'video' ? (
                    <span className='art-info-title'>
                      {t('artSalesArtMarketArtResolution')}
                    </span>
                  ) : (
                    <span className='art-info-title'>
                      {t('artSalesArtMarketArtSize')}
                    </span>
                  )}
                  <span className='art-info-text'>{item?.size_real}</span>
                </p>

                {item?.sell_status === 2 ? (
                  <p className='art-info-desc-line art-info-desc-gallery'>
                    <span className='art-info-title'>
                      {t('artSalesArtMarketArtPrice')}
                    </span>
                    <span className='art-info-text sold-out-text'>
                      Sold Out
                    </span>
                  </p>
                ) : item?.sell_status === 3 ? (
                  <p className='art-info-desc-line art-info-desc-gallery'>
                    <span className='art-info-title'>
                      {t('artSalesArtMarketArtPrice')}
                    </span>
                    <span className='art-info-text sold-out-text'>
                      {t('commonNotForSale')}
                    </span>
                  </p>
                ) : Number(item?.price) === 0 || !item?.price ? null : (
                  <p className='art-info-desc-line art-info-desc-price'>
                    <span className='art-info-title'>
                      {t('artSalesArtMarketArtPrice')}
                    </span>
                    <span className='art-info-text'>
                      {formatNumberCommas(Number(item?.price))}

                      <span className='priceText'>{t('commonPriceText')}</span>
                    </span>
                  </p>
                )}

                {item?.gallery_info?.name && (
                  <p className='art-info-desc-line art-info-desc-gallery'>
                    <span className='art-info-title'>
                      {t('artSalesArtMarketArtGallery')}
                    </span>
                    <span className='art-info-text'>
                      {item?.gallery_info?.name}
                    </span>
                  </p>
                )}
              </div>
              {Number(item?.price) === 0 || Number(item?.price) > 100000000 ? (
                <div className='art-btn-wrap'>
                  <CommonBorderWhiteBtn
                    className='buy-now-btn blue'
                    text={t('commonQnatext')} //문의하기
                    onClick={moveQnaPage}
                  />
                </div>
              ) : (
                <div className='art-btn-wrap'>
                  <CommonBorderWhiteBtn
                    className={
                      item?.sell_status === 1 ? 'cart-btn' : 'cart-btn disabled'
                    }
                    text={t('commonCartText')} //장바구니
                    onClick={handleClickCart}
                  />
                  <CommonBorderWhiteBtn
                    className={
                      item?.sell_status === 1
                        ? 'buy-now-btn blue'
                        : 'buy-now-btn blue disabled'
                    }
                    text={t('commonBuyNowText')} //바로구매
                    onClick={handleClickBuy}
                  />
                </div>
              )}
            </div>
          </div>

          <div className='art-market-art-detail-wrap sCoreDream'>
            {/* 상세 이미지 */}
            {item?.images?.detail_images?.length > 0 && (
              <div className='art-market-art-detail-contents art-content'>
                <p className='content-title'>
                  {t('artSalesArtMarketArtTitle4')}
                </p>

                <div className='art-market-art-detail-content'>
                  <MultipleSwiperCustom
                    items={item?.images?.detail_images}
                    buttonPrevId='multipleSwiperCustomPrev'
                    buttonNextId='multipleSwiperCustomNext'
                    alt={item?.name}
                  />
                </div>
              </div>
            )}

            {item?.description && (
              <div className='art-market-art-detail-contents art-content'>
                <div className='content-title'>
                  {t('artSalesArtMarketArtTitle1')}

                  {item?.voice && item?.voice.original && (
                    <div className='common-border-white-btn maruBuri cart-btn art-docent-box'>
                      <div
                        className='docent-txt'
                        onClick={() => setViewDocent(!viewDocent)}
                        style={
                          !viewDocent ? { width: '100%' } : { width: 'auto' }
                        }
                      >
                        <VolumeUpIcon />
                        <p>Docent</p>
                      </div>
                      {viewDocent ? (
                        <>
                          <audio controls autoPlay>
                            <source src={item?.voice.original} />
                          </audio>

                          <div
                            className='docent-bg-box'
                            onClick={() => setViewDocent(!viewDocent)}
                          ></div>
                        </>
                      ) : null}
                    </div>
                  )}
                </div>

                <p className='art-market-art-detail-content'></p>

                <div className='art-market-art-detail-content'>
                  <p className='line-enter description'>{item?.description}</p>
                </div>
              </div>
            )}

            {item?.artist_info?.description && (
              <div className='art-market-art-detail-contents artist-content'>
                <div className='content-title'>
                  {t('artSalesArtMarketArtTitle5')}

                  {item?.artist_info?.artist_voice &&
                    item?.artist_info?.artist_voice?.original && (
                      <div className='common-border-white-btn maruBuri cart-btn art-docent-box'>
                        <div
                          className='docent-txt'
                          onClick={() => setArtistViewDocent(!artistViewDocent)}
                          style={
                            !artistViewDocent
                              ? { width: '100%' }
                              : { width: 'auto' }
                          }
                        >
                          <VolumeUpIcon />
                          <p>Docent</p>
                        </div>
                        {artistViewDocent ? (
                          <>
                            <audio controls autoPlay>
                              <source
                                src={item?.artist_info?.artist_voice?.original}
                              />
                            </audio>

                            <div
                              className='docent-bg-box'
                              onClick={() =>
                                setArtistViewDocent(!artistViewDocent)
                              }
                            ></div>
                          </>
                        ) : null}
                      </div>
                    )}
                </div>
                <div className='art-market-art-detail-content'>
                  <p className='line-enter description'>
                    {item?.artist_info?.description}
                  </p>
                </div>
              </div>
            )}

            {item?.artist_info?.history && (
              <div className='art-market-art-detail-contents artist-content'>
                <p className='content-title'>
                  {t('artSalesArtMarketArtTitle2')}
                </p>
                <div className='art-market-art-detail-content'>
                  <p>
                    {item?.artist_info?.name && (
                      <span>{item?.artist_info?.name} </span>
                    )}

                    {item?.artist_info?.lifetime && (
                      <span className='artist-lifeTime'>
                        &#47; {item?.artist_info?.lifetime}
                      </span>
                    )}
                  </p>
                </div>
                {item?.artist_info?.history ? (
                  <div className='art-market-art-detail-content artist-history'>
                    <p className='line-enter'>{item?.artist_info?.history}</p>
                  </div>
                ) : null}
              </div>
            )}

            {/* {item?.gallery_info?.name && (
              <div className='art-market-art-detail-contents artist-content'>
                <p className='content-title'>
                  {t('artSalesArtMarketArtTitle3')}
                </p>
                <div className='art-market-art-detail-content'>
                  <p>
                    {item?.gallery_info?.name && (
                      <span>{item?.gallery_info?.name}</span>
                    )}
                    {item?.gallery_info?.ceo_name && (
                      <span> &#47; CEO : {item?.gallery_info?.ceo_name} </span>
                    )}
                  </p>
                  {item?.gallery_info?.homepage_url && (
                    <p
                      className='gallery-link'
                      onClick={() =>
                        window.open(item?.gallery_info?.homepage_url)
                      }
                    >
                      {item?.gallery_info?.homepage_url}
                    </p>
                  )}
                </div>
              </div>
            )} */}
          </div>
        </div>
      ) : (
        /* 해당 작품은 비공개 작품입니다.*/
        <div className='onlyArtLandBuy-wrap sub-page-contents-inner inner-1400'>
          <div className='onlyArtLandBuy-inner maruBuri'>
            <p> {t('artSalesArtMartketClose')}</p>
          </div>
        </div>
      )}
      {loading && <Loading Loading={loading} />}
      <CustomModalBottomTwoBtn
        className='artMarket-cart-modal-wrap custom-modal-bottomTwoBtnWrap'
        open={isCartModal}
        onClose={closeCartModal}
      >
        <Box>
          <div
            style={{
              background: `#fff `,
            }}
            className='custom-modal-bottomTwoBtnWrap-cotents'
          >
            <div className='custom-modal-bottomTwoBtnWrap-cotents-Text maruBuri'>
              <p>{t('artSalesArtMarketArtCartModal')}</p>
            </div>
          </div>
        </Box>
        <div className='modal-two-btn-Wrap'>
          <MoreWhiteBtn
            className='modal-two-btn white'
            text={t('artSalesArtMarketArtCartModal2')}
            onClick={closeCartModal}
          />
          <MoreWhiteBtn
            className='modal-two-btn blue'
            text={t('artSalesArtMarketArtCartModal3')}
            onClick={() => handleClickLink(`/cart`)} /* ${guestId} */
          />
        </div>
      </CustomModalBottomTwoBtn>

      <CustomModal open={isOpenImgModal} onClose={closeImgModal}>
        <Box>
          {/*  <div
            style={{
              background: `url(${selectedImage}) no-repeat center/cover `,
            }}
            className='modalBox'
          /> */}
          <div
            /*    style={{
                background: `url(${imgSrc}) no-repeat center/cover `,
              }} */
            className='art-img-modal-wrap'
          >
            <div className='art-img-modal-inner'>
              {imgType === 'video' /*    <video
                  className='art-video'
                  autoPlay
                  loop
                  controls
                  muted
                  id='myVideo'
                  src={imgSrc}
                ></video> */ ? null : (
                <img className='art-img' src={imgSrc} alt={item?.name} />
              )}
            </div>

            <button className='modal-close-btn' onClick={closeImgModal}>
              <ClearIcon />
            </button>
          </div>
        </Box>
      </CustomModal>
    </div>
  );
}

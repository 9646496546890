import { useRecoilState } from 'recoil';
import { mobileMenuTrigger } from '@stores/mobileMenuTrigger';
import MobileMenuLists from './MobileMenuLists';
import classNames from 'classnames/bind';
import styles from './mobileMenu.module.scss';

const cx = classNames.bind(styles);

export default function MobileMenu() {
  const [isOn, setIsOn] = useRecoilState(mobileMenuTrigger);

  return (
    <div
      className={cx(
        isOn === true ? 'mobile-menu-container view' : 'mobile-menu-container',
        'hidden'
      )}
    >
      <div className={cx('mobile-menu-wrap')}>
        <MobileMenuLists />

        <div className={cx('mobile-menu-lists-bg')}></div>
      </div>
    </div>
  );
}

import { useState } from "react";
import { useNavigate } from "react-router-dom";
import { Trans, useTranslation } from "react-i18next";
import ServiceImg from "@assets/images/main/main_service_img_1.png";
import serviceIcon1 from "@assets/images/main/main_service_icon1.png";
import serviceIcon1Over from "@assets/images/main/main_service_icon1_over.png";
import serviceIcon2 from "@assets/images/main/main_service_icon2.png";
import serviceIcon2Over from "@assets/images/main/main_service_icon2_over.png";
import serviceIcon3 from "@assets/images/main/main_service_icon3.png";
import serviceIcon3Over from "@assets/images/main/main_service_icon3_over.png";
import "./mainService.scss";

export default function MainService() {
  const { t } = useTranslation();
  const navigate = useNavigate();

  /*   const handleClickMore = () => {
    navigate('/service/exhibitionhall_rental');
  };
 */
  const handleClickLink = (link: string) => {
    navigate(link);
  };

  const ourServiceData = [];

  const [mouseHover, setMouseHover] = useState(0);

  const mouseOverContentBox = (
    e: React.MouseEvent,
    status: string,
    number?: number | undefined
  ) => {
    if (status === "on" && number) {
      setMouseHover(number);
    } else {
      setMouseHover(0);
    }
  };

  return (
    <div className="main-content-wrap main-service-wrap">
      <div className="main-content-inner">
        <div className="main-content-title about-title">
          <p
            className="main-content-title-sub"
            data-aos="fade-zoom-in"
            data-aos-easing="linear"
            data-aos-delay="200"
            data-aos-offset="0"
          >
            Our Service
          </p>
          <h3 className="main-content-title-big maruBuri">
            {t("mainServiceTitle")}
          </h3>
        </div>

        {/*   <div className='main-content main-about-us-content'>
          <div className='main-content-text'>
            <Trans i18nKey={`mainNewsText`} components={[<span />]} />
          </div>
        </div>

        <div className='content-more-white-btn main-content-more-btn' onClick={handleClickMore}>
          View more
        </div> */}

        <div className="main-content">
          <div className="main-content-img">
            <img src={ServiceImg} alt="gallery st service" />
          </div>

          <div
            className={
              mouseHover > 0 && mouseHover <= 3
                ? "main-content-text-box active"
                : "main-content-text-box"
            }
          >
            <div
              className={
                mouseHover === 1
                  ? "main-service-content-box active"
                  : "main-service-content-box"
              }
              onMouseOver={(e) => mouseOverContentBox(e, "on", 1)}
              onMouseLeave={(e) => mouseOverContentBox(e, "leave")}
              onClick={() =>  handleClickLink("/service/exhibitionhall_rental")}
            >
              <div className="main-service-text-wrap">
                <p className="main-service-text-title maruBuri fw6Bold">
                  {t("mainServiceSub1Title")}
                </p>
                <p className="main-service-text sCoreDream">
                  <Trans
                    i18nKey={`mainServiceSub1Text`}
                    components={[<span />]}
                  />
                </p>
              </div>

              <div className="main-service-img">
                <img
                  src={mouseHover === 1 ? serviceIcon1Over : serviceIcon1}
                  alt="전시장 대관"
                />
              </div>
            </div>

            <div
              className={
                mouseHover === 2
                  ? "main-service-content-box active"
                  : "main-service-content-box"
              }
              onMouseOver={(e) => mouseOverContentBox(e, "on", 2)}
              onMouseLeave={(e) => mouseOverContentBox(e, "leave")}
              onClick={() => handleClickLink("/service/art_consulting")}
            >
              <div className="main-service-text-wrap">
                <p className="main-service-text-title maruBuri fw6Bold">
                  {t("mainServiceSub2Title")}
                </p>
                <p className="main-service-text sCoreDream">
                  <Trans
                    i18nKey={`mainServiceSub2Text`}
                    components={[<span />]}
                  />
                </p>
              </div>
              <div className="main-service-img">
                <img
                  src={mouseHover === 2 ? serviceIcon2Over : serviceIcon2}
                  alt="아트 컨설팅"
                />
              </div>
            </div>

            <div
              className={
                mouseHover === 3
                  ? "main-service-content-box active"
                  : "main-service-content-box"
              }
              onMouseOver={(e) => mouseOverContentBox(e, "on", 3)}
              onMouseLeave={(e) => mouseOverContentBox(e, "leave")}
              onClick={() => handleClickLink("/service/metaverse_exhibition")}
            >
              <div className="main-service-text-wrap">
                <p className="main-service-text-title maruBuri fw6Bold">
                  {t("mainServiceSub3Title")}
                </p>
                <p className="main-service-text sCoreDream">
                  <Trans
                    i18nKey={`mainServiceSub3Text`}
                    components={[<span />]}
                  />
                </p>
              </div>
              <div className="main-service-img">
                <img
                  src={mouseHover === 3 ? serviceIcon3Over : serviceIcon3}
                  alt="메타버스 가상 전시"
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

import { useCallback, useEffect, useState } from 'react';
import OpenSeadragon from 'openseadragon';
import RemoveIcon from '@mui/icons-material/Remove';
import AddIcon from '@mui/icons-material/Add';
import ClearIcon from '@mui/icons-material/Clear';
import { Box } from '@mui/material';
import CustomModalFullSize from './CustomModalFullSize';
import LoadingRow from '@components/Loading/LoadingRow';
import LoadingRowBgBlack from '@components/Loading/LoadingRowBgBlack';

interface ModalInnerSizeProps {
  w: number;
  h: number;
}
interface ModalProps {
  minScale: number;
  maxScale: number;
  setMinScale: React.Dispatch<React.SetStateAction<number>>;
  setMaxScale: React.Dispatch<React.SetStateAction<number>>;
  scale: number;
  setScale: React.Dispatch<React.SetStateAction<number>>;
  stepScale: number;
  isOpenImgModal: boolean;
  closeImgModal: () => void;
  imgSrc: string;
}

export default function ZoomModal({
  minScale,
  setMinScale,
  maxScale,
  setMaxScale,
  scale,
  setScale,
  stepScale,
  isOpenImgModal,
  imgSrc,
  closeImgModal,
}: ModalProps) {
  const [loading, setLoading] = useState(false);
  const [resizeLoading, setResizeLoading] = useState(false);

  //#openSeaDragon ref
  const [zoomRef, setZoomRef] = useState<HTMLDivElement | null>(null);
  const zoomBoxRef = useCallback((node: HTMLDivElement) => {
    if (node !== null) {
      setZoomRef(node);
      return;
    }
  }, []);

  //modal resize ref
  const [imgModalInnerRef, setImgModalInnerRef] =
    useState<HTMLDivElement | null>(null);
  const modalInnerRef = useCallback((node: HTMLDivElement) => {
    if (node !== null) {
      setImgModalInnerRef(node);
      return;
    }
  }, []);

  let tileSources: any;
  tileSources = {
    type: 'image',
    url: imgSrc,
  };

  const innerWidth = document.documentElement.clientWidth;
  const innerHeight = document.documentElement.clientHeight;
  const [modalInnerSize, setModalInnerSize] = useState<ModalInnerSizeProps>({
    w: innerWidth,
    h: innerHeight,
  });

  //openseadragon viewer(zoom)
  const [viewer, setViewer] = useState<OpenSeadragon.Viewer | null>(null);

  //slider range 조절
  const handleSlider = (newScaleValue: number) => {
    setScale(newScaleValue);
    if (viewer) {
      viewer.viewport.zoomTo(newScaleValue);
    }
  };

  //openseadragon viewer 설정
  useEffect(() => {
    const initOpenSeadragon = () => {
      if (isOpenImgModal) {
        if (tileSources && imgSrc && zoomRef) {
          const newViewer = OpenSeadragon({
            id: 'openSeaDragon',
            element: zoomRef,
            showNavigator: true, // 미니맵
            navigatorPosition: 'TOP_LEFT', //미니맵 위치
            navigatorSizeRatio: 0.16, //미니맵 크기 비율
            tileSources: tileSources, //img
            constrainDuringPan: true, //img 화면 경계밖으로 드래그 되지 않게 막기
            navigationControlAnchor: OpenSeadragon.ControlAnchor.TOP_RIGHT, //컨트롤러위치
            blendTime: 0.1, //확대,축소 애니메이션 시간
            zoomInButton: 'zoomAdd', //'-' click btn id
            zoomOutButton: 'zoomMinus', //'-' click btn id
            navigatorAutoFade: false, // navigator 자동 페이드 인/아웃 활성화 막고 아래 설정 적용
          });

          newViewer.addHandler('open', () => {
            setLoading(false); //이미지 로드 완료시 로딩 종료

            //미니맵,컨트롤바(-,+) 페이드인아웃
            const fadeInTime = 1000;
            const fadeOutTime = 5000;
            const navigatorElement = newViewer.navigator.element;
            const zoomToolsElement = document.getElementById(
              'zoomTools'
            ) as HTMLElement;

            function fadeIn(element: any, time: any) {
              element.style.transition = `opacity ${time}ms`;
              element.style.opacity = '1';
            }
            function fadeOut(element: any, time: any) {
              element.style.transition = `opacity ${time}ms`;
              element.style.opacity = 0;
            }

            //mouse 페이드인아웃
            // Event handlers for canvas
            newViewer.addHandler('canvas-enter', () => {
              fadeIn(navigatorElement, fadeInTime);
              fadeIn(zoomToolsElement, fadeInTime);
            });
            newViewer.addHandler('canvas-exit', () => {
              fadeOut(navigatorElement, fadeOutTime);
              fadeOut(zoomToolsElement, fadeOutTime);
            });
            // Event handlers for zoom tools
            zoomToolsElement.addEventListener('mouseenter', () => {
              fadeIn(zoomToolsElement, fadeInTime);
              fadeIn(navigatorElement, fadeInTime);
            });
            // Event handlers for navigator
            navigatorElement.addEventListener('mouseenter', () => {
              fadeIn(zoomToolsElement, fadeInTime);
              fadeIn(navigatorElement, fadeInTime);
            });

            //touch 시 페이드인아웃
            function handleTouchStart(event: TouchEvent) {
              fadeIn(zoomToolsElement, fadeInTime);
              fadeIn(navigatorElement, fadeInTime);
            }
            function handleTouchEnd(event: TouchEvent) {
              fadeOut(zoomToolsElement, fadeOutTime);
              fadeOut(navigatorElement, fadeOutTime);
            }
            document.addEventListener('touchstart', handleTouchStart);
            document.addEventListener('touchend', handleTouchEnd);
          });
          setViewer(newViewer);
        }
      } else {
        return;
      }
    };

    //loading
    setLoading(true);

    initOpenSeadragon();

    return () => {
      //closed modal reset
      if (!isOpenImgModal && viewer) {
        viewer?.destroy();
        setViewer(null);
      }
    };
  }, [isOpenImgModal, zoomRef, imgSrc]);

  //openseadragon viewer open
  useEffect(() => {
    if (
      viewer &&
      isOpenImgModal &&
      zoomRef &&
      imgSrc &&
      modalInnerSize &&
      tileSources
    ) {
      viewer.open(tileSources);
    }
  }, [imgSrc, isOpenImgModal]);

  //zoom되었을 때, openseadragon에서 값 get
  useEffect(() => {
    if (viewer) {
      viewer.addHandler('zoom', function (e) {
        const newZoomLevel = viewer.viewport.getZoom(); //현재 zoom된 값
        setScale(newZoomLevel);
        setMinScale(viewer.viewport.getMinZoom()); //최소 zoom
        setMaxScale(viewer.viewport.getMaxZoom()); //최대 zoom
      });
    }
  }, [viewer]);

  //modal keyboard(-,+)누를시 확대축소
  useEffect(() => {
    if (isOpenImgModal && viewer) {
      const handleKeyDown = (event: any) => {
        const currentZoom = viewer.viewport.getZoom();
        let newZoom;

        if (event.key === '-') {
          newZoom = Math.max(currentZoom * 0.8, minScale);
        } else if (event.key === '+') {
          newZoom = Math.min(currentZoom * 1.2, maxScale);
        }
        if (newZoom !== undefined) {
          viewer.viewport.zoomTo(newZoom);
        }
      };
      window.addEventListener('keydown', handleKeyDown);
      return () => {
        window.removeEventListener('keydown', handleKeyDown);
      };
    }
  }, [isOpenImgModal, minScale, maxScale]);

  //모달창 resize
  useEffect(() => {
    if (isOpenImgModal) {
      function reSizeModal() {
        /*    const viewWidth = imgModalInnerRef?.offsetWidth;
        const viewHeight = imgModalInnerRef?.offsetHeight; */

        const viewWidth = document.documentElement.clientWidth;
        const viewHeight = document.documentElement.clientHeight;

        if (viewWidth && viewHeight) {
          setModalInnerSize({
            w: viewWidth,
            h: viewHeight,
          });
        } //모달창 사이즈에 맞게 canvas 조절
      }
      reSizeModal();
      function rotateScreen() {
        setResizeLoading(true);
        reSizeModal();
        setTimeout(function () {
          setResizeLoading(false);
        }, 600);
      }
      window.addEventListener('resize', reSizeModal);
      window.addEventListener('orientationchange', rotateScreen);

      return () => {
        window.removeEventListener('resize', reSizeModal);
        window.removeEventListener('orientationchange', rotateScreen);
      };
    }
  }, [imgModalInnerRef, imgSrc, isOpenImgModal]);

  return (
    <CustomModalFullSize
      open={isOpenImgModal}
      onClose={closeImgModal}
      className='artmarket-fullsize-modal'
    >
      <Box>
        <div className='art-img-modal-wrap custom-modal-fullsize-wrap'>
          <div
            className='art-img-modal-inner'
            ref={(node: HTMLDivElement) => modalInnerRef(node)}
          >
            <div className='zoom-tools' id='zoomTools'>
              <button id='zoomMinus' className='zoom-minus'>
                <RemoveIcon />
              </button>
              <div className='input-range-box'>
                <input
                  className='input-range'
                  type='range'
                  min={minScale}
                  max={maxScale}
                  value={scale}
                  step={stepScale} //0.01
                  onChange={(e: any) => handleSlider(e.target.value)}
                />
              </div>

              <button id='zoomAdd' className='zoom-add'>
                <AddIcon />
              </button>
            </div>
            <div className='zoom-pan-wrap'>
              {modalInnerSize && isOpenImgModal && (
                <div
                  ref={(node: HTMLDivElement) => zoomBoxRef(node)}
                  id='openSeaDragon'
                  style={{
                    width: `${modalInnerSize?.w}px`,
                    height: `${modalInnerSize?.h}px`,
                  }}
                ></div>
              )}
            </div>
          </div>

          {/*  <button className='modal-close-btn' onClick={closeImgModal}>
            <ClearIcon />
          </button> */}
        </div>
      </Box>

      {loading && <LoadingRow Loading={loading} />}
      {resizeLoading && <LoadingRowBgBlack Loading={resizeLoading} />}
    </CustomModalFullSize>
  );
}

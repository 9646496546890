import { useEffect, useState } from 'react';
import { BtnInterface } from '@interfaces/components/ButtonInterface';
import moreBtn from '@assets/images/btn/btn_more_blue@3x.png';
import moreBtnOver from '@assets/images/btn/btn_more_blue@3x_over.png';
import { isMobile } from 'react-device-detect';

export default function MoreWhiteBtn({
  text,
  onClick,
  className,
}: BtnInterface.ClickBtnInterface) {
  const [mouseHover, setMouseHover] = useState('');

  const handleMouseOver = () => {
    if (isMobile) {
      setMouseHover('');
    } else {
      setMouseHover('active');
    }
  };

  return (
    <button
      type='button'
      onClick={onClick}
      className={`content-more-white-btn ${className} ${mouseHover}`}
      onMouseOver={handleMouseOver}
      onMouseLeave={() => setMouseHover('')}
    >
      {text}
      <span className='img'>
        {className?.includes('blue') ? (
          <img src={moreBtnOver} alt='more' />
        ) : (
          <img
            src={mouseHover === 'active' ? moreBtnOver : moreBtn}
            alt='more'
          />
        )}
      </span>
    </button>
  );
}

import { useEffect } from 'react';
import ReactGA from 'react-ga4';
import axios from 'axios';
import btnDownload from '@assets/images/btn/btn_download.png';

export default function YoungartistDownLoadBtn() {
  //  const downloadLink = document.createElement('a');
  function pdf() {
    (window as any).gaWrite(
      'web_click_youngartist_pdf',
      '2024_youngartist/contest',
      ''
    );

    // let url: string;
    // axios
    //   .get(
    //     'https://gallery-st-main-public.s3.ap-northeast-2.amazonaws.com/art_fair/doc/%E1%84%89%E1%85%B5%E1%84%8F%E1%85%B3%E1%84%85%E1%85%B5%E1%86%BA%E1%84%90%E1%85%A1%E1%84%8B%E1%85%AE%E1%86%AB%E1%84%92%E1%85%A9%E1%84%90%E1%85%A6%E1%86%AF%E1%84%8B%E1%85%A1%E1%84%90%E1%85%B3%E1%84%91%E1%85%A6%E1%84%8B%E1%85%A52023_%E1%84%86%E1%85%A9%E1%84%8C%E1%85%B5%E1%86%B8%E1%84%8B%E1%85%AD%E1%84%80%E1%85%A1%E1%86%BC.pdf',
    //     { responseType: 'blob' }
    //   )
    //   .then((res) => {
    //     url = URL.createObjectURL(
    //       new Blob([res.data], { type: res.headers['content-type'] })
    //     );
    //     downloadLink.href = url;
    //
    //     downloadLink.download =
    //       '2023 호텔아트페어 공모요강_시크릿타운주.pdf';
    //     downloadLink.click();
    //     URL.revokeObjectURL(url);
    //   });
  }
  useEffect(() => {
    const el = document.getElementsByClassName('youngartistContestInfoBtn')[0];
    el?.addEventListener('click', pdf);
    return () => {
      const el = document.getElementsByClassName(
        'youngartistContestInfoBtn'
      )[0];
      el?.removeEventListener('clcik', pdf);
    };
  }, []);

  return (
    <p className='youngartistContestInfoBtn youngartistContestDownBtn artistContestBtn'>
      <a href='https://gallery-st-live-public.s3.ap-northeast-2.amazonaws.com/2024_시크릿타운_YOUNG_ARTIST_미술상.pdf'>
        <img
          src={btnDownload}
          alt='공모요강 다운로드'
          className='pointer-event-true'
        />
      </a>
    </p>
  );
}

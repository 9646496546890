import { httpCommunicator } from '@api/httpCommunicator';

const url = process.env.REACT_APP_API_URL;

export async function getArticleNotice(page: number, limit: number, guestSessionId?: number) {
  const header = {guest_session_id: guestSessionId};
  const res = await httpCommunicator(
    `${url}/v1/op/notice/list?page=${page}&limit=${limit}`,
    'get',
    {},
    header
  );
  return res.data;
}

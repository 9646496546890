import React, { forwardRef } from 'react';

import { FormInterface } from '@interfaces/components/FormInterface';

export default forwardRef(function TextArea(
  {
    labelClassName,
    value,
    onChange,
    onFocus,
    name,
    placeholder,
    className,
    maxLength,
  }: FormInterface.TextAreaInterface,
  ref?: React.ForwardedRef<HTMLTextAreaElement> | undefined
) {
  //글자 수 자르기
  const handleChangeReview = (e: React.ChangeEvent<HTMLTextAreaElement>) => {
    if (maxLength && maxLength < e.target.value.length) {
      e.target.value = e.target.value.slice(0, maxLength);
    }

    onChange(e);
  };

  return (
    <label className={labelClassName}>
      <textarea
        name={name}
        value={value}
        onChange={handleChangeReview}
        placeholder={placeholder}
        ref={ref}
        className={className}
        maxLength={maxLength}
        onFocus={onFocus}
      />
    </label>
  );
});

import { ModalInterface } from '@interfaces/components/ModalInterface';

import Modal from '@mui/material/Modal';
import Paper from '@mui/material/Paper';
import ClearIcon from '@mui/icons-material/Clear';
import styled from '@emotion/styled';
import './customModalBottomTwoBtn.scss';

const Box = styled.div({
  display: 'flex',
  justifyContent: 'flex-end',
});

export default function CustomModalBottomTwoBtn({
  open,
  onClose,
  children,
  className,
}: ModalInterface.CustomModalInterface) {
  return (
    <div>
      <Modal open={open} onBackdropClick={onClose} className={className}>
        <Paper
          elevation={2}
          sx={{
            position: 'absolute',
            top: '50%',
            left: '50%',
            transform: 'translate(-50%, -50%)',
            width: 'auto',
            bgcolor: 'background.paper',
            boxShadow: 24,
            p: 4,
            overflowY: 'auto',
            padding: '4rem',
          }}
        >
          {children}
        </Paper>
      </Modal>
    </div>
  );
}

import { useEffect, useState } from 'react';
import logo from '@assets/images/hinh_logo.png';
import stLogo from '@assets/images/secrettownLogo.png';
import HandymanIcon from '@mui/icons-material/Handyman';
import { ConfigInterface } from '@interfaces/config/Config';
import { getMaintenanceInfo } from '@services/MaintenanceService';
import './maintenance.scss';
import './gallerystMaintenance.scss';
import './hinhMaintenance.scss';

export default function HinhMaintenance() {
  const [maintenance, setMaintenance] = useState({
    id: 0,
    name: '',
    key: '',
    value: '',
    created_at: 0,
  });
  useEffect(() => {
    const getMaintenanceData = async () => {
      const maintenanceInfo = await getMaintenanceInfo();

      let datas = maintenanceInfo?.list?.filter(
        (item: ConfigInterface.ConfigContentsInterface) =>
          item.key === 'notice_for_service_check_hinh'
      );
      setMaintenance(datas[0]);
    };
    getMaintenanceData();
  }, []);

  return (
    <article className='maintenance-wrap galleryst-maintenance-wrap hinh-maintenance-wrap'>
      <div className='maintenance-inner'>
        <div className='img-wrap'>
          <img src={logo} alt='hinh' />
        </div>
      </div>
      <h3 className='text-box line'>
        <span className='icon'>
          <HandymanIcon />
        </span>
        {maintenance?.name}
      </h3>
      <div className='maintenance-inner'>
        <div className='text-box'>
          <p
            style={{ whiteSpace: 'pre-wrap' }}
            dangerouslySetInnerHTML={{ __html: maintenance?.value }}
          ></p>
        </div>

        <div className='st-logo'>
          <img src={stLogo} alt='secrettown' />
        </div>
      </div>
    </article>
  );
}

import { useEffect, useState } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import arrow from '@assets/images/arrow/arrow1.png';
import { useLocation } from 'react-router';
import './orderLists.scss';

export default function OrderLists() {
  const { t } = useTranslation();

  const location = useLocation();

  const orderLists = [
    {
      id: 1,
      title: t('cart'),
      link: '/cart',
    },
    { id: 2, title: t('orderPayment'), link: '/order_sheet' },
    { id: 3, title: t('orderCompleted'), link: '/order_sheet/complete' },
  ];

  const [active, setActive] = useState(0);

  useEffect(() => {
    if (location.pathname.includes('/cart')) {
      setActive(1);
    } else if (location.pathname.includes('/order_sheet')) {
      setActive(2);
    } else if (location.pathname.includes('/order_sheet/complete')) {
      setActive(3);
    }
  }, [active]);

  return (
    <ul className='order-lists'>
      {orderLists.map((item, i) => (
        <li className='order-list-li' key={item.id}>
          <p
            className={
              active === item.id
                ? 'order-list maruBuri active'
                : 'order-list maruBuri'
            }
          >
            {i + 1}. {item.title}
          </p>
          <span className='arrow-img'>
            <img src={arrow} alt='next' />
          </span>
        </li>
      ))}
    </ul>
  );
}

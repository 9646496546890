import React, { forwardRef, useState } from 'react';

import { FormInterface } from '@interfaces/components/FormInterface';

export default forwardRef(function InputOnchangeRegexCommonDefault(
  {
    title,
    type,
    name,
    value,
    placeholder,
    inputTitle,
    onChange,
    labelClassName,
    className,
    maxValue,
    regexCheck,
    successText,
    errorText,
    helperTextClassName,
    required,
  }: FormInterface.InputRegexInterface,
  ref?: React.ForwardedRef<HTMLInputElement>
) {
  const [isError, setIsError] = useState('');
  const [helperText, setHelperText] = useState('');

  const handleChangeRegex = (e: React.ChangeEvent<HTMLInputElement>) => {
    onChange(e);

    if (maxValue && maxValue < e.target.value.length) {
      e.target.value = e.target.value.slice(0, maxValue);
    }
    if (e.target.value === '' && required === true) {
      setIsError('requiredErr');
      return setHelperText('필수 정보입니다.');
    }

    if (e.target.value !== '' && regexCheck) {
      if (regexCheck.test(e.target.value) && successText) {
        setIsError('success');
        return setHelperText('');
      }
      if (!regexCheck.test(e.target.value) && errorText) {
        setIsError('err');
        return setHelperText(errorText);
      }
    }
    if (!regexCheck && !errorText && e.target.value !== '') {
      setIsError('success');
      return setHelperText('');
    } else {
      setIsError('success');
      return setHelperText('');
    }
  };

  const handleOnfocus = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (e.target.value === '' && required === true) {
      setIsError('requiredErr');
      return setHelperText('필수 정보입니다.');
    }
  };

  return (
    <label
      className={
        labelClassName +
        (isError === 'err' || isError === 'requiredErr'
          ? `Err ${labelClassName}`
          : '') +
        (isError === 'success' ? `Success ${labelClassName}` : '')
      }
    >
      <input
        type={type}
        name={name}
        value={value || ''}
        onChange={handleChangeRegex}
        placeholder={placeholder}
        ref={ref}
        className={className}
        maxLength={maxValue}
        onInput={handleChangeRegex}
        onFocus={handleOnfocus}
      />

      {helperText ? <p className='helperText'>{helperText}</p> : null}
    </label>
  );
});

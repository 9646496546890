import { useEffect, useRef, useState } from 'react';
import VideoCameraBackIcon from '@mui/icons-material/VideoCameraBack';
import ClearRoundedIcon from '@mui/icons-material/ClearRounded';
import { ArtistApplyInterface } from '@interfaces/youngartist/ArtistApplyInterface';
import formatNumberCommas from 'helpers/formatNumberCommas';
import Loading from '@components/Loading/Loading';
import FileUploadIcon from '@mui/icons-material/FileUpload';
import './artistApplyFile.scss';

export default function ArtistApplyTotalFile({
  fileList, //각 영역 해당 파일
  setFileList,
  artCountValues,
  helperText,
  setHelperText,
  initialTotalFilesSize,
  maxFileCount, //파일 제한 갯수
  totalFileList, //총 파일리스트(1~3번 메인+디테일)
  defaultFilesLength, //기존 업로드 파일 갯수
} /* control, */ : ArtistApplyInterface.ArtistApplyFileProps) {
  const fileRef = useRef<HTMLInputElement>(null);

  const [selectedFile, setSelectedFile] = useState<File | null>(null);
  const [totalFilesSize, setTotalFilesSize] = useState<number>(
    initialTotalFilesSize
  );
  const [loading, setLoading] = useState(false);

  // 허용가능한 확장자 목록
  const ALLOW_FILE_EXTENSION = [
    'image/jpeg',
    'image/jpg',
    'image/png',
    'video/mp4',
    'video/mkv',
    'video/webm',
    'video/quicktime', //mov
    'video/avi',
  ];
  //각 파일 제한 용량
  const FILE_SIZE_MAX_LIMIT = 100 * 1024 * 1024; //400MB(419430400) //5*1024*1024= 5MB (5242880)
  //총 파일 제한 용량(1,000MB=1GB)
  const TOTAL_FILE_SIZE_MAX_LIMIT = 1000 * 1024 * 1024; //400MB(419430400) //5*1024*1024= 5MB (5242880)
  // 최대 파일 갯수
  /*   const MAX_FILE_COUNT = 1; */

  const allowFileAlert = ALLOW_FILE_EXTENSION.map((type) => type.split('/')[1]);

  //quicktime > mov 텍스트 변환
  function formatFileExtension(extension: string) {
    if (extension === 'quicktime') {
      return 'mov';
    }
    return extension;
  }

  const handleClickFile = () => {
    fileRef?.current?.click();
  };

  const handleChangeFile = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (!e.target.files) {
      return;
    }

    const files = e.target.files?.[0];
    const fileType = files.type;

    // 새로운 파일 적용시 총 파일 용량 계산
    const newTotalFilesSize = totalFilesSize + files.size;

    if (newTotalFilesSize > TOTAL_FILE_SIZE_MAX_LIMIT) {
      alert(
        `파일 용량이 초과되었습니다.\n제한 용량 : 총 1GB (${formatNumberCommas(
          TOTAL_FILE_SIZE_MAX_LIMIT / (1024 * 1024)
        )}MB)`
      );
      e.target.value = '';
      return;
    }

    if (!ALLOW_FILE_EXTENSION.includes(fileType)) {
      alert(`업로드 가능한 파일 형식이 아닙니다.`);
      e.target.value = '';
      return;
    }

    //TODO:제한 조건 변경시 사용
    //길이 용량제한
    //기존 업로드된 파일이 있는 경우
    if (defaultFilesLength) {
      if (maxFileCount) {
        if (fileList.length + defaultFilesLength >= maxFileCount) {
          alert(`최대 ${maxFileCount}개의 파일까지 업로드할 수 있습니다.`);
          e.target.value = '';
          return;
        }
      }
    } else {
      if (maxFileCount) {
        if (fileList.length >= maxFileCount) {
          alert(`최대 ${maxFileCount}개의 파일까지 업로드할 수 있습니다.`);
          e.target.value = '';
          return;
        }
      }
    }

    //각 파일 용량제한
    //  if (files.size /* 5242881 */ > /* 27930099 */ FILE_SIZE_MAX_LIMIT)
    if (files.size > FILE_SIZE_MAX_LIMIT) {
      alert(
        `파일 용량이 초과되었습니다.\n제한 용량 : ${formatNumberCommas(
          FILE_SIZE_MAX_LIMIT / (1024 * 1024)
        )}MB`
      );
      e.target.value = '';
      return;
    }

    /* ////수정 메인이미지 한개제어
    if (control) {
      alert('새로 추가하려면 기존 파일을 삭제해야합니다.(최대 1개 파일 가능)');
      return;
    } */

    try {
      setLoading(true);
      /*  await new Promise((resolve) => setTimeout(resolve, 2000)); */
      setSelectedFile(files);
      setFileList([...fileList, files]);
      e.target.value = '';
    } catch (err) {
      console.log(err);
    } finally {
      setLoading(false);
    }
  };

  const handleClickDelete = (fileToDelete: File) => {
    const updateFileList = fileList.filter((file) => file !== fileToDelete);
    setFileList(updateFileList);
  };

  useEffect(() => {
    if (loading) {
      document.documentElement.style.overflow = 'hidden';
    } else {
      document.documentElement.style.overflow = 'auto';
    }
  }, [loading]);

  //총 파일 용량 구하기
  useEffect(() => {
    setTotalFilesSize(
      totalFileList?.reduce(
        (acc: any, file: any) => acc + file.size,
        initialTotalFilesSize
      )
    );
    if (fileList.length > 0 && setHelperText) {
      setHelperText((helperText) => ({
        ...helperText,
        images: '',
      }));
    }
  }, [fileList, totalFilesSize, initialTotalFilesSize, totalFileList]);

  return (
    <div className='artistApplyItemWrap artistApplyFileWrap'>
      {/* <div className='artistApplyFileDescription'>
        <p className='inputTitle'>출품작 파일 업로드</p>
        <p className='artistApplyTxt'>
          1.{' '}
          <span className='strong'>
            jpeg, jpg, png 또는 mp4, mkv, webm, mov, avi(영상 작품) 등 형식 파일
          </span>{' '}
          자유 업로드 (단, 입체 작품은 정면 및 측면 디테일 컷 필수)
        </p>
        <p className='artistApplyTxt'>
          2. 파일명 : 작가명_작품제목_사이즈(세로x가로x높이cm)_재료_제작년도
        </p>
        <p className='artistApplyTxt'>
          3. 파일용량 : 총{' '}
          {formatNumberCommas(FILE_SIZE_MAX_LIMIT / (1024 * 1024))}MB 이내
        </p>
      </div> */}

      <div className='artistApplyFileUploadtext' onClick={handleClickFile}>
        이미지 추가하기 <FileUploadIcon />
      </div>
      <input
        ref={fileRef}
        type='file'
        multiple
        id='inputFile'
        accept='.jpg,.jpeg,.png,video/*'
        onChange={handleChangeFile}
      />
      <div
        className={
          fileList.length > 0
            ? 'artistApplyFileListsWrapView artistApplyFileListsWrap'
            : 'artistApplyFileListsWrap'
        }
      >
        {fileList.map((file, index) => (
          <div className='artistApplyFileUploadWrap' key={index}>
            <div className='artistApplyFileUploadThumbnail'>
              {file.type.startsWith('image/') ? (
                <div className='fileImg'>
                  <img src={URL.createObjectURL(file)} alt={file.name} />
                  <div
                    className='fileDeleteIcon'
                    onClick={() => handleClickDelete(file)}
                  >
                    <ClearRoundedIcon />
                  </div>
                </div>
              ) : file.type.startsWith('video/') ? (
                <div className='fileImg fileIcon'>
                  <VideoCameraBackIcon />
                  <div
                    className='fileDeleteIcon'
                    onClick={() => handleClickDelete(file)}
                  >
                    <ClearRoundedIcon />
                  </div>
                </div>
              ) : null}
            </div>
            <div className='artistApplyFileName'>{file.name}</div>
            <div>{(file.size / (1024 * 1024)).toFixed(3)}MB</div>
          </div>
        ))}
      </div>
      {loading && <Loading Loading={loading} />}
    </div>
  );
}

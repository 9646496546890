import { useEffect, useRef, useState } from "react";
import { useNavigate, useLocation } from "react-router";
import { isMobile, isBrowser, isAndroid, isIOS } from "react-device-detect";
import dayjs from "dayjs";
import { useForm } from "@hooks/useForm";
import InputOnchangeRegexCommonDefault from "@components/Input/InputOnchangeRegexCommonDefault";
import regex from "helpers/regex";
// import Radio from '@components/radio/Radio';
import isBetween from "dayjs/plugin/isBetween";
// import ArtistApplyFile from './ArtistApplyFile';
import blueLogo from "@assets/images/blueLogo.png";
import TextArea from "@components/Input/TextArea";
// import ArtistApplyFilePortfolio from './ArtistApplyFilePortfolio';
import CheckboxClassic from "@components/checkbox/CheckboxClassic";
import HotelArtFairCheckBoxAgreement from "./HotelArtFairCheckBoxAgreement";
import { Box } from "@mui/material";
import { useTranslation } from "react-i18next";
import CustomModalBottomBtn from "@components/modal/CustomModalBottomBtn";
import ClickBtn from "@components/btns/ClickBtn";
/* import {
  doSubmitArtistApply,
  getArtistPrizeDate,
} from '@services/ArtistApplyService'; */
import CustomModalBottomTwoBtn from "@components/modal/CustomModalBottomTwoBtn ";
import ClosedBox from "@components/closed/ClosedBox";
import Loading from "@components/Loading/Loading";
import CustomModal from "@components/modal/CustomModal";
import smoothscroll from "smoothscroll-polyfill";
import cardIcon from "@assets/images/order/card.png";
import cardOverIcon from "@assets/images/order/card_over.png";
import payIcon from "@assets/images/order/easy_pay.png";
import payOverIcon from "@assets/images/order/easy_pay_over.png";
import accountIcon from "@assets/images/order/account.png";
import accountOverIcon from "@assets/images/order/account_over.png";
import "./hotelArtFairTicketingOrder.scss";
import formatNumberCommas from "helpers/formatNumberCommas";
import AddIcon from "@mui/icons-material/Add";
import RemoveIcon from "@mui/icons-material/Remove";
import ClearIcon from "@mui/icons-material/Clear";
import { hotelArtFairTermsOfUseData } from "datas/hotelArtFairTermsOfUseData";
import {
  getTicketAmount,
  postCreateOrder,
  postTicketDiscountCode,
} from "@services/HotelArtFairService";
import { useRecoilState } from "recoil";
import { router } from "@stores/router";
import queryString from "query-string";
import hotel_art_fair_order_banner from "@assets/images/hotelArtFair/hotel_art_fair_order_banner_1.png";
import { getArtFairDate } from "@services/DateArtFairService";

export default function HotelArtFairTicketingOrder() {
  const navigate = useNavigate();
  const { t } = useTranslation();
  const location = useLocation();
  const locationState = location.state as {
    discountCode?: number | string | null;
  };

  const handleClickMain = () => {
    window.open("/");
  };
  const url = process.env.REACT_APP_API_URL;

  const nameRef = useRef<HTMLInputElement>(null);
  const emailRef = useRef<HTMLInputElement>(null);
  const discountCodeRef = useRef<HTMLInputElement>(null);
  const ticketPcsRef = useRef<HTMLInputElement>(null);

  const emailAgreeRef = useRef<HTMLDivElement>(null);
  const paymentAgreeRef = useRef<HTMLDivElement>(null);

  const phoneStartRef = useRef<HTMLInputElement>(null);
  const phoneMidRef = useRef<HTMLInputElement>(null);
  const phoneEndRef = useRef<HTMLInputElement>(null);

  const historyRef = useRef<HTMLTextAreaElement>(null);
  const artDescriptionRef = useRef<HTMLTextAreaElement>(null);

  const [totalPrice, setTotalPrice] = useState(0);
  const [portFolioList, setPortFolioList] = useState<File[]>([]);

  const [loading, setLoading] = useState(false);
  const [isPageLoading, setIsPageLoading] = useState(true);
  //day
  const [isBetweenDateHidden, setIsBetweenDateHidden] = useState(false);
  dayjs.extend(isBetween);

  useEffect(() => {
    setIsPageLoading(true);
    setLoading(true);

    const getDate = async () => {
      let localStartDate;
      let localEndDate;
      try {
        /*   const data = await getArtistPrizeDate();
        localStartDate = data.data.start_data;
        localEndDate = data.data.end_data; */
        const data = await getArtFairDate("josun_hotel_art_fair");
        localStartDate = data.data.start_data;
        localEndDate = data.data.end_data;
      } catch (err) {
        console.log(err);
      }
      const startDate = dayjs(localStartDate);
      const endDate = dayjs(localEndDate);

      if (dayjs().isBetween(startDate, endDate, "day", "[]")) {
        setIsBetweenDateHidden(false);
      } else {
        setIsBetweenDateHidden(true); //true:hidden
      }
      setIsPageLoading(false);
      setLoading(false);
    };
    getDate();
  }, []);

  useEffect(() => {
    if (loading) {
      document.documentElement.style.overflow = "hidden";
    } else {
      document.documentElement.style.overflow = "auto";
    }
  }, [loading]);

  const [artCategoryValues, setArtCategoryValues] = useState("");
  const [artInterestValues, setArtInterestValues] = useState("");
  const [artCountValues, setArtCountValues] = useState(0);
  //체크>email
  const [emailAgreement, setEmailAgreement] = useState(false);
  //체크>payment
  const [paymentAgreement, setPaymentAgreement] = useState(false);

  //submit button 활성화
  const [isOn, setIsOn] = useState(false);
  const [isMobileOrWeb, setIsMobileOrWeb] = useState("");
  const [isAosOrIos, setIsAosOrIos] = useState("");
  const [helperText, setHelperText] = useState({
    ticketPcs: "",
    discountCode: "",
    emailAgreement: "",
    paymentAgreement: "",
    paymentType: "",
    phone: "",
  });
  const [successDiscountCode, setSuccessDiscountCode] = useState(false);
  const [successDiscountType, setSuccessDiscountType] = useState("일반권");

  const discountCodeValue = locationState?.discountCode
    ? locationState?.discountCode
    : "";

  const { values, setValues, handleChange } = useForm({
    name: "",
    phoneStart: "",
    phoneMid: "",
    phoneEnd: "",
    email: "",
    discountCode: discountCodeValue,
    ticketPcs: 1,
  });

  //체크박스
  const emailAgreementChange = (e: boolean) => {
    setEmailAgreement(e);
  };
  const paymentAgreementChange = (e: boolean) => {
    setPaymentAgreement(e);
  };
  //티켓수량
  const handleTicketPcs = (e: any) => {
    /*  if (values?.ticketPcs && values?.ticketPcs <= 0) {
      setValues((value) => ({
        ...value,
        ticketPcs: 1,
      }));
      return;
    } */

    /* if (e.target.value <= 0) {
      // setHelperText((helperText) => ({
      //   ...helperText,
      //   ticketPcs: '1매 이상부터 구매가능합니다.',
      // }));
      e.target.value = 1;
    }
    if (e.target.value > 2) {
      // setHelperText((helperText) => ({
      //   ...helperText,
      //   ticketPcs: '최대 2매까지 구매가능합니다.',
      // }));
      e.target.value = 2;
    } else {
      setHelperText((helperText) => ({
        ...helperText,
        ticketPcs: '',
      }));
    } */
    handleChange(e);
    setValues((value) => ({
      ...value,
      ticketPcs: e.target.value.replace(/[^0-9]/g, ""),
    })); //숫자만 입력가능하도록 설정
  };
  //티켓수량 -+
  const handleClickBtnTicketPcs = (type: string) => {
    if (type === "minus") {
      if (values?.ticketPcs && values?.ticketPcs > 1) {
        setValues((value) => ({
          ...value,
          ticketPcs: Number(values?.ticketPcs) - 1,
        }));
      } else {
        setValues((value) => ({
          ...value,
          ticketPcs: 1,
        }));
      }
    } else {
      if (successDiscountCode) {
        if (values?.ticketPcs && values?.ticketPcs < 2) {
          setValues((value) => ({
            ...value,
            ticketPcs: Number(values?.ticketPcs) + 1,
          }));
        } else {
          setValues((value) => ({
            ...value,
            ticketPcs: 2,
          }));
          alert("할인 코드 적용 구매는 최대 2매까지 구매 가능합니다.");
          return;
        }
      } else {
        if (values?.ticketPcs && values?.ticketPcs < 50) {
          setValues((value) => ({
            ...value,
            ticketPcs: Number(values?.ticketPcs) + 1,
          }));
        } else {
          setValues((value) => ({
            ...value,
            ticketPcs: 50,
          }));
          alert("일반 구매는 최대 50매까지 구매 가능합니다.");
          return;
        }
      }
    }
  };
  const handleOnBlurTicketPcs = (e: any) => {
    if (e.target.value <= 0) {
      setValues((value) => ({
        ...value,
        ticketPcs: 1,
      }));
    }

    /* if (e.target.value > 2) {
      setValues((value) => ({
        ...value,
        ticketPcs: 2,
      }));
    } else {
      setHelperText((helperText) => ({
        ...helperText,
        ticketPcs: '',
      }));
    } */

    if (successDiscountCode) {
      if (e.target.value > 2) {
        setValues((value) => ({
          ...value,
          ticketPcs: 2,
        }));
      } else {
        setHelperText((helperText) => ({
          ...helperText,
          ticketPcs: "",
        }));
      }
    } /* else {
       if (e.target.value > 2) {
         setValues((value) => ({
           ...value,
           ticketPcs: 2,
         }));
       } else {
         setHelperText((helperText) => ({
           ...helperText,
           ticketPcs: '',
         }));
       } 
    } */ else {
      if (values?.ticketPcs && values?.ticketPcs > 50) {
        setValues((value) => ({
          ...value,
          ticketPcs: 50,
        }));
        return;
      }
    }
  };

  //결제수단
  const [paymentRequest, setPaymentRequest] = useState<{
    name: string | undefined;
  }>({
    name: "CARD",
  });
  //결제수단 클릭
  const handlePayment = (e: any, nameValue: string) => {
    e.preventDefault();
    setPaymentRequest({ name: nameValue });
  };

  const handleChangeTarget = (
    e:
      | React.ChangeEvent<HTMLInputElement>
      | React.ChangeEvent<HTMLTextAreaElement>,
    name?: any,
    maxLength?: number
  ) => {
    handleChange(e);

    if (name === "phoneMid" || name === "phoneStart" || name === "phoneEnd") {
      if (
        e.target.value !== "" &&
        maxLength &&
        e.target.value.length > maxLength
      ) {
        e.target.value = e.target.value.slice(0, maxLength);
      }
      if (e.target.value === "" && name) {
        setIsOn(false);
        return setHelperText((helperText) => ({
          ...helperText,
          phone: "필수 정보입니다.",
        }));
      }
      if (
        name === "phoneStart" &&
        regex.phoneStart.test(String(phoneStartRef.current?.value))
      ) {
        phoneMidRef.current?.focus();
      }
      if (
        name === "phoneMid" &&
        regex.phoneMid.test(String(phoneMidRef.current?.value))
      ) {
        phoneEndRef.current?.focus();
      }
      if (
        (e.target.value !== "" &&
          !regex.phoneStart.test(String(phoneStartRef.current?.value))) ||
        !regex.phoneMid.test(String(phoneMidRef.current?.value)) ||
        !regex.phoneEnd.test(String(phoneEndRef.current?.value))
      ) {
        return setHelperText((helperText) => ({
          ...helperText,
          phone: "핸드폰 번호를 정확히 입력해주세요.(숫자만 입력)",
        }));
      } else {
        return setHelperText((helperText) => ({
          ...helperText,
          phone: "",
        }));
      }
    } else {
      if (e.target.value === "" && name) {
        setIsOn(false);
        return setHelperText((helperText) => ({
          ...helperText,
          [name]: "필수 정보입니다.",
        }));
      } else {
        return setHelperText((helperText) => ({
          ...helperText,
          [name]: "",
        }));
      }
    }
  };

  const handleOnfocus = (
    e:
      | React.ChangeEvent<HTMLInputElement>
      | React.ChangeEvent<HTMLTextAreaElement>,
    name: string
  ) => {
    if (name === "history") {
      if (!regex.history.test(String(historyRef.current?.value))) {
        return setHelperText((helperText) => ({
          ...helperText,
          history: "필수 정보입니다.",
        }));
      } else {
        return setHelperText((helperText) => ({
          ...helperText,
          history: "",
        }));
      }
    }

    if (name === "phoneMid" || name === "phoneStart" || name === "phoneEnd") {
      if (e.target.value === "" && name) {
        setIsOn(false);
        return setHelperText((helperText) => ({
          ...helperText,
          phone: "필수 정보입니다.",
        }));
      }
    }

    if (name === "emailAgreement") {
      if (!emailAgreement) {
        return setHelperText((helperText) => ({
          ...helperText,
          emailAgreement: "필수 정보입니다.",
        }));
      } else {
        return setHelperText((helperText) => ({
          ...helperText,
          emailAgreement: "",
        }));
      }
    }
    if (name === "paymentAgreement") {
      if (!paymentAgreement) {
        return setHelperText((helperText) => ({
          ...helperText,
          paymentAgreement: "필수 정보입니다.",
        }));
      } else {
        return setHelperText((helperText) => ({
          ...helperText,
          paymentAgreement: "",
        }));
      }
    } else {
      if (e.target.value === "") {
        return setHelperText((helperText) => ({
          ...helperText,
          [name]: "필수 정보입니다.",
        }));
      } else {
        return setHelperText((helperText) => ({
          ...helperText,
          [name]: "",
        }));
      }
    }
  };

  const phoneTotal = `${values?.phoneStart}${values?.phoneMid}${values?.phoneEnd}`;
  const emailValue = String(values?.email);
  const nameValue = String(values?.name);

  //할인코드 팝업 값 가져와서 적용시키기
  useEffect(() => {
    if (locationState?.discountCode) {
      postDiscountCode();
    } else {
      return;
    }
  }, []);

  useEffect(() => {
    if (!values) {
      return;
    }

    if (
      values.name !== "" &&
      values.phoneStart !== "" &&
      values.phoneMid !== "" &&
      values.phoneEnd !== "" &&
      values.email !== "" &&
      regex.email.test(emailValue) &&
      regex.phone.test(phoneTotal) &&
      regex.name.test(nameValue) &&
      emailAgreement &&
      /*    paymentAgreement && */
      paymentRequest.name
    ) {
      if (successDiscountType === "초대권") {
        setIsOn(true);
        return;
      } else if (successDiscountType !== "초대권") {
        if (paymentAgreement) {
          setIsOn(true);
          return;
        } else {
          setIsOn(false);
        }
      } else {
        setIsOn(false);
      }
    } else {
      setIsOn(false);
    }
  }, [
    values,
    isOn,
    emailAgreement,
    paymentAgreement,
    paymentRequest,
    successDiscountType,
  ]);

  //신청완료 modal
  const [isModalOpen, setIsModalOpen] = useState(false);
  const openModal = () => {
    setIsModalOpen(true);
    document.documentElement.style.overflow = "hidden";
  };
  const closeModal = () => {
    setIsModalOpen(false);
    document.documentElement.style.overflow = "auto";
    (window as any).gaWrite(
      "web_click_hotelartfair_ticketing_payment_close",
      "2023_hotel_artfair/ticketing",
      ""
    );
    navigate("/2023_hotel_artfair/intro");
  };

  const checkedApply = () => {
    if (!values) {
      return;
    }

    if (!paymentAgreement) {
      paymentAgreeRef.current?.scrollIntoView({
        behavior: "smooth",
      });
      setHelperText((helperText) => ({
        ...helperText,
        paymentAgreement: "동의가 필요합니다.",
      }));
    }
    if (!emailAgreement) {
      emailRef.current?.scrollIntoView({
        behavior: "smooth",
      });
      setHelperText((helperText) => ({
        ...helperText,
        emailAgreement: "동의가 필요합니다.",
      }));
    }

    if (values.email === "" || !regex.email.test(emailValue)) {
      emailRef.current?.focus();
    }
    if (
      values.phoneEnd === "" ||
      !regex.phoneEnd.test(String(phoneEndRef.current?.value))
    ) {
      phoneEndRef.current?.focus();
    }
    if (
      values.phoneMid === "" ||
      !regex.phoneMid.test(String(phoneMidRef.current?.value))
    ) {
      phoneMidRef.current?.focus();
    }
    if (
      values.phoneStart === "" ||
      !regex.phoneStart.test(String(phoneStartRef.current?.value))
    ) {
      phoneStartRef.current?.focus();
    }

    if (values.name === "" || !regex.name.test(nameValue)) {
      nameRef.current?.focus();
    }
  };
  ////

  const postDiscountCode = async () => {
    /*  try { */
    const body = {
      code: values?.discountCode,
      count: values?.ticketPcs,
    };
    const data = await postTicketDiscountCode(body);

    // console.log(data.data);
    /*  console.log(data.data.alert_message); */

    if (!data.data.result) {
      // alert('유효하지 않은 할인코드입니다.');
      /*  setHelperText((helperText) => ({
        ...helperText,
        discountCode: '올바르지 않은 할인코드입니다.',
      })); */
      //alert_message: false일시 message를 helperText노출/ true일시 alert에 message띄우기
      if (!data.data.alert_message) {
        setHelperText((helperText) => ({
          ...helperText,
          discountCode: data.data.message,
        }));
      }
      if (data.data.alert_message) {
        alert(data.data.message);
      }
      setValues((value) => ({
        ...value,
        discountCode: "",
      }));
      return;
    } else {
      if (data.data.max_count === data.data.use_count) {
        // alert('할인코드가 모두 소진되었습니다.');
        setHelperText((helperText) => ({
          ...helperText,
          discountCode: "할인코드가 모두 소진되었습니다.",
        }));
      } else {
        setTotalPrice(data.data.amount);
        setSuccessDiscountCode(true);

        //티켓타입 저장
        if (data.data.amount === 0) {
          setSuccessDiscountType("초대권");
        } else if (data.data.amount > 0) {
          setSuccessDiscountType("할인권");
        }

        if (values?.ticketPcs && values?.ticketPcs > 2) {
          setValues((value) => ({
            ...value,
            ticketPcs: 2,
          }));
        }
      }
    }
    /*  }  */ /* catch (err) {
      alert('작성한 정보를 다시 확인해주세요.');
    } */
  };
  const submitDiscountCode = async () => {
    /* setHelperText((helperText) => ({
      ...helperText,
      discountCode: '올바르지 않은 할인코드입니다.',
    })); */

    if (!values?.discountCode) {
      alert("할인코드를 입력해주세요.");
    } else {
      postDiscountCode();
    }
  };

  const [registrationNumber, setRegistrationNumber] = useState<Number>(0);
  const formDoSubmit = async () => {
    try {
      //할인코드 성공시에만 code보내도록 설정
      let successDiscountCodeData;
      if (successDiscountCode) {
        successDiscountCodeData = values?.discountCode;
      } else {
        successDiscountCodeData = "";
      }

      const body = {
        name: values?.name,
        phone: phoneTotal,
        email: values?.email,
        // code: values?.discountCode,
        code: successDiscountCodeData,
        count: values?.ticketPcs,
      };

      setLoading(true);

      const data = await postCreateOrder(body);

      if (data.code === 200) {
        setLoading(false);
        let order_id;
        let order_type;
        order_id = data.data.order_id;
        order_type = data.data.type;
        const totalBody = {
          phone: body.phone,
          email: body.email,
          order_name: body.name,
          order_id: order_id,
          pay_method: paymentRequest.name,
          market_type: "joSunHotelTicket", //결제 page
        };

        setTotalBodyQueryString(queryString.stringify(totalBody));
        if (order_type === 1) {
          openModal();
          return;
        } else {
          openPaymentModal();
        }
      } else {
        alert("결제 시도가 실패되었습니다.");
      }
    } catch (err) {
      alert("오류로 인해 실패했습니다.");
    } finally {
      setLoading(false);
    }
  };

  // 이노페이 결제창 modal
  const [totalBodyQueryString, setTotalBodyQueryString] = useState("");
  const [isPaymentModalOpen, setPaymentModalOpen] = useState(false);
  const openPaymentModal = () => {
    setPaymentModalOpen(true);
    document.documentElement.style.overflow = "hidden";
  };
  const closePaymentModal = () => {
    setPaymentModalOpen(false);
    document.documentElement.style.overflow = "auto";
  };

  //입력 체크
  const successArtCountModalOpen = () => {
    //

    //
    if (isOn) {
      (window as any).gaWrite(
        "web_click_hotelartfair_ticketing_payment",
        "2023_hotel_artfair/ticketing",
        successDiscountType + "/" + `${values?.ticketPcs}매`
      );
      formDoSubmit();
    } else {
      alert("입력하신 항목을 다시 확인해주세요.");
      checkedApply();
    }
  };

  //이용약관모달
  const [termsOfUseModalOpen, setTermsOfUseModalOpen] = useState(false);
  const openTermsOfUseModal = () => {
    setTermsOfUseModalOpen(true);
  };
  const closedTermsOfUseModal = () => {
    setTermsOfUseModalOpen(false);
  };
  // console.log(`${url}/v1/payment/art/market?${totalBodyQueryString}`);

  const [defaultAmount, setDefaultAmount] = useState(0);
  //기본 결제금액 가져오기
  const getDefaultAmount = async () => {
    try {
      const data = await getTicketAmount({});
      setTotalPrice(data);
      setDefaultAmount(data);
    } catch (err) {
      console.log(err);
    }
  };

  useEffect(() => {
    getDefaultAmount();
    smoothscroll.polyfill();
  }, []);

  useEffect(() => {
    if (isMobile) {
      setIsMobileOrWeb("M");
    } else if (isBrowser) {
      setIsMobileOrWeb("P");
      setIsAosOrIos("WEB");
    }

    if (isAndroid) {
      setIsAosOrIos("AOS");
    } else if (isIOS) {
      setIsAosOrIos("IOS");
    }
  }, [isMobileOrWeb, isAosOrIos]);

  let isFrom = true;
  const [routerModel, setRouterModel] = useRecoilState(router);
  useEffect(() => {
    closePaymentModal();

    window.addEventListener("message", (e) => {
      const data = e.data;
      let json;
      try {
        json = JSON.parse(data);
      } catch (e) {
        return;
      }
      // 취소 버튼
      if (json.result) {
        if (json.result === "failure") {
          closePaymentModal(); //닫기 버튼 누를시
        } else if (
          json.result &&
          (json.result === "3001" || json.result === "4000") //성공(3001:카드,이페이,4000:계좌성공)
        ) {
          document.documentElement.style.overflow = "auto";
          closePaymentModal();
          openModal();
        } else {
          alert("결제에 실패 했습니다. \n실패사유: " + json.result_msg);
          closePaymentModal();
        }
      }
    });
    //브라우저가 꺼지기전 작동(x버튼이나 탭을 닫았을때 발생)
    const beforeunload = (e: BeforeUnloadEvent) => {
      e.preventDefault(); // 이벤트 기본 동작을 중지
      if (isFrom) {
        e.returnValue = t("commonReset"); //변경사항이 저장되지 않을 수 있습니다.
      }
    };
    //컴포넌트 나갈때
    const unload = (e: Event) => {
      isFrom = false;
    };
    window.addEventListener("beforeunload", beforeunload);
    window.addEventListener("unload", unload);
    setRouterModel((p) => ({ ...p, isGuard: true })); //헤더에 있는 모달 제어(isGuard:true일경우 뜸)
    //나가면 모달이 사라지게 함

    return () => {
      window.removeEventListener("beforeunload", beforeunload);
      setRouterModel((p) => ({ ...p, isGuard: false }));
    };
  }, []);

  useEffect(() => {
    if (values && setHelperText) {
      if (emailAgreement) {
        setHelperText((helperText) => ({
          ...helperText,
          emailAgreement: "",
        }));
      }

      if (paymentAgreement) {
        setHelperText((helperText) => ({
          ...helperText,
          paymentAgreement: "",
        }));
      }
    }
  }, [emailAgreement, paymentAgreement, values]);

  //수량변경시 할인코드 가격 다시받아오기
  useEffect(() => {
    //기존 할인코드 적용하기버튼 누르고 성공된 상태에서 수량변경시 조건(주석)/현재는 수량변경시 계속 체크
    /* if (!values?.discountCode || !successDiscountCode) {
      return;
    } else { */
    postDiscountCode();
    /* } */
  }, [values?.ticketPcs]);

  //할인코드 적용후, 값변경 되었을 경우
  useEffect(() => {
    if (successDiscountCode) {
      alert("기존 적용된 할인코드가 사라집니다. 다시 적용해주세요.");
      setSuccessDiscountCode(false);

      setSuccessDiscountType("일반권");
      /* const defaultPrice = defaultAmount * Number(values?.ticketPcs);
      setTotalPrice(defaultPrice); */
    }
  }, [values?.discountCode]);

  /* console.log(totalPrice); */
  useEffect(() => {
    if (successDiscountCode) {
      setHelperText((helperText) => ({
        ...helperText,
        discountCode: "할인코드가 적용되었습니다.",
      }));
    } else {
      setHelperText((helperText) => ({
        ...helperText,
        discountCode: "",
      }));
    }
  }, [successDiscountCode]);

  return (
    <div
      className={
        isPageLoading
          ? "hotelArtFairTicketingOrder-wrap Gap"
          : isBetweenDateHidden
          ? "hotelArtFairTicketingOrder-wrap Hidden"
          : "hotelArtFairTicketingOrder-wrap View"
      }
    >
      <div className="hotelArtFairTicket_banner">
        <img
          src={hotel_art_fair_order_banner}
          alt="2023 SecretTown Hotel Art Fair"
        />
      </div>
      <div className="hotelArtFairTicketingOrder-inner">
        <form className="hotel-order-wrap applyForm">
          <p className="order-title">
            2023 Secret Town Hotel Art Fair 티켓 구매
          </p>

          <div className="hotel-order-contents">
            <div className="hotel-order-item-wrap">
              <p className="hotel-order-comment">
                티켓 구매를 위한 정보를 입력해주세요. (<span>*</span> 항목 필수
                입력)
              </p>

              <div className="labelBox">
                <div className="inputTitleWrap">
                  <p className="inputTitle">구매자명</p>
                </div>
                <InputOnchangeRegexCommonDefault
                  title="구매자명"
                  type="text"
                  name="name"
                  value={values?.name}
                  placeholder="구매자 성함을 입력해주세요."
                  onChange={handleChangeTarget}
                  labelClassName="label"
                  className="input"
                  regexCheck={regex.name}
                  successText={""}
                  errorText={"문자 밖의 공백을 제외하여 입력해주세요."}
                  helperTextClassName="helperText"
                  required={true}
                  inputTitle="inputTitle"
                  ref={nameRef}
                />
              </div>

              <div className="inputPhoneWrap">
                <p className="inputTitle">핸드폰 번호</p>
                <div className="phoneComponentWrap">
                  <input
                    type="text"
                    name="phoneStart"
                    value={values?.phoneStart || ""}
                    placeholder="숫자만 입력"
                    onChange={(e) => handleChangeTarget(e, e.target.name, 3)}
                    maxLength={3}
                    ref={phoneStartRef}
                    className="input phoneInput"
                    onFocus={(e) => handleOnfocus(e, e.target.name)}
                  />
                  <p className="hyphen">-</p>
                  <input
                    type="text"
                    name="phoneMid"
                    value={values?.phoneMid || ""}
                    placeholder="숫자만 입력"
                    onChange={(e) => handleChangeTarget(e, e.target.name, 4)}
                    maxLength={4}
                    ref={phoneMidRef}
                    className="input phoneInput"
                    onFocus={(e) => handleOnfocus(e, e.target.name)}
                  />
                  <p className="hyphen">-</p>
                  <input
                    type="text"
                    name="phoneEnd"
                    value={values?.phoneEnd || ""}
                    placeholder="숫자만 입력"
                    onChange={(e) => handleChangeTarget(e, e.target.name, 4)}
                    maxLength={4}
                    ref={phoneEndRef}
                    className="input phoneInput"
                    onFocus={(e) => handleOnfocus(e, e.target.name)}
                  />
                </div>
                {helperText.phone && (
                  <p className="helperText phone">{helperText.phone}</p>
                )}
              </div>

              <div className="labelBox">
                <div className="inputTitleWrap">
                  <p className="inputTitle">이메일</p>
                </div>
                <InputOnchangeRegexCommonDefault
                  title="이메일"
                  type="text"
                  name="email"
                  value={values?.email}
                  placeholder=""
                  onChange={handleChangeTarget}
                  labelClassName="label"
                  className="input"
                  regexCheck={regex.email}
                  successText={""}
                  errorText={"이메일 형식이 올바르지 않습니다."}
                  helperTextClassName="helperText"
                  required={true}
                  inputTitle="inputTitle"
                  ref={emailRef}
                />
                <div
                  ref={emailAgreeRef}
                  className="bottom-text-wrap email-agree-text-wrap"
                >
                  <p>
                    휴대폰과 이메일을 통해 QR 티켓이 송부됩니다.{" "}
                    <span>
                      {" "}
                      작성하신 정보가 정확한지 다시 한 번 확인해주세요.
                    </span>{" "}
                    <span>정보 오기입 시 티켓은 재발급 되지 않습니다.</span>
                  </p>
                  <label className="labelInputCheckBox">
                    <input
                      type="checkBox"
                      name="emailAgreement"
                      checked={emailAgreement}
                      onChange={({ target: { checked } }) =>
                        emailAgreementChange(checked)
                      }
                    />
                    <span>확인했습니다.</span>
                    {helperText.emailAgreement && (
                      <p className="helperText Agreement emailAgreement">
                        {helperText.emailAgreement}
                      </p>
                    )}
                  </label>
                </div>
              </div>

              <div className="labelBox">
                <div className="inputTitleWrap">
                  <p className="inputTitle option">할인코드</p>
                </div>

                <label className="label discount-code-label">
                  <div>
                    <input
                      type="text"
                      className="input"
                      name="discountCode"
                      value={values?.discountCode}
                      placeholder="할인코드를 입력해주세요."
                      onChange={handleChangeTarget}
                      ref={discountCodeRef}
                    />
                    <div
                      className="discount-code-check-btn"
                      onClick={submitDiscountCode}
                    >
                      할인코드 적용하기
                    </div>
                  </div>
                  {helperText.discountCode && (
                    <p className="helperText discountCode">
                      {helperText.discountCode}
                    </p>
                  )}
                </label>
              </div>

              <div className="labelBox">
                <div className="inputTitleWrap">
                  <p className="inputTitle option">티켓 수량</p>
                </div>
                <label className="labelInputCheckBox">
                  <div className="ticketPcs-box">
                    <div
                      className="ticketPcs-btn"
                      onClick={() => handleClickBtnTicketPcs("minus")}
                    >
                      <RemoveIcon />
                    </div>
                    <input
                      type="text"
                      name="ticketPcs"
                      /*    defaultValue={1} */
                      value={values?.ticketPcs}
                      onChange={(e) => handleTicketPcs(e)}
                      onBlur={(e) => handleOnBlurTicketPcs(e)}
                      ref={ticketPcsRef}
                    />
                    매
                    <div
                      className="ticketPcs-btn"
                      onClick={() => handleClickBtnTicketPcs("plus")}
                    >
                      <AddIcon />
                    </div>
                  </div>
                  {helperText.ticketPcs && (
                    <p className="helperText ticketPcs">
                      {helperText.ticketPcs}
                    </p>
                  )}
                </label>
                <p className="ticketPcs-txt">
                  * 최대 구매 수량 제한
                  <span>일반 구매: 최대 50매</span>
                  <span>할인코드 적용 구매: 최대 2매</span>
                </p>
              </div>

              <div className="labelBox total-price-label-box">
                <div className="inputTitleWrap">
                  <p className="inputTitle option">결제금액</p>
                </div>

                <label className="label discount-code-label">
                  <div className="total-price-txt">
                    {/* 할인코드 유효할시 로컬계산, 아닐시 서버 amount받기 */}
                    {!values?.discountCode || !successDiscountCode
                      ? formatNumberCommas(
                          Number(defaultAmount) * Number(values?.ticketPcs)
                        )
                      : formatNumberCommas(totalPrice)}
                    {/* {formatNumberCommas(totalPrice)} */}
                    <span>원</span>
                  </div>
                  {/*   {helperText.discountCode && (
                    <p className='helperText discountCode'>
                      {helperText.discountCode}
                    </p>
                  )} */}
                </label>
              </div>
              {successDiscountType === "초대권" ? null : (
                <div className="labelBox">
                  <div className="inputTitleWrap">
                    <p className="inputTitle option">결제수단</p>
                  </div>

                  {/* 결제수단 */}
                  <div className="orderSheet-box">
                    <div className="orderSheet-title">
                      <h3 className="maruBuri">{/* {t('ordertitle10')} */}</h3>
                    </div>

                    <div className="orderSheet-info-payment-box">
                      <div className="payment-btn-wrap">
                        {/* 신용카드 */}
                        <button
                          className={
                            paymentRequest.name === "CARD"
                              ? "active maruBuri"
                              : "btn maruBuri"
                          }
                          onClick={(e) => handlePayment(e, "CARD")}
                        >
                          <img
                            src={
                              paymentRequest.name === "CARD"
                                ? cardOverIcon
                                : cardIcon
                            }
                            alt="card"
                          />{" "}
                          {t("ordertitle11")}
                        </button>
                        {/* 간편결제 */}
                        <button
                          className={
                            paymentRequest.name === "EPAY"
                              ? "active maruBuri"
                              : "btn maruBuri"
                          }
                          onClick={(e) => handlePayment(e, "EPAY")}
                        >
                          <img
                            src={
                              paymentRequest.name === "EPAY"
                                ? payOverIcon
                                : payIcon
                            }
                            alt="easy pay"
                          />
                          {t("ordertitle12")}
                        </button>
                        {/* 계좌이체 */}
                        <button
                          className={
                            paymentRequest.name === "BANK"
                              ? "active maruBuri"
                              : "btn maruBuri"
                          }
                          onClick={(e) => handlePayment(e, "BANK")}
                        >
                          <img
                            src={
                              paymentRequest.name === "BANK"
                                ? accountOverIcon
                                : accountIcon
                            }
                            alt="bank"
                          />
                          {t("ordertitle13")}
                        </button>
                      </div>
                      {<p className="helperText">{helperText.paymentType}</p>}
                    </div>
                  </div>

                  <div ref={paymentAgreeRef} className="bottom-text-wrap">
                    <p>
                      11월 19일(일)까지 티켓 환불이 가능합니다.{" "}
                      <span>
                        이용 약관 및 티켓 발권 취소 및 환불규정을 꼭
                        확인해주세요.
                      </span>{" "}
                      <strong onClick={openTermsOfUseModal}>약관 보기</strong>
                    </p>
                    <label className="labelInputCheckBox">
                      <input
                        type="checkBox"
                        name="paymentAgreement"
                        checked={paymentAgreement}
                        onChange={({ target: { checked } }) =>
                          paymentAgreementChange(checked)
                        }
                      />
                      <span>확인했습니다.</span>
                      {helperText.paymentAgreement && (
                        <p className="helperText Agreement paymentAgreement">
                          {helperText.paymentAgreement}
                        </p>
                      )}
                    </label>
                  </div>
                </div>
              )}
            </div>

            <div className="hotel-order-item-wrap" /*  ref={agreeRef} */></div>

            <div
              className={/* isOn === false ? 'submitBtn Err' :  */ "submitBtn"}
            >
              <ClickBtn
                text="티켓 결제 및 발권하기"
                className="applySubmitBtn"
                /*   disabled={!isOn} */
                // onClick={artCountModalOpen}
                onClick={successArtCountModalOpen}
              />
            </div>
          </div>
        </form>
        <CustomModalBottomBtn
          className="artistApplySuccessModalWrap"
          open={isModalOpen}
          onClose={closeModal}
        >
          <Box>
            <div
              style={{
                background: `#fff `,
              }}
              className="artistApplySuccessModalCotents"
            >
              <div className="artistApplySuccessModalCotentsText">
                <p className="artistApplySuccessModalTitle">
                  시크릿타운 호텔 아트페어 2023
                  <span>발권이 완료되었습니다.</span>
                </p>

                <p>구매하신 티켓은 휴대폰과 이메일로 확인하실 수 있습니다.</p>
                <p>
                  발권 취소 및 환불 가능 기간 :{" "}
                  <span>11월 19일(일)까지, 이후 환불 불가</span>
                </p>
              </div>

              <p className="artistApplySuccessModalBottomContents">
                팝업을 닫으면 현재 페이지가 종료됩니다.
              </p>
            </div>
          </Box>
        </CustomModalBottomBtn>

        {/* mobile 이노페이 modal */}
        {totalBodyQueryString && (
          <CustomModal
            className="artistPaymentModalWrap"
            open={isPaymentModalOpen}
            onClose={closePaymentModal}
          >
            <iframe
              src={`${url}/v1/payment/art/market?${totalBodyQueryString}`}
              style={{
                width: "100%",
                height: "100%",
              }}
            ></iframe>
          </CustomModal>
        )}

        {/* 이용약관 */}
        <CustomModal open={termsOfUseModalOpen} onClose={closedTermsOfUseModal}>
          <div className="orderAgree-modal-wrap termsOfUse-modal-wrap">
            <h3 className="modal-title maruBuri">이용 약관</h3>
            <button className="modal-close-btn" onClick={closedTermsOfUseModal}>
              <ClearIcon />
            </button>
            <div className="orderAgree-modal-text-box sCoreDream">
              <div className="orderAgree-modal-text-box-inner">
                {/* {modalContent} */}
                {hotelArtFairTermsOfUseData}
              </div>
            </div>
            <div className="orderAgree-modal-text-box-fix">
              <div className="big-title">티켓 발권 취소 및 환불규정</div>

              <div className="small-title">
                <p> 티켓 사용 가능 기간 : 11월 24일(금) ~ 26일(일)</p>
                <p>
                  발권 취소 및 환불 가능 기간 : 11월 19일(일)까지, 이후 환불
                  불가
                </p>
              </div>
            </div>
          </div>
        </CustomModal>
        <div className="artistContestWrapHiddenBox">
          <ClosedBox />
        </div>
        {loading && <Loading Loading={loading} />}
      </div>
    </div>
  );
}

import { Trans, useTranslation } from 'react-i18next';
import formatNumberCommas from 'helpers/formatNumberCommas';
import './artSelectBox.scss';

export default function ArtSelectBox({
  guestIdState,
  items,
}: {
  guestIdState?: number;
  items: any | any[];
}) {
  const { t } = useTranslation();

  //합계
  const prices = items.map((obj: any) => obj.art_infos.price);
  const totalPrice = prices.reduce(
    (accumulator: number, currentValue: number) => accumulator + currentValue,
    0
  );

  return (
    <div className='order-select-lists-box'>
      <table className='cart-lists maruBuri'>
        <thead className='cart-lists-title-wrap'>
          <tr>
            <th className='item-img' scope='col'>
              {t('ordertitle1')}
            </th>
            <th className='item-info' scope='col'>
              {t('ordertitle2')}
            </th>
            <th className='item-amount' scope='col'>
              {t('ordertitle3')}
            </th>
            <th className='item-sellPrice' scope='col'>
              {t('ordertitle4')}
            </th>
            <th className='item-totalPrice' scope='col'>
              {t('ordertitle5')}
            </th>
          </tr>
        </thead>
        <tbody className='table-tbody'>
          {items.map((item: any, i: number) => (
            <tr key={i}>
              <td align='center'>
                <div className='art-img'>
                  {item?.images?.s && (
                    <img src={item?.images?.s} alt={item?.art_infos?.name} />
                  )}
                </div>
              </td>
              <td align='left' className='table-art-info'>
                {item?.art_infos?.name && (
                  <p className='table-art-name'>{item?.art_infos?.name}</p>
                )}
                {item?.artist_info?.name && (
                  <p className='table-art-artist'>{item?.artist_info?.name}</p>
                )}
                {item?.art_infos?.size_real && (
                  <p>{item?.art_infos.size_real}</p>
                )}
              </td>
              <td align='center'>1</td>
              <td align='center' className='break-all'>
                {item?.art_infos?.price &&
                  `${formatNumberCommas(item?.art_infos?.price)} ${t(
                    'commonPriceText'
                  )}`}
              </td>
              {i === 0 ? (
                <td
                  align='center'
                  className='total-price'
                  rowSpan={items.length + 1}
                >
                  {`${formatNumberCommas(totalPrice)} ${t('commonPriceText')}`}
                </td>
              ) : null}
            </tr>
          ))}

          <tr>
            <td align='center'></td>
            <td>{t('cartText5')}</td>
            <td align='center'>1</td>
            <td align='center'>0 {t('commonPriceText')}</td>
          </tr>
        </tbody>
      </table>

      {/* 
      <div className='total-btn-wrap'>
        <MoreWhiteBtn
          className='cart-two-btn white white-border shadowNone'
          text={t('cartText7')}
          onClick={handleOrderItems}
        />
        <MoreWhiteBtn
          className='cart-two-btn blue shadowNone'
          text={t('cartText8')}
          onClick={handleOrderTotal}
        />
      </div> */}
    </div>
  );
}

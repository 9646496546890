import { useEffect, useRef, useState } from 'react';
import { useNavigate } from 'react-router';
import { isMobile, isBrowser, isAndroid, isIOS } from 'react-device-detect';
import dayjs from 'dayjs';
import smoothscroll from 'smoothscroll-polyfill';
import { useTranslation } from 'react-i18next';
import { useDaumPostcodePopup } from 'react-daum-postcode';
import { postcodeScriptUrl } from 'react-daum-postcode/lib/loadPostcode';
import { useForm } from '@hooks/useForm';
import InputOnchangeRegex from '@components/Input/InputOnchangeRegex';
import regex from 'helpers/regex';
import Radio from '@components/radio/Radio';
import isBetween from 'dayjs/plugin/isBetween';
import TextArea from '@components/Input/TextArea';
import ArtistApplyFilePortfolio from './ArtistApplyFilePortfolio';
import RadioClassic from '@components/radio/RadioClassic';
import ArtistApplyRadioAgreement from './ArtistApplyRadioAgreement';
import { Box } from '@mui/material';
import CustomModalBottomBtn from '@components/modal/CustomModalBottomBtn';
import ClickBtn from '@components/btns/ClickBtn';
import {
  doSubmitArtistApply,
  getYoungartistDate,
  /* getArtistPrizeDate, */
} from '@services/YoungartistService';
import CustomModalBottomTwoBtn from '@components/modal/CustomModalBottomTwoBtn ';
import Loading from '@components/Loading/Loading';
import CustomModal from '@components/modal/CustomModal';
import './youngartistApply.scss';
import ArtistApplyTotalFile from './ArtistApplyTotalFile';
import { useRecoilState } from 'recoil';
import { router } from '@stores/router';
import ClosedBoxDetailDate from '@components/closed/ClosedBoxDetailDate';

export default function YoungartistApply() {
  const navigate = useNavigate();
  const { t } = useTranslation();

  const handleClickMain = () => {
    window.open('/');
  };
  const url = process.env.REACT_APP_API_URL;

  const nameRef = useRef<HTMLInputElement>(null);
  const birthRef = useRef<HTMLInputElement>(null);
  const emailRef = useRef<HTMLInputElement>(null);
  const addressRef = useRef<HTMLInputElement>(null);
  const artCategoryRef = useRef<HTMLDivElement>(null);
  const artCountOptionRef = useRef<HTMLDivElement>(null);
  const artAmountRef = useRef<HTMLInputElement>(null);
  const fileRef = useRef<HTMLDivElement>(null);
  const portFolioRef = useRef<HTMLDivElement>(null);
  const agreeRef = useRef<HTMLDivElement>(null);
  const phoneStartRef = useRef<HTMLInputElement>(null);
  const phoneMidRef = useRef<HTMLInputElement>(null);
  const phoneEndRef = useRef<HTMLInputElement>(null);
  const historyRef = useRef<HTMLTextAreaElement>(null);
  const artDescriptionRef = useRef<HTMLTextAreaElement>(null);
  const artInfo1Ref = useRef<HTMLDivElement>(null);
  const artInfo2Ref = useRef<HTMLDivElement>(null);
  const artInfo3Ref = useRef<HTMLDivElement>(null);

  //const [fileList, setFileList] = useState<File[]>([]);
  const [portFolioList, setPortFolioList] = useState<File[]>([]);
  //1~3번 대표 이미지 파일
  const [fileList1, setFileList1] = useState<File[]>([]);
  const [fileList2, setFileList2] = useState<File[]>([]);
  const [fileList3, setFileList3] = useState<File[]>([]);
  const [totalFileList, setTotalFileList] = useState<File[]>([]); //1~3번 합친 대표이미지
  //1~3번 디테일 이미지 파일
  const [fileListDetail1, setFileListDetail1] = useState<File[]>([]);
  const [fileListDetail2, setFileListDetail2] = useState<File[]>([]);
  const [fileListDetail3, setFileListDetail3] = useState<File[]>([]);
  const [totalSumFileList, setTotalSumFileList] = useState<File[]>([]); //1~3번 대표+디테일이미지
  const [totalArtInfo, setTotalArtInfo] = useState<any[]>([]); //수량에따른artInfo합치기

  //출품작 정보
  const [artInfo1, setArtInfo1] = useState<{
    // [key: string]: string | undefined;
    [key: string]: any;
    // art_name: string | undefined;
    art_name: any;
    art_size: any;
    art_material: any;
    art_creation: any;
    art_amount: any;
    art_description: any;
    art_number: Number;
  }>({
    art_name: '',
    art_size: '',
    art_material: '',
    art_creation: undefined,
    art_amount: undefined,
    art_description: '',
    art_number: 1,
  });
  const [artInfo2, setArtInfo2] = useState<{
    [key: string]: any;
    art_name: any;
    art_size: any;
    art_material: any;
    art_creation: any;
    art_amount: any;
    art_description: any;
    art_number: Number;
  }>({
    art_name: '',
    art_size: '',
    art_material: '',
    art_creation: undefined,
    art_amount: undefined,
    art_description: '',
    art_number: 2,
  });
  const [artInfo3, setArtInfo3] = useState<{
    [key: string]: any;
    art_name: any;
    art_size: any;
    art_material: any;
    art_creation: any;
    art_amount: any;
    art_description: any;
    art_number: Number;
  }>({
    art_name: '',
    art_size: '',
    art_material: '',
    art_creation: undefined,
    art_amount: undefined,
    art_description: '',
    art_number: 3,
  });
  //출품작 1~3번 대표,디테일이미지 파일명 각artInfo에 저장
  useEffect(() => {
    setArtInfo1((p) => ({
      ...p,
      art_main_image_name: fileList1[0]?.name,
    }));
    setArtInfo1((p) => ({
      ...p,
      art_sub_image_name: fileListDetail1?.map((file) => file.name),
    }));
  }, [fileList1, fileListDetail1]);
  useEffect(() => {
    setArtInfo2((p) => ({
      ...p,
      art_main_image_name: fileList2[0]?.name,
    }));
    setArtInfo2((p) => ({
      ...p,
      art_sub_image_name: fileListDetail2?.map((file) => file.name),
    }));
  }, [fileList2, fileListDetail2]);
  useEffect(() => {
    setArtInfo3((p) => ({
      ...p,
      art_main_image_name: fileList3[0]?.name,
    }));
    setArtInfo3((p) => ({
      ...p,
      art_sub_image_name: fileListDetail3?.map((file) => file.name),
    }));
  }, [fileList3, fileListDetail3]);

  const [loading, setLoading] = useState(false);
  const [isPageLoading, setIsPageLoading] = useState(true);

  //day
  const [isBetweenDateHidden, setIsBetweenDateHidden] = useState(false);
  dayjs.extend(isBetween);

  useEffect(() => {
    setIsPageLoading(true);
    setLoading(true);

    const getDate = async () => {
      let localStartDate;
      let localEndDate;
      try {
        const data = await getYoungartistDate();
        localStartDate = data.data.contest_apply_start_date;
        localEndDate = data.data.contest_apply_end_date;
        //test공모요강
        /*  localStartDate = '2024-02-07 20:18';
        localEndDate = '2024-02-06 20:17'; */
      } catch (err) {
        console.log(err);
      }
      const startDate = dayjs(localStartDate);
      const endDate = dayjs(localEndDate);

      if (dayjs().isBetween(startDate, endDate, 'minute', '[]')) {
        setIsBetweenDateHidden(false);
      } else {
        setIsBetweenDateHidden(true); //true:hidden
      }
      setIsPageLoading(false);
      setLoading(false);
    };
    getDate();
  }, []);

  useEffect(() => {
    if (loading) {
      document.documentElement.style.overflow = 'hidden';
    } else {
      document.documentElement.style.overflow = 'auto';
    }
  }, [loading]);

  const artOptionsLists = [
    {
      value: '회화',
    },
    {
      value: '사진',
    },
    {
      value: '조각',
    },
    {
      value: '영상',
    },
    {
      value: '혼합',
    },
    {
      value: '기타',
      type: 'text',
    },
  ];
  const originalCategoryArray = ['회화', '영상', '사진', '조각', '혼합'];
  const artCountOptionsLists = [
    {
      value: '1',
    },
    {
      value: '2',
    },
    {
      value: '3',
    },
  ];

  const [artCategoryValues, setArtCategoryValues] = useState('');
  const [artCountValues, setArtCountValues] = useState(0);
  //이용약관 전체체크
  const [totalAgreement, setTotalAgreement] = useState(false);
  //출품작 소개 기타 체크시 내용 여부
  const [categoryEtcPass, setCategoryEtcPass] = useState(true);
  //submit button 활성화
  const [isOn, setIsOn] = useState(false);
  const [isMobileOrWeb, setIsMobileOrWeb] = useState('');
  const [isAosOrIos, setIsAosOrIos] = useState('');
  const [helperText, setHelperText] = useState({
    history: '',
    art_category: '',
    display_art_count: '',
    art_description: '',
    totalAgreement: '',
    portfolio: '',
    phone: '',
    address: '',
    files: '',
    artDescription1: '',
    artDescription2: '',
    artDescription3: '',
  });

  const { values, handleChange } = useForm({
    name: '',
    birth: '',
    phoneStart: '',
    phoneMid: '',
    phoneEnd: '',
    email: '',
    address: '',
    history: '',
    art_category: '',
    display_art_count: '',
    art_amount: '',
  });

  const handleChangeTarget = (
    e:
      | React.ChangeEvent<HTMLInputElement>
      | React.ChangeEvent<HTMLTextAreaElement>,
    name?: any,
    maxLength?: number
  ) => {
    handleChange(e);

    if (
      name === 'artDescription3' &&
      !regex.artDescription.test(String(e.target.value))
    ) {
      //
      return setHelperText((helperText) => ({
        ...helperText,
        artDescription3: '필수 정보입니다.',
      }));
    } else if (
      name === 'artDescription3' &&
      regex.artDescription.test(String(e.target.value))
    ) {
      return setHelperText((helperText) => ({
        ...helperText,
        artDescription3: '',
      }));
    }
    if (
      name === 'artDescription2' &&
      !regex.artDescription.test(String(e.target.value))
    ) {
      return setHelperText((helperText) => ({
        ...helperText,
        artDescription2: '필수 정보입니다.',
      }));
    } else if (
      name === 'artDescription2' &&
      regex.artDescription.test(String(e.target.value))
    ) {
      return setHelperText((helperText) => ({
        ...helperText,
        artDescription2: '',
      }));
    }
    if (
      name === 'artDescription1' &&
      !regex.artDescription.test(String(e.target.value))
    ) {
      return setHelperText((helperText) => ({
        ...helperText,
        artDescription1: '필수 정보입니다.',
      }));
    } else if (
      name === 'artDescription1' &&
      regex.artDescription.test(String(e.target.value))
    ) {
      return setHelperText((helperText) => ({
        ...helperText,
        artDescription1: '',
      }));
    }

    if (
      name === 'history' &&
      !regex.history.test(String(historyRef.current?.value))
    ) {
      return setHelperText((helperText) => ({
        ...helperText,
        history: '필수 정보입니다.',
      }));
    } else if (
      name === 'history' &&
      regex.history.test(String(historyRef.current?.value))
    ) {
      return setHelperText((helperText) => ({
        ...helperText,
        history: '',
      }));
    }

    if (
      name === 'art_description' &&
      !regex.artDescription.test(String(artDescriptionRef.current?.value))
    ) {
      return setHelperText((helperText) => ({
        ...helperText,
        art_description: '필수 정보입니다.',
      }));
    } else if (
      name === 'art_description' &&
      regex.artDescription.test(String(artDescriptionRef.current?.value))
    ) {
      return setHelperText((helperText) => ({
        ...helperText,
        art_description: '',
      }));
    }

    if (name === 'phoneMid' || name === 'phoneStart' || name === 'phoneEnd') {
      if (
        e.target.value !== '' &&
        maxLength &&
        e.target.value.length > maxLength
      ) {
        e.target.value = e.target.value.slice(0, maxLength);
      }
      if (e.target.value === '' && name) {
        setIsOn(false);
        return setHelperText((helperText) => ({
          ...helperText,
          phone: '필수 정보입니다.',
        }));
      }
      if (
        name === 'phoneStart' &&
        regex.phoneStart.test(String(phoneStartRef.current?.value))
      ) {
        phoneMidRef.current?.focus();
      }
      if (
        name === 'phoneMid' &&
        regex.phoneMid.test(String(phoneMidRef.current?.value))
      ) {
        phoneEndRef.current?.focus();
      }
      if (
        (e.target.value !== '' &&
          !regex.phoneStart.test(String(phoneStartRef.current?.value))) ||
        !regex.phoneMid.test(String(phoneMidRef.current?.value)) ||
        !regex.phoneEnd.test(String(phoneEndRef.current?.value))
      ) {
        return setHelperText((helperText) => ({
          ...helperText,
          phone: '핸드폰 번호를 정확히 입력해주세요.(숫자만 입력)',
        }));
      } else {
        return setHelperText((helperText) => ({
          ...helperText,
          phone: '',
        }));
      }
    } else {
      if (e.target.value === '' && name) {
        setIsOn(false);
        return setHelperText((helperText) => ({
          ...helperText,
          [name]: '필수 정보입니다.',
        }));
      } else {
        return setHelperText((helperText) => ({
          ...helperText,
          [name]: '',
        }));
      }
    }
  };

  const handleOnfocus = (
    e:
      | React.ChangeEvent<HTMLInputElement>
      | React.ChangeEvent<HTMLTextAreaElement>,
    name: string
  ) => {
    if (name === 'artDescription3') {
      if (!regex.artDescription.test(String(e.target.value))) {
        return setHelperText((helperText) => ({
          ...helperText,
          artDescription3: '필수 정보입니다.',
        }));
      } else {
        return setHelperText((helperText) => ({
          ...helperText,
          artDescription3: '',
        }));
      }
    }
    if (name === 'artDescription2') {
      if (!regex.artDescription.test(String(e.target.value))) {
        return setHelperText((helperText) => ({
          ...helperText,
          artDescription2: '필수 정보입니다.',
        }));
      } else {
        return setHelperText((helperText) => ({
          ...helperText,
          artDescription2: '',
        }));
      }
    }
    if (name === 'artDescription1') {
      if (!regex.artDescription.test(String(e.target.value))) {
        return setHelperText((helperText) => ({
          ...helperText,
          artDescription1: '필수 정보입니다.',
        }));
      } else {
        return setHelperText((helperText) => ({
          ...helperText,
          artDescription1: '',
        }));
      }
    }

    if (name === 'history') {
      if (!regex.history.test(String(historyRef.current?.value))) {
        return setHelperText((helperText) => ({
          ...helperText,
          history: '필수 정보입니다.',
        }));
      } else {
        return setHelperText((helperText) => ({
          ...helperText,
          history: '',
        }));
      }
    }

    if (name === 'art_description') {
      if (
        !regex.artDescription.test(String(artDescriptionRef.current?.value))
      ) {
        return setHelperText((helperText) => ({
          ...helperText,
          art_description: '필수 정보입니다.',
        }));
      } else {
        return setHelperText((helperText) => ({
          ...helperText,
          art_description: '',
        }));
      }
    }

    if (name === 'phoneMid' || name === 'phoneStart' || name === 'phoneEnd') {
      if (e.target.value === '' && name) {
        setIsOn(false);
        return setHelperText((helperText) => ({
          ...helperText,
          phone: '필수 정보입니다.',
        }));
      }
    } else {
      if (e.target.value === '') {
        return setHelperText((helperText) => ({
          ...helperText,
          [name]: '필수 정보입니다.',
        }));
      } else {
        return setHelperText((helperText) => ({
          ...helperText,
          [name]: '',
        }));
      }
    }
  };

  ////주소
  const [deliveryInfo, setDeliveryInfo] = useState<{
    [key: string]: string | undefined;
    postNumber: string | undefined;
    address: string | undefined;
    subAddress: string | undefined;
  }>({
    postNumber: undefined,
    address: undefined,
    subAddress: '',
  });

  const open = useDaumPostcodePopup(postcodeScriptUrl);
  const handleComplete = (data: any) => {
    const el: any = document.getElementById('addressYoungartist');
    const postNumber: any = document.getElementById('postNumberYoungartist');
    if (el) {
      el.value = data.address;
    }
    if (postNumber) {
      postNumber.value = data.zonecode;
    }
    setDeliveryInfo((p) => ({
      ...p,
      address: data.address,
      postNumber: data.zonecode,
    }));
  };
  const handleClickAddress = () => {
    open({ onComplete: handleComplete });
  };
  ////주소끝

  const phoneTotal = `${values?.phoneStart}${values?.phoneMid}${values?.phoneEnd}`;
  const birthValue = String(values?.birth);
  const emailValue = String(values?.email);
  const nameValue = String(values?.name);
  const historyValue = String(values?.history);
  const addressValue = String(deliveryInfo?.address);

  //수량변경에 따른 작품 정보(대표 이미지-합치기)
  useEffect(() => {
    let mergedList: File[] = [];
    if (artCountValues === 1) {
      mergedList = fileList1;
    } else if (artCountValues === 2) {
      mergedList = [...fileList1, ...fileList2];
    } else if (artCountValues === 3) {
      mergedList = [...fileList1, ...fileList2, ...fileList3];
    }
    setTotalFileList(mergedList);
  }, [artCountValues, fileList1, fileList2, fileList3]);
  //수량변경에 따른 작품 정보 (대표 이미지+디테일이미지-1~3번 모두 합치기)
  useEffect(() => {
    let mergedList: File[] = [];
    if (artCountValues === 1) {
      mergedList = [...totalFileList, ...fileListDetail1];
      setArtInfo1((p) => ({
        ...p,
        main_files: [...fileList1],
        files: [...fileListDetail1],
      }));
    } else if (artCountValues === 2) {
      mergedList = [...totalFileList, ...fileListDetail1, ...fileListDetail2];
      setArtInfo1((p) => ({
        ...p,
        main_files: [...fileList1],
        files: [...fileListDetail1],
      }));
      setArtInfo2((p) => ({
        ...p,
        main_files: [...fileList2],
        files: [...fileListDetail2],
      }));
    } else if (artCountValues === 3) {
      mergedList = [
        ...totalFileList,
        ...fileListDetail1,
        ...fileListDetail2,
        ...fileListDetail3,
      ];
      setArtInfo1((p) => ({
        ...p,
        main_files: [...fileList1],
        files: [...fileListDetail1],
      }));
      setArtInfo2((p) => ({
        ...p,
        main_files: [...fileList2],
        files: [...fileListDetail2],
      }));
      setArtInfo3((p) => ({
        ...p,
        main_files: [...fileList3],
        files: [...fileListDetail3],
      }));
    }
    setTotalSumFileList(mergedList);
  }, [
    artCountValues,
    fileList1,
    fileList2,
    fileList3,
    totalFileList,
    fileListDetail1,
    fileListDetail2,
    fileListDetail3,
  ]);

  /* console.log(artInfo1, 'artInfo1');
  console.log(artInfo2, 'artInfo2');
  console.log(artInfo3, 'artInfo3'); */
  //
  //setTotalArtInfo 수량에따른 artInfo들 합치기
  useEffect(() => {
    let mergedList: any[] = [];
    if (artCountValues === 1) {
      mergedList = [artInfo1];
    } else if (artCountValues === 2) {
      mergedList = [artInfo1, artInfo2];
    } else if (artCountValues === 3) {
      mergedList = [artInfo1, artInfo2, artInfo3];
    }
    setTotalArtInfo(mergedList);
  }, [artInfo1, artInfo2, artInfo3, artCountValues]);

  useEffect(() => {
    if (!values) {
      return;
    }

    if (addressRef.current?.value) {
      setHelperText((helperText) => ({
        ...helperText,
        address: '',
      }));
    }
    /* if (portFolioList.length !== 0) {
      setHelperText((helperText) => ({
        ...helperText,
        portfolio: '',
      }));
    } */
    //수량별 출품작(대표 이미지 파일 업로드 체크)+추가하기
    if (totalFileList.length === artCountValues) {
      setHelperText((helperText) => ({
        ...helperText,
        files: '',
      }));
    }

    ///각 작품별 정보
    function validateArtInfo(artInfo: any) {
      //작품명
      if (!artInfo.art_name || !regex.fileName.test(artInfo.art_name)) {
        return false;
      }
      //사이즈
      if (!artInfo.art_size || !regex.nonGap.test(artInfo.art_size)) {
        return false;
      }
      //재료
      if (!artInfo.art_material || !regex.nonGap.test(artInfo.art_material)) {
        return false;
      }
      //제작연도
      if (
        !artInfo.art_creation ||
        !regex.artCreation.test(artInfo.art_creation)
      ) {
        return false;
      }
      //희망 소비자 판매가
      if (!artInfo.art_amount || !regex.artAmount.test(artInfo.art_amount)) {
        return false;
      }
      //출품작 설명
      if (
        !artInfo.art_description ||
        !regex.artDescription.test(artInfo.art_description)
      ) {
        return false;
      }
      //작품디테일컷 fileListDetail;
      /*  if (artInfo === artInfo1) {
        if (fileListDetail1.length === 0) {
          return false;
        }
      }
      if (artInfo === artInfo2) {
        if (fileListDetail1.length === 0 || fileListDetail2.length === 0) {
          return false;
        }
      }
      if (artInfo === artInfo3) {
        if (
          fileListDetail1.length === 0 ||
          fileListDetail2.length === 0 ||
          fileListDetail3.length === 0
        ) {
          return false;
        }
      } */

      return true;
    }

    if (artCountValues === 1) {
      if (!validateArtInfo(artInfo1)) {
        return setIsOn(false);
      }
    }
    if (artCountValues === 2) {
      if (!validateArtInfo(artInfo1) || !validateArtInfo(artInfo2)) {
        return setIsOn(false);
      }
    }
    if (artCountValues === 3) {
      if (
        !validateArtInfo(artInfo1) ||
        !validateArtInfo(artInfo2) ||
        !validateArtInfo(artInfo3)
      ) {
        return setIsOn(false);
      }
    }

    if (
      values.name !== '' &&
      values.birth !== '' &&
      values.phoneStart !== '' &&
      values.phoneMid !== '' &&
      values.phoneEnd !== '' &&
      values.email !== '' &&
      deliveryInfo.address !== '' &&
      deliveryInfo.address !== undefined &&
      values.history !== '' &&
      regex.youngartistbirth.test(birthValue) &&
      regex.email.test(emailValue) &&
      regex.phone.test(phoneTotal) &&
      regex.name.test(nameValue) &&
      regex.history.test(historyValue) &&
      regex.nonGap.test(addressValue) &&
      artCategoryValues !== '' &&
      categoryEtcPass &&
      artCountValues !== 0 &&
      /* portFolioList.length !== 0 && */
      totalFileList.length === artCountValues &&
      totalAgreement
    ) {
      setIsOn(true);
    } else {
      setIsOn(false);
    }
  }, [
    values,
    isOn,
    artCategoryValues,
    artCountValues,
    fileList1,
    fileList2,
    fileList3,
    fileListDetail1,
    fileListDetail2,
    fileListDetail3,
    totalFileList,
    portFolioList,
    totalAgreement,
    categoryEtcPass,
    deliveryInfo,
    artInfo1,
    artInfo2,
    artInfo3,
  ]);

  //신청완료 modal
  const [isModalOpen, setIsModalOpen] = useState(false);
  const openModal = () => {
    setIsModalOpen(true);
    document.documentElement.style.overflow = 'hidden';
  };
  const closeModal = () => {
    setIsModalOpen(false);
    document.documentElement.style.overflow = 'auto';
    navigate('/2024_youngartist/contest');
  };

  //결제창 팝업 값 get
  const paymentResult = (event: any) => {
    if (event.data === 'success') {
      closePaymentModal();
      openModal();
    }

    if (event.data === 'failure') {
      closePaymentModal();
      alert('결제가 실패되었습니다.');
      return;
    }
    return;
  };

  const checkedApply = () => {
    if (!values) {
      return;
    }

    /*  */
    if (!totalAgreement) {
      agreeRef.current?.scrollIntoView({
        behavior: 'smooth',
      });
      setHelperText((helperText) => ({
        ...helperText,
        totalAgreement: '모든 동의가 필요합니다.',
      }));
    }

    //출품작 파일업로드
    if (totalFileList.length !== artCountValues) {
      /* fileRef.current?.scrollIntoView({
        behavior: 'smooth',
      }); */
      /*  alert('모든 작품 정보는 필수입니다.'); */
      setHelperText((helperText: any) => ({
        ...helperText,
        files: '작품 정보의 필수 항목을 확인해주세요.',
      }));
    }
    ///출품작 정보    // artCountValues에 따라 다르게 처리
    function validateArtInfo(artInfo: any) {
      //작품명
      if (!regex.fileName.test(artInfo.art_name)) {
        return false;
      }
      //사이즈
      if (!regex.nonGap.test(artInfo.art_size)) {
        return false;
      }
      //재료
      if (!regex.nonGap.test(artInfo.art_material)) {
        return false;
      }
      //제작연도
      if (!regex.artCreation.test(artInfo.art_creation)) {
        return false;
      }
      //희망 소비자 판매가
      if (!regex.artAmount.test(artInfo.art_amount)) {
        return false;
      }
      //출품작 설명
      if (!regex.artDescription.test(artInfo.art_description)) {
        /* fileRef.current?.scrollIntoView({
          behavior: 'smooth',
        }); */
        if (artInfo === artInfo1) {
          setHelperText((helperText: any) => ({
            ...helperText,
            artDescription1: '필수 정보입니다.',
          }));
        }
        if (artInfo === artInfo2) {
          setHelperText((helperText: any) => ({
            ...helperText,
            artDescription2: '필수 정보입니다.',
          }));
        }
        if (artInfo === artInfo3) {
          setHelperText((helperText: any) => ({
            ...helperText,
            artDescription3: '필수 정보입니다.',
          }));
        }
        return false;
      }
      //
      //작품디테일컷 fileListDetail;
      if (artInfo === artInfo1) {
        if (fileList1.length === 0) {
          return false;
        }

        /*   if (fileListDetail1.length === 0) {
          return false;
        } */
      }
      if (artInfo === artInfo2) {
        if (fileList1.length === 0) {
          return false;
        }
        if (fileList2.length === 0) {
          return false;
        }
        /*  if (fileListDetail1.length === 0 || fileListDetail2.length === 0) {
          return false;
        } */
      }
      if (artInfo === artInfo3) {
        if (fileList1.length === 0) {
          return false;
        }
        if (fileList2.length === 0) {
          return false;
        }
        if (fileList3.length === 0) {
          return false;
        }

        /* if (
          fileListDetail1.length === 0 ||
          fileListDetail2.length === 0 ||
          fileListDetail3.length === 0
        ) {
          return false;
        } */
      }

      return true;
    }
    if (artCountValues === 1 || artCountValues === 2 || artCountValues === 3) {
      const artInfoArray = [artInfo1, artInfo2, artInfo3].slice(
        0,
        artCountValues
      );

      for (const artInfo of artInfoArray) {
        if (!validateArtInfo(artInfo)) {
          if (artInfo === artInfo1) {
            artInfo1Ref.current?.scrollIntoView({
              behavior: 'smooth',
            });
          }
          if (artInfo === artInfo2) {
            artInfo2Ref.current?.scrollIntoView({
              behavior: 'smooth',
            });
          }
          if (artInfo === artInfo3) {
            artInfo3Ref.current?.scrollIntoView({
              behavior: 'smooth',
            });
          }
          alert('작품 정보의 필수 항목을 확인해주세요.');
          setHelperText((helperText: any) => ({
            ...helperText,
            files: '작품 정보의 필수 항목을 확인해주세요.',
          }));
          return setIsOn(false);
        }
      }
    }

    if (artCountValues === 0) {
      artCountOptionRef.current?.scrollIntoView({
        behavior: 'smooth',
      });
      setHelperText((helperText) => ({
        ...helperText,
        display_art_count: '필수 정보입니다.',
      }));
    }

    if (artCategoryValues === '' || !categoryEtcPass) {
      artCategoryRef.current?.scrollIntoView({
        behavior: 'smooth',
      });
      setHelperText((helperText) => ({
        ...helperText,
        art_category: '1개 분야 이상 필수 선택',
      }));
    }
    if (!categoryEtcPass) {
      artCategoryRef.current?.scrollIntoView({
        behavior: 'smooth',
      });
      setHelperText((helperText) => ({
        ...helperText,
        art_category: '기타 선택시 내용을 입력해주세요.(15자 이내)',
      }));
    }

    /* if (portFolioList.length === 0) {
      portFolioRef.current?.scrollIntoView({
        behavior: 'smooth',
      });
      setHelperText((helperText) => ({
        ...helperText,
        portfolio: '필수 정보입니다.',
      }));
    } */

    if (values.history === '' || !regex.history.test(historyValue)) {
      historyRef.current?.focus();
    }
    if (!deliveryInfo.address || !regex.nonGap.test(addressValue)) {
      addressRef.current?.focus();
      setHelperText((helperText) => ({
        ...helperText,
        address: '주소를 입력해주세요.',
      }));
    }
    if (values.email === '' || !regex.email.test(emailValue)) {
      emailRef.current?.focus();
    }
    if (
      values.phoneEnd === '' ||
      !regex.phoneEnd.test(String(phoneEndRef.current?.value))
    ) {
      phoneEndRef.current?.focus();
    }
    if (
      values.phoneMid === '' ||
      !regex.phoneMid.test(String(phoneMidRef.current?.value))
    ) {
      phoneMidRef.current?.focus();
    }
    if (
      values.phoneStart === '' ||
      !regex.phoneStart.test(String(phoneStartRef.current?.value))
    ) {
      phoneStartRef.current?.focus();
    }
    if (values.birth === '' || !regex.youngartistbirth.test(birthValue)) {
      birthRef.current?.focus();
    }
    if (values.name === '' || !regex.name.test(nameValue)) {
      nameRef.current?.focus();
    }
  };

  const [registrationNumber, setRegistrationNumber] = useState<Number>(0);
  const formDoSubmit = async () => {
    try {
      const body = {
        writer_name: values?.name,
        writer_birthday: values?.birth,
        writer_phone: phoneTotal,
        writer_email: values?.email,
        writer_address: deliveryInfo?.address,
        writer_detail_address: deliveryInfo?.subAddress
          ? deliveryInfo?.subAddress
          : '　',
        writer_zipcode: deliveryInfo?.postNumber,

        //상세주소, 우편번호도 넣기
        writer_history: values?.history,
        art_category: artCategoryValues,
        /* display_art_count: artCountValues, */
        device_type: isMobileOrWeb,
        os: isAosOrIos,
        /* images: totalSumFileList, */
        portfolios: portFolioList,
        art_info: totalArtInfo,
      };

      setLoading(true);

      const data = await doSubmitArtistApply(body);

      if (data.code === 200) {
        setLoading(false);
        setRegistrationNumber(data.data.registration_number);
        openMobileModal();
      } else {
        alert(data?.message);
        setLoading(false);
      }
    } catch (err) {
      alert('오류로 인해 실패했습니다.');
      setLoading(false);
    } finally {
      setLoading(false);
    }
  };

  //submit>출품작수량체크 modal
  const [isArtCountModal, setIsArtCountModal] = useState(false);
  const artCountModalCloseModal = () => {
    setIsArtCountModal(false);
    document.documentElement.style.overflow = 'auto';
  };

  //mobile 이노페이 결제창 modal
  const [isMobileModalOpen, setIsMobileModalOpen] = useState(false);
  const openMobileModal = () => {
    setIsMobileModalOpen(true);
    document.documentElement.style.overflow = 'hidden';
  };
  const closePaymentModal = () => {
    setIsMobileModalOpen(false);
    setLoading(false);
    document.documentElement.style.overflow = 'auto';
  };

  const artCountModalOpen = () => {
    if (isOn) {
      /*  if (artCountValues && artCountValues > fileList.length) {
        setIsArtCountModal(true);
        document.documentElement.style.overflow = 'hidden';
      } else { */

      formDoSubmit();

      (window as any).gaWrite(
        'web_click_youngartist_payment',
        '2024_youngartist/apply',
        ''
      );
      // }
    } else {
      checkedApply();
    }
  };

  const successArtCountModalOpen = () => {
    artCountModalCloseModal();
    if (isOn) {
      formDoSubmit();
    } else {
      checkedApply();
    }
  };

  useEffect(() => {
    if (isMobile) {
      setIsMobileOrWeb('M');
    } else if (isBrowser) {
      setIsMobileOrWeb('P');
      setIsAosOrIos('WEB');
    }

    if (isAndroid) {
      setIsAosOrIos('AOS');
    } else if (isIOS) {
      setIsAosOrIos('IOS');
    }
  }, [isMobileOrWeb, isAosOrIos]);

  ///결제
  let isFrom = true;
  const [routerModel, setRouterModel] = useRecoilState(router);
  useEffect(() => {
    /*  closePaymentModal(); */

    window.addEventListener('message', (e) => {
      if (e.data === 'success') {
        closePaymentModal();
        openModal();
      }

      if (e.data === 'failure') {
        closePaymentModal();
        alert('결제가 실패되었습니다.');
      }
      setLoading(false);
    });
  }, []);

  useEffect(() => {
    smoothscroll.polyfill();
  }, []);

  return (
    <div
      className={
        isPageLoading
          ? 'artistApplyWrap Gap youngartistApplyWrap'
          : isBetweenDateHidden
          ? 'artistApplyWrap Hidden youngartistApplyWrap'
          : 'artistApplyWrap View youngartistApplyWrap'
      }
    >
      <div className='artistApplyInner'>
        <form className='artistApplyContentsWrap applyForm'>
          <p className='gmarketSans artistAppyTitle'>
            2024 시크릿타운 YOUNG ARTIST 미술상 공모전
          </p>

          <div className='artistApplyContents'>
            <div className='artistApplyItemWrap'>
              <p className='artistApplyComment'>
                <span>*</span>가 표시된 항목은 필수 입력 항목입니다.
              </p>
              <p className='artistApplyTitle'>1. 인적사항</p>
              <InputOnchangeRegex
                title='작가명'
                type='text'
                name='name'
                value={values?.name}
                placeholder='단체의 경우 ‘단체명(대표 작가명)’으로 기입'
                onChange={handleChangeTarget}
                labelClassName='label'
                className='input'
                regexCheck={regex.name}
                successText={''}
                errorText={'문자 밖의 공백을 제외하여 입력해주세요.'}
                helperTextClassName='helperText'
                required={true}
                inputTitle='inputTitle'
                ref={nameRef}
                maxValue={100}
              />
              <InputOnchangeRegex
                title='생년월일'
                type='text'
                name='birth'
                value={values?.birth}
                placeholder='숫자 8자리 입력(예 : 20011223)'
                onChange={handleChangeTarget}
                labelClassName='label'
                className='input'
                maxValue={8}
                regexCheck={regex.youngartistbirth}
                successText={''}
                errorText={'숫자 8자리 입력(1990~2005년생만 가능)'}
                helperTextClassName='helperText'
                required={true}
                inputTitle='inputTitle'
                ref={birthRef}
              />
              <div className='inputPhoneWrap'>
                <p className='inputTitle'>핸드폰 번호</p>
                <div className='phoneComponentWrap'>
                  <input
                    type='text'
                    name='phoneStart'
                    value={values?.phoneStart || ''}
                    placeholder='숫자만 입력'
                    onChange={(e) => {
                      e.target.value = e.target.value.slice(0, 3);
                      handleChangeTarget(e, e.target.name, 3);
                    }}
                    maxLength={3}
                    ref={phoneStartRef}
                    className='input phoneInput'
                    onFocus={(e) => handleOnfocus(e, e.target.name)}
                  />
                  <p className='hyphen'>-</p>
                  <input
                    type='text'
                    name='phoneMid'
                    value={values?.phoneMid || ''}
                    placeholder='숫자만 입력'
                    onChange={(e) => {
                      e.target.value = e.target.value.slice(0, 4);
                      handleChangeTarget(e, e.target.name, 4);
                    }}
                    maxLength={4}
                    ref={phoneMidRef}
                    className='input phoneInput'
                    onFocus={(e) => handleOnfocus(e, e.target.name)}
                  />
                  <p className='hyphen'>-</p>
                  <input
                    type='text'
                    name='phoneEnd'
                    value={values?.phoneEnd || ''}
                    placeholder='숫자만 입력'
                    onChange={(e) => {
                      e.target.value = e.target.value.slice(0, 4);
                      handleChangeTarget(e, e.target.name, 4);
                    }}
                    maxLength={4}
                    ref={phoneEndRef}
                    className='input phoneInput'
                    onFocus={(e) => handleOnfocus(e, e.target.name)}
                  />
                </div>
                {helperText.phone && (
                  <p className='helperText phone'>{helperText.phone}</p>
                )}
              </div>

              <InputOnchangeRegex
                title='이메일'
                type='text'
                name='email'
                value={values?.email}
                placeholder='접수번호 수신용 이메일'
                onChange={handleChangeTarget}
                labelClassName='label'
                className='input'
                regexCheck={regex.email}
                successText={''}
                errorText={'이메일 형식이 올바르지 않습니다.'}
                helperTextClassName='helperText'
                required={true}
                inputTitle='inputTitle'
                ref={emailRef}
                maxValue={50}
              />

              <div className='addressBox' /* ref={artCountOptionRef} */>
                <p className='inputTitle'>주소</p>
                <div className='orderSheet-info-box-content'>
                  <div className='orderSheet-info-box-content-inner'>
                    {
                      <p className='address-btn' onClick={handleClickAddress}>
                        {t('ordertitle7')}
                      </p>
                    }
                    <div className='orderSheet-info-box-content-inner-item'>
                      <span className='orderSheet-info-box-content-title keep-all'>
                        {t('userInfo8')}
                      </span>
                      {
                        <input
                          className='input orderSheet-info-box-content-input'
                          id='postNumberYoungartist'
                          type='text'
                          name='postNumberYoungartist'
                          readOnly={true}
                          maxLength={5}
                          onChange={(e) =>
                            setDeliveryInfo((p) => ({
                              ...p,
                              postNumber: e.target.value,
                            }))
                          }
                        />
                      }
                    </div>

                    <div className='orderSheet-info-box-content-inner-item'>
                      <span className='orderSheet-info-box-content-title keep-all'>
                        {t('userInfo6')}
                      </span>
                      {
                        <input
                          className='input orderSheet-info-box-content-input'
                          id='addressYoungartist'
                          type='text'
                          name='addressYoungartist'
                          readOnly={true}
                          onChange={(e) =>
                            setDeliveryInfo((p) => ({
                              ...p,
                              address: e.target.value,
                            }))
                          }
                          ref={addressRef}
                        />
                      }
                    </div>
                    <div className='orderSheet-info-box-content-inner-item'>
                      <span className='orderSheet-info-box-content-title keep-all sub-address'>
                        {t('userInfo7')}
                      </span>
                      <div className='input-wrap'>
                        <label className='label orderSheet-info-box-content-input'>
                          {
                            <input
                              className='input orderSheet-info-box-content-input'
                              type='text'
                              name='addressDetail'
                              maxLength={50}
                              onChange={(e) => {
                                e.target.value = e.target.value.slice(0, 50);
                                setDeliveryInfo((p) => ({
                                  ...p,
                                  subAddress: e.target.value,
                                }));
                              }}
                            />
                          }
                        </label>

                        {<p className='helperText'>{helperText.address}</p>}
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <label className='labelBox'>
                <div className='inputTitleWrap'>
                  <p className='inputTitle'>작가 경력&#40;CV&#41;</p>
                  <span className='artAmountText'>
                    <span
                      className={
                        String(values?.history).length >= 1500
                          ? 'txt-count active'
                          : 'txt-count'
                      }
                    >
                      {String(values?.history).length > 0
                        ? String(values?.history)?.replace(/\r\n/g, ' ').length
                        : 0}
                    </span>
                    자 / 1500자
                  </span>
                </div>
                <TextArea
                  value={values?.history}
                  onChange={(e) => handleChangeTarget(e, e.target.name)}
                  name='history'
                  placeholder='학력, 경력, 수상, 개인전, 단체전 등 주요 전시만 요약(1500자 이내)'
                  className='textarea'
                  maxLength={1500}
                  onFocus={(e) => handleOnfocus(e, e.target.name)}
                  ref={historyRef}
                />
                {helperText.history && (
                  <p className='helperText'>{helperText.history}</p>
                )}
              </label>

              <div className='width100' ref={portFolioRef}>
                <ArtistApplyFilePortfolio
                  fileList={portFolioList}
                  setFileList={setPortFolioList}
                  initialTotalFilesSize={0}
                />
                {helperText.portfolio && (
                  <p className='helperText'>{helperText.portfolio}</p>
                )}
              </div>
            </div>

            <div className='artistApplyItemWrap'>
              <p className='artistApplyTitle'>2. 출품작 소개</p>
              <div className='width100'>
                <div ref={artCategoryRef}>
                  <p className='inputTitle'>작품 분야(중복 선택 가능)</p>
                  <Radio
                    optionsLists={artOptionsLists}
                    values={artCategoryValues}
                    setValues={setArtCategoryValues}
                    categoryEtcPass={categoryEtcPass}
                    setCategoryEtcPass={setCategoryEtcPass}
                    helperText={helperText}
                    setHelperText={setHelperText}
                    originalArray={originalCategoryArray}
                    helperTextKey='art_category'
                  />
                  {helperText.art_category && (
                    <p className='helperText'>{helperText.art_category}</p>
                  )}
                </div>

                <div ref={artCountOptionRef}>
                  <p className='inputTitle'>출품작 수량</p>
                  <RadioClassic
                    optionsLists={artCountOptionsLists}
                    values={artCountValues}
                    setValues={setArtCountValues}
                    helperText={helperText}
                    setHelperText={setHelperText}
                  />
                  {helperText.display_art_count && (
                    <p className='helperText'>{helperText.display_art_count}</p>
                  )}
                </div>

                {/* 출품작 파일업로드 */}
                <div className='width100' ref={fileRef}>
                  <div className='youngArtistApplyFileDescription artistApplyFileDescription'>
                    <p className='inputTitle'>출품작 파일 업로드</p>
                    <p className='artistApplyTxt'>
                      1.{' '}
                      <span className='strong'>
                        jpeg, jpg, png 또는 mp4, mkv, webm, mov, avi(영상 작품)
                        형식 파일 중
                      </span>{' '}
                      자유 업로드 (단, 입체 작품은 정면 및 측면 디테일 컷 필수)
                    </p>
                    <p className='artistApplyTxt'>
                      2. 파일명 : 작가명_작품제목_사이즈(세로x가로cm, 조각은
                      높이까지)_재료_제작년도
                    </p>
                    <p className='artistApplyTxt'>
                      3. 파일용량 : 각 100MB 이내, 총 1GB 이내
                    </p>
                    {helperText.files && (
                      <p className='helperText'>{helperText.files}</p>
                    )}
                    {/* 반복 */}
                    {[...Array(Math.max(1, artCountValues))].map((item, i) => (
                      <div
                        className='subLabelTotalBox'
                        key={'youngArtistArtCount' + i}
                        ref={
                          i + 1 === 1
                            ? artInfo1Ref
                            : i + 1 === 2
                            ? artInfo2Ref
                            : artInfo3Ref
                        }
                      >
                        <p className='subLabelLine'>{i + 1}번 작품 정보 입력</p>
                        {/* 작품정보 */}
                        <div className='subLabelTotalInner'>
                          <div className='subLabelBox'>
                            <p className='inputTitle'>1. 작품명</p>
                            <InputOnchangeRegex
                              /*     title='1. 작품명' */
                              type='text'
                              name={`artName${i + 1}`}
                              value={
                                i + 1 === 1
                                  ? artInfo1?.art_name
                                  : i + 1 === 2
                                  ? artInfo2?.art_name
                                  : artInfo3?.art_name
                              }
                              placeholder=''
                              onChange={(e) => {
                                handleChangeTarget(e, e.target.name);
                                {
                                  if (i + 1 === 1) {
                                    setArtInfo1((p) => ({
                                      ...p,
                                      art_name: e.target.value,
                                    }));
                                  } else if (i + 1 === 2) {
                                    setArtInfo2((p) => ({
                                      ...p,
                                      art_name: e.target.value,
                                    }));
                                  } else {
                                    setArtInfo3((p) => ({
                                      ...p,
                                      art_name: e.target.value,
                                    }));
                                  }
                                }
                              }}
                              labelClassName='label subLabel'
                              className='input'
                              regexCheck={regex.fileName}
                              successText={''}
                              errorText={
                                '문자 밖의 공백을 제외하여 입력해주세요.'
                              }
                              helperTextClassName='helperText'
                              required={true}
                              inputTitle='inputTitle'
                              /*   ref={birthRef} */
                              maxValue={60}
                            />
                          </div>

                          <div className='subLabelBox'>
                            <p className='inputTitle'>2. 사이즈(세로X가로cm)</p>
                            {/* 사이즈 */}
                            <InputOnchangeRegex
                              /* title='2. 사이즈(세로X가로cm)' */
                              type='text'
                              name={`artSize${i + 1}`}
                              value={
                                i + 1 === 1
                                  ? artInfo1?.art_size
                                  : i + 1 === 2
                                  ? artInfo2?.art_size
                                  : artInfo3?.art_size
                              }
                              placeholder='조각은 높이까지 기재'
                              onChange={(e) => {
                                handleChangeTarget(e, e.target.name);
                                {
                                  if (i + 1 === 1) {
                                    setArtInfo1((p) => ({
                                      ...p,
                                      art_size: e.target.value,
                                    }));
                                  } else if (i + 1 === 2) {
                                    setArtInfo2((p) => ({
                                      ...p,
                                      art_size: e.target.value,
                                    }));
                                  } else {
                                    setArtInfo3((p) => ({
                                      ...p,
                                      art_size: e.target.value,
                                    }));
                                  }
                                }
                              }}
                              labelClassName='label subLabel'
                              className='input'
                              regexCheck={regex.nonGap}
                              successText={''}
                              errorText={'필수 정보입니다.'}
                              helperTextClassName='helperText'
                              required={true}
                              inputTitle='inputTitle'
                              /*   ref={birthRef} */
                              maxValue={40}
                            />
                          </div>

                          <div className='subLabelBox'>
                            <p className='inputTitle'>3. 재료</p>
                            {/* 재료 */}
                            <InputOnchangeRegex
                              /* title='3. 재료' */
                              type='text'
                              name={`artMaterial${i + 1}`}
                              value={
                                i + 1 === 1
                                  ? artInfo1?.art_material
                                  : i + 1 === 2
                                  ? artInfo2?.art_material
                                  : artInfo3?.art_material
                              }
                              placeholder=''
                              onChange={(e) => {
                                handleChangeTarget(e, e.target.name);
                                {
                                  if (i + 1 === 1) {
                                    setArtInfo1((p) => ({
                                      ...p,
                                      art_material: e.target.value,
                                    }));
                                  } else if (i + 1 === 2) {
                                    setArtInfo2((p) => ({
                                      ...p,
                                      art_material: e.target.value,
                                    }));
                                  } else {
                                    setArtInfo3((p) => ({
                                      ...p,
                                      art_material: e.target.value,
                                    }));
                                  }
                                }
                              }}
                              labelClassName='label subLabel'
                              className='input'
                              regexCheck={regex.nonGap}
                              successText={''}
                              errorText={'필수 정보입니다.'}
                              helperTextClassName='helperText'
                              required={true}
                              inputTitle='inputTitle'
                              /*   ref={birthRef} */
                              maxValue={60}
                            />
                          </div>

                          <div className='subLabelBox subLabelUnitBox'>
                            <p className='inputTitle'>4. 제작연도</p>
                            {/* 제작연도 */}
                            <div className='subLabelUnitBox'>
                              <InputOnchangeRegex
                                /*     title='4. 제작연도' */
                                type='text'
                                name={`artCreation${i + 1}`}
                                value={
                                  i + 1 === 1
                                    ? artInfo1?.art_creation
                                    : i + 1 === 2
                                    ? artInfo2?.art_creation
                                    : artInfo3?.art_creation
                                }
                                placeholder=''
                                onChange={(e) => {
                                  handleChangeTarget(e, e.target.name);
                                  {
                                    if (i + 1 === 1) {
                                      setArtInfo1((p) => ({
                                        ...p,
                                        art_creation: e.target.value,
                                      }));
                                    } else if (i + 1 === 2) {
                                      setArtInfo2((p) => ({
                                        ...p,
                                        art_creation: e.target.value,
                                      }));
                                    } else {
                                      setArtInfo3((p) => ({
                                        ...p,
                                        art_creation: e.target.value,
                                      }));
                                    }
                                  }
                                }}
                                labelClassName='label subLabel'
                                className='input'
                                regexCheck={regex.artCreation}
                                successText={''}
                                errorText={'제작연도 4자리를 입력하세요.'}
                                helperTextClassName='helperText'
                                required={true}
                                inputTitle='inputTitle'
                                maxValue={4}
                                /*   ref={birthRef} */
                              />
                              <p className='unit'>년</p>
                            </div>
                          </div>

                          <div className='subLabelBox subLabelUnitBox'>
                            <p className='inputTitle'>5. 희망 소비자 판매가</p>
                            {/* 희망 소비자 판매가 */}
                            <div className='subLabelUnitBox'>
                              <InputOnchangeRegex
                                /* title='5. 희망 소비자 판매가' */
                                type='text'
                                name={`artAmount${i + 1}`}
                                value={
                                  i + 1 === 1
                                    ? artInfo1?.art_amount
                                    : i + 1 === 2
                                    ? artInfo2?.art_amount
                                    : artInfo3?.art_amount
                                }
                                placeholder='갤러리와 작가 5:5 비율 감안하여 작성'
                                onChange={(e) => {
                                  handleChangeTarget(e, e.target.name);
                                  {
                                    if (i + 1 === 1) {
                                      setArtInfo1((p) => ({
                                        ...p,
                                        art_amount: e.target.value,
                                      }));
                                    } else if (i + 1 === 2) {
                                      setArtInfo2((p) => ({
                                        ...p,
                                        art_amount: e.target.value,
                                      }));
                                    } else {
                                      setArtInfo3((p) => ({
                                        ...p,
                                        art_amount: e.target.value,
                                      }));
                                    }
                                  }
                                }}
                                labelClassName='label subLabel'
                                className='input'
                                regexCheck={regex.artAmount}
                                successText={''}
                                errorText={'숫자로만 정확히 입력해주세요.'}
                                helperTextClassName='helperText'
                                required={true}
                                inputTitle='inputTitle'
                                /*   ref={birthRef} */
                                maxValue={13}
                              />
                              <p className='unit'>원</p>
                            </div>
                          </div>

                          {/* 출품작 설명 */}
                          <div className='subLabelBox'>
                            <label className='labelBox subLabel'>
                              <div className='inputTitleWrap'>
                                <p className='inputTitle description'>
                                  6. 출품작 설명
                                </p>
                                <span className='artAmountText'>
                                  <span
                                    className={
                                      (
                                        i + 1 === 1
                                          ? String(artInfo1?.art_description)
                                              .length >= 200
                                          : i + 1 === 2
                                          ? String(artInfo2?.art_description)
                                              .length >= 200
                                          : String(artInfo3?.art_description)
                                              .length >= 200
                                      )
                                        ? 'txt-count active'
                                        : 'txt-count'
                                    }
                                  >
                                    {i + 1 === 1
                                      ? String(artInfo1?.art_description).length
                                      : i + 1 === 2
                                      ? String(artInfo2?.art_description).length
                                      : String(artInfo3?.art_description)
                                          .length}
                                  </span>
                                  자 / 200자
                                </span>
                              </div>
                              <TextArea
                                value={
                                  i + 1 === 1
                                    ? artInfo1?.art_description
                                    : i + 1 === 2
                                    ? artInfo2?.art_description
                                    : artInfo3?.art_description
                                }
                                onChange={(e) => {
                                  handleChangeTarget(e, e.target.name);
                                  {
                                    if (i + 1 === 1) {
                                      setArtInfo1((p) => ({
                                        ...p,
                                        art_description: e.target.value,
                                      }));
                                    } else if (i + 1 === 2) {
                                      setArtInfo2((p) => ({
                                        ...p,
                                        art_description: e.target.value,
                                      }));
                                    } else {
                                      setArtInfo3((p) => ({
                                        ...p,
                                        art_description: e.target.value,
                                      }));
                                    }
                                  }
                                }}
                                name={`artDescription${i + 1}`}
                                placeholder='200자 이내'
                                className='textarea'
                                maxLength={200}
                                onFocus={(e) => handleOnfocus(e, e.target.name)}
                              />
                              {i + 1 === 1
                                ? helperText.artDescription1 && (
                                    <p className='helperText'>
                                      {helperText.artDescription1}
                                    </p>
                                  )
                                : i + 1 === 2
                                ? helperText.artDescription2 && (
                                    <p className='helperText'>
                                      {helperText.artDescription2}
                                    </p>
                                  )
                                : helperText.artDescription3 && (
                                    <p className='helperText'>
                                      {helperText.artDescription3}
                                    </p>
                                  )}
                            </label>
                          </div>
                        </div>
                        {/* 대표이미지 */}
                        <div className='subImgWrap padding-top'>
                          <p className='subImgTitle'>대표 이미지</p>
                          <ArtistApplyTotalFile
                            fileList={
                              i + 1 === 1
                                ? fileList1
                                : i + 1 === 2
                                ? fileList2
                                : fileList3
                            }
                            setFileList={
                              i + 1 === 1
                                ? setFileList1
                                : i + 1 === 2
                                ? setFileList2
                                : setFileList3
                            }
                            artCountValues={artCountValues}
                            helperText={helperText}
                            setHelperText={setHelperText}
                            initialTotalFilesSize={0}
                            maxFileCount={1} //파일갯수제한
                            totalFileList={totalSumFileList}
                          />
                          {/*  {i + 1 === 1
                            ? helperText.images1 && (
                                <p className='helperText'>
                                  {helperText.images1}
                                </p>
                              )
                            : i + 1 === 2
                            ? helperText.images2 && (
                                <p className='helperText'>
                                  {helperText.images2}
                                </p>
                              )
                            : helperText.images3 && (
                                <p className='helperText'>
                                  {helperText.images3}
                                </p>
                              )} */}
                        </div>
                        {/* 디테일컷 이미지 */}
                        <div className='subImgWrap'>
                          <p className='subImgTitle option'>
                            입체 작품 디테일컷
                          </p>
                          <ArtistApplyTotalFile
                            fileList={
                              i + 1 === 1
                                ? fileListDetail1
                                : i + 1 === 2
                                ? fileListDetail2
                                : fileListDetail3
                            }
                            setFileList={
                              i + 1 === 1
                                ? setFileListDetail1
                                : i + 1 === 2
                                ? setFileListDetail2
                                : setFileListDetail3
                            }
                            artCountValues={artCountValues}
                            helperText={helperText}
                            setHelperText={setHelperText}
                            initialTotalFilesSize={0}
                            totalFileList={totalSumFileList}
                          />
                        </div>
                      </div>
                    ))}
                  </div>
                </div>
              </div>
            </div>

            <div className='artistApplyItemWrap' ref={agreeRef}>
              <p className='artistApplyTitle'>
                3. 유의사항 및 저작권 관련 사항
              </p>
              {helperText.totalAgreement && (
                <p className='helperText'>{helperText.totalAgreement}</p>
              )}
              <ArtistApplyRadioAgreement
                values={totalAgreement}
                setValues={setTotalAgreement}
                helperText={helperText}
                setHelperText={setHelperText}
              />
            </div>

            <div
              className={/* isOn === false ? 'submitBtn Err' :  */ 'submitBtn'}
            >
              <ClickBtn
                text='공모전 신청완료/결제하기'
                className='applySubmitBtn'
                /*   disabled={!isOn} */
                onClick={artCountModalOpen}
              />
            </div>
          </div>
        </form>

        <CustomModalBottomBtn
          className='artistApplySuccessModalWrap'
          open={isModalOpen}
          onClose={closeModal}
        >
          <Box>
            <div
              style={{
                background: `#fff `,
              }}
              className='artistApplySuccessModalCotents'
            >
              <div className='artistApplySuccessModalCotentsText'>
                <p className='artistApplySuccessModalTitle'>
                  2024 시크릿타운
                  <span>YOUNG ARTIST 미술상 공모전</span>
                </p>

                <p>신청이 완료되었습니다.</p>
                {registrationNumber !== 0 ? (
                  <p>접수번호 : {String(registrationNumber)}</p>
                ) : null}
              </div>

              <p className='artistApplySuccessModalBottomContents'>
                팝업을 닫으면 현재 페이지가 종료됩니다
              </p>
            </div>
          </Box>
        </CustomModalBottomBtn>

        <CustomModalBottomTwoBtn
          className='artistApplySuccessModalWrap  customModalBottomTwoBtnWrap customModalBottomTwoBtnWrapAcount'
          open={isArtCountModal}
          onClose={artCountModalCloseModal}
        >
          <Box>
            <div
              style={{
                background: `#fff `,
              }}
              className='artistApplySuccessModalCotents'
            >
              <div className='artistApplySuccessModalCotentsText'>
                <div className='artistApplySuccessModalTexts'>
                  {/* <p>출품작 수량에 비해 업로드한 출품작 파일 수가 적습니다.</p> */}
                  <p>
                    수정을 원하시면 '닫기'버튼을, 계속 진행하시려면 '확인'버튼을
                    눌러주세요.
                  </p>
                </div>
              </div>
            </div>
          </Box>
          <div className='modalTwoCloseBtnWrap'>
            <button
              className='modalCloseBtn  modalCloseBtnClassic'
              onClick={artCountModalCloseModal}
            >
              <p>닫기</p>
            </button>
            <button
              className='modalCloseBtn modalCloseBtnClassic'
              onClick={successArtCountModalOpen}
            >
              <p>확인</p>
            </button>
          </div>
        </CustomModalBottomTwoBtn>
        {/* mobile 이노페이 modal */}
        {registrationNumber !== 0 && (
          <CustomModal
            className='artistPaymentModalWrap'
            open={isMobileModalOpen}
            onClose={closePaymentModal}
          >
            <iframe
              src={`${url}/v1/payment/art/secrettown/young_artist?registration_number=${registrationNumber}&market_type=secrettownYoungArtist&main_image_count=${artCountValues}`}
              style={{
                width: '100%',
                height: '100%',
              }}
            ></iframe>
          </CustomModal>
        )}

        <div className='artistContestWrapHiddenBox'>
          <ClosedBoxDetailDate />
        </div>
        {loading && <Loading Loading={loading} />}
      </div>
    </div>
  );
}

import { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import { Outlet } from 'react-router';
import { useTranslation } from 'react-i18next';
import dayjs from 'dayjs';
import isBetween from 'dayjs/plugin/isBetween';
import { useRecoilState } from 'recoil';
import { isMobile } from 'react-device-detect';
import Header from '@components/layouts/Header';
import Footer from '@components/layouts/Footer';
import FloatingMain from '@components/floating/FloatingMain';
import MenuTrigger from '@components/mobileMenu/MenuTrigger';
import MobileMenu from '@components/mobileMenu/MobileMenu';
import FloatingCircle from '@components/floating/FloatingCircle';
import { mobileMenuTrigger } from '@stores/mobileMenuTrigger';
import { guestId } from '@stores/guest';
import { artLandLayout } from '@stores/artLand';
import { getVersion } from '@services/VersionService';
import { getYoungartistDate } from '@services/YoungartistService';
import meta from '@assets/images/floating/meta.png';
import youtube from '@assets/images/floating/youtube.png';
import top from '@assets/images/floating/top.png';
import floatingBtn from '@assets/images/floating/floating_btn_apply.png';
import './layoutDefault.scss';

export default function LayoutDefault() {
  const [artLandLayoutState, setArtLandLayoutState] =
    useRecoilState(artLandLayout);

  const { t } = useTranslation();

  const [guestIdState, setGuestIdState] = useRecoilState(guestId);
  const metaverse_art_land_url = process.env.REACT_APP_METAVERSE_ARTLAND_URL;
  const location = useLocation();

  const handleClickLink = (link: string, type: string) => {
    if (link === metaverse_art_land_url) {
      /*  if (isMobile) {
        window.open(`${metaverse_art_land_url}/mo`);
      } else {
        window.open(`${metaverse_art_land_url}/pc`);
      } */
      window.open(`${metaverse_art_land_url}`);
    } else if (link) {
      window.open(link);
    }

    if (type === 'art_land') {
      (window as any).gaWrite(
        'web_click_fixed_floating_btn_metaverse_art_land',
        'home',
        ''
      );
    } else {
      (window as any).gaWrite(
        'web_click_fixed_floating_btn_galleryst_youtube',
        'home',
        ''
      );
    }
  };

  const handleClickTop = () => {
    window.scrollTo({
      top: 0,
      behavior: 'smooth',
    });

    (window as any).gaWrite('web_click_fixed_floating_btn_top', 'home', '');
  };

  const [isOn, setIsOn] = useRecoilState(mobileMenuTrigger);

  const [versionData, setVersionData] = useState({
    server_type: '',
    api_version: '',
    is_display: '',
  });

  const getVersionData = async () => {
    try {
      const data = await getVersion(guestIdState);
      setVersionData(data.data.data);
    } catch (err) {
      console.log(err);
    }
  };

  //youngartist 플로팅버튼
  const [isBetweenDate, setIsBetweenDate] = useState(false);
  const currentDate = dayjs();
  dayjs.extend(isBetween);
  const getDate = async () => {
    let startDate;
    let endDate;
    try {
      const data = await getYoungartistDate();
      startDate = data.data.contest_start_date;
      endDate = data.data.contest_end_date;
    } catch (err) {
      console.log(err);
    }
    const youngartistStartDate = dayjs(startDate);
    const youngartistEndDate = dayjs(endDate);
    if (
      dayjs().isBetween(
        youngartistStartDate,
        youngartistEndDate,
        'minute',
        '[]'
      )
    ) {
      setIsBetweenDate(true);
    } else {
      setIsBetweenDate(false);
    }
  };

  const handleClickFloating = () => {
    (window as any).gaWrite(
      'web_click_fixed_floating_btn_youngartist',
      'home',
      ''
    );

    getDate();
    if (isBetweenDate) {
      window.open('/2024_youngartist/contest');
    } else {
      alert('신청 마감되었습니다.');
    }
  };

  useEffect(() => {
    getVersionData();

    getDate(); //공모요강 날짜get
  }, []);

  useEffect(() => {
    document.documentElement.style.overflow = 'auto';
  }, [location]);

  return (
    <>
      {versionData?.is_display === 'Y' ? (
        <div className='version-wrap'>
          <span>개발환경: {versionData.server_type}</span> &#47;
          <span> 버전: {versionData.api_version}</span>
        </div>
      ) : null}
      {!artLandLayoutState && <Header />}

      {/*  */}
      {/* Mobile Menu */}
      {!artLandLayoutState && <MenuTrigger />}

      <MobileMenu />

      <div className='layout-default-contents-wrap'>
        <Outlet />
      </div>
      {!artLandLayoutState && <Footer />}
      {!artLandLayoutState && (
        <div
          className={isOn ? 'main-floating-wrap hidden' : 'main-floating-wrap'}
        >
          {/* <FloatingCircle
            img={floatingBtn}
            alt='2024youngartist'
            className='floating'
            onClick={handleClickFloating}
          /> */}

          <FloatingMain
            className='main-floating-btn meta one'
            img={meta}
            text={t('floatingMeta')}
            text2={t('floatingMeta2')}
            alt={t('floatingMeta')}
            onClick={() =>
              handleClickLink(`${metaverse_art_land_url}`, 'art_land')
            }
          />
          <FloatingMain
            className='main-floating-btn meta two'
            img={youtube}
            text={t('floatingYoutube')}
            text2={t('floatingYoutube2')}
            alt={t('floatingYoutube')}
            onClick={() =>
              handleClickLink(
                'https://www.youtube.com/channel/UCBnLwNguF8VHIcXPmNByTDw',
                'youtube'
              )
            }
          />
          <FloatingMain
            className='main-floating-btn meta three'
            img={top}
            text={t('floatingTop')}
            alt={t('floatingTop')}
            onClick={handleClickTop}
          />
        </div>
      )}
    </>
  );
}

import './BaseTable.scss';
import changeDate from 'helpers/changeDate';
import { useNavigate } from 'react-router';
import { TableInterface } from '@interfaces/components/BaseTableInterface';
import GuideIcon from '@assets/images/guideIcon.png';
export default function BaseTable({
  header,
  list,
  link,
  count,
  currentPage,
}: TableInterface.BaseTableInterfase) {
  const navigate = useNavigate();

  const listNumber = Math.max(0, count - (currentPage - 1) * 10);
  const arrListNumber: number[] = new Array(listNumber)
    .fill(0)
    .map((_, i) => i + 1);

  return (
    <div className='base-table-wrap'>
      <div className='base-table-inner'>
        <div className='table-box maruBuri'>
          <table>
            <thead>
              <tr>
                {header.map((th, idx) => (
                  <th key={idx}>{th}</th>
                ))}
              </tr>
            </thead>
            {list?.length > 0 ? (
              <tbody className='table-list'>
                {list?.map((list, idx) => (
                  <tr key={idx} onClick={() => navigate(link + list.id)}>
                    <td>{arrListNumber[arrListNumber.length - idx - 1]}</td>
                    <td>{list.title}</td>
                    <td>{changeDate(list.created_at)}</td>
                  </tr>
                ))}
              </tbody>
            ) : (
              <tbody className='data-non'>
                <tr>
                  <td colSpan={3}>
                    <div className='data-inner'>
                      <img src={GuideIcon} alt='느낌표 아이콘' />
                      <p>데이터가 없습니다.</p>
                    </div>
                  </td>
                </tr>
              </tbody>
            )}
          </table>
        </div>
      </div>
    </div>
  );
}

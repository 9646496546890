import { httpCommunicator } from '@api/httpCommunicator';
const url = process.env.REACT_APP_API_URL;

export async function getMainPopUp(guestSessionId?: number) {
  const header = { guest_session_id: guestSessionId };
  const res = await httpCommunicator(
    `${url}/v1/op/popup/list`,
    'get',
    {},
    header
  );
  return res.data;
}

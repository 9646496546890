import "./metaverseExhibition.scss";
import { Trans, useTranslation } from "react-i18next";
import EmailIcon from "@assets/images/service/emailIcon.png";
import PhoneIcon from "@assets/images/service/phoneIcon.png";
import Mataverse01 from "@assets/images/service/metaverse_img_01.png";
import Mataverse02 from "@assets/images/service/metaverse_img_02.png";
import Mataverse03 from "@assets/images/service/metaverse_img_03.png";
import Mataverse04 from "@assets/images/service/metaverse_img_04.png";
import Mataverse05 from "@assets/images/service/metaverse_img_05.png";
import Mataverse06 from "@assets/images/service/metaverse_img_06.png";
import Mataverse07 from "@assets/images/service/metaverse_img_07.png";

export default function MetaverseExhibition() {
  const { t } = useTranslation();
  const descriptionList = [
    {
      title: "artist",
      description: [
        "serviceMetaverseDescriptionText1",
        "serviceMetaverseDescriptionText2",
        "serviceMetaverseDescriptionText3",
      ],
    },
    {
      title: "collector",
      description: [
        "serviceMetaverseDescriptionText4",
        "serviceMetaverseDescriptionText5",
        "serviceMetaverseDescriptionText6",
      ],
    },
    {
      title: "gallery",
      description: [
        "serviceMetaverseDescriptionText7",
        "serviceMetaverseDescriptionText8",
        "serviceMetaverseDescriptionText9",
      ],
    },
  ];
  return (
    <div className="sub-page-contents-wrap service-metaverseExhibition-wrap">
      <div className="sub-page-contents-inner">
        <div className="metaverseExhibition-top-wrap">
          <div className="metaverse-title-box">
            <h2 className="pretendard">
              metaverse <span>gallery</span>
            </h2>
            <p className="metaverse-subTitle maruBuri">
              {t("serviceMetaverseGallerySubTitle")}
            </p>
            <p className="metaverse-content">
              <Trans
                i18nKey={`serviceMetaverseGalleryText`}
                components={[<span />]}
              />
            </p>
          </div>
          <div className="metaverse-description-wrap">
            {descriptionList.map((item, idx) => (
              <div className="metaverse-description-inner-box" key={idx}>
                <div className="inner-top">
                  <h3 className="description-title maruBuri">{item.title}</h3>
                  <p className="dexscription-subtitle sCoreDream">
                    {t(`serviceMetaverseDescriptionSubTitle${idx + 1}`)}
                  </p>
                </div>
                <ul className="sCoreDream">
                  {descriptionList[idx].description.map((list, i) => {
                    return (
                      <li key={i}>
                        <Trans i18nKey={list} components={[<span />]} />
                      </li>
                    );
                  })}
                </ul>
              </div>
            ))}
          </div>
        </div>
        <div className="metaverse-middle-img-wrap">
          <ul className="metaverse-middle-img-inner">
            <li>
              <a href={Mataverse01} className="oblong-img" target="_blank">
                <img src={Mataverse01} alt="메타버스 전시 공간 이미지 1" />
              </a>
              <a
                href={Mataverse02}
                target="_blank"
                className="middle-oblong-img hide780"
              >
                <img src={Mataverse02} alt="메타버스 전시 공간 이미지 2" />
              </a>
            </li>
            <li>
              <a href={Mataverse02} target="_blank" className="show780">
                <img src={Mataverse02} alt="메타버스 전시 공간 이미지 2" />
              </a>
              <a href={Mataverse03} target="_blank">
                <img src={Mataverse03} alt="메타버스 전시 공간 이미지 3" />
              </a>
              <a href={Mataverse04} target="_blank">
                <img src={Mataverse04} alt="메타버스 전시 공간 이미지 4" />
              </a>
              <a href={Mataverse05} target="_blank">
                <img src={Mataverse05} alt="메타버스 전시 공간 이미지 5" />
              </a>
            </li>
            <li>
              <a href={Mataverse06} target="_blank">
                <img src={Mataverse06} alt="메타버스 전시 공간 이미지 6" />
              </a>
              <a href={Mataverse07} target="_blank" className="oblong-img">
                <img src={Mataverse07} alt="메타버스 전시 공간 이미지 7" />
              </a>
            </li>
          </ul>
        </div>
        <div className="metaverse-bottom-wrap">
          <div className="text-box sCoreDream">
            <h3>
              <Trans
                i18nKey={`serviceMetaverseBottomTitle`}
                components={[<span />]}
              />
            </h3>
            <p>{t("serviceMetaverseBottomSubTitle")}</p>
            <div className="text-bottom">
              <div className="text-item">
                <img src={EmailIcon} alt="" className="email-icon" />
                <span>galleryst@secrettown.co.kr</span>
              </div>
              <div className="text-item">
                <img src={PhoneIcon} alt="" className="phone-icon" />
                <span>02-6949-4831</span>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

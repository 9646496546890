import { Trans, useTranslation } from 'react-i18next';

import rowImg1 from '@assets/images/metaverseArtland/mo/row/mo1_vertical_galleryhome.png';
import rowImg2 from '@assets/images/metaverseArtland/mo/row/mo2_vertical_artfair_page1.png';
import rowImg3 from '@assets/images/metaverseArtland/mo/row/mo3_vertical_artfair_page2.png';
import rowImg4 from '@assets/images/metaverseArtland/mo/row/mo4_vertical_interaction_page1.png';
import rowImg5 from '@assets/images/metaverseArtland/mo/row/mo5_vertical_interaction_page2.png';
import colImg1 from '@assets/images/metaverseArtland/mo/col/mo1_horizontal_galleryhome.png';
import colImg2 from '@assets/images/metaverseArtland/mo/col/mo2_horizontal_artfair_page1.png';
import colImg3 from '@assets/images/metaverseArtland/mo/col/mo3_horizontal_artfair_page2.png';
import colImg4 from '@assets/images/metaverseArtland/mo/col/mo4_horizontal_interaction_page1.png';
import colImg5 from '@assets/images/metaverseArtland/mo/col/mo5_horizontal_interaction_page2.png';

export default function ArtlandMo() {
  const { t } = useTranslation();

  return (
    <div className='artland-content-box sCoreDream'>
      <div className='artland-content'>
        <h4 className='maruBuri'>STEP 1. {t('artlandContentTitle1')}</h4>
        <p className='step-content'>
          {t('artlandContentTxt1')}
          <span>{t('artlandContentTxt2')}</span>
        </p>
        <div className='step-img-mo'>
          <img src={rowImg1} className='row' alt='artland guide' />
          <img src={colImg1} className='col' alt='artland guide' />
        </div>
      </div>

      <div className='line'></div>

      <div className='artland-content'>
        <h4 className='maruBuri'>STEP 2. {t('artlandContentTitle2')}</h4>
        <p className='step-content'>
          {t('artlandContentTxt3')}
          <span>{t('artlandContentTxt4')}</span>
        </p>
        <div className='step-img-mo'>
          <img src={rowImg2} className='row' alt='artland guide' />
          <img src={colImg2} className='col' alt='artland guide' />
        </div>
      </div>

      <ul className='artland-text list'>
        <h5>{t('artlandContentSubTitle1')}</h5>
        <li>{t('artlandContentTxt5Mo')}</li>
        <li>{t('artlandContentTxt6Mo')}</li>
        <li>{t('artlandContentTxt7Mo')}</li>
      </ul>

      <div className='step-img-mo'>
        <img src={rowImg3} className='row' alt='artland guide' />
        <img src={colImg3} className='col' alt='artland guide' />
      </div>

      <div className='artland-text'>
        <h5>{t('artlandContentSubTitle2_1')}</h5>
        <p>{t('artlandContentTxt8')}</p>
      </div>

      <div className='artland-text'>
        <h5>{t('artlandContentSubTitle2_2')}</h5>
        <p>{t('artlandContentTxt9')}</p>
      </div>

      <div className='artland-text'>
        <h5>{t('artlandContentSubTitle2_3')}</h5>
        <p>{t('artlandContentTxt10')}</p>
      </div>

      <div className='line'></div>

      <div className='artland-content'>
        <h4 className='maruBuri'>STEP 3. {t('artlandContentTitle3')}</h4>
        <p className='step-content'>
          {t('artlandContentTxt11')}
          <span>{t('artlandContentTxt12')}</span>
        </p>
        <div className='step-img-mo'>
          <img src={rowImg4} className='row' alt='artland guide' />
          <img src={colImg4} className='col' alt='artland guide' />
        </div>
      </div>

      <div className='artland-text'>
        <h5>{t('artlandContentSubTitle4')}</h5>
        <p>{t('artlandContentTxt13')}</p>
      </div>

      <div className='artland-text'>
        <h5>{t('artlandContentSubTitle4_2')}</h5>
        <p>{t('artlandContentTxt13_2')}</p>
      </div>

      <div className='step-img-mo'>
        <img src={rowImg5} className='row' alt='artland guide' />
        <img src={colImg5} className='col' alt='artland guide' />
      </div>

      <div className='artland-text'>
        <h5>{t('artlandContentSubTitle5')}</h5>
        <p>{t('artlandContentTxt14')}</p>
      </div>

      <div className='artland-text'>
        <h5>{t('artlandContentSubTitle6')}</h5>
        <p>
          {t('artlandContentTxt15')}
          <span className='padding-top'>{t('artlandContentTxt16')}</span>
          <span>{t('artlandContentTxt17')}</span>
        </p>
      </div>

      <div className='artland-text'>
        <h5>{t('artlandContentSubTitle7')}</h5>
        <p>{t('artlandContentTxt18')}</p>
      </div>

      <div className='artland-text'>
        <h5>{t('artlandContentSubTitle8')}</h5>
        <p>{t('artlandContentTxt19')}</p>
      </div>
    </div>
  );
}

import { useNavigate } from "react-router-dom";
import { Trans, useTranslation } from "react-i18next";
import "./gallerySt.scss";
import ClickBtn from "@components/btns/ClickBtn";
import consultingImg from "@assets/images/galleryst/consulting_img.png";
import metaverseImg from "@assets/images/galleryst/metaverse_img.png";
export default function GallerySt() {
  const { t } = useTranslation();

  //   const navigate = useNavigate();
  //   const handleClickLink = () => {
  //     navigate("/community/academy_info");
  //   };

  return (
    <div className="galleryst-wrap">
      <div className="galleryst-inner">
        <div className="galleryst-content-wrap galleryst-content-about-wrap">
          <div className="galleryst-content-about-inner">
            <div className="galleryst-content-title-wrap">
              <p className="galleryst-content-title-sub pretendard">about</p>
              <h3 className="galleryst-content-title-big maruBuri">
                GalleryST
              </h3>
            </div>

            <div className="galleryst-content-text galleryst-content-about-text sCoreDream">
              <Trans i18nKey={`galleryStAboutText1`} components={[<span />]} />
              <Trans i18nKey={`galleryStAboutText2`} components={[<span />]} />
            </div>
          </div>
        </div>

        <div className="galleryst-content-wrap galleryst-content-metaverse-wrap">
          <div className="galleryst-content-title-wrap">
            <p className="galleryst-content-title-sub title-business">
              business
            </p>
            <h3 className="galleryst-content-title-big maruBuri">
              Metaverse <span>Gallery</span>
            </h3>
          </div>
          <div className="galleryst-content-metaverse-text-wrap">
            <div className="galleryst-content-metaverse-img">
              <img src={metaverseImg} alt="" />
            </div>
            <div className="galleryst-content-text galleryst-content-metaverse-text">
              <p className="galleryst-content-text--margin maruBuri">
                <Trans i18nKey={`galleryStMetaText1`} components={[<span />]} />
              </p>
              <p className="galleryst-content-metaverse-text-bottom sCoreDream">
                <Trans i18nKey={`galleryStMetaText2`} components={[<span />]} />
              </p>
            </div>
          </div>
        </div>

        <div className="galleryst-content-wrap galleryst-content-support-wrap">
          <div className="galleryst-content-title-wrap">
            <p className="galleryst-content-title-sub title-business">
              Business
            </p>
            <h3 className="galleryst-content-title-big maruBuri">
              Artist Support and <span>VIP Networking</span>
            </h3>
          </div>

          <div className="galleryst-content-text galleryst-content-support-text">
            <div className="galleryst-content-support-text-box">
              <div className="number maruBuri">
                <p>01</p>
              </div>
              <div className="title-sub maruBuri">
                <Trans
                  i18nKey={`galleryStSupportSubTitle1`}
                  components={[<span />]}
                />
              </div>
              <div className="galleryst-content-text sCoreDream">
                <Trans
                  i18nKey={`galleryStSupportText1`}
                  components={[<span />]}
                />
              </div>
            </div>

            <div className="galleryst-content-support-text-box">
              <div className="number maruBuri">
                <p>02</p>
              </div>
              <div className="title-sub maruBuri">
                <Trans
                  i18nKey={`galleryStSupportSubTitle2`}
                  components={[<span />]}
                />
              </div>
              <div className="galleryst-content-text sCoreDream">
                <Trans
                  i18nKey={`galleryStSupportText2`}
                  components={[<span />]}
                />

                {/* <ClickBtn
                  text="아카데미 바로가기"
                  className="academy-link-btn maruBuri"
                  onClick={handleClickLink}
                /> */}
              </div>
            </div>

            <div className="galleryst-content-support-text-box">
              <div className="number maruBuri">
                <p>03</p>
              </div>
              <div className="title-sub maruBuri">Art Gathering</div>
              <div className="galleryst-content-text sCoreDream">
                <Trans
                  i18nKey={`galleryStSupportText3`}
                  components={[<span />]}
                />
              </div>
            </div>
          </div>
        </div>

        <div className="galleryst-content-wrap galleryst-content-consulting-wrap">
          <div className="galleryst-content-consulting-text-wrap">
            <div className="galleryst-content-text galleryst-content-consulting-text">
              <div className="galleryst-content-title-wrap">
                <p className="galleryst-content-title-sub title-business">
                  Business
                </p>
                <h3 className="galleryst-content-title-big maruBuri">
                  Art consulting
                  <div>
                    and
                    <span className="inline-span">marketing</span>
                  </div>
                </h3>
              </div>
              <div className="galleryst-content-consulting-text-box sCoreDream">
                <p className="galleryst-content-text--margin">
                  <Trans
                    i18nKey={`galleryStConsultingText1`}
                    components={[<span />]}
                  />
                </p>
                <p>
                  <Trans
                    i18nKey={`galleryStConsultingText2`}
                    components={[<span />]}
                  />
                </p>
              </div>
            </div>
            <div className="galleryst-content-consulting-img">
              <img src={consultingImg} alt="" />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

import { Outlet } from 'react-router';
import { Swiper, SwiperSlide } from 'swiper/react';
import { Navigation, Pagination } from 'swiper';
import 'swiper/css';
import 'swiper/css/effect-fade';
import 'swiper/css/navigation';
import 'swiper/css/pagination';
import { SwiperInterface } from '@interfaces/components/SwiperInterface';
import './multipleSwiper.scss';
import ProductItem from '@components/swiper/product/ProductItem';

export default function MultipleSwiper(
  { items }: any /* : SwiperInterface.SwiperItemsInterface */
) {
  //swiper:Slides per group skip
  return (
    <div className='article-multiple-swiper'>
      <div className='article-multiple-swiper-arrow-wrap'>
        <button id='multipleSwiperPrev'></button>
        <button id='multipleSwiperNext'></button>
      </div>

      <Swiper
        modules={[Navigation, Pagination]}
        className='mySwiper'
        centeredSlides={false}
        grabCursor={true}
        pagination={{
          el: '.swiper-pagination', // 페이지네이션 컨테이너 선택자
          clickable: true, // 페이지 번호를 클릭해서 슬라이드로 이동할 수 있는지 설정
        }}
        slidesPerView={5} //처음 보여지는개수
        slidesPerGroup={5} //보여지는 개수 묶음
        spaceBetween='2.8%' //slide gap
        navigation={{
          prevEl: '#multipleSwiperPrev',
          nextEl: '#multipleSwiperNext',
        }} //버튼 커스텀
        breakpoints={{
          0: {
            slidesPerView: 2.2,
            slidesPerGroup: 2,
          },
          780: {
            slidesPerView: 3,
            slidesPerGroup: 3,
          },
          1200: {
            slidesPerView: 4,
            slidesPerGroup: 4,
          },
          1366: {
            slidesPerView: 5,
          },
        }}
      >
        {items &&
          items?.map((item: any, idx: number) => {
            return (
              <SwiperSlide key={idx}>
                <ProductItem item={item} />
              </SwiperSlide>
            );
          })}
        <div className='swiper-pagination'></div>
      </Swiper>
    </div>
  );
}

import { useEffect } from 'react';
import ReactGA from 'react-ga4';
import axios from 'axios';
import btnApply from '@assets/images/hotelArtFair/hotelartFairApplyBtn.png';

export default function ContestDownLoadApplyBtn() {
  //const downloadLink = document.createElement('a');

  function file() {
    ReactGA.event({
      category: 'hotel_artFair_btn_docx_click',
      action: 'hotel_artFair_btn_docx_click',
    }); //GA
    // let url: string;
    // axios
    //   .get(
    //     'https://gallery-st-main-public.s3.ap-northeast-2.amazonaws.com/art_fair/doc/%E1%84%89%E1%85%B5%E1%84%8F%E1%85%B3%E1%84%85%E1%85%B5%E1%86%BA%E1%84%90%E1%85%A1%E1%84%8B%E1%85%AE%E1%86%AB%E1%84%92%E1%85%A9%E1%84%90%E1%85%A6%E1%86%AF%E1%84%8B%E1%85%A1%E1%84%90%E1%85%B3%E1%84%91%E1%85%A6%E1%84%8B%E1%85%A52023_%E1%84%8E%E1%85%A1%E1%86%B7%E1%84%80%E1%85%A1%E1%84%89%E1%85%B5%E1%86%AB%E1%84%8E%E1%85%A5%E1%86%BC%E1%84%89%E1%85%A5.docx',
    //     {responseType: 'blob'}
    //   )
    //   .then((res) => {
    //     url = URL.createObjectURL(
    //       new Blob([res.data], {type: res.headers['content-type']})
    //     );
    //     downloadLink.href = url;
    //
    //     downloadLink.download =
    //       '2023 호텔아트페어 공모신청_시크릿타운주.docx';
    //     downloadLink.click();
    //     URL.revokeObjectURL(url);
    //   });
  }

  useEffect(() => {
    const el = document.getElementsByClassName('hotelContestApplyBtn')[0];
    el?.addEventListener('click', file);
    return () => {
      const el = document.getElementsByClassName('hotelContestApplyBtn')[0];
      el?.removeEventListener('clcik', file);
    };
  }, []);

  return (
    <p className='hotelContestApplyBtn hotelContestDownBtn'>
      <a href='https://gallery-st-live-public.s3.ap-northeast-2.amazonaws.com/art_fair/doc/%E1%84%89%E1%85%B5%E1%84%8F%E1%85%B3%E1%84%85%E1%85%B5%E1%86%BA%E1%84%90%E1%85%A1%E1%84%8B%E1%85%AE%E1%86%AB%E1%84%92%E1%85%A9%E1%84%90%E1%85%A6%E1%86%AF%E1%84%8B%E1%85%A1%E1%84%90%E1%85%B3%E1%84%91%E1%85%A6%E1%84%8B%E1%85%A52023_%E1%84%8E%E1%85%A1%E1%86%B7%E1%84%80%E1%85%A1%E1%84%89%E1%85%B5%E1%86%AB%E1%84%8E%E1%85%A5%E1%86%BC%E1%84%89%E1%85%A5.docx'>
        <img
          src={btnApply}
          alt='공모신청 다운로드'
          className='pointer-event-true'
        />
      </a>
    </p>
  );
}

import { useEffect, useState } from 'react';
import { useParams } from 'react-router';
import ClearIcon from '@mui/icons-material/Clear';
import { getArtUrl } from '@services/ZoomInService';
import ZoomNoParams from './ZoomNoParams';
import LoadingRow from '@components/Loading/LoadingRow';
import ZoomModal from '@components/modal/ZoomModal';
import './zoomInWrap.scss';

export default function ZoomInWrap() {
  const { id } = useParams();

  const [loading, setLoading] = useState(false);
  const [isOn, setIsOn] = useState('reset');
  const [item, setItem] = useState({
    id: 0,
    name: '',
    type: '',
    high_quality_art_url: '',
  });

  const [isOpenImgModal, setIsOpenImgModal] = useState(false);
  const [imgSrc, setImgSrc] = useState('');
  const openImgModal = (type: string, src: string) => {
    if (type === 'video') {
      setLoading(true);
      setImgSrc(src);
      openFullscreen(document.getElementById('myVideo'));
    } else {
      setImgSrc(src);
      setIsOpenImgModal(true);
      document.documentElement.style.overflow = 'hidden';
    }
  };
  const closeImgModal = () => {
    document.documentElement.style.overflow = 'auto';
    /* window.parent.postMessage({ type: 'close' }, '*'); */
  };

  //video loaded loading clear
  const handleVideoLoaded = () => {
    setLoading(false);
  };

  //영상클릭시 전체화면
  function openFullscreen(elem: any) {
    if (elem) {
      if (elem.requestFullscreen) {
        elem.requestFullscreen();
      } else if (elem.mozRequestFullScreen) {
        /* Firefox */

        elem.mozRequestFullScreen();
      } else if (elem.webkitRequestFullscreen) {
        /* Chrome, Safari & Opera */

        elem.webkitRequestFullscreen();
      } else if (elem.msRequestFullscreen) {
        /* IE/Edge */

        elem.msRequestFullscreen();
      }
    }
  }

  ///*art modal zoom
  const STEP_SCALE = 0.01; //커지는 단위
  const [scale, setScale] = useState<number>(0); //slider 값
  const [minScale, setMinScale] = useState<number>(0); //slider 최소 값
  const [maxScale, setMaxScale] = useState<number>(0); //slider 최대 값

  useEffect(() => {
    const doGetOne = async () => {
      try {
        const body = {
          art_info_id: id,
        };

        if (id) {
          const data = await getArtUrl(body);
          if (data?.code === 200) {
            setItem(data.data);
            if (
              data?.data?.high_quality_art_url &&
              data?.data?.high_quality_art_url !== ''
            ) {
              setIsOn('true');
            } else {
              setIsOn('false');
            }
          } else {
            setIsOn('false');
          }
        }
      } catch (err) {
        console.log(err);
      }
    };

    doGetOne();
  }, [id]);

  useEffect(() => {
    if (item?.type === 'video') {
      openImgModal('video', item?.high_quality_art_url);
    } else {
      openImgModal('image', item?.high_quality_art_url);
    }
  }, [item]);

  if (!id) {
    return <ZoomNoParams />;
  }

  return isOn === 'false' ? (
    <ZoomNoParams />
  ) : (
    <div className='zoomin-wrap'>
      {item?.type === 'video' && imgSrc ? (
        <div className='art-img-modal-wrap custom-modal-fullsize-wrap'>
          {/*  <button className='modal-close-btn' onClick={closeImgModal}>
            <ClearIcon />
          </button> */}
          <video
            className='art-video'
            autoPlay
            loop
            controls
            muted
            playsInline
            id='myVideo'
            src={imgSrc}
            controlsList='nodownload'
            onLoadedMetadata={handleVideoLoaded} //영상 로드되면 실행
          ></video>
        </div>
      ) : (
        <ZoomModal
          minScale={minScale}
          setMinScale={setMinScale}
          maxScale={maxScale}
          setMaxScale={setMaxScale}
          scale={scale}
          setScale={setScale}
          stepScale={STEP_SCALE}
          isOpenImgModal={isOpenImgModal}
          closeImgModal={closeImgModal}
          imgSrc={imgSrc}
        />
      )}
      {loading && <LoadingRow Loading={loading} />}
    </div>
  );
}

import { useEffect, useState } from 'react';
import { ConfigInterface } from '@interfaces/config/Config';
import apple from '@assets/images/apple.svg';
import { getMaintenanceInfo } from '@services/MaintenanceService';
import './maintenance.scss';
import './iosMaintenance.scss';

export default function IosMaintenance() {
  const [maintenance, setMaintenance] = useState({
    id: 0,
    name: '',
    key: '',
    value: '',
    created_at: 0,
  });

  useEffect(() => {
    const getMaintenanceData = async () => {
      const maintenanceInfo = await getMaintenanceInfo();

      let datas = maintenanceInfo?.list?.filter(
        (item: ConfigInterface.ConfigContentsInterface) =>
          item.key === 'ios_artland_open_phrase'
      );
      setMaintenance(datas[0]);
    };
    getMaintenanceData();
  }, []);

  return (
    <article className='maintenance-wrap ios-maintenance-wrap'>
      <div className='maintenance-inner'>
        <div className='img-wrap'>
          <img src={apple} alt='ios' />
        </div>
        <div className='text-box'>
          <h3>{maintenance?.name}</h3>
          <p
            style={{ whiteSpace: 'pre-wrap' }}
            dangerouslySetInnerHTML={{ __html: maintenance?.value }}
          ></p>
        </div>
      </div>
    </article>
  );
}

import OrderLists from '@components/order/OrderLists';
import { useLocation, useNavigate } from 'react-router';
import ArtSelectBox from './ArtSelectBox';
import { useEffect, useState, useRef } from 'react';

import { postOrderCreate, postArtCheck } from '@services/OrderService';
import './artMarketOrderSheet.scss';
import { useForm } from '@hooks/useForm';
import regex from 'helpers/regex';
import { useDaumPostcodePopup } from 'react-daum-postcode';
import { postcodeScriptUrl } from 'react-daum-postcode/lib/loadPostcode';
import { useTranslation } from 'react-i18next';
import cardIcon from '@assets/images/order/card.png';
import cardOverIcon from '@assets/images/order/card_over.png';
import payIcon from '@assets/images/order/easy_pay.png';
import payOverIcon from '@assets/images/order/easy_pay_over.png';
import accountIcon from '@assets/images/order/account.png';
import accountOverIcon from '@assets/images/order/account_over.png';
import NaverIcon from '@assets/images/naverPaycon.png';
import KakaoIcon from '@assets/images/kakaoPayIcon.png';
import CommonBorderWhiteBtn from '@components/btns/CommonBorderWhiteBtn';
import MoreWhiteBtn from '@components/btns/MoreWhiteBtn';
import CustomModal from '@components/modal/CustomModal';
import OrderAgree from './OrderAgree';
import queryString from 'query-string';
import Loading from '@components/Loading/Loading';
import { useRecoilState } from 'recoil';
import { router } from '../../stores/router';
import { guestId } from '@stores/guest';
import { artLandLayout } from '@stores/artLand';

export default function ArtMarketOrderSheet() {
  const location = useLocation();
  const locationState = location.state as {
    id: number[];
    items: any | any[];
    is_basket: number;
    art_land?: boolean; //아트랜드 layout적용
  };
  //아트랜드 layout적용
  const [artLandLayoutState, setArtLandLayoutState] =
    useRecoilState(artLandLayout);

  useEffect(() => {
    if (locationState.art_land) {
      setArtLandLayoutState(true);
    } else {
      return;
    }
  }, []);

  const item = locationState.items;
  const [routerModel, setRouterModel] = useRecoilState(router);

  const navigate = useNavigate();
  const url = process.env.REACT_APP_API_URL;

  const artId = locationState.id;

  const { t } = useTranslation();

  const [guestIdState, setGuestIdState] = useRecoilState(guestId);
  const [loading, setLoading] = useState(false);
  const [isAgreeHidden, setIsAgreeHidden] = useState(false);
  const [isOn, setIsOn] = useState(false);
  const [infoChecked, setInfoChecked] = useState(false);
  const [helperText, setHelperText] = useState<{
    [key: string]: string;
    orderName: string;
    orderPhone: string;
    orderEmail: string;
    deliveryName: string;
    deliveryPhone: string;
    deliveryAddress: string;
    requestMsg: string;
    paymentType: string;
  }>({
    orderName: '',
    orderPhone: '',
    orderEmail: '',
    deliveryName: '',
    deliveryPhone: '',
    deliveryAddress: '',
    requestMsg: '',
    paymentType: '',
  });

  const [orderInfo, setOrderInfo] = useState<{
    [key: string]: string | undefined;
    name: string | undefined;
    startPh: string | undefined;
    midPh: string | undefined;
    endPh: string | undefined;
    email: string | undefined;
  }>({
    name: undefined,
    startPh: undefined,
    midPh: undefined,
    endPh: undefined,
    email: undefined,
  });

  const [deliveryInfo, setDeliveryInfo] = useState<{
    [key: string]: string | undefined;
    name: string | undefined;
    startPh: string | undefined;
    midPh: string | undefined;
    endPh: string | undefined;
    postNumber: string | undefined;
    address: string | undefined;
    subAddress: string | undefined;
    requestMsg: string | undefined;
  }>({
    name: undefined,
    startPh: undefined,
    midPh: undefined,
    endPh: undefined,
    postNumber: undefined,
    address: undefined,
    subAddress: '',
    requestMsg: undefined,
  });

  const [paymentRequest, setPaymentRequest] = useState<{
    name: string | undefined;
  }>({
    name: undefined,
  });

  useEffect(() => {
    const name = orderInfo.name;
    const startPh = orderInfo.startPh;
    const midPh = orderInfo.midPh;
    const endPh = orderInfo.endPh;
    const email = orderInfo.email;
    const deliveryName = deliveryInfo.name;
    const deliveryStartPh = deliveryInfo.startPh;
    const deliveryMidPh = deliveryInfo.midPh;
    const deliveryEndPh = deliveryInfo.endPh;
    const deliveryPostNumber = deliveryInfo.postNumber;
    const deliveryAddress = deliveryInfo.address;
    const requestMsg = deliveryInfo.requestMsg;
    let isInfoSame = infoChecked;
    let isSubmit = true;
    if (!name && name != '') {
      helperText.orderName = '';
    } else if (!regex.name.test(name)) {
      helperText.orderName = '이름이 공백이거나 유형이 맞지 않습니다.';
    } else {
      helperText.orderName = '';
    }

    if (!startPh && startPh != '') {
      helperText.orderPhone = '';
    } else if (regex.phoneStart.test(startPh)) {
      helperText.orderPhone = '';
    } else {
      helperText.orderPhone = '앞자리 휴대폰 번호가 잘못되었습니다.';
    }

    if (!helperText.orderPhone) {
      if (!midPh && midPh != '') {
        helperText.orderPhone = '';
      } else if (regex.phoneMid.test(midPh)) {
        helperText.orderPhone = '';
      } else {
        helperText.orderPhone = '가운데 휴대폰 번호가 잘못되었습니다.';
      }
    }
    if (!helperText.orderPhone) {
      if (!endPh && endPh != '') {
        helperText.orderPhone = '';
      } else if (regex.phoneEnd.test(endPh)) {
        helperText.orderPhone = '';
      } else {
        helperText.orderPhone = '뒷자리 휴대폰 번호가 잘못되었습니다.';
      }
    }
    if (!email && email != '') {
      helperText.orderEmail = '';
    } else if (regex.email.test(email)) {
      helperText.orderEmail = '';
    } else {
      helperText.orderEmail = '이메일 형식이 일치하지 않습니다.';
    }
    if (!deliveryName && deliveryName != '') {
      helperText.deliveryName = '';
    } else if (regex.name.test(deliveryName)) {
      helperText.deliveryName = '';
    } else {
      helperText.deliveryName = '이름이 공백이거나 유형이 맞지 않습니다.';
    }

    if (!deliveryStartPh && '' != deliveryStartPh) {
      helperText.deliveryPhone = '';
    } else if (regex.phoneStart.test(deliveryStartPh)) {
      helperText.deliveryPhone = '';
    } else {
      helperText.deliveryPhone = '앞자리 휴대폰 번호가 잘못되었습니다.';
    }
    if (!helperText.deliveryPhone) {
      if (!deliveryMidPh && '' != deliveryMidPh) {
        helperText.deliveryPhone = '';
      } else if (regex.phoneMid.test(deliveryMidPh)) {
        helperText.deliveryPhone = '';
      } else {
        helperText.deliveryPhone = '가운데 휴대폰 번호가 잘못되었습니다.';
      }
    }

    if (!helperText.deliveryPhone) {
      if (!deliveryEndPh && '' != deliveryEndPh) {
        helperText.deliveryPhone = '';
      } else if (regex.phoneEnd.test(deliveryEndPh)) {
        helperText.deliveryPhone = '';
      } else {
        helperText.deliveryPhone = '뒷자리 휴대폰 번호가 잘못되었습니다.';
      }
    }

    if (!deliveryPostNumber && '' != deliveryPostNumber) {
      helperText.deliveryAddress = '';
    } else if (!regex.postNumber.test(deliveryPostNumber)) {
      helperText.deliveryAddress = '우편 번호를 입력해주세요.';
    } else {
      helperText.deliveryAddress = '';
    }

    if (!deliveryAddress && '' != deliveryAddress) {
      helperText.deliveryAddress = '';
    } else if (!regex.nonGap.test(deliveryAddress)) {
      helperText.deliveryAddress = '주소를 입력 해주세요';
    } else {
      helperText.deliveryAddress = '';
    }

    // if (!requestMsg && requestMsg != "") {
    //   helperText.requestMsg = "";
    // } else if (!regex.nonGap.test(requestMsg)) {
    //   helperText.requestMsg = "배송 요청을 입력해주세요";
    // } else {
    //   helperText.requestMsg = "";
    // }
    if (!paymentRequest && paymentRequest != '') {
      helperText.paymentType = '';
    } else if (paymentRequest.name == '') {
      helperText.paymentType = '결제 수단을 선택 해주세요';
    } else {
      helperText.paymentType = '';
    }

    if (isInfoSame) {
      isInfoSame &&= orderInfo.name == deliveryInfo.name;
      isInfoSame &&= orderInfo.startPh == deliveryInfo.startPh;
      isInfoSame &&= orderInfo.midPh == deliveryInfo.midPh;
      isInfoSame &&= orderInfo.endPh == deliveryInfo.endPh;
      if (!isInfoSame) {
        setInfoChecked(false);
      }
    }

    Object.keys(helperText).forEach((f) => {
      isSubmit &&= helperText[f] == '';
    });

    Object.keys(orderInfo).forEach((f) => {
      isSubmit &&= orderInfo[f] != undefined;
    });

    Object.keys(deliveryInfo).forEach((f) => {
      isSubmit &&= deliveryInfo[f] != undefined;
    });
    //console.log(deliveryInfo);
    isSubmit &&= paymentRequest.name != undefined;

    setIsOn(isSubmit);
    setHelperText({ ...helperText });
  }, [orderInfo, deliveryInfo, paymentRequest]);

  useEffect(() => {
    if (infoChecked) {
      const name = orderInfo.name;
      const startPh = orderInfo.startPh;
      const midPh = orderInfo.midPh;
      const endPh = orderInfo.endPh;

      setOrderInfo((p) => ({
        ...p,
        name: p.name || '',
        startPh: p.startPh || '',
        midPh: p.midPh || '',
        endPh: p.endPh || '',
        email: p.email || '',
      }));
      let el: any = document.getElementById('delivery-name');
      setDeliveryInfo((p) => ({
        ...p,
        name: name || '',
        startPh: startPh || '',
        midPh: midPh || '',
        endPh: endPh || '',
        /*  postNumber: p.postNumber || '',
        address: p.address || '',*/
        requestMsg: p.requestMsg || '',
      }));

      if (el) {
        el.value = name || '';
      }
      el = document.getElementById('delivery-s-ph');
      if (el) {
        el.value = startPh || '';
      }
      el = document.getElementById('delivery-m-ph');
      if (el) {
        el.value = midPh || '';
      }
      el = document.getElementById('delivery-e-ph');
      if (el) {
        el.value = endPh || '';
      }
    }
  }, [infoChecked]);

  const handleCheckChange = () => {
    setInfoChecked((infoChecked) => !infoChecked);
    if (infoChecked) {
      let el: any = document.getElementById('delivery-name');
      setDeliveryInfo((p) => ({
        ...p,
        name: '',
        startPh: '',
        midPh: '',
        endPh: '',
        /* postNumber: '',
        address: '',
        requestMsg: '', */
      }));

      if (el) {
        el.value = '';
      }
      el = document.getElementById('delivery-s-ph');
      if (el) {
        el.value = '';
      }
      el = document.getElementById('delivery-m-ph');
      if (el) {
        el.value = '';
      }
      el = document.getElementById('delivery-e-ph');
      if (el) {
        el.value = '';
      }
    }
  };

  const open = useDaumPostcodePopup(postcodeScriptUrl);
  const handleComplete = (data: any) => {
    const el: any = document.getElementById('address');
    const postNumber: any = document.getElementById('postNumber');

    if (el) {
      el.value = data.address;
    }
    if (postNumber) {
      postNumber.value = data.zonecode;
    }
    setDeliveryInfo((p) => ({
      ...p,
      address: data.address,
      postNumber: data.zonecode,
    }));
  };
  const handleClickAddress = () => {
    (window as any).gaWrite(
      'web_click_artinfo_order_step_01_address ',
      'order',
      ''
    );

    open({ onComplete: handleComplete });
  };

  const handlePostOrder = async () => {
    const data = await postArtCheck(artId, guestIdState);
    if (!data.data) {
      document.documentElement.style.overflow = 'auto';
      alert('작품 중 구매 불가능한 작품이 있습니다.');
      navigate('/cart/');
      return;
    }
    if (isOn) {
      const body = {
        guest_session_id: guestIdState,
        name: orderInfo.name,
        phone: (orderInfo.startPh || '') + orderInfo.midPh + orderInfo.endPh,
        email: orderInfo.email,
        order_name: deliveryInfo.name,
        order_phone:
          deliveryInfo.startPh + '' + deliveryInfo.midPh + deliveryInfo.endPh,
        order_zip_code: deliveryInfo.postNumber,
        order_address: deliveryInfo.address,
        order_detail_address: deliveryInfo.subAddress,
        /*  order_zip_code: '', */
        art_info_id: artId,
        delivery_request_message: deliveryInfo.requestMsg,
        is_basket: locationState.is_basket,
      };
      setLoading(true);
      const data = await postOrderCreate(body, guestIdState);
      if (data.code === 200) {
        setLoading(false);
        /* openCartModal(); */
        order_number = data.data.order_number;
        const totalBody = {
          phone: body.phone,
          email: body.email,
          order_name: body.order_name,
          order_number: order_number,
          pay_method: paymentRequest.name,
          market_type: 'artMarket', //결제 page
        };
        setTotalBodyQueryString(queryString.stringify(totalBody));
        openPaymentModal();
      }
    }
  };

  //이노페이 결제창 modal
  const [isPaymentModalOpen, setPaymentModalOpen] = useState(false);
  const openPaymentModal = () => {
    setPaymentModalOpen(true);
    document.documentElement.style.overflow = 'hidden';
  };
  const closePaymentModal = () => {
    setPaymentModalOpen(false);
    document.documentElement.style.overflow = 'auto';
  };

  //신청완료 modal

  let order_number: number;
  const [totalBodyQueryString, setTotalBodyQueryString] = useState('');

  let isFrom = true;
  useEffect(() => {
    closePaymentModal();
    setRouterModel((p) => ({ ...p, isGuard: true }));
    //message:다 받기
    window.addEventListener('message', (e) => {
      const data = e.data;
      let json;
      let event;
      try {
        json = JSON.parse(data);
        event = data.event;
      } catch (e) {
        return;
      }

      //guardOff: 서울 아트쇼에서 내려주는 설정한 이벤트명
      //서울아트쇼에서 알림창(결제 나가겠습니까? 확인 알림창)이 안뜨게 설정하느라 따로 작업함
      if (event === 'guardOff') {
        setRouterModel((p) => ({ ...p, isGuard: false }));
      }
      const pc: string | undefined = process.env.REACT_APP_SAF_PC_URL;
      const mo: string | undefined = process.env.REACT_APP_SAF_MO_URL;

      //result는 결제때 직접 설정한 값임(이노페이)
      // 취소 버튼
      if (json.result) {
        if (json.result == 'failure') {
          closePaymentModal();
        } else if (
          json.result == '3001' ||
          (json.result && json.result == '4000') ||
          json.result === 'success'
        ) {
          document.documentElement.style.overflow = 'auto';
          if (json.result_number && json.result_date) {
            navigate('/art_sales/art_market/order_sheet/success', {
              state: {
                orderNumber: json.result_number,
                created: json.result_date,
              },
            });
          }
          closePaymentModal();
        } else {
          alert('결제에 실패 했습니다. \n실패사유: ' + json.result_msg);
          closePaymentModal();
        }
      }
    });
    const beforeunload = (e: BeforeUnloadEvent) => {
      e.preventDefault(); // 이벤트 기본 동작을 중지
      if (isFrom) {
        e.returnValue = t('commonReset'); //변경사항이 저장되지 않을 수 있습니다.
      } //탭 닫기누르면 뜸
    };
    const unload = (e: Event) => {
      isFrom = false;
    };

    window.addEventListener('beforeunload', beforeunload); //컴포넌트 나갈때 사용(페이지 변경되기 전에 작동)
    window.addEventListener('unload', unload);

    return () => {
      window.removeEventListener('beforeunload', beforeunload);
      setRouterModel((p) => ({ ...p, isGuard: false }));
    };
  }, []);

  return (
    <div className='sub-page-contents-wrap art-market-orderSheet-wrap'>
      <div className='sub-page-contents-inner inner-1400 '>
        <OrderLists />

        <div
          className={
            isAgreeHidden ? 'order-view-wrap active' : 'order-view-wrap hidden'
          }
        >
          {item ? (
            <ArtSelectBox guestIdState={guestIdState} items={item} />
          ) : null}

          <div className='orderSheet-wrap'>
            {/* 주문자 정보*/}
            <div className='orderSheet-box'>
              <div className='orderSheet-title'>{t('userInfo1')}</div>

              <p className='orderSheet-title-required SCoreDream'>
                {t('orderRequired')}
              </p>

              <div className='orderSheet-info-box'>
                <div className='title orderSheet-info-box-title required'>
                  <p className='orderSheet-info-box-title-txt'>
                    {t('userInfo2')}
                  </p>
                </div>
                <label className='label orderSheet-info-box-content-input'>
                  <input
                    className='input orderSheet-info-box-content-input'
                    maxLength={60}
                    onChange={(e) => {
                      setOrderInfo((p) => ({ ...p, name: e.target.value }));
                    }}
                    // onChange={(e) => { setOrderInfo((p) => ({...p, name : e.target.value}))}}
                  />
                  <p className='helperText phone'>{helperText?.orderName}</p>
                </label>
              </div>

              <div className='orderSheet-info-box phone'>
                <div className='title orderSheet-info-box-title required'>
                  <p className='orderSheet-info-box-title-txt'>
                    {t('userInfo3')}
                  </p>
                </div>
                <div className='label orderSheet-info-box-content-input'>
                  <div className='input-phone-wrap'>
                    {
                      <input
                        className='input orderSheet-info-box-content-input'
                        onChange={(e) => {
                          setOrderInfo((p) => ({
                            ...p,
                            startPh: e.target.value,
                          }));
                        }}
                        maxLength={3}
                      />
                    }
                    <p className='hyphen'>-</p>
                    {
                      <input
                        className='input orderSheet-info-box-content-input'
                        onChange={(e) => {
                          setOrderInfo((p) => ({
                            ...p,
                            midPh: e.target.value,
                          }));
                        }}
                        maxLength={4}
                      />
                    }
                    <p className='hyphen'>-</p>
                    {
                      <input
                        className='input orderSheet-info-box-content-input'
                        onChange={(e) => {
                          setOrderInfo((p) => ({
                            ...p,
                            endPh: e.target.value,
                          }));
                        }}
                        maxLength={4}
                      />
                    }
                  </div>
                  {<p className='helperText phone'>{helperText.orderPhone}</p>}
                </div>
              </div>

              <div className='orderSheet-info-box'>
                <div className='title orderSheet-info-box-title required'>
                  <p className='orderSheet-info-box-title-txt'>
                    {t('userInfo4')}
                  </p>
                </div>

                {
                  <label className='label orderSheet-info-box-content-input'>
                    <input
                      className='input orderSheet-info-box-content-input'
                      onChange={(e) => {
                        setOrderInfo((p) => ({ ...p, email: e.target.value }));
                      }}
                      maxLength={60}
                    />
                    {
                      <p className='helperText phone'>
                        {helperText.orderEmail}
                      </p>
                    }
                  </label>
                }
              </div>
            </div>
            {/* 배송지 정보 */}
            <div className='orderSheet-delivery-box orderSheet-box'>
              <div className='orderSheet-title'>{t('ordertitle6')}</div>

              <p className='orderSheet-title-required SCoreDream'>
                {t('orderRequired')}
              </p>

              <div className='title checkbox-title'>
                <span>{t('orderCheckText')}</span>
                {
                  <input
                    type='checkbox'
                    id='orderSheetInfoCheckBox'
                    className='order-checkbox'
                    checked={infoChecked}
                    onChange={handleCheckChange}
                  />
                }
              </div>

              <div className='orderSheet-info-box'>
                <div className='title orderSheet-info-box-title required'>
                  <p className='orderSheet-info-box-title-txt'>
                    {t('userInfo2')}
                  </p>
                </div>

                <label className='label orderSheet-info-box-content-input'>
                  {
                    <input
                      className='input orderSheet-info-box-content-input'
                      id='delivery-name'
                      onChange={(e) => {
                        setDeliveryInfo((p) => ({
                          ...p,
                          name: e.target.value,
                        }));
                      }}
                      maxLength={60}
                    ></input>
                  }
                  <p className='helperText phone'>{helperText?.deliveryName}</p>
                </label>
              </div>
              <div className='orderSheet-info-box phone'>
                <div className='title orderSheet-info-box-title required'>
                  <p className='orderSheet-info-box-title-txt'>
                    {t('userInfo3')}
                  </p>
                </div>
                <div className='label orderSheet-info-box-content-input'>
                  <div className='input-phone-wrap'>
                    {
                      <input
                        id='delivery-s-ph'
                        className='input orderSheet-info-box-content-input'
                        onChange={(e) => {
                          setDeliveryInfo((p) => ({
                            ...p,
                            startPh: e.target.value,
                          }));
                        }}
                        maxLength={3}
                      />
                    }
                    <p className='hyphen'>-</p>
                    {
                      <input
                        id='delivery-m-ph'
                        className='input orderSheet-info-box-content-input'
                        onChange={(e) => {
                          setDeliveryInfo((p) => ({
                            ...p,
                            midPh: e.target.value,
                          }));
                        }}
                        maxLength={4}
                      />
                    }
                    <p className='hyphen'>-</p>
                    {
                      <input
                        id='delivery-e-ph'
                        className='input orderSheet-info-box-content-input'
                        onChange={(e) => {
                          setDeliveryInfo((p) => ({
                            ...p,
                            endPh: e.target.value,
                          }));
                        }}
                        maxLength={4}
                      />
                    }
                  </div>
                  {
                    <p className='helperText phone'>
                      {helperText.deliveryPhone}
                    </p>
                  }
                </div>
              </div>

              <div className='orderSheet-info-box'>
                <div className='orderSheet-info-box-contents sCoreDream'>
                  <div className='orderSheet-info-box-content'>
                    <div className='title orderSheet-info-box-title required'>
                      <p className='orderSheet-info-box-title-txt'>
                        {t('userInfo5')}
                      </p>
                    </div>
                    <div className='orderSheet-info-box-content-inner'>
                      {
                        <button onClick={handleClickAddress}>
                          {t('ordertitle7')}
                        </button>
                      }
                      <div className='orderSheet-info-box-content-inner-item'>
                        <span className='orderSheet-info-box-content-title keep-all'>
                          {t('userInfo8')}
                        </span>
                        {
                          <input
                            className='input orderSheet-info-box-content-input'
                            id='postNumber'
                            type='text'
                            name='postNumber'
                            readOnly={true}
                            maxLength={7}
                            onChange={(e) =>
                              setDeliveryInfo((p) => ({
                                ...p,
                                postNumber: e.target.value,
                              }))
                            }
                          />
                        }
                      </div>

                      <div className='orderSheet-info-box-content-inner-item'>
                        <span className='orderSheet-info-box-content-title keep-all'>
                          {t('userInfo6')}
                        </span>
                        {
                          <input
                            className='input orderSheet-info-box-content-input'
                            id='address'
                            type='text'
                            name='address'
                            readOnly={true}
                            onChange={(e) =>
                              setDeliveryInfo((p) => ({
                                ...p,
                                address: e.target.value,
                              }))
                            }
                          />
                        }
                      </div>
                      <div className='orderSheet-info-box-content-inner-item'>
                        <span className='orderSheet-info-box-content-title keep-all sub-address'>
                          {t('userInfo7')}
                        </span>
                        <div className='input-wrap'>
                          <label className='label orderSheet-info-box-content-input'>
                            {
                              <input
                                className='input orderSheet-info-box-content-input'
                                type='text'
                                name='addressDetail'
                                onChange={(e) =>
                                  setDeliveryInfo((p) => ({
                                    ...p,
                                    subAddress: e.target.value,
                                  }))
                                }
                              />
                            }
                          </label>

                          <small>{t('ordertitle9')}</small>
                          {
                            <p className='helperText'>
                              {helperText.deliveryAddress}
                            </p>
                          }
                        </div>
                      </div>
                    </div>
                  </div>

                  <div className='orderSheet-info-box-content'>
                    <div className='title orderSheet-info-box-title'>
                      <p className='orderSheet-info-box-title-txt'>
                        {t('ordertitle8')}
                      </p>
                    </div>
                    <label className='label orderSheet-info-box-content-input'>
                      {
                        <input
                          className='input orderSheet-info-box-content-input'
                          type='text'
                          name='deliveryRequest'
                          onChange={(e) =>
                            setDeliveryInfo((p) => ({
                              ...p,
                              requestMsg: e.target.value,
                            }))
                          }
                        />
                      }
                      {<p className='helperText'>{helperText.requestMsg}</p>}
                    </label>
                  </div>
                </div>
              </div>
            </div>
            {/* 결제수단 */}
            <div className='orderSheet-box'>
              <div className='orderSheet-title'>
                <h3 className='maruBuri'>{t('ordertitle10')}</h3>
              </div>

              <div className='orderSheet-info-payment-box'>
                <div className='payment-btn-wrap'>
                  {/* 신용카드 */}
                  <button
                    className={
                      paymentRequest.name === 'CARD'
                        ? 'active maruBuri'
                        : 'btn maruBuri'
                    }
                    onClick={() => {
                      (window as any).gaWrite(
                        'web_click_artinfo_order_payment method ',
                        'order',
                        ''
                      );
                      setPaymentRequest({ name: 'CARD' });
                    }}
                  >
                    <img
                      src={
                        paymentRequest.name === 'CARD' ? cardOverIcon : cardIcon
                      }
                      alt='card'
                    />{' '}
                    {t('ordertitle11')}
                  </button>
                  {/* 간편결제 */}
                  <button
                    className={
                      paymentRequest.name === 'EPAY'
                        ? 'active maruBuri'
                        : 'btn maruBuri'
                    }
                    onClick={() => {
                      (window as any).gaWrite(
                        'web_click_artinfo_order_payment method ',
                        'order',
                        ''
                      );
                      setPaymentRequest({ name: 'EPAY' });
                    }}
                  >
                    <img
                      src={
                        paymentRequest.name === 'EPAY' ? payOverIcon : payIcon
                      }
                      alt='easy pay'
                    />
                    {t('ordertitle12')}
                  </button>
                  {/* 계좌이체 */}
                  <button
                    className={
                      paymentRequest.name === 'BANK'
                        ? 'active maruBuri'
                        : 'btn maruBuri'
                    }
                    onClick={() => {
                      (window as any).gaWrite(
                        'web_click_artinfo_order_payment method ',
                        'order',
                        ''
                      );
                      setPaymentRequest({ name: 'BANK' });
                    }}
                  >
                    <img
                      src={
                        paymentRequest.name === 'BANK'
                          ? accountOverIcon
                          : accountIcon
                      }
                      alt='bank'
                    />
                    {t('ordertitle13')}
                  </button>
                </div>
                {<p className='helperText'>{helperText.paymentType}</p>}
              </div>
            </div>
            <MoreWhiteBtn
              className={isOn ? 'order-btn blue' : 'order-btn blue disabled'}
              text={t('주문하기')}
              onClick={() => {
                handlePostOrder();
              }}
            />
          </div>
        </div>

        {/* 이노페이 modal */}
        {totalBodyQueryString && (
          <CustomModal
            className='artistPaymentModalWrap'
            open={isPaymentModalOpen}
            onClose={closePaymentModal}
          >
            <iframe
              src={`${url}/v1/payment/art/market?${totalBodyQueryString}`}
              style={{
                width: '100%',
                height: '100%',
              }}
            ></iframe>
          </CustomModal>
        )}

        {loading && <Loading Loading={loading} />}

        <OrderAgree isHidden={isAgreeHidden} setIsHidden={setIsAgreeHidden} />
      </div>
    </div>
  );
}

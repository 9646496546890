import { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { useParams } from 'react-router';
import { useRecoilState } from 'recoil';
import changeDate from 'helpers/changeDate';
import { NewsroomService } from './../../../services/NewsRoomService';
import { guestId } from '@stores/guest';
import './newsLetter.scss';

export default function NewsLetterDetail() {
  const navigate = useNavigate();
  const [guestIdState, setGuestIdState] = useRecoilState(guestId);
  const { id } = useParams();

  const [detailData, setDetailData] = useState({
    title: '',
    content: '',
    attach_file: '',
    created_at: 0,
  });

  useEffect(() => {
    const getOneNotice = async () => {
      if (id) {
        const data = await NewsroomService.getOneNewsLetterData(
          id,
          guestIdState
        );
        setDetailData(data);
      }
    };
    getOneNotice();
  }, [id]);
  return (
    <div className='sub-page-contents-wrap newsroom-newsLetter-wrap'>
      <div className='sub-page-contents-inner'>
        <div className='newsRoom-newsLetter-detail-wrap'>
          <div className='newsRoom-newsLetter-title-box'>
            <h4 className='maruBuri'>{detailData.title}</h4>
            <small className='sCoreDream'>
              {changeDate(detailData.created_at)}
            </small>
          </div>
          <div
            className='newsLetter-text-box sCoreDream'
            style={{ whiteSpace: 'pre-wrap' }}
          >
            {detailData.content}
            {detailData.attach_file && (
              <div className='newsRoom-content-img'>
                <img src={detailData.attach_file} alt={detailData.title} />
              </div>
            )}
          </div>
          <div className='btn-box'>
            <button onClick={() => navigate('/newsroom/newsletter')}>
              <p>목록</p>
              <div className='icon'></div>
            </button>
          </div>
        </div>
      </div>
    </div>
  );
}

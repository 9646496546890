import ModalPortal from './ModalPortal';
import ClearIcon from '@mui/icons-material/Clear';

interface ModalProps {
  isOpen: boolean;
  onClose?: () => void;
  children: React.ReactNode;
  className?: string;
}

const ModalInPortal = ({
  isOpen,
  onClose,
  children,
  className,
}: ModalProps) => {
  const handleClickInnerModal = (e: React.MouseEvent<HTMLDivElement>) => {
    // modal-wrapper로 이벤트전파방지
    e.stopPropagation();
  };

  return (
    <>
      {isOpen && (
        <ModalPortal>
          <div
            className={`modal-wrapper ${className} ${isOpen}`} /*  onClick={onClose} */
          >
            <div className='modal-contents' /* onClick={onClose} */>
              <button className='close' onClick={onClose}>
                <ClearIcon className='modal-close-btn' />
              </button>
              <div
                className='modal-contents-inner'
                onClick={handleClickInnerModal}
              >
                {children}
              </div>
            </div>
          </div>
        </ModalPortal>
      )}
    </>
  );
};

export default ModalInPortal;

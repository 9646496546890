import { httpCommunicator } from "@api/httpCommunicator";

const url = process.env.REACT_APP_API_URL;

export class CommunityService {
  static async sendClassOpenNotificationEmail(email: string, guestSessionId?: number) {
    const header = {guest_session_id: guestSessionId};
    const res = await httpCommunicator(
      `${url}/v1/class_letter/create`,
      "post",
      {
        email: email,
      },
      header
    );
    return res.data;
  }
}

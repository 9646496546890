import './faq.scss';
import { useState, useEffect } from 'react';
import { InquiryService } from './../../../../services/InquiryService';
import { Trans, useTranslation } from 'react-i18next';
import { InquiryInterface } from '@interfaces/inquiry/InquiryInterface';
import Arrow from '@assets/images/service/arrowBottom.png';
import GuideIcon from '@assets/images/guideIcon.png';
import Paging from './../../../../components/pagination/Pagination';
import { guestId } from '@stores/guest';
import { useRecoilState } from 'recoil';
export default function Faq() {
  const { t } = useTranslation();
  const [guestIdState, setGuestIdState] = useRecoilState(guestId);
  const [isMobile, setIsMobile] = useState(window.innerWidth < 789);
  const [currentPage, setCurrentPage] = useState(1);
  const [itemsPerPage] = useState(isMobile ? 5 : 10); // 페이지당 아이템 수
  const [currentData, setCurrentData] = useState([]);
  const [count, setCount] = useState(0);
  const [currentIndex, setCurrentIndex] = useState(null);

  const getFaqList = async () => {
    const data = await InquiryService.getFaqList(
      currentPage,
      itemsPerPage,
      guestIdState
    );
    if (data.count === 0) {
      setCount(1);
    } else {
      setCount(data.count);
    }
    setCurrentData(data.list);
  };

  const toggleActive = (idx: any) => {
    if (currentIndex === idx) {
      setCurrentIndex(null);
    } else {
      setCurrentIndex(idx);
    }
  };
  useEffect(() => {
    getFaqList();
    const handleResize = () => {
      setIsMobile(window.innerWidth < 789);
    };

    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, [currentPage]);

  const handlePageChange = (pageNumber: number) => {
    setCurrentPage(pageNumber);
  };

  return (
    <div className='sub-page-contents-wrap service-inquiry-faq-wrap'>
      <div className='service-inquiry-faq-inner'>
        {currentData.length > 0 ? (
          <ul className='faq-list-wrap'>
            {currentData?.map((item: InquiryInterface.Item, idx: number) => (
              <li key={idx} className={currentIndex === idx ? 'on' : ''}>
                <div
                  className='question-box'
                  onClick={() => {
                    toggleActive(idx);
                  }}
                >
                  <div className='question-left maruBuri'>
                    <span>Q</span>
                    <p>{item.question}</p>
                  </div>
                  <img
                    src={Arrow}
                    alt=''
                    className={currentIndex === idx ? 'active-arrow' : ''}
                  />
                </div>
                <div className='answer-box sCoreDream '>
                  <p>{item.answer}</p>
                </div>
              </li>
            ))}
          </ul>
        ) : (
          <div className='non-list maruBuri'>
            <div className='non-inner'>
              <img src={GuideIcon} alt='느낌표' />
              <p>{t('serviceInquiryNonFaqText')}</p>
            </div>
          </div>
        )}
        <Paging
          currentPage={currentPage}
          totalPages={Math.ceil(count / itemsPerPage)}
          onPageChange={handlePageChange}
        />
      </div>
    </div>
  );
}

import timeIcon from '@assets/images/icons/time_icon.png';
import { Trans, useTranslation } from 'react-i18next';
import './closedBox.scss';

export default function ClosedBoxDetailDate() {
  const { t } = useTranslation();

  return (
    <div className='closedBox'>
      <img src={timeIcon} alt='timer' className='timerIcon' />
      <p> {t('commonClosedBoxTitle2')}</p>
      <p className='detail-txt'>(2024.02.15 14:00 ~ 2024.03.31 24:00)</p>
    </div>
  );
}

import { useNavigate } from 'react-router-dom';
import { useLocation } from 'react-router';
import { useTranslation } from 'react-i18next';
import CommonModal from '@components/modal/CommonModal';
import logo from '@assets/images/blueLogo.png';
import cartIcon from '@assets/images/header/btn_cart@3x.png';
import myIcon from '@assets/images/header/btn_my@3x.png';
import styles from './header.module.scss';
import classNames from 'classnames/bind';
import { useState } from 'react';
import { mobileMenuTrigger } from '@stores/mobileMenuTrigger';
import { useRecoilState } from 'recoil';
import ReactGA from 'react-ga4';
import { router } from '../../../stores/router';
import { useEffect } from 'react';
import Modal from '../../../components/modal/CommonModal';
import { isMobile } from 'react-device-detect';

const cx = classNames.bind(styles);
const metaverse_art_land_url = process.env.REACT_APP_METAVERSE_ARTLAND_URL;

export default function Header() {
  const { t } = useTranslation();

  const navigate = useNavigate();
  const location = useLocation();
  const [isOn, setIsOn] = useRecoilState(mobileMenuTrigger);
  const currentPage = location.pathname.split('/')[1];
  const current2depthPage = location.pathname.split('/')[2];

  const [isModalOpen, setIsModalOpen] = useState(false);
  const [alarmText, setAlarmText] = useState('');
  const [alarmSubText, setAlarmSubText] = useState('');
  const [btnCloseText, setBtnCloseText] = useState('');
  const [routerModel, setRouterModel] = useRecoilState(router);
  const [isModalView, setIsModalView] = useState(false);
  const [isShowBtn, setIsShowBtn] = useState(false);
  const handleClick = () => {
    (window as any).gaWrite('web_click_navigation_logo', 'main', '');
    navigate('/');
    setIsOn(false);
  };
  useEffect(() => {
    if (routerModel.link != '' && !routerModel.isGuard) {
      setRouterModel((p) => ({ ...p, link: '' }));
      navigate(routerModel.link);
    } else if (routerModel.link != '' && routerModel.isGuard) {
      setIsModalView(true);
      setBtnCloseText(t('commonCancelText'));
      setIsShowBtn(true);
      document.documentElement.style.overflow = 'hidden';
    }
  }, [routerModel]);

  const [mouseHover, setMouseHover] = useState('');

  const headerLists = [
    {
      list: 'GalleryST',
      link: '/galleryst/about',
      linkMatching: '/galleryst',
      twoDepth: [{ list: t('headerTwoDepth1_1'), link: '/galleryst/about' }],
    },
    {
      list: 'Art Sales',
      link: '/art_sales/art_market',
      linkMatching: '/art_sales',
      twoDepth: [
        {
          list: t('headerTwoDepth2_1'),
          link: '/art_sales/art_market',
        },
        {
          list: t('headerTwoDepth2_2'),
          link: '/art_sales/open_market',
        },
        {
          list: t('headerTwoDepth2_3'),
          link: '/art_sales/piece_trade',
        },
        {
          list: t('headerTwoDepth2_4'),
          link: '/art_sales/group_buying',
        },
      ],
    },
    {
      list: 'Metaverse Artland',
      link: `/artland`,
      linkMatching: '/artland',
      twoDepth: [
        {
          list: t('headerTwoDepth6'),
          link: '/artland',
        },
        {
          list: t('headerTwoDepth6_2'),
          link: '/artland/guide',
        },
      ],
    },
    {
      list: 'Community',
      link: '/community/academy_info',
      linkMatching: '/community',
      twoDepth: [
        {
          list: t('headerTwoDepth3_1'),
          link: '/community/academy_info',
        },
        {
          list: t('headerTwoDepth3_2'),
          link: '/community/class_apply',
        },
      ],
    },
    {
      list: 'Newsroom',
      link: '/newsroom/notice',
      linkMatching: '/newsroom',
      twoDepth: [
        {
          list: t('headerTwoDepth4_1'),
          link: '/newsroom/notice',
        },
        {
          list: t('headerTwoDepth4_2'),
          link: '/newsroom/newsletter',
        },
      ],
    },
    {
      list: 'Service',
      link: '/service/exhibitionhall_rental',
      linkMatching: '/service',
      twoDepth: [
        {
          list: t('headerTwoDepth5_1'),
          link: '/service/exhibitionhall_rental',
        },
        {
          list: t('headerTwoDepth5_2_2'),
          link: '/service/metaverse_exhibition',
        },
        {
          list: t('headerTwoDepth5_3'),
          link: '/service/art_consulting',
        },

        {
          list: t('headerTwoDepth5_4'),
          link: '/service/inquiry',
        },
      ],
    },
  ];

  const handleClickMenu = (link: string) => {
    const gaLink = link.split('/');
    if (link == '/order_check') {
      (window as any).gaWrite('web_click_header_order', 'home', gaLink);
    }
    if (link == '/cart/') {
      (window as any).gaWrite('web_click_header_basket', 'home', gaLink);
    }

    if (link != '/cart/' && link != '/order_check') {
      (window as any).gaWrite('web_click_navagation', 'home', gaLink);
    }

    ReactGA.event({
      category: 'header_nav_' + gaLink[1] + '_' + gaLink[2] + '_btn',
      action: 'header_nav_' + gaLink[1] + '_' + gaLink[2] + '_btn',
    });

    localStorage.removeItem('activeTab');
    localStorage.removeItem('currentPage');

    switch (link) {
      /*  case '/metaverse/art_land':
        //  if (isMobile) {
        //   window.open(`${metaverse_art_land_url}/mo`);
        //   break;
        // } else {
        //   window.open(`${metaverse_art_land_url}/pc`);
        //   break;
        // }
        window.open(`${metaverse_art_land_url}`);
        break; */
      case '/art_sales/open_market':
      case '/art_sales/piece_trade':
      case '/art_sales/group_buying':
        setIsShowBtn(false);
        setIsModalOpen(true);
        setBtnCloseText(t('commonCloseText'));
        document.documentElement.style.overflow = 'hidden';
        setAlarmText('commonModalPreparingTitle');
        setAlarmSubText('coming soon');
        return;
      default:
        setRouterModel((p) => ({ ...p, link: link }));
    }
  };

  const doCloseModal = () => {
    setIsModalOpen(false);
    setIsModalView(false);
    document.documentElement.style.overflow = 'auto';
  };

  return (
    <header
      className={
        mouseHover === 'active'
          ? cx('header', 'active')
          : isOn
          ? cx('header bg-none')
          : cx('header')
      }
    >
      <div className={cx('header-inner')}>
        <div className={cx('logo')} onClick={handleClick}>
          <img src={logo} alt='gallery st logo' />
        </div>

        <ul
          className={cx('header-lists')}
          onMouseOver={() => setMouseHover('active')}
          onMouseLeave={() => setMouseHover('')}
        >
          {headerLists.map((item, idx) => (
            <li className={`maruBuri ${cx('header-lists-box')}`} key={idx}>
              <p
                onClick={() => handleClickMenu(item.link)}
                className={
                  item.linkMatching && `/${currentPage}` === item.linkMatching
                    ? cx('header-list-1depth', 'active')
                    : cx('header-list-1depth')
                }
              >
                {item.list}
              </p>
              <ul className={cx('twoDepth-lists')}>
                {/* {item.twoDepth
                    ? item.twoDepth
                        .split(',')
                        .map((depthItem, i) => <li>{depthItem}</li>)
                    : null} */}
                {item.twoDepth
                  ? item.twoDepth.map((depthItem: any, i: number) => (
                      <li
                        key={i}
                        onClick={() => handleClickMenu(depthItem.link)}
                        className={
                          location.pathname === depthItem.link
                            ? cx('twoDepth-list', 'active')
                            : cx('twoDepth-list')
                        }
                      >
                        <p
                          className={cx('twoDepth-list-text')}
                          dangerouslySetInnerHTML={{ __html: depthItem.list }}
                        ></p>
                      </li>
                    ))
                  : null}
              </ul>
            </li>
          ))}
        </ul>

        <ul className={cx('header-icon-lists')}>
          <li
            className={cx('mobile-menu-icon')}
            onClick={() => handleClickMenu('/order_check')}
          >
            <img src={myIcon} alt='my page' />
          </li>
          <li
            className={cx('mobile-menu-icon')}
            // onClick={() => handleClickMenu(`/cart/${userId}`)}
            onClick={() => handleClickMenu(`/cart/`)}
          >
            <img src={cartIcon} alt='cart' />
          </li>
          {/*   <li onClick={() => handleClickMenu('/mypage/my_collection')}>좋아요</li>
          <li>다국어지원</li> */}
        </ul>
      </div>
      <div className={cx('header-sub-inner')}></div>
      <CommonModal
        open={isModalOpen}
        isShowBtn={isShowBtn}
        btnCloseText={btnCloseText}
        onClose={() => doCloseModal()}
        alarmText={alarmText}
        alarmSubText={alarmSubText}
      />
      <Modal
        open={isModalView}
        isShowBtn={isShowBtn}
        btnCloseText={btnCloseText}
        onClose={() => doCloseModal()}
        onClick={() => {
          setIsModalView(false);
          setRouterModel((pre) => ({ ...pre, isGuard: false }));
          document.documentElement.style.overflow = 'auto';
        }}
        alarmText='orderModalAlarm'
      />
    </header>
  );
}

//errCode 컴포넌트 안에서 처리하기
/*
  const handleClickUpdateView = async () => {
    try {
      const body = {
        registration_number: values?.registration_number,
        name: values?.name,
        email: values?.email,
      };

      const data = await doSubmitArtistUpdateReceive(body);
      if (data.code === 200) {
        openForm(data.data);
      }

      if (data.code === 5002) {
        alert('작성 정보를 확인해주세요.');
      }
    } catch (err) {
      return err;
      // alert(err);
    }
  };
  */

import { useNavigate } from 'react-router';
import logo from '@assets/images/blueLogo.png';
import './policyHeader.scss';

export default function PolicyHeader() {
  const navigate = useNavigate();

  const handleClick = () => {
    (window as any).gaWrite('web_click_navigation_logo', 'main', '');
    navigate('/');
  };

  return (
    <header className='header-policy'>
      <div className='header-policy-inner'>
        <div className='logo' onClick={handleClick}>
          <img src={logo} alt='gallery st logo' />
        </div>
      </div>
    </header>
  );
}

import { useCallback, useEffect, useState } from 'react';
import { Outlet } from 'react-router';

export default function LayoutDefaultFullWrap() {
  //wrap full size
  interface ModalInnerSizeProps {
    w: number;
    h: number;
  }
  const [wrapRefState, setWrapRefState] = useState<HTMLDivElement | null>(null);
  const wrapRef = useCallback((node: HTMLDivElement) => {
    if (node !== null) {
      setWrapRefState(node);
      return;
    }
  }, []);

  const viewWidth = document.documentElement.clientWidth;
  const viewHeight = document.documentElement.clientHeight;
  const [wrapSize, setWrapSize] = useState<ModalInnerSizeProps>({
    w: viewWidth,
    h: viewHeight,
  });

  useEffect(() => {
    function reSizeWrap() {
      const viewWidth = document.documentElement.clientWidth;
      const viewHeight = document.documentElement.clientHeight;
      setWrapSize({
        w: viewWidth,
        h: viewHeight,
      });
    }

    window.addEventListener('resize', reSizeWrap);
    return () => {
      window.removeEventListener('resize', reSizeWrap);
    };
  }, [wrapRefState, wrapSize]);

  return (
    <div
      className='layout-full-contents-wrap'
      ref={(node: HTMLDivElement) => wrapRef(node)}
      style={{
        width: wrapSize?.w,
        height: wrapSize?.h,
      }}
    >
      <Outlet />
    </div>
  );
}

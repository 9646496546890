import { useEffect, useState } from 'react';
import { useForm } from '@hooks/useForm';
import Input from '@components/Input/Input';
import ClickBtn from '@components/btns/ClickBtn';
import regex from 'helpers/regex';
import { doSubmitArtistUpdateReceive } from '@services/YoungartistService';
// import { doSubmitArtistUpdateReceive } from '@services/ArtistApplyService';

export default function ArtistApplyUpdateReceive({
  openForm,
}: {
  openForm?: any;
}) {
  const { values, handleChange } = useForm({
    registration_number: '',
    name: '',
    email: '',
  });

  const [isOn, setIson] = useState(false);

  const [helperText, setHelperText] = useState({
    registration_number: '',
    name: '',
    email: '',
  });

  const handleOnChange = (
    e:
      | React.ChangeEvent<HTMLInputElement>
      | React.ChangeEvent<HTMLTextAreaElement>,
    name: string
  ) => {
    handleChange(e);

    if (name === 'registration_number') {
      if (!regex.registrationNumber.test(String(e.target.value))) {
        setHelperText((helperText) => ({
          ...helperText,
          registration_number: '정확히 숫자만 입력해주세요',
        }));
      } else {
        setHelperText((helperText) => ({
          ...helperText,
          registration_number: '',
        }));
      }
    }

    if (name === 'email') {
      if (!regex.email.test(String(e.target.value))) {
        setHelperText((helperText) => ({
          ...helperText,
          email: '이메일 형식이 올바르지 않습니다.',
        }));
      } else {
        setHelperText((helperText) => ({
          ...helperText,
          email: '',
        }));
      }
    }
  };

  useEffect(() => {
    if (
      values?.registration_number &&
      values?.name &&
      values?.email &&
      regex.registrationNumber.test(String(values?.registration_number)) &&
      regex.email.test(String(values?.email))
    ) {
      setIson(true);
    } else {
      setIson(false);
    }
  }, [values, isOn, helperText]);

  const handleClickUpdateView = async () => {
    try {
      const body = {
        registration_number: values?.registration_number,
        writer_name: values?.name,
        writer_email: values?.email,
      };

      const data = await doSubmitArtistUpdateReceive(body);
      if (data.code === 200) {
        openForm(data.data);
      } else {
        alert('작성 정보를 확인해주세요.');
      }
    } catch (err) {
      alert('작성 정보를 확인해주세요.');
    }
  };

  return (
    <div className='artistApplyUpdateReceiveContents'>
      <p className='artistApplyUpdateReceiveTitle'>
        신청서 수정, 출품 취소 등을 위한 접수 정보를 작성 후 하단 버튼을
        선택해주세요.
      </p>

      <form className='artistApplyUpdateReceiveForm'>
        <Input
          title='접수번호'
          type='text'
          name='registration_number'
          value={values?.registration_number}
          onChange={(e) => handleOnChange(e, e.target.name)}
          placeholder=''
          className='input'
        />
        {helperText.registration_number ? (
          <span className='helperText'>{helperText.registration_number}</span>
        ) : null}

        <Input
          title='이름'
          type='text'
          name='name'
          value={values?.name}
          onChange={(e) => handleOnChange(e, e.target.name)}
          placeholder=''
          className='input'
        />
        {helperText.name ? (
          <span className='helperText'>{helperText.name}</span>
        ) : null}

        <Input
          title='메일주소'
          type='text'
          name='email'
          value={values?.email}
          onChange={(e) => handleOnChange(e, e.target.name)}
          placeholder=''
          className='input'
        />
        {helperText.email ? (
          <span className='helperText'>{helperText.email}</span>
        ) : null}

        <div className={isOn === false ? 'submitBtn Err' : 'submitBtn'}>
          <ClickBtn
            text='신청서 수정하기'
            disabled={!isOn}
            className='btn'
            onClick={handleClickUpdateView}
          />
        </div>
      </form>
    </div>
  );
}

import { atom } from 'recoil';
import { recoilPersist } from 'recoil-persist';

const { persistAtom } = recoilPersist();

export const artLandLayout = atom({
  key: 'artLandLayout',
  default: false,
  /*  effects_UNSTABLE: [persistAtom], */
});

import { httpCommunicator } from "@api/httpCommunicator";

const url = process.env.REACT_APP_API_URL;

export class InquiryService {
  static async getFaqList(
    page: number,
    limit: number,
    guestSessionId?: number
  ) {
    const header = { guest_session_id: guestSessionId };
    const res = await httpCommunicator(
      `${url}/v1/op/faq/list?page=${page}&limit=${limit}`,
      "get",
      {},
      header
    );
    return res.data.data;
  }

  static async setQnaCreate(inquiry: any, guestSessionId?: number) {
    const header = { guest_session_id: guestSessionId };
    const data = {
      type: inquiry.type,
      email: inquiry.email,
      title: inquiry.title,
      content: inquiry.content,
      group: inquiry.group,
      art_info_id: inquiry.art_info_id,
    };
    const res = await httpCommunicator(
      `${url}/v1/op/qna/create`,
      "post",
      data,
      header
    );
    return res.data;
  }
}

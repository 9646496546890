import axios from 'axios';

export async function httpCommunicator(url: string, method: string, params?: any, header?: any) {
  try {
    let res;
    switch (method) {
      case 'post':
        res = await axios.post(url, params, header);
        break;
      case 'get':
        params.headers = header
        res = await axios.get(url, params);
        break;
      default:
        return null;
    }
    return res;
  } catch (err: any) {
    let errRes;
    errRes = err.response;
    if (errRes) {
      return errRes;
    } else {
      alert('관리자에게 문의해주세요.');
      return;
    }
  }
}

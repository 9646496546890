import { useState, useEffect, useRef } from 'react';
import { useLocation, useNavigate } from 'react-router';
import { Trans, useTranslation } from 'react-i18next';
import ArtlandPc from './ArtlandPc';
import ArtlandMo from './ArtlandMo';
import MoreWhiteBtn from '@components/btns/MoreWhiteBtn';
import './artland.scss';

export default function Artland() {
  const { t } = useTranslation();
  const location = useLocation();
  const navigate = useNavigate();
  const metaverse_art_land_url = process.env.REACT_APP_METAVERSE_ARTLAND_URL;
  const scrollRef = useRef<HTMLDivElement>(null);
  const [isTabPMobile, setIsTabMobile] = useState(false);

  const handleClickMore = () => {
    window.open(`${metaverse_art_land_url}`);
    (window as any).gaWrite('web_click_artland_enterbtn', 'artland', '');
  };

  const scrollInfo = () => {
    window.scrollTo({ top: 0, behavior: 'smooth' });
  };
  const scrollGuide = () => {
    if (scrollRef.current) {
      const scrollPosition = scrollRef.current.offsetTop - 60;
      window.scrollTo({
        top: scrollPosition,
        behavior: 'smooth',
      });
    }
  };

  const handleClickPc = () => {
    setIsTabMobile(false);
    (window as any).gaWrite('web_click_artland_guide_pc', 'artland', '');
  };

  const handleClickMo = () => {
    setIsTabMobile(true);
    (window as any).gaWrite('web_click_artland_guide_mo', 'artland', '');
  };

  useEffect(() => {
    const path = location.pathname;
    if (path === '/artland/guide') {
      scrollGuide();
    } else {
      scrollInfo();
    }
  }, [location]);

  return (
    <div className='galleryst-wrap artland-wrap'>
      <div className='galleryst-inner artland-inner'>
        <div className='galleryst-content-wrap galleryst-content-about-wrap artland-about'>
          <div className='galleryst-content-about-inner'>
            <div className='galleryst-content-title-wrap'>
              <p className='galleryst-content-title-sub pretendard'>about</p>
              <h3 className='galleryst-content-title-big maruBuri'>Artland</h3>
            </div>

            <div className='galleryst-content-text galleryst-content-about-text sCoreDream'>
              <Trans i18nKey={`lnbArtlandDes1`} components={[<span />]} />
            </div>

            <MoreWhiteBtn
              className='main-content-more-btn'
              text={t('lnbArtlandBtn')}
              onClick={handleClickMore}
            />
          </div>
        </div>

        <div className='artland-content-wrap' ref={scrollRef}>
          <div className='artland-content-title-wrap'>
            <h2 className='artland-content-title-big maruBuri'>
              {t('lnbArtlandTitle')}
            </h2>
            <p className='sCoreDream'>{t('lnbArtlandDes2')}</p>
          </div>

          <div className='btn-box maruBuri'>
            <button
              className={!isTabPMobile ? 'tab-btn active' : 'tab-btn'}
              onClick={handleClickPc}
            >
              PC
            </button>
            <button
              className={isTabPMobile ? 'tab-btn active' : 'tab-btn'}
              onClick={handleClickMo}
            >
              Mobile
            </button>
          </div>

          {!isTabPMobile ? <ArtlandPc /> : <ArtlandMo />}
        </div>
      </div>
    </div>
  );
}

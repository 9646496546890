import './notice.scss';
import { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { useParams } from 'react-router';
import changeDate from 'helpers/changeDate';

import { NewsroomService } from './../../../services/NewsRoomService';
import DownloadIcon from '@mui/icons-material/Download';
import { useRecoilState } from 'recoil';
import { guestId } from '@stores/guest';
export default function NoticeDetail() {
  const navigate = useNavigate();
  const [guestIdState, setGuestIdState] = useRecoilState(guestId);
  const { id } = useParams();

  const [detailData, setDetailData] = useState({
    title: '',
    content: '',
    content_url: '',
    link_type: '',
    link: '',
    link_btn_name: '',
    created_at: 0,
  });

  useEffect(() => {
    const getOneNotice = async () => {
      if (id) {
        const data = await NewsroomService.getOneNoticeData(
          Number(id),
          guestIdState
        );
        setDetailData(data);
      }
    };
    getOneNotice();
  }, [id]);

  const linkOrDownloadBtn = (type: string, link: string, name: string) => {
    if (type === 'download') {
      const linkBtn = document.createElement('a');
      linkBtn.href = link;
      linkBtn.download = name;
      linkBtn.click();
    } else if (
      (type !== 'download' && link.search('https') !== -1) ||
      link.search('http') !== -1
    ) {
      window.open(link);
    } else {
      navigate(link);
    }
  };

  return (
    <div className='sub-page-contents-wrap newsroom-notice-wrap'>
      <div className='sub-page-contents-inner'>
        <div className='newsRoom-notice-detail-wrap'>
          <div className='newsRoom-notice-title-box'>
            <h4 className='maruBuri'>{detailData.title}</h4>
            <small className='sCoreDream'>
              {changeDate(detailData.created_at)}
            </small>
          </div>
          <div
            className='notice-text-box sCoreDream'
            style={{ whiteSpace: 'pre-wrap' }}
          >
            {detailData.content}
            {detailData.content_url ? (
              <div className='newsRoom-detail-img-wrap'>
                <img src={detailData.content_url} alt={detailData.title} />
              </div>
            ) : null}
            {detailData.link_btn_name !== '' ||
            detailData.link_btn_name === null ? (
              <div
                className={`newsRoom-detail-btn-box ${
                  detailData.link_btn_name === '' ? 'none' : ''
                }`}
              >
                <button
                  onClick={() =>
                    linkOrDownloadBtn(
                      detailData.link_type,
                      detailData.link,
                      detailData.link_btn_name
                    )
                  }
                >
                  {detailData.link_btn_name}
                  {detailData.link_type === 'download' && (
                    <DownloadIcon className='download-icon' />
                  )}
                  <a
                    href={detailData.link}
                    download
                    className='pointer-event-true'
                  ></a>
                </button>
              </div>
            ) : null}
          </div>
          <div className='btn-box'>
            <button onClick={() => navigate('/newsroom/notice')}>
              <p>목록</p>
              <div className='icon'></div>
            </button>
          </div>
        </div>
      </div>
    </div>
  );
}

import { useNavigate } from "react-router";
import "./inquiry.scss";
import { useState, useEffect } from "react";
import FAQ from "./Faq/index";
import QNA from "./Qna/index";
export default function Inquiry() {
  const navigate = useNavigate();

  const [activeTab, setActiveTab] = useState(
    localStorage.getItem("activeTab") || "qna"
  );
  const handleClickTab = (type: string) => {
    setActiveTab(type);
    localStorage.setItem("activeTab", type);
  };

  useEffect(() => {
    const getActiveTab = localStorage.getItem("activeTab");
    if (getActiveTab) {
      setActiveTab(getActiveTab);
    }
  }, []);

  return (
    <div className="sub-page-contents-wrap service-inquiry-wrap">
      <div className="sub-page-contents-inner service-inquiry-inner">
        <div className="tab-wrap pretendard">
          <div
            onClick={() => {
              (window as any).gaWrite("web_click_service_3depth_Q&A","service","");
              handleClickTab("qna");
            }}
            className={activeTab === "qna" ? "active" : ""}
          >
            1:1 문의
          </div>
          <div
            onClick={() => {
              (window as any).gaWrite("web_click_service_3depth_F&Q","service","");
              handleClickTab("faq");
            }}
            className={activeTab === "faq" ? "active" : ""}
          >
            자주 묻는 질문
          </div>
        </div>
        <div>
          {activeTab === "qna" && (
            <div className="service-inquiry-tab-qna-wrap">
              <QNA />
            </div>
          )}
          {activeTab === "faq" && (
            <div className="service-inquiry-tab-faq-wrap">
              <FAQ />
            </div>
          )}
        </div>
      </div>
    </div>
  );
}

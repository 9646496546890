import { httpCommunicator } from '@api/httpCommunicator';

const url = process.env.REACT_APP_API_URL;

export async function postCreateOrder(values: any) {
  const res = await httpCommunicator(
    `${url}/v1/event/josun_hotel/apply/create`,
    'post',
    values,
    {}
  );
  return res.data;
}

export async function getTicketInfo(qr: string) {
  const res = await httpCommunicator(
    `${url}/v1/event/josun_hotel/get_qr_info?qr_data=${qr}`,
    'get',
    {}
  );
  return res.data.data;
}

export async function getBuyerInfo(name: string, phone: string, email: string) {
  const res = await httpCommunicator(
    `${url}/v1/event/josun_hotel/get_order_list?name=${name}&phone=${phone}&email=${email}`,
    'get',
    {}
  );
  return res.data;
}

export async function getTicketAmount(values: any) {
  const res = await httpCommunicator(
    `${url}/v1/event/josun_hotel/get_default_amount`,
    'get',
    {
      params: values,
    }
  );
  return res.data.data;
}

export async function postTicketDiscountCode(values: any) {
  const res = await httpCommunicator(
    `${url}/v1/event/josun_hotel/sale/get_one_by_code`,
    'post',
    values,
    {}
  );
  return res.data;
}

export async function getRefundTicketPrice(ids: number[]) {
  const res = await httpCommunicator(
    `${url}/v1/event/josun_hotel/cancel_money`,
    'post',
    { ids: ids }
  );
  return res.data;
}

export async function setTicketCancel(ids: number[]) {
  const res = await httpCommunicator(
    `${url}/v1/event/josun_hotel/do_cancel_apply`,
    'post',
    { ids: ids }
  );
  return res.data;
}

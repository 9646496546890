import { useOutletContext } from 'react-router';
import { PolicyInterface } from '@interfaces/policy/policy';
import './policy.scss';

export default function Policy() {
  interface OutletContext {
    content: PolicyInterface.PolicyContentsInterface;
  }

  const { content }: OutletContext = useOutletContext();

  return (
    <div className='policy-wrap sCoreDream'>
      <h3 className='policy-title'>{content?.name !== '' && content?.name}</h3>

      {content?.value !== '' && (
        <div
          className='policy-contents'
          dangerouslySetInnerHTML={{ __html: content?.value }}
        ></div>
      )}
    </div>
  );
}

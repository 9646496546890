import { useEffect, useState } from "react";
import { useRecoilState } from "recoil";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router";
import Box from "@mui/material/Box";
import ReactGA from "react-ga4";
import { isMobile, isBrowser, isAndroid, isIOS } from "react-device-detect";
import axios from "axios";
import AOS from "aos";

import FloatingCircle from "@components/floating/FloatingCircle";
import CommonBorderWhiteBtn from "@components/btns/CommonBorderWhiteBtn";
import CustomModalClearBtn from "@components/modal/CustomModalClearBtn";
import ModalInPortal from "@components/modal/ModalInPortal";
import MainBanner from "./MainBanner";
import MainAboutUs from "./MainAboutUs";
import MainExhibition from "./MainExhibition";
import MainCommunity from "./MainCommunity";
import MainNewsRoom from "./MainNewsRoom";
import MainService from "./MainService";
import MainArtMarket from "./MainArtMarket";
import { getMainPopUp } from "@services/PopUpService";
import { guestId, guestSession } from "@stores/guest";
import { popUp } from "@stores/popUp";
import { mobileMenuTrigger } from "@stores/mobileMenuTrigger";
import { artLandLayout } from "@stores/artLand";
import kArtistApplyResult from "@assets/images/popUp/2023_10_18_k_artist_prize.jpg";
import "./mainWrap.scss";

export default function MainWrap() {
  const [guestIdState, setGuestIdState] = useRecoilState(guestId);
  //아트랜드 layout적용 (reset)
  const [artLandLayoutState, setArtLandLayoutState] =
    useRecoilState(artLandLayout);
  const { t } = useTranslation();
  const navigate = useNavigate();

  //메인 팝업
  const [recoilPopUp, setRecoilPopUp] = useRecoilState(popUp);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [popUpData, setPopUpData] = useState<any>("");

  const openModal = (modalContents?: string) => {
    if (popUpData) {
      // document.documentElement.style.overflow = 'hidden';
      if (popUpData.length > 0) {
        setIsModalOpen(true);
        document.documentElement.style.overflow = "hidden";
      }
      if (popUpData.length > 1) {
        setViewModalPopUpTwo(true);
      }
    } else {
      // document.documentElement.style.overflow = 'auto';
      setIsModalOpen(false);
      setViewModalPopUpTwo(false);
    }

    // setFloatingModalContents(modalContents);
  };

  const closeModal = () => {
    setIsModalOpen(false);
    setRecoilPopUp(true);
    // document.documentElement.style.overflow = 'auto';

    //ga (팝업 바뀔시 변경)/티켓구매하기
    (window as any).gaWrite(
      "web_click_main_popup_hotelartfair_close",
      "home",
      ""
    );
  };

  const getPopUp = async () => {
    try {
      const data = await getMainPopUp(guestIdState);

      setPopUpData(data.data.list);
    } catch (err) {
      console.log(err);
    }
  };

  const handleClickMore = (
    number: number,
    ga: string,
    discountCode?: number | string
  ) => {
    //ga (팝업 바뀔시 변경)
    (window as any).gaWrite(ga, "home", "");

    setRecoilPopUp(true);
    document.documentElement.style.overflow = "auto";

    if (discountCode) {
      navigate(popUpData[number]?.link_pc, {
        state: { discountCode: discountCode },
      });
    } else {
      windowOpenLink(popUpData[number]?.link_pc);
    }
  };

  const windowOpenLink = (link: string) => {
    if (link.indexOf("http") !== -1 || link.indexOf("https") !== -1) {
      window.open(link);
    } else {
      navigate(link);
    }
  };

  ////////////
  /* const [viewModalPopUpOne, setViewModalPopUpOne] = useState(true); */
  const [viewModalPopUpTwo, setViewModalPopUpTwo] = useState(false);
  /*  const handleClickViewModalPopUpOne = () => {
    setViewModalPopUpOne(true);
  };
  const handleClickCloseModalPopUpOne = () => {
    setViewModalPopUpOne(false);
    if (!viewModalPopUpTwo) {
      document.documentElement.style.overflow = 'auto';
    }
  }; */
  const handleClickCloseModalPopUpTwo = () => {
    (window as any).gaWrite(
      "web_click_main_popup_hotelartfair_discount_code_close",
      "home",
      ""
    );

    setViewModalPopUpTwo(false);
    if (!isModalOpen) {
      document.documentElement.style.overflow = "auto";
    }
  };

  //////////////
  useEffect(() => {
    AOS.init();

    //아트마켓 페이지 리셋
    localStorage.removeItem("currentPage");
    //아트랜드 layout 리셋
    setArtLandLayoutState(false);
  }, []);

  useEffect(() => {
    (async () => {
      await getPopUp();
      // openModal();
      if (recoilPopUp) {
        //한번 팝업 닫으면 다시 안뜨게 설정
        setIsModalOpen(false);
        setViewModalPopUpTwo(false);
        document.documentElement.style.overflow = "auto";
        return;
      }
    })();
  }, []);

  useEffect(() => {
    if (!recoilPopUp) {
      openModal();
    } else {
      return;
    }
  }, [popUpData]);

  //main진입시 hidden안되도록 설정
  const [isOn, setIsOn] = useRecoilState(mobileMenuTrigger);
  useEffect(() => {
    if (!isOn) {
      document.documentElement.style.overflow = "auto";
    } else {
      document.documentElement.style.overflow = "hidden";
    }
  }, [isOn]);

  //메인 팝업 최상단
  useEffect(() => {
    const modalRoot = document.getElementById("modal") as HTMLElement;
    if (!isModalOpen && !viewModalPopUpTwo) {
      modalRoot.style.display = "none";
      document.documentElement.style.overflow = "auto";
    } else {
      modalRoot.style.display = "block";
    }

    if (isModalOpen && viewModalPopUpTwo) {
      document.documentElement.style.overflow = "hidden";
    }
  }, [isModalOpen, viewModalPopUpTwo]);

  return (
    <div className="main-wrap">
      <MainBanner />
      <MainAboutUs />
      <MainArtMarket />
      <MainExhibition />
      <MainCommunity />
      <MainNewsRoom />
      <MainService />

      <div className="floating-Wrap">
        {/*   <FloatingCircle
          img={floatingimgApply}
          alt='k artist 지원'
          className='floating'
          onClick={handleClickArtistApply}
        /> */}
      </div>

      {/* <CustomModalClearBtn
        className='floatingModal main-popUp-modal'
        open={isModalOpen}
        onClose={closeModal}
        backdropOnClose={() => setIsModalOpen(true)} //배경클릭시 닫힘 막기
      >
        <Box>
          <div
            style={{
              background: `#fff `,
            }}
            className='modalBox'
          >
            <img
              src={popUpData?.image}
              alt='2023 k-artist-prize 공모전 발표'
              className='kArtist-apply-result'
            />

            <CommonBorderWhiteBtn
              className={'popUp-btn blue'}
              text={t('mainPopUpBtn2')}
              onClick={handleClickMore}
            />
          </div>
        </Box>
      </CustomModalClearBtn> */}

      {/* 티켓팅 */}
      {popUpData.length > 1 ? (
        <>
          <ModalInPortal
            isOpen={isModalOpen}
            onClose={closeModal}
            className="modal-in-portal modal-in-portal-one main-popUp-modal"
          >
            <div className="modal-in-portal-img-box">
              <div className="modal-in-portal-img-inner">
                <img
                  src={popUpData[1]?.image}
                  alt={popUpData[1]?.name}
                  className="kArtist-apply-result"
                />
                {popUpData[1]?.content !== "" && (
                  <div className="discount-number-wrap">
                    할인코드 : {popUpData[1]?.content}
                  </div>
                )}
              </div>
              {popUpData[1]?.link_pc === "" ||
              popUpData[1]?.link_pc === null ? null : (
                <CommonBorderWhiteBtn
                  className={"popUp-btn blue"}
                  text={popUpData[1]?.btn_name}
                  onClick={() =>
                    handleClickMore(1, "web_click_main_popup_hotelartfair")
                  }
                />
              )}
            </div>
          </ModalInPortal>

          <ModalInPortal
            isOpen={viewModalPopUpTwo}
            onClose={handleClickCloseModalPopUpTwo}
            className="modal-in-portal modal-in-portal-two"
          >
            <div className="modal-in-portal-img-box">
              <div className="modal-in-portal-img-inner">
                <img
                  src={popUpData[0]?.image}
                  alt={popUpData[0]?.name}
                  className="kArtist-apply-result"
                />
                {popUpData[0]?.content !== "" && (
                  <div className="discount-number-wrap">
                    할인코드 : {popUpData[0]?.content}
                  </div>
                )}
              </div>
              {popUpData[0]?.link_pc === "" ||
              popUpData[0]?.link_pc === null ? null : (
                <CommonBorderWhiteBtn
                  className={"popUp-btn blue"}
                  text={popUpData[0]?.btn_name}
                  onClick={() =>
                    handleClickMore(
                      0,
                      "web_click_main_popup_hotelartfair_discount_code",
                      popUpData[0]?.content
                    )
                  }
                />
              )}
            </div>
          </ModalInPortal>
        </>
      ) : (
        <ModalInPortal
          isOpen={isModalOpen}
          onClose={closeModal}
          className="modal-in-portal modal-in-portal-one main-popUp-modal modal-in-portal-only-one"
        >
          <div className="modal-in-portal-img-box">
            <div className="modal-in-portal-img-inner">
              <img
                src={popUpData[0]?.image}
                alt={popUpData[0]?.name}
                className="kArtist-apply-result"
              />
              {popUpData[0]?.content !== "" && (
                <div className="discount-number-wrap">
                  할인코드 : {popUpData[0]?.content}
                </div>
              )}
            </div>
            {popUpData[0]?.link_pc === "" ||
            popUpData[0]?.link_pc === null ? null : (
              <CommonBorderWhiteBtn
                className={"popUp-btn blue"}
                text={popUpData[0]?.btn_name}
                onClick={() =>
                  handleClickMore(0, "web_click_main_popup_hotelartfair")
                }
              />
            )}
          </div>
        </ModalInPortal>
      )}

      <div id="modal"></div>
    </div>
  );
}

import { useState } from 'react';
import { BtnInterface } from '@interfaces/components/ButtonInterface';
import moreBtn from '@assets/images/btn/btn_more_blue@3x.png';
import moreBtnOver from '@assets/images/btn/btn_more_blue@3x_over.png';

export default function CommonBorderWhiteBtn({
  text,
  onClick,
  className,
}: BtnInterface.ClickBtnInterface) {
  const [mouseHover, setMouseHover] = useState('');

  return (
    <button
      type='button'
      onClick={onClick}
      className={`common-border-white-btn maruBuri ${className} ${mouseHover}`}
      onMouseOver={() => setMouseHover('active')}
      onMouseLeave={() => setMouseHover('')}
    >
      {text}
    </button>
  );
}

import { httpCommunicator } from '@api/httpCommunicator';

const url = process.env.REACT_APP_API_URL;

/* export async function getArtMarketLists(values: any) {
  const res = await httpCommunicator(`${url}/v1/art/list`, 'get', {
    params: values,
  });
  return res.data;
} */

export async function postCreateGuest(values: any) {
  const res = await httpCommunicator(
    `${url}/v1/art/guest/create`,
    'post',
    values,
    {}
  );
  return res.data;
}

export async function getGuestId(values: any) {
  const res = await httpCommunicator(
    `${url}/v1/art/guest/get_one`,
    'post',
    values,
    {}
  );
  return res.data;
}

import { useState, useEffect } from 'react';
import { useLocation } from 'react-router';
import { useNavigate } from 'react-router-dom';
import { Trans, useTranslation } from 'react-i18next';
import './subMenuBanner.scss';

import CommonModal from '@components/modal/CommonModal';

export default function Lnb() {
  const location = useLocation();
  const { t } = useTranslation();
  const navigate = useNavigate();

  const [isModalOpen, setIsModalOpen] = useState(false);
  const [alarmText, setAlarmText] = useState('');
  const [alarmSubText, setAlarmSubText] = useState('');

  const [isMobile, setIsMobile] = useState(window.innerWidth < 789);

  interface MenuItem {
    label: string;
    link: string;
    title?: string;
    des?: string;
  }

  interface MenuData {
    [key: string]: MenuItem[];
  }

  const menuLists: MenuData = {
    art_sales: [
      {
        label: t('headerTwoDepth2_1'),
        link: '/art_sales/art_market',
        title: t('lnbArtSalesArtMarketTitle'),
        des: t('lnbArtSalesArtMarketDes'),
      },
      { label: t('headerTwoDepth2_2'), link: '' },
      { label: t('headerTwoDepth2_3'), link: '' },
      { label: t('headerTwoDepth2_4'), link: '' },
    ],
    /*   artland: [
      {
        label: t('headerTwoDepth6'),
        link: '/artland',
        title: t('headerTwoDepth6'),
        des: t('lnbArtlandDes'),
      },
      {
        label: t('headerTwoDepth6'),
        link: '/artland',
        title: t('lnbArtlandTitle'),
        des: t('lnbArtlandDes'),
      },
    ], */
    community: [
      {
        label: t('headerTwoDepth3_1'),
        link: '/community/academy_info',
        title: t('lnbCommunityAcademyInfoTitle'),
        des: t('lnbCommunityAcademyInfoDes'),
      },
      {
        label: t('headerTwoDepth3_2'),
        link: '/community/class_apply',
        title: t('lnbCommunityAcademyInfoTitle'),
        des: t('lnbCommunityAcademyInfoDes'),
      },
    ],
    newsroom: [
      {
        label: t('headerTwoDepth4_1'),
        link: '/newsroom/notice',
        title: t('lnbNewsRoomNewsNoticeTitle'),
        des: t('lnbNewsRoomNewsNoticeDes'),
      },
      {
        label: t('headerTwoDepth4_2'),
        link: '/newsroom/newsletter',
        title: t('lnbNewsRoomNewsletterTitle'),
        des: t('lnbNewsRoomNewsletterDes'),
      },
    ],
    service: [
      {
        label: t('headerTwoDepth5_1'),
        link: '/service/exhibitionhall_rental',
        title: t('lnbServiceExhibitionHallTitle'),
        des: t('lnbServiceExhibitionHallDes'),
      },
      {
        label: t('headerTwoDepth5_2_2'),
        link: '/service/metaverse_exhibition',
        title: t('lnbServiceMetaverseTitle'),
        des: t('lnbServiceMetaverseDes'),
      },
      {
        label: t('headerTwoDepth5_3'),
        link: '/service/art_consulting',
        title: t('lnbServiceArtConsultingTitle'),
        des: t('lnbServiceArtConsultingDes'),
      },
      {
        label: t('headerTwoDepth5_4'),
        link: '/service/inquiry',
        title: t('lnbServiceInquiryTitle'),
        des: t('lnbServiceInquiryDes'),
      },
    ],
  };

  const currentPage = location.pathname.split('/')[1];
  const current2depthPage = location.pathname.split('/')[2];
  const currentMenu = menuLists[currentPage] || [];

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth < 789);
    };

    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);
  const changeSubMainTitle = () => {
    let pcImg = '';
    let mobileImg = '';
    let title = '';
    if (currentPage === 'art_sales') {
      pcImg = 'artSales.jpg';
      mobileImg = 'artSalesMobile.jpg';
      title = 'art sales';
    } else if (currentPage === 'community') {
      pcImg = 'community.png';
      mobileImg = 'communityMobile.jpg';
      title = 'community';
    } else if (currentPage === 'newsroom') {
      pcImg = 'newsRoom.png';
      mobileImg = 'newsroomMobile.jpg';
      title = 'newsroom';
    } else if (currentPage === 'service') {
      pcImg = 'service.png';
      mobileImg = 'serviceMobile.jpg';
      title = 'service';
    }
    return { pcImg, mobileImg, title };
  };
  const { pcImg, mobileImg, title } = changeSubMainTitle();

  const handleClickLink = (link: string) => {
    if (link == '/service/exhibitionhall_rental') {
      (window as any).gaWrite(
        'web_click_service_2depth_rent_showroom ',
        'service',
        ''
      );
    }
    if (link == '/service/metaverse_exhibition') {
      (window as any).gaWrite(
        'web_click_service_2depth_created_metaverse_showroom ',
        'service',
        ''
      );
    }
    if (link == '/service/art_consulting') {
      (window as any).gaWrite(
        'web_click_service_2depth_art_consulting ',
        'service',
        ''
      );
    }
    if (link == '/service/inquiry') {
      (window as any).gaWrite(
        'web_click_service_2depth_question_and partnerships ',
        'service',
        ''
      );
    }

    if (link) {
      navigate(link);
    } else {
      setIsModalOpen(true);
      document.documentElement.style.overflow = 'hidden';
      setAlarmText('commonModalPreparingTitle');
      setAlarmSubText('coming soon');
    }
  };

  const doCloseModal = () => {
    setIsModalOpen(false);
    document.documentElement.style.overflow = 'auto';
  };

  const subMainStyle = {
    background: isMobile
      ? `url(/images/subPageMain/${mobileImg}) center center / cover no-repeat`
      : `url(/images/subPageMain/${pcImg}) center center / cover no-repeat`,
  };

  return (
    <div className='subMenuBanner-wrap'>
      <div className={`subMenuBanner-inner`}>
        <div className='subMenuBanner-menu-wrap' style={subMainStyle}>
          <div className='subMenuBanner-btn-wrap maruBuri'>
            {currentMenu?.map((item) => (
              <button
                key={item.label}
                className={
                  `/${currentPage}/${current2depthPage}` === item.link
                    ? 'btn-lists-2depth active'
                    : 'btn-lists-2depth'
                }
                onClick={() => handleClickLink(item.link)}
              >
                <p>{item.label}</p>
              </button>
            ))}
          </div>
          <h2 className='banner-title pretendard'>{title}</h2>
        </div>
        <div className='subMenuBanner-title-wrap'>
          {currentMenu?.map((titleItem, i) =>
            titleItem.des && location.pathname === titleItem.link ? (
              <div key={titleItem.label}>
                <h2
                  className='maruBuri'
                  dangerouslySetInnerHTML={{ __html: String(titleItem.title) }}
                ></h2>

                <p className='sCoreDream'>
                  <Trans i18nKey={titleItem.des} components={[<span />]} />
                </p>
              </div>
            ) : null
          )}
        </div>
      </div>
      <CommonModal
        open={isModalOpen}
        isShowBtn={false}
        btnCloseText={t('commonCloseText')}
        onClose={() => doCloseModal()}
        alarmText={alarmText}
        alarmSubText={alarmSubText}
      />
    </div>
  );
}

import { Trans, useTranslation } from 'react-i18next';
import Success from '@components/success/Success';
import { useLocation, useNavigate } from 'react-router';
import { useRecoilState } from 'recoil';
import { artLandLayout } from '@stores/artLand';

export default function ArtMarketOrderSheetSuccess() {
  const { t } = useTranslation();

  const location = useLocation();
  const locationState = location.state as {
    orderNumber: number;
    created: number;
  };
  const navigate = useNavigate();

  const [artLandLayoutState, setArtLandLayoutState] =
    useRecoilState(artLandLayout);

  return (
    <div>
      <Success
        bigTitle={t('orderSuccess3')}
        orderNumber={locationState?.orderNumber}
        created={locationState?.created}
        btnText={t('orderSuccess6')}
        btnLink={'/order_check'}
      >
        {!artLandLayoutState && (
          <p className='success-bottom-txt'>
            <strong onClick={() => navigate('/order_check')}>
              {t('orderSuccess1')}
            </strong>
            {t('orderSuccess2')}
          </p>
        )}
      </Success>
    </div>
  );
}

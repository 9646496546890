import { useEffect, useRef, useState } from 'react';
import VideoCameraBackIcon from '@mui/icons-material/VideoCameraBack';
import ClearRoundedIcon from '@mui/icons-material/ClearRounded';
import { ArtistApplyInterface } from '@interfaces/youngartist/ArtistApplyInterface';
import formatNumberCommas from 'helpers/formatNumberCommas';
import Loading from '@components/Loading/Loading';
import FileUploadIcon from '@mui/icons-material/FileUpload';
import InsertDriveFileIcon from '@mui/icons-material/InsertDriveFile';

export default function ArtistApplyFilePortfolio({
  fileList,
  setFileList,
  initialTotalFilesSize,
  defaultFilesLength, //기존 업로드 파일 갯수
}: ArtistApplyInterface.ArtistApplyFileProps) {
  const fileRef = useRef<HTMLInputElement>(null);

  const [selectedFile, setSelectedFile] = useState<File | null>(null);
  const [totalFilesSize, setTotalFilesSize] = useState<number>(
    initialTotalFilesSize
  );
  const [loading, setLoading] = useState(false);

  //파일 총 제한 용량
  const FILE_SIZE_MAX_LIMIT = 100 * 1024 * 1024;
  // 최대 파일 갯수
  const MAX_FILE_COUNT = 2;

  //quicktime > mov 텍스트 변환
  function formatFileExtension(extension: string) {
    if (extension === 'quicktime') {
      return 'mov';
    }
    return extension;
  }

  const handleClickFile = () => {
    fileRef?.current?.click();
  };

  const handleChangeFile = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (!e.target.files) {
      return;
    }

    const files = e.target.files?.[0];
    const fileType = files.type;

    // 새로운 파일 적용시 총 파일 용량 계산
    /*  const newTotalFilesSize = totalFilesSize + files.size;

    if (newTotalFilesSize > FILE_SIZE_MAX_LIMIT) {
      alert(
        `파일 용량이 초과되었습니다.\n제한 용량 : 총 ${formatNumberCommas(
          FILE_SIZE_MAX_LIMIT / (1024 * 1024)
        )}MB`
      );
      e.target.value = '';
      return;
    } */

    //TODO:제한 조건 변경시 사용
    //길이 용량제한
    //기존 업로드된 파일이 있는 경우
    if (defaultFilesLength) {
      if (fileList.length + defaultFilesLength >= MAX_FILE_COUNT) {
        alert(`최대 ${MAX_FILE_COUNT}개의 파일까지 업로드할 수 있습니다.`);
        e.target.value = '';
        return;
      }
    } else {
      if (fileList.length >= MAX_FILE_COUNT) {
        alert(`최대 ${MAX_FILE_COUNT}개의 파일까지 업로드할 수 있습니다.`);
        e.target.value = '';
        return;
      }
    }
    /* else if (fileList.length >= MAX_FILE_COUNT) {
      ;defaultFilesLength;
    } */

    //각 파일 용량제한
    //  if (files.size /* 5242881 */ > /* 27930099 */ FILE_SIZE_MAX_LIMIT)
    if (files.size > FILE_SIZE_MAX_LIMIT) {
      alert(
        `파일 용량이 제한을 초과하였습니다.\n최대 용량 : ${formatNumberCommas(
          FILE_SIZE_MAX_LIMIT / (1024 * 1024)
        )}MB`
      );
      e.target.value = '';
      return;
    }

    try {
      setLoading(true);
      /*  await new Promise((resolve) => setTimeout(resolve, 2000)); */
      setSelectedFile(files);
      setFileList([...fileList, files]);
      e.target.value = '';
    } catch (err) {
      console.log(err);
    } finally {
      setLoading(false);
    }
  };

  const handleClickDelete = (fileToDelete: File) => {
    const updateFileList = fileList.filter((file) => file !== fileToDelete);
    setFileList(updateFileList);
  };

  useEffect(() => {
    if (loading) {
      document.documentElement.style.overflow = 'hidden';
    } else {
      document.documentElement.style.overflow = 'auto';
    }
  }, [loading]);

  //총 파일 용량 구하기
  /*   useEffect(() => {
    setTotalFilesSize(
      fileList.reduce((acc, file) => acc + file.size, initialTotalFilesSize)
    );
  }, [fileList, totalFilesSize, initialTotalFilesSize]); */

  return (
    <div className='artistApplyItemWrap artistApplyFileWrap'>
      <div className='artistApplyFileDescription'>
        <p className='inputTitle option'>포트폴리오</p>
        <p className='artistApplyTxt'>
          1. 활동 이력 및 작품세계를 보여줄 수 있는 작가노트 혹은 포트폴리오
          제출(파일형식 제한 없음)
        </p>
        <p className='artistApplyTxt'>
          2. 파일용량 :{' '}
          {formatNumberCommas(FILE_SIZE_MAX_LIMIT / (1024 * 1024))}
          MB 이내
        </p>
      </div>

      <div className='artistApplyFileUploadtext' onClick={handleClickFile}>
        파일 추가하기 <FileUploadIcon />
      </div>
      <input
        ref={fileRef}
        type='file'
        multiple
        id='inputFile'
        onChange={handleChangeFile}
      />
      <div
        className={
          fileList.length > 0
            ? 'artistApplyFileListsWrapView artistApplyFileListsWrap'
            : 'artistApplyFileListsWrap'
        }
      >
        {fileList.map((file, index) => (
          <div className='artistApplyFileUploadWrap' key={index}>
            <div className='artistApplyFileUploadThumbnail'>
              {file.type.startsWith('image/') ? (
                <div className='fileImg'>
                  <img src={URL.createObjectURL(file)} alt={file.name} />
                  <div
                    className='fileDeleteIcon'
                    onClick={() => handleClickDelete(file)}
                  >
                    <ClearRoundedIcon />
                  </div>
                </div>
              ) : file.type.startsWith('video/') ? (
                <div className='fileImg fileIcon'>
                  <VideoCameraBackIcon />
                  <div
                    className='fileDeleteIcon'
                    onClick={() => handleClickDelete(file)}
                  >
                    <ClearRoundedIcon />
                  </div>
                </div>
              ) : (
                <div className='fileImg fileIcon'>
                  <InsertDriveFileIcon />
                  <div
                    className='fileDeleteIcon'
                    onClick={() => handleClickDelete(file)}
                  >
                    <ClearRoundedIcon />
                  </div>
                </div>
              )}
            </div>
            <div className='artistApplyFileName'>{file.name}</div>
            <div>{(file.size / (1024 * 1024)).toFixed(3)}MB</div>
          </div>
        ))}
      </div>
      {loading && <Loading Loading={loading} />}
    </div>
  );
}

import timeIcon from '@assets/images/icons/time_icon.png';
import { Trans, useTranslation } from 'react-i18next';
import './closedBox.scss';

export default function ClosedBoxNotDate() {
  const { t } = useTranslation();

  return (
    <div className='closedBox'>
      <img src={timeIcon} alt='timer' className='timerIcon' />
      <p> {t('commonClosedBoxTitle2')}</p>
    </div>
  );
}

import { httpCommunicator } from '@api/httpCommunicator';

const url = process.env.REACT_APP_API_URL;

export async function getArtFairDate(values: string) {
  const res = await httpCommunicator(
    `${url}/v1/art_fair/check/date`,
    'post',
    {
      key: values,
    },
    {}
  );
  return res.data;
}
